import * as types from "./../constants/signup";

export const apiSessionKeyRequest = () => ({
    type: types.API_REQUEST_SESSION_KEY,
});

// {data: {session_key: ''}} = response
export const apiSessionKeyRequestSuccess = (response) => ({
    type: types.API_REQUEST_SUCCESS_SESSION_KEY,
    response,
});

// response is an error object
export const apiSessionKeyRequestFailed = (response) => ({
    type: types.API_REQUEST_FAILED_SESSION_KEY,
    response,
});

// fetch questionnaire
export const apiSignUpQuestionnaireRequest = () => ({
    type: types.API_REQUEST_SIGNUP_PROFILING_QUESTIONNAIRE,
});

// response
export const apiSignUpQuestionnaireRequestFailed = (response) => ({
    type: types.API_REQUEST_FAILED_SIGNUP_PROFILING_QUESTIONNAIRE,
    response,
});

// response
export const apiSignUpQuestionnaireRequestSuccess = (response) => ({
    type: types.API_REQUEST_SUCCESS_SIGNUP_PROFILING_QUESTIONNAIRE,
    response,
});

// fetch questionnaire
export const apiVirtualInstructorQuestionnaireRequest = () => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE,
});

// response
export const apiVirtualInstructorQuestionnaireRequestFailed = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_FAILED,
    response,
});

// response
export const apiVirtualInstructorQuestionnaireRequestSuccess = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_SUCCESS,
    response,
});

// fetch Recalibrate
export const apiVirtualInstructorRecalibrateQuestionaireRequest = () => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE,
});

// response
export const apiVirtualInstructorRecalibrateQuestionaireRequestFailed = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_FAILED,
    response,
});

// response
export const apiVirtualInstructorRecalibrateQuestionaireRequestSuccess = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_SUCCESS,
    response,
});

// fetch question
export const apiQuestionRequest = (question_id) => ({
    type: types.API_REQUEST_QUESTION_BY_ID,
    question_id,
});

// fetch init question
export const apiQuestionInitRequest = (data) => ({
    type: types.API_REQUEST_QUESTION_INIT,
    data,
});

export const apiQuestionRequestFailed = (response) => ({
    type: types.API_REQUEST_FAILED_QUESTION_BY_ID,
    response,
});

export const apiQuestionRequestSuccess = (response) => ({
    type: types.API_REQUEST_SUCCESS_QUESTION_BY_ID,
    response,
});

// submit answer
export const apiSubmitAnswerRequest = (data) => ({
    type: types.API_REQUEST_SUBMIT_ANSWER,
    data,
});

export const apiSubmitAnswerRequestFailed = (response) => ({
    type: types.API_REQUEST_FAILED_SUBMIT_ANSWER,
    response,
});

export const apiSubmitAnswerRequestSuccess = (response) => ({
    type: types.API_REQUEST_SUCCESS_SUBMIT_ANSWER,
    response,
});
