import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { FaCircleNotch } from 'react-icons/fa';
import cn from 'classnames';
import { Button, Error, Input } from '../Components/FormElements';

const string = {
  title: 'Restore your password',
  hasAccount: 'Already have an account?',
  noAccount: 'Don’t have an account?',
  login: 'Log in',
  signup: 'Sign Up',
};

const ResetPasswordPage = ({ onSubmit, register, errors, state }) => {
  return (
    <form
      onSubmit={onSubmit}
      className="container-auto-sm flex-1 flex flex-col justify-center text-center px-20 py-20 my-auto"
    >
      <Link to="/">
        <Logo className="h-50 mx-auto" />
      </Link>
      <p className="fs-24 md:fs-28 fw-700 py-30">{string.title}</p>
      <p>You will receive a link with your new password on your email.</p>

      <div>
        <Input placeholder="Email" {...register('email')} hasError={errors.email} />
        {errors.email && <Error text={errors.email.message} />}
        {!state.isLoading && state.success && (
          <div className="fg-primary fw-700">Password verification email has been sent</div>
        )}

        <Button
          primary
          type="submit"
          className={cn('fit-w md:w-550 my-10', (state.isLoading || state.success) && 'disabled')}
        >
          {state.isLoading ? <FaCircleNotch className="w-20 h-20 spin" /> : 'Reset password'}
        </Button>
      </div>

      <p className="fs-14 md:fs-18 fw-600 my-15 lh-2">
        {string.noAccount}{' '}
        <Link to="/signup" className="fw-700 fg-primary p-5">
          {string.signup}
        </Link>
        <br />
        {string.hasAccount}{' '}
        <Link to="/login" className="fw-700 fg-primary p-5">
          {string.login}
        </Link>
      </p>
    </form>
  );
};

export default ResetPasswordPage;
