/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IconCard, IconDate, IconPass } from './Icons';
import { FaChevronDown, FaCircleNotch } from 'react-icons/fa';
import { IoTicketOutline } from 'react-icons/io5';
import cn from 'classnames';
import Modal from './Modal';
import { Button, InputExtra } from './FormElements';
// import { apiPaymentMethodRequest } from '../Redux/actions/subscription';
import { apiCouponReset } from '../Redux/actions/coupon';

const string = {
  title: 'Subscribe to',
  duration: 'Duration',
  months: 'months',
  couponCode: 'I have a coupon',
  couponCodePlaceholder: 'Enter your coupon',
  apply: 'Apply',
  caprion: 'Secure payment',
  subtotal: 'Subtotal',
  total: 'Total',
  discountPlan: 'Yearly plan discount',
  discountCoupon: 'Coupon discount',
  cardNumber: 'Card number',
  expiration: 'MM/YY',
  cvv: 'CVV',
  submit: 'Submit payment',
  needFill: 'Fill credit card details',
};

export const formatCardNumber = (str) => {
  const digits = str.match(/\d/g);
  if (digits?.length > 15 && digits[0] === '3') { // AMEX format
    const groups = [digits.slice(0, 4), digits.slice(4, 10), digits.slice(10, 15)];
    return groups.map(group => group.join('')).join(' ');
  } else { // Other cards format
    const groups = [];
    for (let i = 0; i < digits?.length; i += 4) {
      groups.push(digits.slice(i, i + 4).join(''));
    }
    return groups.join(' ');
  }
};

export const formatCardDate = (str) => {
  const digits = str.match(/\d/g)?.slice(0, 4);
  const groups = [];
  for (let i = 0; i < digits?.length; i += 2) {
    groups.push(digits.slice(i, i + 2).join(''));
  }
  return groups.join('/');
};

export const formatCardSecurity = (str, cardNumber) => {
  if (cardNumber?.startsWith('3')) { // AMEX
    const digits = str.match(/\d/g)?.slice(0, 4);
    return digits?.join('') || '';
  } else {
    const digits = str.match(/\d/g)?.slice(0, 3);
    return digits?.join('') || '';
  }
};

const expireDate = (card) =>
  card?.exp_month.toString().padStart(2, '0') + '/' + card?.exp_year.toString().slice(-2);

export default function PaymentModal({
  show,
  title,
  caption,
  price,
  acceptCoupon,
  productId,
  priceId,
  membershipType,
  isLoading,
  onClose,
  onSubmit,
  onSubmitCoupon,
}) {
  const dispatch = useDispatch();
  const couponState = useSelector((state) => state.coupon);
  const paymentMethods = useSelector((state) => state.subscription.paymentMethod);
  const [card, setCard] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpMonth, setCardExpMonth] = useState('');
  const [cardExpYear, setCardExpYear] = useState('');
  const [cardCode, setCardCode] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isError, setIsError] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  const isFormFilled = cardNumber && cardExpMonth && cardExpYear && cardCode;

  const onCardhange = (e) => {
    setCard(e.target.value);
    setCardNumber('');
    setCardExpMonth('');
    setCardExpYear('');
    setCardCode('');
  };

  const onCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };

  const onCardExpChange = (e) => {
    let expMY = e.target.value.split('/');
    setCardExpMonth(expMY[0]);
    setCardExpYear(expMY[1]);
  };

  const onCardCodeChange = (e) => {
    setCardCode(e.target.value);
  };

  const onCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };

  // const onMetaError = (is_error) => {
  //   setIsError(is_error);
  // };

  const handleClose = () => {
    setCard('');
    setCardNumber('');
    setCardExpMonth('');
    setCardExpYear('');
    setCardCode('');
    setCouponCode('');
    setDiscountAmount(0);
    setIsError(false);
    dispatch(apiCouponReset());
    if (typeof onClose === 'function') onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (productId && !membershipType) {
      onSubmit(productId, priceId, card, cardNumber, cardExpMonth, cardExpYear, cardCode);
    } else {
      onSubmit(
        membershipType,
        card,
        cardNumber,
        cardExpMonth,
        cardExpYear,
        cardCode,
        couponCode,
        isError,
      );
    }
  };

  const handleSubmitCoupon = (e) => {
    onSubmitCoupon(couponCode);
  };

  useEffect(() => {
    if (!couponState.isLoading) {
      const coupon = couponState?.coupon?.coupon;
      const couponIsActive = couponState?.coupon?.active;

      setDiscountAmount(0);

      if (couponIsActive && coupon?.percent_off) {
        setDiscountAmount((parseFloat(price) || 0) * (coupon.percent_off || 0) * 0.01);
      }

      if (couponIsActive && coupon?.amount_off) {
        setDiscountAmount(coupon.amount_off * 0.01);
      }
    }
  }, [couponState]);

  useEffect(() => {
    if (show && !couponState.isLoading && couponState.success) {
      toast.success('Promo code is valid!');
    }
    if (show && !couponState.isLoading && couponState.error) {
      const msg = couponState?.error?.message?.Error || 'Promo code is invalid!';
      toast.error(msg);
      dispatch(apiCouponReset());
      setCouponCode('');
    }
  }, [couponState]);

  useEffect(() => {
    if (!isLoading && paymentMethods?.length > 0) {
      setCard(paymentMethods[0].id);
      setCardNumber('');
      setCardExpMonth('');
      setCardExpYear('');
      setCardCode('');
    }
  }, [isLoading]);

  return (
    <Modal defaultClass={false} visible={show} onClose={handleClose}>
      <form onSubmit={handleSubmit} className="container-auto-sm p-0 r-15 bg-white of-hidden">
        <div className="px-20 md:px-40 pb-20">
          <p className="fs-20 md:fs-24 fw-700">{title}</p>
          <p className="fs-14 fg-grey-400 mb-10">{caption}</p>
          <p className="fs-16 fw-600 fg-primary my-10">{string.caprion}</p>
          {isLoading ? (
            <div className="flex flex-col align-center justify-center text-center py-20">
              <FaCircleNotch className="fg-primary w-30 spin" />
            </div>
          ) : (
            <>
              {paymentMethods?.length > 0 && (
                <div className="py-5">
                  <label className="relative flex align-center r-10 b-1 b-grey-400 focus:b-primary">
                    <select
                      className="fit-w h-50 md:h-55 fs-16 lh-1 px-45 md:px-55"
                      style={{ WebkitAppearance: 'none', appearance: 'none' }}
                      onChange={onCardhange}
                    >
                      {paymentMethods?.map(({ id, card }) => (
                        <option key={id} value={id}>
                          •• {card.last4} | Expire {expireDate(card)} | {card.brand}
                        </option>
                      ))}
                      <option value="">Use another creadit card</option>
                    </select>
                    <IconCard className="absolute top-0 bottom-0 left-15 md:left-20 w-25 my-auto fg-grey-400 pointer-none" />
                    <FaChevronDown className="absolute top-0 bottom-0 right-15 w-15 my-auto fg-grey-600 pointer-none" />
                  </label>
                </div>
              )}
              {!card && (
                <>
                  <div>
                    <InputExtra
                      type="text"
                      Icon={IconCard}
                      placeholder={string.cardNumber}
                      inputMode="numeric"
                      disabled={(!!!membershipType && !productId) || isLoading}
                      onInput={(e) => (e.target.value = formatCardNumber(e.target.value))}
                      onChange={onCardNumberChange}
                    />
                  </div>
                  <div className="flex">
                    <InputExtra
                      type="text"
                      Icon={IconDate}
                      className="flex-1 mr-10"
                      placeholder={string.expiration}
                      inputMode="numeric"
                      disabled={(!!!membershipType && !productId) || isLoading}
                      onInput={(e) => (e.target.value = formatCardDate(e.target.value))}
                      onChange={onCardExpChange}
                    />
                    <InputExtra
                      type="text"
                      Icon={IconPass}
                      className="flex-1 ml-10"
                      placeholder={string.cvv}
                      inputMode="numeric"
                      disabled={(!!!membershipType && !productId) || isLoading}
                      onInput={(e) => (e.target.value = formatCardSecurity(e.target.value, cardNumber))}
                      onChange={onCardCodeChange}
                    />
                  </div>
                </>
              )}
              {acceptCoupon && (
                <div>
                  <p className="fs-16 fw-600 fg-grey-400 my-10">{string.couponCode}</p>
                  <div>
                    <InputExtra
                      type="text"
                      Icon={IoTicketOutline}
                      Button={
                        couponState?.isLoading ? (
                          <FaCircleNotch className="w-20 h-20 m-auto spin fg-primary" />
                        ) : (
                          <button
                            type="button"
                            className="fs-14 md:fs-16 fw-700 uppercase fg-grey-400 hover:fg-black active:fg-black"
                            disabled={discountAmount > 0 || !couponCode}
                            onClick={handleSubmitCoupon}
                          >
                            {string.apply}
                          </button>
                        )
                      }
                      value={couponCode}
                      placeholder={string.couponCodePlaceholder}
                      disabled={
                        couponState?.isLoading ||
                        (!!!membershipType && !productId) ||
                        isLoading ||
                        discountAmount > 0
                      }
                      onChange={onCouponCodeChange}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="fs-15 fw-500 p-20 md:px-40 bg-grey-50">
          {acceptCoupon && (
            <>
              <div className="flex align-center h-30">
                <p className="flex-1">{string.subtotal}:</p>
                <p>${(parseFloat(price) || 0).toFixed(2)}</p>
              </div>

              <div className="flex align-center h-30 fg-primary">
                <p className="flex-1">{string.discountCoupon}:</p>
                <p>
                  {discountAmount > 0 ? '-' : ''}${(parseFloat(discountAmount) || 0).toFixed(2)}
                </p>
              </div>
              <hr className="b-grey-200" />
            </>
          )}

          <div className="flex align-center h-30 fw-700">
            <p className="flex-1">{string.total}:</p>
            <p>${(parseFloat(price - discountAmount) || 0).toFixed(2)}</p>
          </div>

          <div className="text-center pt-10">
            <Button
              primary
              type="submit"
              className={cn(
                ((!!!membershipType && !productId) || isLoading || (!card && !isFormFilled)) &&
                  'disabled',
              )}
            >
              {card || isFormFilled ? string.submit : string.needFill}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
