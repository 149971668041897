import * as types from "../constants/myStats";

import { mapErrorMessage } from "../../Utils/mapErrorMessage";

const INITIAL_STATE = {
    isLoading: false,
    success: false,
    error: null,
    stats: null
};

export default function myStatsApiReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.API_REQUEST_MY_STATS:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: null,
            };

        case types.API_REQUEST_MY_STATS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: null,
                stats: action.response.data,
            };

        case types.API_REQUEST_MY_STATS_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: mapErrorMessage(action),
            };

        default:
            return state;
    }
}
