import * as types from "../constants/balance";

export const apiBalanceRequest = (data) => ({
  type: types.API_BALANCE_REQUEST,
  data,
});

export const apiBalanceRequestSuccess = (response) => ({
  type: types.API_BALANCE_REQUEST_SUCCESS,
  response,
});

export const apiBalanceRequestFailed = (response) => ({
  type: types.API_BALANCE_REQUEST_FAILED,
  response,
});
