export const API_REQUEST_CHANGE_SUBSCRIPTION = 'API_REQUEST_CHANGE_SUBSCRIPTION';
export const API_REQUEST_CHANGE_SUBSCRIPTION_SUCCESS = 'API_REQUEST_CHANGE_SUBSCRIPTION_SUCCESS';
export const API_REQUEST_CHANGE_SUBSCRIPTION_FAILED = 'API_REQUEST_CHANGE_SUBSCRIPTION_FAILED';

export const API_REQUEST_SUBSCRIPTION = 'API_REQUEST_SUBSCRIPTION';
export const API_REQUEST_SUBSCRIPTION_SUCCESS = 'API_REQUEST_SUBSCRIPTION_SUCCESS';
export const API_REQUEST_SUBSCRIPTION_FAILED = 'API_REQUEST_SUBSCRIPTION_FAILED';

export const API_REQUEST_CHECKOUT_SUBSCRIPTION = 'API_REQUEST_CHECKOUT_SUBSCRIPTION';
export const API_REQUEST_CHECKOUT_SUBSCRIPTION_SUCCESS =
  'API_REQUEST_CHECKOUT_SUBSCRIPTION_SUCCESS';
export const API_REQUEST_CHECKOUT_SUBSCRIPTION_FAILED = 'API_REQUEST_CHECKOUT_SUBSCRIPTION_FAILED';

export const API_REQUEST_TERMS_CONDITIONS = 'API_REQUEST_TERMS_CONDITIONS';
export const API_REQUEST_TERMS_CONDITIONS_SUCCESS = 'API_REQUEST_TERMS_CONDITIONS_SUCCESS';
export const API_REQUEST_TERMS_CONDITIONS_FAILED = 'API_REQUEST_TERMS_CONDITIONS_FAILED';

export const API_REQUEST_PAYMENT_METHOD = 'API_REQUEST_PAYMENT_METHOD';
export const API_REQUEST_PAYMENT_METHOD_SUCCESS = 'API_REQUEST_PAYMENT_METHOD_SUCCESS';
export const API_REQUEST_PAYMENT_METHOD_FAILED = 'API_REQUEST_PAYMENT_METHOD_FAILED';

export const API_REQUEST_UPDATE_PAYMENT_METHOD = 'API_REQUEST_UPDATE_PAYMENT_METHOD';
export const API_REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS =
  'API_REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS';
export const API_REQUEST_UPDATE_PAYMENT_METHOD_FAILED = 'API_REQUEST_UPDATE_PAYMENT_METHOD_FAILED';

export const API_REQUEST_DELETE_PAYMENT_METHOD = 'API_REQUEST_DELETE_PAYMENT_METHOD';
export const API_REQUEST_DELETE_PAYMENT_METHOD_SUCCESS =
  'API_REQUEST_DELETE_PAYMENT_METHOD_SUCCESS';
export const API_REQUEST_DELETE_PAYMENT_METHOD_FAILED = 'API_REQUEST_DELETE_PAYMENT_METHOD_FAILED';
