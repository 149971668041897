import * as types from '../constants/exploreVideo';

import { mapErrorMessage } from '../../Utils/mapErrorMessage';

const INITIAL_STATE = {
  isLoading: false,
  success: false,

  isVideoLoading: false,
  videoSuccess: false,

  isCombineLoading: false,
  combineSuccess: false,
  error: null,
  videos: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  combinedVideos: [],
  categories: [],

  bodyparts: [],
};

export default function exploreVideoApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Essential
    case types.API_REQUEST_EXPLORE_VIDEO_CATEGORY:
      return {
        ...state,
        isLoading: true,
        success: false,
        isCombineLoading: false,
        combineSuccess: false,
        isVideoLoading: false,
        videoSuccess: false,
        error: null,
      };

    case types.API_REQUEST_EXPLORE_VIDEO_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        categories: action.response.data,
      };

    case types.API_REQUEST_EXPLORE_VIDEO_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_BODY_PART:
      return {
        ...state,
        isLoading: true,
        success: false,
        isCombineLoading: false,
        combineSuccess: false,
        isVideoLoading: false,
        videoSuccess: false,
        error: null,
      };

    case types.API_REQUEST_BODY_PART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        bodyparts: action.response.data,
      };

    case types.API_REQUEST_BODY_PART_FAILED:
      return {
        ...state,
        isScheduleLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_EXPLORE_VIDEO:
      return {
        ...state,
        isLoading: true,
        success: false,
        isVideoLoading: true,
        videoSuccess: false,
        isCombineLoading: false,
        combineSuccess: false,

        error: null,
        videos: {
          count: null,
          next: null,
          previous: null,
          results: [],
        },
      };

    case types.API_REQUEST_EXPLORE_VIDEO_SUCCESS:
      // let results = state.videos?.results || [];
      // results = [...results, ...action.response.data?.results];
      // const data = { ...action.response.data, results: results };
      return {
        ...state,
        isLoading: false,
        isVideoLoading: false,
        success: true,
        videoSuccess: true,
        error: null,
        videos: action.response.data,
      };

    case types.API_REQUEST_EXPLORE_VIDEO_FAILED:
      return {
        ...state,
        isLoading: false,
        isVideoLoading: false,
        success: false,
        videoSuccess: false,
        error: mapErrorMessage(action),
      };
    case types.API_REQUEST_COMBINE_VIDEO:
      return {
        ...state,
        isLoading: true,
        success: false,
        isCombineLoading: true,
        combineSuccess: false,
        error: null,
      };

    case types.API_REQUEST_COMBINE_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        isCombineLoading: false,
        combineSuccess: true,
        combinedVideos: action.response.data,
        error: null,
      };

    case types.API_REQUEST_COMBINE_VIDEO_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        isCombineLoading: false,
        combineSuccess: false,
        error: mapErrorMessage(action),
      };
    default:
      return state;
  }
}
