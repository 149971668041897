/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Decoration } from '../Assets/my-studio-decoration.svg';
import { IconMobility, IconTimes } from '../Components/Icons';
import videoThumbnail from '../Assets/videoThumbnail.jpg';
import _ from 'lodash';
import ExploreLayout from '../Layouts/ExploreLayout';
import { unlockAccessAlertShow } from '../Redux/actions/setting';
import ExerciseCard from '../Components/ExerciseCard';
import {
  FaCheckSquare,
  FaChevronDown,
  FaChevronUp,
  FaCircleNotch,
  FaRegSquare,
} from 'react-icons/fa';
import styleCategories from '../Utils/styleCategories';
import { Button } from '../Components/FormElements';
import cn from 'classnames';
import { ContextMenu, MenuItem } from '../Components/ContextMenu';
import { useLocation } from 'react-router-dom';
import CreateWorkoutModal from '../Components/CreateWorkoutModal';
import { toast } from 'react-toastify';
import { scrollLock, scrollUnlock } from '../Utils/scrollLock';
import ReactPlayer from "react-player";
import Hls from 'hls.js'
import {Capacitor} from "@capacitor/core";

const levelOptions = [
  { value: '1', title: 'Level 1' },
  { value: '2', title: 'Level 2' },
  { value: '3', title: 'Level 3' },
  { value: '4', title: 'Level 4' },
  { value: '5', title: 'Level 5' },
];

const Dropdown = ({ className, filter, setFilter, title, options, isLoading }) => {
  const [showMenu, setShowMenu] = useState(false);
  const isAllSelected = options?.reduce(
    (acc, el) => (acc = acc && filter.includes(el.value.toString())),
    true,
  );

  const handleSelectAll = () => {
    if (isAllSelected) {
      setFilter([]);
    } else {
      setFilter(options.map((el) => el.value.toString()));
    }
  };

  return (
    <div className={cn('relative w-6/12 md:w-auto inline-flex lh-1', className)}>
      <div
        className="fit-w h-45 flex align-center fs-16 p-15 r-15 fg-grey-700 b-1 b-grey-300 hover:fg-black active:fg-black cursor-pointer"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div className="fit-w flex align-center of-hidden">
          <span className="flex-1 pr-5 md:pr-15 text-trim">{title}</span>
          {filter?.length > 0 ? (
            <div className="flex justify-center align-center w-25 h-25 mr-10 bg-primary fg-white fs-12 fw-800 r-100">
              {filter?.length}
            </div>
          ) : (
            <div className="md:show w-25 h-25 mr-10" />
          )}
          <FaChevronDown className="w-15" />
        </div>
      </div>
      <ContextMenu
        visible={showMenu}
        disableFixedWidth
        onClose={() => setShowMenu(false)}
        className="fw-500 top-65 right-0 md:left-0 of-y-overlay modal-scrollstyle"
        style={{ maxHeight: '335px', minWidth: '100%', width: 'max-content' }}
      >
        <MenuItem
          keepOpen
          className={cn('hover:bg-primary-light active:bg-primary-light', isLoading && 'disabled')}
          onClick={handleSelectAll}
        >
          {isAllSelected ? (
            <FaCheckSquare className="w-20 fw-20 fg-primary" />
          ) : (
            <FaRegSquare className="w-20 fg-grey-300" />
          )}
          <span className="pl-10 fg-grey-700">Select All</span>
        </MenuItem>

        {options?.map((item, key) => {
          const selected = filter?.includes(item?.value.toString());

          return (
            <React.Fragment key={key}>
              <hr className={cn('md:mx-15 my-0 b-grey-200', key > 0 && 'md:hide')} />
              <MenuItem
                keepOpen
                className={cn(
                  'hover:bg-primary-light active:bg-primary-light',
                  isLoading && 'disabled',
                )}
                onClick={(e) => {
                  setFilter((state) =>
                    selected
                      ? state.filter((el) => el !== item.value.toString())
                      : [...state, item.value.toString()],
                  );
                }}
              >
                {selected ? (
                  <FaCheckSquare className="w-20 fw-20 fg-primary" />
                ) : (
                  <FaRegSquare className="w-20 fg-grey-300" />
                )}
                <span className="pl-10 fg-grey-700">{item?.title}</span>
              </MenuItem>
            </React.Fragment>
          );
        })}
      </ContextMenu>
    </div>
  );
};

const VideoCard = ({ video = {}, onCheckboxClicked, isCombineLoading }) => {
  const { id, length, thumbnail } = video;
  const duration = length ? `${length} mins` : <>&nbsp;</>;
  const { Icon, className: style } =
  styleCategories.find((el) => el.id === video?.video_category?.id) || {};

  return (
    <div className="flex-0 w-150 md:w-2/12 px-10 text-center">
      <div
        className={cn(
          'relative r-8 b-1',
          id ? 'bg-grey-400 b-solid b-white' : 'bg-grey-100 b-dashed b-grey-300',
        )}
        style={{ paddingTop: '56%' }}
      >
        {id && (
          <img
            src={thumbnail || videoThumbnail}
            alt=""
            className="absolute top-0 left-0 fit fit-cover r-8 fadein"
          />
        )}
        {id && (
          <>
            <Button
              size="none"
              disablePadding
              className={cn(
                'absolute top-5 right-5 w-25 h-25 r-100 bg-white',
                isCombineLoading && 'pointer-off',
              )}
              onClick={() => onCheckboxClicked(id, false)}
            >
              <IconTimes className="fg-grey-400 w-15 h-15 m-auto" />
            </Button>
            <div className={cn('absolute top-5 left-5 flex w-25 h-25 r-100', style)}>
              <Icon className="w-15 h-15 m-auto" />
            </div>
          </>
        )}
      </div>
      <div className="md:show mt-5 fg-grey-500">{duration}</div>
    </div>
  );
};

const ExplorePage = ({
                       categories,
                       // selectedCategory,
                       stateWorkoutModal,
                       body_parts,
                       videos,
                       isLoading,
                       isCombineLoading,
                       isCombineSuccess,
                       isCombinePossible,
                       combinedVideos,
                       noMore,
                       selectedVideo,
                       onFilterChange,
                       onTopCardMenuSelected,
                       onLoadMore,
                       onCombineClick,
                       onDeleteAVideoClick,
                       onCheckboxClicked,
                       onVideoWatched,
                       onClearVideos,
                       isFreeUser,
                     }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const cardMenuSelection = useSelector((state) => state.cardMenuSelection);

  const videoRef = useRef(null);
  const initialVideoState = { index: 0, time: 0, status: '' };
  const [videoState, setVideoState] = useState(initialVideoState);
  const [isPlaying, setIsPlaying] = useState(false);
  const [video, setVideo] = useState(undefined | '');
  const [levelsList, setLevelsList] = useState([]);
  const [levels, setLevels] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = Capacitor.getPlatform() === 'ios';

  const needsHlsLibrary = !isSafari && !isIOS;

  const [showWorkoutModal, setShowWorkoutModal] = stateWorkoutModal;
  const [stateVideos, setStateVideos] = useState(null);
  const [showPanel, setShowPanel] = useState(query.get('showpanel') === 'true');
  const [showPanelList, setShowPanelList] = useState(false);
  const totalTime = selectedVideo?.length
    ? selectedVideo.reduce((acc, curr) => acc + curr.length, 0)
    : 0;

  useEffect(() => {
    function getScrollPercent() {
      var h = document.documentElement || document.body,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
      return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
    }

    const handleScroll = _.throttle(() => {
      if (getScrollPercent() >= 90 && !isLoading && !noMore && stateVideos?.count > 0) {
        onLoadMore();
      }
    }, 300);

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll, { passive: true });
  }, [isLoading, noMore, onLoadMore, stateVideos?.count]);

  useEffect(() => {
    // console.log(videos);
    if ('count' in videos) {
      setStateVideos(videos);
    }
  }, [videos]);

  useEffect(() => {
    setLevels([]);
    setBodyParts([]);
    setLevelsList(getlevelOptions(cardMenuSelection.menu));
  }, [cardMenuSelection.menu]);

  useEffect(() => {
    onFilterChange(levels, bodyParts);
  }, [levels, bodyParts]);

  useEffect(() => {
    if (!showPanel) {
      onClearVideos();
    }
  }, [showPanel]);

  useEffect(() => {
    if (!isCombineLoading && isCombineSuccess) {
      setShowPanel(false);
    }
  }, [isCombineLoading, isCombineSuccess]);

  const getlevelOptions = (id) => {
    switch (parseInt(id)) {
      case 1:
        return levelOptions.slice(0, 3);
      case 3:
        return levelOptions.slice(0, 5);
      case 4:
        return levelOptions.slice(0, 5);
      case 5:
        return levelOptions.slice(0, 3);
      case 6:
        return [];
      case 7:
        return levelOptions.slice(0, 5);
      default:
    }
  };

  const bodyPartOptions = body_parts
    .sort((a, b) => a.part_name.localeCompare(b.part_name))
    .map((el) => {
      return { value: el.id, title: el.part_name };
    });

  // Video Functions
  const onVideoClicked = (video) => {
    setVideoState(initialVideoState);
    setIsPlaying(true);
    setVideo(video?.filter((el) => el.video));
  };

  const startCombinedVideo = () => {
    const video = combinedVideos?.list_of_videos
      ?.map(({ id, video }) => ({ id, video }))
      .filter((el) => el.video);

    setVideoState(initialVideoState);
    setShowWorkoutModal(false);
    setIsPlaying(true);
    setVideo(video);
  };

  const onProgress = () => {
    videoState.status !== 'pause' && setVideoState((state) => ({ ...state, time: state.time + 1 }));
  };

  const onEnded = () => {
    onVideoWatched(video[videoState.index].id, videoState.time);

    if (videoState.index < video.length - 1) {
      setVideoState((state) => ({ ...state, index: state.index + 1 }));
    } else {
      setIsPlaying(false);
    }
  };

  const onVideoClose = () => {
    if (videoRef.playing) {
      videoRef.playing = false;
      videoRef.url = '';
    }

    onVideoWatched(video[videoState.index].id, videoState.time);
    setVideo(undefined | '');
    setIsPlaying(false);
  };

  // const onLevelChange = (e) => {
  //   setLevels(e);
  //   onFilterChange(e, bodyParts);
  // };

  // const onBodyPartsChange = (e) => {
  //   setBodyParts(e);
  //   onFilterChange(levels, e);
  // };

  const onVideoPlayFailedHandle = () => {
    dispatch(unlockAccessAlertShow());
  };

  useEffect(() => {
    if (isPlaying) {
      scrollLock();
    } else {
      scrollUnlock();
    }
  }, [isPlaying]);

  return (
    <>
      <ExploreLayout
        statePanel={[showPanel, setShowPanel]}
        categories={categories}
        onTopCardMenuSelected={onTopCardMenuSelected}
        selectedVideo={selectedVideo}
        onCombineClick={onCombineClick}
        onDeleteAVideoClick={onDeleteAVideoClick}
        isLoading={isLoading}
        isCombineLoading={isCombineLoading}
        isCombinePossible={isCombinePossible}
        isFreeUser={isFreeUser}
      >
        <div className="flex category-filters flex-gap-10">
          <Dropdown
            title="Level"
            filter={levels}
            setFilter={setLevels}
            options={levelsList}
            isLoading={isLoading}
          />
          <Dropdown
            title="Body part"
            filter={bodyParts}
            setFilter={setBodyParts}
            options={bodyPartOptions}
            isLoading={isLoading}
          />
        </div>

        <div className="lg:hide relative flex align-center r-15 p-15 b-1 b-grey-300 mt-15 of-hidden">
          <div className="w-70/100 z-1">
            {!showPanel ? (
              <>
                <p className="fs-14 md:fs-18 fw-700">Want to create your own workout?</p>
                <p className="fs-12 md:fs-14 fg-grey-500 py-2">
                  Combine any videos of your choice.
                </p>

                <Button
                  primary
                  size="small"
                  className="my-10"
                  onClick={() =>
                    // isFreeUser ? navigate('/my-profile?page=subscription') : setShowPanel(true)
                    setShowPanel(true)
                  }
                >
                  {/* {isFreeUser ? 'Upgrade Plan' : 'Create workout'} */}
                  Create workout
                </Button>
              </>
            ) : (
              <>
                <p className="w-10/12 fs-12 md:fs-14 py-2 my-10 fg-primary">
                  1. Select any combination of videos and press "Create workout"".
                </p>
                <p
                  className={cn(
                    'w-10/12 fs-12 md:fs-14 py-2 my-10 fg-primary',
                    // selectedVideo?.length > 0 && 'fg-primary',
                  )}
                >
                  2. I will arrange the videos in the right order and save it in your workouts.
                </p>
              </>
            )}
          </div>
          <Decoration
            className="absolute top-0 bottom-0 fit-h w-auto py-10"
            style={{ right: '-10px', maxWidth: '45%' }}
          />
        </div>

        <div className="line-separator"></div>
        <div
          className={cn(
            'exercise-row explore-exercises mt-5 md:mt-15 lg:mt-20',
            showPanel && 'pb-200 md:pb-100',
          )}
        >
          <div className="flex flex-wrap exercise-row md:flex-gap-15 xl:flex-gap-30">
            {stateVideos?.results?.map((video, index) => (
              <ExerciseCard
                key={video.id}
                index={index}
                id={video.id}
                // {...video}
                disableContextMenu
                duration={video?.length}
                video_category={video?.video_category?.id}
                video_level={video?.level}
                video_thumbnail={video?.thumbnail}
                video_title={video?.title}
                video_instructors={[video?.video_instructor]}
                list_of_videos={[video]}
                actions={['select']}
                selected={!!_.find(selectedVideo, { id: video.id }) ? true : false}
                // category={categories.find((category) => category.id == selectedCategory.menu)}
                onVideoClicked={onVideoClicked}
                onVideoPlayFailed={onVideoPlayFailedHandle}
                isCombinePossible={isCombinePossible}
                onSelect={showPanel && onCheckboxClicked}
                isFreeUser={isFreeUser}
                forceUpgradeRequired={isFreeUser}
              />
            ))}
          </div>

          {stateVideos?.count > 0 && !noMore && (
            <div className="text-center load-more">
              <Button
                primary
                className={cn('m-auto mt-20', isLoading && 'disabled')}
                onClick={() => {
                  onLoadMore();
                }}
                disabled={noMore ? true : false}
              >
                {isLoading && <FaCircleNotch className="w-25 h-25 mr-10 spin" />}
                More videos
              </Button>
            </div>
          )}
        </div>
        {isPlaying && (
          <div
            className="align-center bottom-0 fixed flex justify-center left-0 right-0 top-0 lg:p-20 video-dialog z-100"
            style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
            onClick={onVideoClose}
          >
            <div
              className="container-auto-lg m-0 p-0 absolute ratio-16/9 bg-black lg:r-15 lg:of-hidden video-container"
              style={{ maxHeight: '100%' }}
              onClick={(e) => e.stopPropagation()}
            >
              <ReactPlayer
                ref={videoRef}
                url={video[videoState.index].video}
                controls
                config={{
                  file: {
                    forceHLS: needsHlsLibrary,
                    ...(needsHlsLibrary && { hlsVersion: Hls.version }),
                    attributes: {
                      controlsList: 'nodownload noremoteplayback noplaybackrate',
                      disablePictureInPicture: true,
                    },
                  },
                }}
                onEnded={onEnded}
                onProgress={onProgress}
                onPlay={() => setVideoState((state) => ({ ...state, status: '' }))}
                onPause={() => setVideoState((state) => ({ ...state, status: 'pause' }))}
                onBufferEnd={() => setVideoState((state) => ({ ...state, status: '' }))}
                onBuffer={() => setVideoState((state) => ({ ...state, status: 'pasue' }))}
                playing
                playsinline
                width="100%"
                height="100%"
              />
              <div
                className="absolute top-10 md:top-20 right-10 md:right-20 flex w-35 h-35 r-100 fg-white hover:bg-white hover:fg-black active:bg-white active:fg-black cursor-pointer"
                onClick={onVideoClose}
              >
                <IconTimes className="fg-grey-400 w-30 h-30 m-auto" />
              </div>
            </div>
          </div>
        )}

        {showPanel && (
          <div className="fixed z-9 container-auto-xl left-0 right-0 bottom-0 md:bottom-10 md:px-10 bt-1 md:b-0 b-grey-200">
            <div className="flex flex-col md:flex-row shadow-8 md:r-15 bg-white">
              <div className="md:hide flex align-center py-15 md:py-20 px-20">
                <div className="fg-grey-500">{totalTime} mins</div>
                <div className="flex-1" />
                <Button
                  size="none"
                  disablePadding
                  className={cn('w-30 h-30 r-100', isCombineLoading && 'pointer-off')}
                  onClick={() => setShowPanel(false)}
                >
                  <IconTimes className="fg-grey-400 w-25 h-25 m-auto" />
                </Button>
              </div>
              <div
                className={cn(
                  'md:flex-1 flex of-x-overlay hide-scrollbar px-10 md:p-15',
                  !showPanelList && 'md:show',
                )}
                style={{ marginLeft: '-10px', marginRight: '-10px' }}
              >
                {[...Array(6).keys()].map((key) => (
                  <VideoCard
                    key={key}
                    video={selectedVideo?.[key]}
                    onCheckboxClicked={onCheckboxClicked}
                    isCombineLoading={isCombineLoading}
                  />
                ))}
              </div>

              <div className="fit-w md:w-2/12 md:pl-0 text-center p-20">
                <div className="text-right" style={{ marginTop: '-10px', marginRight: '-10px' }}>
                  <Button
                    size="none"
                    disablePadding
                    className={cn(
                      'md:show flex w-30 h-30 r-100',
                      isCombineLoading && 'pointer-off',
                    )}
                    onClick={() => setShowPanel(false)}
                  >
                    <IconTimes className="fg-grey-400 w-25 h-25 m-auto" />
                  </Button>
                </div>
                <div className="md:show fg-grey-500">{totalTime} mins</div>
                <div className="flex align-center pt-15">
                  <div
                    className="md:hide relative flex-0 w-50 h-50 flex r-10 mr-20 bg-grey-100 b-1 b-grey-300"
                    onClick={() => setShowPanelList(!showPanelList)}
                  >
                    {selectedVideo?.length > 0 ? (
                      <img
                        src={selectedVideo[selectedVideo?.length - 1]?.thumbnail || videoThumbnail}
                        alt=""
                        className="fit fit-cover r-10"
                      />
                    ) : (
                      <IconMobility className="w-25 h-25 fg-grey-400 m-auto" />
                    )}
                    <div
                      className="absolute h-25 flex align-center bg-primary fg-white top-0 right-0 px-6 py-2 r-25"
                      style={{ top: '-12px', right: '-12px' }}
                    >
                      {showPanelList ? (
                        <FaChevronUp className="m-auto" />
                      ) : (
                        <>{parseInt(selectedVideo?.length)}/6</>
                      )}
                    </div>
                  </div>

                  <Button
                    primary
                    disablePadding
                    size="mini"
                    className={cn('fit-w h-50 md:h-40 px-20', isCombineLoading && 'pointer-off')}
                    onClick={onCombineClick}
                  >
                    {isCombineLoading && <FaCircleNotch className="w-20 h-20 mr-10 spin" />}
                    <span className="text-trim">Create workout</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ExploreLayout>
      {showWorkoutModal && (
        <CreateWorkoutModal
          selectedVideo={selectedVideo}
          combinedVideos={combinedVideos}
          isModalVisible={true}
          isLoading={false}
          handleOk={startCombinedVideo}
          handleCancel={() => {
            setShowWorkoutModal(false);
            toast.success('Your workout was saved!');
          }}
        />
      )}
    </>
  );
};

export default ExplorePage;
