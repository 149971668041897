// fetch unique session key
export const API_REQUEST_SESSION_KEY = "API_REQUEST_SESSION_KEY";
export const API_REQUEST_FAILED_SESSION_KEY = "API_REQUEST_FAILED_SESSION_KEY";
export const API_REQUEST_SUCCESS_SESSION_KEY = "API_REQUEST_SUCCESS_SESSION_KEY";

// signup profiling questionnaire
export const API_REQUEST_SIGNUP_PROFILING_QUESTIONNAIRE = "API_REQUEST_SIGNUP_PROFILING_QUESTIONNAIRE";
export const API_REQUEST_SUCCESS_SIGNUP_PROFILING_QUESTIONNAIRE = "API_REQUEST_SUCCESS_SIGNUP_PROFILING_QUESTIONNAIRE";
export const API_REQUEST_FAILED_SIGNUP_PROFILING_QUESTIONNAIRE = "API_REQUEST_FAILED_SIGNUP_PROFILING_QUESTIONNAIRE";

export const API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE = "API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE";
export const API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_SUCCESS =
    "API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_SUCCESS";
export const API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_FAILED =
    "API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_FAILED";

export const API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE =
    "API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE";
export const API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_SUCCESS =
    "API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_SUCCESS";
export const API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_FAILED =
    "API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_FAILED";

// get question with id
export const API_REQUEST_QUESTION_BY_ID = "API_REQUEST_QUESTION_BY_ID";
export const API_REQUEST_QUESTION_INIT = "API_REQUEST_QUESTION_INIT";
export const API_REQUEST_FAILED_QUESTION_BY_ID = "API_REQUEST_FAILED_QUESTION_BY_ID";
export const API_REQUEST_SUCCESS_QUESTION_BY_ID = "API_REQUEST_SUCCESS_QUESTION_BY_ID";

// submit answer
export const API_REQUEST_SUBMIT_ANSWER = "API_REQUEST_SUBMIT_ANSWER";
export const API_REQUEST_SUCCESS_SUBMIT_ANSWER = "API_REQUEST_SUCCESS_SUBMIT_ANSWER";
export const API_REQUEST_FAILED_SUBMIT_ANSWER = "API_REQUEST_FAILED_SUBMIT_ANSWER";

// create account
/* export const API_REQUEST_CREATE_ACCOUNT = "API_REQUEST_CREATE_ACCOUNT";
export const API_REQUEST_CREATE_ACCOUNT_SUCCESS = "API_REQUEST_CREATE_ACCOUNT_SUCCESS";
export const API_REQUEST_CREATE_ACCOUNT_FAILED = "API_REQUEST_CREATE_ACCOUNT_FAILED"; */
