import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { exploreVideoService } from "../services/exploreVideo";
import * as types from "../constants/exploreVideo";
import * as actions from "../actions/exploreVideo";

function* apiVideoCategoryRequestWorker(action) {
    try {
        const result = yield call(exploreVideoService.apiVideoCategoryRequest, action);
        yield put(actions.apiVideoCateogryRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiVideoCateogryRequestFailed(err));
    }
}

function* apiVideoCategoryRequestWatcher() {
    yield takeEvery(types.API_REQUEST_EXPLORE_VIDEO_CATEGORY, apiVideoCategoryRequestWorker);
}

function* apiBodyPartRequestWorker(action) {
    try {
        const result = yield call(exploreVideoService.apiBodyPartyRequest, action);
        yield put(actions.apiBodyPartyRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiBodyPartyRequestFailed(err));
    }
}

function* apiBodyPartyRequestWatcher() {
    yield takeEvery(types.API_REQUEST_BODY_PART, apiBodyPartRequestWorker);
}

function* apiExploreVideoRequestWorker(action) {
    try {
        const result = yield call(exploreVideoService.apiExploreVideoRequest, action);
        yield put(actions.apiExploreVideoRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiExploreVideoRequestFailed(err));
    }
}

function* apiExploreVideoRequestWatcher() {
    yield takeEvery(types.API_REQUEST_EXPLORE_VIDEO, apiExploreVideoRequestWorker);
}

function* apiCombineVideoRequestWorker(action) {
    try {
        const result = yield call(exploreVideoService.apiCombineVideoRequest, action);
        yield put(actions.apiCombineVideoRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiCombineVideoRequestFailed(err));
    }
}

function* apiCombineVideoRequestWatcher() {
    yield takeEvery(types.API_REQUEST_COMBINE_VIDEO, apiCombineVideoRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* studioRootSaga() {
    const sagas = [
        apiVideoCategoryRequestWatcher,
        apiBodyPartyRequestWatcher,
        apiExploreVideoRequestWatcher,
        apiCombineVideoRequestWatcher,
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
