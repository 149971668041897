/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import { updateSideMenuSelection } from '../Redux/actions/sideMenu';
import MyStatsPage from '../Pages/MyStatsPage';
import { apiMyHabitRequest } from '../Redux/actions/myHabit';
import { apiMyStatsRequest } from '../Redux/actions/myStats';
import { apiSettingRequest } from '../Redux/actions/setting';

const MyStatsContainer = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const myHabit = useSelector((state) => state.myHabit);
  const myStats = useSelector((state) => state.myStats);

  const dispatchMenu = () => {
    const category = query.get('category');
    dispatch(updateCardMenuSelection(category));
  };

  useEffect(() => {
    dispatch(apiMyHabitRequest());
    dispatch(apiMyStatsRequest());
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  }, []);

  useEffect(() => {
    if (!query.get('category')) navigate('/my-stats?category=week');
    dispatchMenu();
  }, [query.get('category')]);

  useEffect(() => {
    dispatch(updateCardMenuSelection('habit-week'));
    dispatch(updateSideMenuSelection('my-stats'));
  }, []);

  return <MyStatsPage myHabbit={myHabit} myStats={myStats} category={query.get('category')} />;
};

export default MyStatsContainer;
