import * as types from "../constants/about";

export const resetAboutState = () => ({
    type: types.RESET_ABOUT_STATE,
});

// ABOUT
export const apiGetAboutRequest = () => ({
    type: types.API_GET_REQUEST_ABOUT,
});

export const apiGetAboutRequestSuccess = (response) => ({
    type: types.API_GET_REQUEST_ABOUT_SUCCESS,
    response,
});

export const apiGetAboutRequestFailed = (response) => ({
    type: types.API_GET_REQUEST_ABOUT_FAILED,
    response,
});
