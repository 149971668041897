/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import { updateSideMenuSelection } from '../Redux/actions/sideMenu';
import { apiEssentialsRequest, apiVideoActivityRequest } from '../Redux/actions/studioVideo';
import { apiSettingRequest, unlockAccessAlertShow } from '../Redux/actions/setting';
import { apiVideoCateogryRequest } from '../Redux/actions/exploreVideo';
import MyStudioPage from '../Pages/MyStudio';
import { showErrorToastNoti } from '../Utils/errorToast';

const DashboardContainer = () => {
  const dispatch = useDispatch();
  const studioVideo = useSelector((state) => state.studio);
  const auth = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state.explore);

  useEffect(() => {
    showErrorToastNoti(studioVideo);
  }, [studioVideo]);

  // const onVideoScheduled = (id, scheduledDate) => {
  //   let data = {
  //     video_id: id,
  //     scheduled_date: scheduledDate,
  //   };
  //   dispatch(apiScheduleVideoRequest(data));
  // };

  const onVideoWatched = (id, watchedTime) => {
    let data = {
      video_id: id,
      duration_watched: Math.round(watchedTime / 60),
    };
    dispatch(apiVideoActivityRequest(data));
  };

  const onVideoUnavailableHandle = () => {
    dispatch(unlockAccessAlertShow());
  };

  useEffect(() => {
    dispatch(updateCardMenuSelection('dashboard'));
    dispatch(updateSideMenuSelection('my-studio'));
    dispatch(apiVideoCateogryRequest());
    dispatch(apiEssentialsRequest());
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  }, []);

  return (
    <MyStudioPage
      state={studioVideo}
      categories={categories}
      // onVideoScheduled={onVideoScheduled}
      onVideoWatched={onVideoWatched}
      onVideoUnavailable={onVideoUnavailableHandle}
    />
  );
};

export default DashboardContainer;
