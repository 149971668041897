import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsLayout from '../Layouts/SettingsLayout';
import {
  CardInfoRow,
  CardInfoRowToggle,
  SettingsCard,
  SettingsCardContent,
  SettingsCardHeader,
} from '../Components/SettingsCard';
import { Button, InputExtra } from '../Components/FormElements';
import { FaCheck, FaCircleNotch } from 'react-icons/fa';
import { formatCardDate, formatCardNumber, formatCardSecurity } from '../Components/PaymentModal';
import valid from 'card-validator';
import { toast } from 'react-toastify';
import { IconCard, IconDate, IconPass, IconTrash } from '../Components/Icons';
import moment from 'moment';
import PaymnetMethodDeleteModal from '../Components/PaymnetMethodDeleteModal';

const zeroPad = (num, places) => {
  const zero = places - num?.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

const SettingsPage = ({
  profile,
  paymentMethod,
  paymentMethodIsLoading,
  currentPlan,
  onSettingChange,
  onPaymentMethodChange,
  onPaymentMethodDelete,
}) => {
  const navigate = useNavigate();
  const setting = profile.setting;
  const planCanceled = setting?.active_membership?.canceled;
  const planEndDate = setting?.active_membership?.end_date;
  // const isLoading = profile.isLoading;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deletePaymentMethod, setDeletePaymentMethod] = useState(null);
  const [editCardPayment, setEditCardPayment] = useState(false);
  const [addNewCardPayment, setAddNewCardPayment] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpMonth, setCardExpMonth] = useState('');
  const [cardExpYear, setCardExpYear] = useState('');
  const [cardCode, setCardCode] = useState('');
  const [isError, setIsError] = useState(false);

  const expireDate = (card) =>
    zeroPad(card?.exp_month, 2) + '/' + card?.exp_year.toString().slice(-2);

  const onCardNumberChange = (e) => {
    e.target.value = formatCardNumber(e.target.value);
    setCardNumber(e.target.value);
    setIsError(false);
  };

  const onCardExpChange = (e) => {
    e.target.value = formatCardDate(e.target.value);
    let expMY = e.target.value.split('/');
    setCardExpMonth(expMY[0]);
    setCardExpYear(expMY[1]);
    setIsError(false);
  };

  const onCardCodeChange = (e) => {
    e.target.value = formatCardSecurity(e.target.value);
    setCardCode(e.target.value);
    setIsError(false);
  };

  const onClickCardSave = () => {
    if (addNewCardPayment) {
      if (
        !valid.number(cardNumber).isValid ||
        !valid.expirationDate(cardExpMonth + '/' + cardExpYear).isValid ||
        !valid.cvv(cardCode).isValid
      ) {
        setIsError(true);
        toast.error('You have provided invalid credit card details.');
      } else {
        onPaymentMethodChange(cardNumber, cardExpMonth, cardExpYear, cardCode, isError);
      }
    }

    setCardNumber('');
    setCardExpMonth('');
    setCardExpYear('');
    setCardCode('');
    setAddNewCardPayment(false);
    setEditCardPayment(false);
  };

  const onClickDelete = (paymentMethod) => {
    setDeletePaymentMethod(paymentMethod);
    setIsDeleteModalVisible(true);
  };

  return (
    <SettingsLayout>
      <div className="flex flex-wrap py-10">
        {/* Notifications */}
        <div className="fit-w lg:w-50/100 py-10 lg:pr-15">
          <SettingsCard>
            <SettingsCardHeader>
              <div className="flex-1 text-trim">Notifications</div>
            </SettingsCardHeader>
            <SettingsCardContent className="fs-14">
              <CardInfoRowToggle
                title="Workout reminders"
                caption="Keep this turned on to help you stay on track"
                value={setting?.workout_reminders_enabled}
                onChange={() => onSettingChange('workout_reminders_enabled')}
              />
              <hr className="b-grey-300 my-20" />
              <CardInfoRowToggle
                title="Newsletter"
                caption="Keep this turned on to stay updated on info and workshops"
                value={setting?.newsletter_enabled}
                onChange={() => onSettingChange('newsletter_enabled')}
              />
            </SettingsCardContent>
          </SettingsCard>
        </div>

        {/* Subscription & Integrations */}
        <div className="flex flex-col fit-w lg:w-50/100 py-10 lg:pl-15">
          <div className="flex-1 pb-10 md:pb-15">
            <SettingsCard>
              <SettingsCardHeader>
                <div className="flex-1 text-trim">Subscription</div>
                <div>
                  <Button
                    disablePadding
                    primary
                    size="small"
                    className="w-120 md:w-140 ml-15"
                    onClick={() => navigate('/my-profile?page=subscription')}
                  >
                    Change plan
                  </Button>
                </div>
              </SettingsCardHeader>
              <SettingsCardContent className="fs-14">
                <CardInfoRow title="Current Plan">
                  {currentPlan?.plan_name}{' '}
                  {planCanceled &&
                    ' (Canceled) / Expires: ' + moment(planEndDate).format('DD/MM/YYYY')}
                </CardInfoRow>
              </SettingsCardContent>
            </SettingsCard>
          </div>

          <div className="flex-1 pt-10 md:pt-15">
            <SettingsCard>
              <SettingsCardHeader>
                <div className="flex-1 text-trim">Integrations</div>
              </SettingsCardHeader>
              <SettingsCardContent className="fs-14">
                <CardInfoRowToggle
                  title="Google Calendar"
                  caption="Keep this turned on so your schedule can sync with your calendar"
                  value={setting?.calendar_integration_enabled}
                  onChange={() => onSettingChange('calendar_integration_enabled')}
                />
              </SettingsCardContent>
            </SettingsCard>
          </div>
        </div>

        {/* Payment Method */}
        <div className="fit-w pt-10 md:pt-15">
          <div className="pb-10 md:pb-15">
            <SettingsCard>
              <SettingsCardHeader>
                <div className="flex-1 text-trim">Payment Method</div>
                <div>
                  {editCardPayment ? (
                    <>
                      <Button
                        secondary
                        size="small"
                        className="w-90 md:w-130 ml-15"
                        onClick={() => {
                          setEditCardPayment(false);
                          setAddNewCardPayment(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        primary
                        size="small"
                        disablePadding
                        className="w-40 md:w-130 ml-15"
                        onClick={onClickCardSave}
                      >
                        <span className="md:show">Save</span>
                        <span className="md:hide">
                          <FaCheck />
                        </span>
                      </Button>
                    </>
                  ) : (
                    <Button
                      primary
                      size="small"
                      disablePadding
                      className="w-90 md:w-130 ml-15"
                      onClick={() => {
                        setEditCardPayment(true);
                        setAddNewCardPayment(!paymentMethod?.length);
                      }}
                    >
                      {paymentMethod?.length ? 'Edit' : 'Add new'}
                    </Button>
                  )}
                </div>
              </SettingsCardHeader>
              <SettingsCardContent className="fs-14">
                {/* Empty state */}
                {!paymentMethodIsLoading && !paymentMethod?.length > 0 && !editCardPayment && (
                  <CardInfoRow title="No Credit Cards" />
                )}

                {/* Loading state */}
                {paymentMethodIsLoading && (
                  <div className="flex justify-center py-30">
                    <FaCircleNotch className="w-25 h-25 spin fg-primary" />
                  </div>
                )}

                {/* List payment menthods */}
                {!paymentMethodIsLoading &&
                  paymentMethod?.map(({ id, card }, key) => {
                    return (
                      <React.Fragment key={id}>
                        {key > 0 && <hr className="b-grey-300 my-20" />}
                        <CardInfoRow title="Credit Card" className="flex flex-wrap align-center">
                          <div className="fit-w md:flex-1 flex align-center pb-20 md:pb-0 pr-20 sx-10 md:sx-20 of-hidden">
                            <div className="w-110 text-trim">{card.brand}</div>
                            <div className="">•• {card.last4}</div>
                            <div className="">
                              <span className="md:show">Expires </span>
                              {expireDate(card)}
                            </div>
                            <div className="flex-1" />
                          </div>
                          {editCardPayment && card.last4 && (
                            <button
                              className="flex w-40 h-40 r-100 fg-red-700 b-1 b-black hover:fg-white hover:bg-black active:fg-white active:bg-black cursor-pointer"
                              onClick={() => onClickDelete({ id, card })}
                            >
                              <IconTrash className="m-auto" />
                            </button>
                          )}
                        </CardInfoRow>
                      </React.Fragment>
                    );
                  })}

                {/* Add new button */}
                {!paymentMethodIsLoading && editCardPayment && !addNewCardPayment && (
                  <>
                    {paymentMethod?.length > 0 && <hr className="b-grey-300 my-20" />}
                    <CardInfoRow>
                      <span
                        className="fs-14 py-10 pr-10 fw-600 fg-primary hover:fg-black active:fg-black cursor-pointer"
                        onClick={() => setAddNewCardPayment(true)}
                      >
                        + Add new
                      </span>
                    </CardInfoRow>
                  </>
                )}

                {/* Add new payment method */}
                {!paymentMethodIsLoading && addNewCardPayment && (
                  <>
                    {paymentMethod?.length > 0 && <hr className="b-grey-300 my-20" />}

                    <CardInfoRow
                      title="Credit Card"
                      className="flex flex-wrap"
                      classTitle="md:show"
                    >
                      <div className="fit-w md:w-6/12 lg:w-270 md:pr-15 py-5 md:py-0">
                        <p className="md:hide fw-500 fg-grey-500 py-5">Card Number</p>
                        <InputExtra
                          disableFiexedHeight
                          disableMargin
                          disablePadding
                          Icon={IconCard}
                          className="h-50 px-15"
                          placeholder="Card number"
                          onChange={onCardNumberChange}
                        />
                      </div>
                      <div className="w-6/12 md:w-3/12 lg:w-160 pr-8 py-5 md:py-0">
                        <p className="md:hide fw-500 fg-grey-500 py-5">MM/YY</p>
                        <InputExtra
                          disableFiexedHeight
                          disableMargin
                          disablePadding
                          Icon={IconDate}
                          className="h-50 px-15"
                          placeholder="MM/YY"
                          onChange={onCardExpChange}
                        />
                      </div>
                      <div className="w-6/12 md:w-3/12 lg:w-160 pl-8 py-5 md:py-0">
                        <p className="md:hide fw-500 fg-grey-500 py-5">CVV</p>
                        <InputExtra
                          disableFiexedHeight
                          disableMargin
                          disablePadding
                          Icon={IconPass}
                          className="h-50 px-15"
                          placeholder="CVV"
                          onChange={onCardCodeChange}
                        />
                      </div>
                    </CardInfoRow>
                  </>
                )}
              </SettingsCardContent>
            </SettingsCard>
          </div>
        </div>
      </div>
      <PaymnetMethodDeleteModal
        isModalVisible={isDeleteModalVisible}
        data={deletePaymentMethod}
        handleOk={onPaymentMethodDelete}
        handleCancel={setIsDeleteModalVisible}
      />
    </SettingsLayout>
  );
};

export default SettingsPage;
