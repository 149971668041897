/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import videoThumbnail from '../Assets/videoThumbnail.jpg';
import { toast } from 'react-toastify';
import { FaPlay } from 'react-icons/fa';
import cn from 'classnames';
import { ContextMenu, MenuItem } from './ContextMenu';
import { useSelector } from 'react-redux';
import { Button } from './FormElements';
import { useNavigate } from 'react-router-dom';
import styleCategories from '../Utils/styleCategories';
import {
  IconCheckEmpty,
  IconCheckFilled,
  IconClock,
  IconEllipsis,
  IconLevel1,
  IconLevel2,
  IconLevel3,
  IconLevel4,
  IconLevel5,
} from './Icons';

const BadgeCategory = ({ id, className }) => {
  const { categories } = useSelector((state) => state.explore);
  const category = categories.find((el) => el.id === id);
  const { Icon, ...styleCategory } = styleCategories.find((el) => el.id === id);

  return (
    <div
      className={cn(
        'inline-flex align-center h-30 md:h-35 fs-12 md:fs-14 fw-600 lh-1 px-10 md:px-15 r-100 goal bg-white fg-black',
        className,
        styleCategory?.className,
      )}
    >
      {Icon && <Icon className="flex-0 w-15 h-15 mr-5" />}
      <span className="text-trim">{category?.title || 'Custom'}</span>
    </div>
  );
};

const BadgeUpNext = ({ className }) => {
  return (
    <div
      className={cn(
        'flex-0 inline-flex align-center ml-auto h-30 md:h-35 fs-12 md:fs-14 lh-1 px-10 md:px-15 r-100 goal bg-black/50 fg-white',
        className,
      )}
    >
      <span>Up next</span>
    </div>
  );
};

const IconLevel = ({ level, ...props }) => {
  switch (level) {
    case 1:
      return <IconLevel1 {...props} />;
    case 2:
      return <IconLevel2 {...props} />;
    case 3:
      return <IconLevel3 {...props} />;
    case 4:
      return <IconLevel4 {...props} />;
    case 5:
      return <IconLevel5 {...props} />;
    default:
      return false;
  }
};

const ExerciseCard = ({
  actions = [],
  standAlone = false,
  disableControls = false,
  disableCategory = false,
  disableLevel = false,
  disableContextMenu = false,
  selected,
  onSelect,
  index,
  id,
  isFreeUser,
  forceUpgradeRequired = false,
  category_type,
  ovalColor,
  upNext,
  list_of_videos,
  video_category,
  video_instructors,
  video_title,
  video_thumbnail,
  duration,
  video_level,
  scheduled,
  isPlaying,
  onVideoClicked,
  onScheduleClicked,
  onDelete,
  onLevelChange,
  onVideoUnavailable,
  ...props
}) => {
  // const hasMoveAction = actions.includes('move');
  const hasDeleteAction = actions.includes('delete');

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  // const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [toolTipParams, setToolTipParams] = useState(false);
  const videoObject = _.first(_.orderBy(list_of_videos, ['video'], ['asc']));
  const hasVideo = list_of_videos?.filter((el) => el.video).length > 0;

  // var equals = list_of_videos.every(function (video, index, array) {
  //   return video.category === array[0].category;
  // });

  // let category = equals ? list_of_videos.length && list_of_videos[0].category : 'Custom';

  const category = video_category || list_of_videos?.[0]?.video_category.id || null;
  const upgradeRequired = forceUpgradeRequired || (isFreeUser && !videoObject?.video);
  const refPlayButton = useRef(null);

  const videoTitle =
    video_title
      ?.replaceAll('/n', '\n')
      .split('\n')
      .map((el) => el.trim())
      .filter((el) => !!el)
      .join(' — ') || 'Title';

  const imageOnLoad = (e) => {
    e.target.classList.add('fadein');
    e.target.classList.remove('transparent');
  };

  const onVideoPlay = (videoUrl) => {
    if (videoUrl === undefined) {
      onVideoUnavailable();
      return;
    }

    if (_.size(videoUrl) === 0) {
      toast.error('This video is not available.');
      return;
    }

    let urls = _.filter(list_of_videos, (it) => !!it.video).map((it) => {
      return { id: it.id, video: it.video };
    });

    onVideoClicked(urls, id);
  };

  // const onVideoPause = () => {
  //   // videoRef.current.pause();
  //   // setIsPlaying(false);
  // };

  // function handleMenuClick(e) {
  //   onLevelChange(id, e.key);
  // }

  // const htmlDecode = (input) => {
  //   var e = document.createElement('div');
  //   e.innerHTML = input;
  //   return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  // };

  const Dropdown = () => {
    const handleClickScheduled = (e) => {
      e.stopPropagation();
      onScheduleClicked(id);
    };

    const handleClickDelete = (e) => {
      e.stopPropagation();
      onDelete(id);
    };

    return (
      !disableContextMenu && (
        <>
          <div
            className="flex w-35 h-35 r-100 hover:bg-grey-800 active:bg-grey-800 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
          >
            <IconEllipsis className="w-25 m-auto" />
          </div>
          <ContextMenu
            visible={showMenu}
            onClose={() => setShowMenu(false)}
            className="top-50 right-15"
          >
            <MenuItem className="fg-black" onClick={handleClickScheduled}>
              Schedule workout
            </MenuItem>

            {hasDeleteAction && !isFreeUser && (
              <MenuItem className="fg-red-700" onClick={handleClickDelete}>
                Delete workout
              </MenuItem>
            )}
          </ContextMenu>
        </>
      )
    );
  };

  const CheckBoxSelect = () => {
    const Icon = selected ? IconCheckFilled : IconCheckEmpty;
    const handleClick = (e) => {
      e.stopPropagation();
      onSelect(id, !selected);
    };

    return (
      hasVideo && (
        <Icon className="fg-primary w-25 h-25 ml-20 cursor-pointer" onClick={handleClick} />
      )
    );
  };

  let timerTooltip;

  const onMouseEnter = (e) => {
    if (e.touches?.length > 0) return false;

    clearTimeout(timerTooltip);
    timerTooltip = setTimeout(() => {
      setToolTipParams({
        x: e.clientX,
        y: e.clientY,
        text: video_title?.replaceAll('/n', '\n'),
      });
      clearTimeout(timerTooltip);
    }, 100);
  };

  const onMouseLeave = () => {
    clearTimeout(timerTooltip);
    setToolTipParams(false);
  };

  const ToolTip = () => {
    const { x = 0, y = 0, text = '' } = toolTipParams;

    if (!x || !y || !text) return false;

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br />'),
        }}
        className="md:show"
        style={{
          position: 'fixed',
          top: y + 10,
          left: x + 10,
          zIndex: 9999,
          color: '#eee',
          backgroundColor: '#272626',
          padding: '5px 9px',
          fontSize: '12px',
          fontWeight: '400',
          maxWidth: '500px',
          borderRadius: '3px',
        }}
      />
    );
  };

  useEffect(() => {
    if (id === parseInt(params.get('play_video')) && refPlayButton.current) {
      refPlayButton.current.click();
    }
  }, [id]);

  return (
    <div className={cn('fit-w py-10', !standAlone && 'md:w-6/12 lg:w-3/12')}>
      <div
        className={cn(
          'r-15 bg-white b-1 of-hidden exercise-card cursor-pointer',
          selected ? 'b-primary' : 'b-grey-300',
        )}
        style={
          upNext
            ? {
                borderColor: ovalColor,
                boxShadow: `0px 0px 10px 5px ${ovalColor}59`,
              }
            : null
        }
        onClick={() =>
          !upgradeRequired
            ? onVideoPlay(videoObject?.video)
            : navigate('/my-profile?page=subscription')
        }
      >
        <div className="content-wrapper">
          <div
            className="relative video-goal-container"
            onMouseLeave={() => {
              setShowMenu(false);
            }}
          >
            <div
              className={cn('bg-grey-400', upgradeRequired && 'disabled')}
              style={{ paddingTop: '56%' }}
            >
              <img
                loading="lazy"
                onLoad={imageOnLoad}
                src={video_thumbnail || videoThumbnail}
                alt=""
                className="absolute top-0 left-0 fit fit-cover transparent"
              />
            </div>

            {!disableControls && (
              <>
                <div className="md:show absolute fit top-0 left-0 p-20">
                  <div className="fit-w flex align center">
                    {!disableCategory && (
                      <BadgeCategory
                        id={category}
                        className={cn('text-trim', upgradeRequired && 'disabled')}
                      />
                    )}
                    {upNext && <BadgeUpNext />}
                  </div>
                </div>
                <div
                  className="absolute fit top-0 left-0 fg-white controls"
                  style={{
                    backgroundColor: !upgradeRequired ? 'rgba(0,0,0,.45)' : null,
                  }}
                >
                  <div
                    className="md:show absolute flex fit top-0 left-0 fg-white"
                    style={{ pointerEvents: 'none' }}
                  >
                    {!upgradeRequired ? (
                      <div
                        ref={refPlayButton}
                        className="flex w-65 h-65 m-auto cursor-pointer"
                        style={{ pointerEvents: 'auto' }}
                        onClick={() => onVideoPlay(videoObject?.video)}
                      >
                        <FaPlay className="w-35 m-auto" />
                      </div>
                    ) : (
                      <div className="flex mx-auto mt-auto" style={{ pointerEvents: 'auto' }}>
                        <Button
                          primary
                          size="small"
                          disablePadding
                          className="px-30 mb-20"
                          onClick={() => navigate('/my-profile?page=subscription')}
                        >
                          Upgrade Plan
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="fit-w h-auto md:h-75 flex align-center p-15 md:p-20">
                    <div className="md:hide fit-w flex align-center">
                      {!disableCategory && (
                        <BadgeCategory id={category} className={upgradeRequired && 'disabled'} />
                      )}
                      <div
                        className="flex ml-10 w-35 h-35 r-100"
                        onClick={() =>
                          !upgradeRequired
                            ? onVideoPlay(videoObject?.video)
                            : navigate('/my-profile?page=subscription')
                        }
                      >
                        <FaPlay className="w-20 m-auto" />
                      </div>
                    </div>
                    <div className="flex-0 flex align-center ml-auto sx-5">
                      {upNext && <BadgeUpNext className="md:hide" />}
                      {!upgradeRequired ? (
                        !upNext && <Dropdown />
                      ) : (
                        <Button
                          primary
                          size="none"
                          className="fs-13 md:hide h-30 px-15"
                          onClick={() => navigate('/my-profile?page=subscription')}
                        >
                          Upgrade Plan
                        </Button>
                      )}

                      {disableContextMenu && typeof onSelect === 'function' && <CheckBoxSelect />}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={cn(
              'flex fit-w flex-col p-20 exercise-information-container',
              upgradeRequired && 'disabled',
            )}
          >
            <div className="flex fit-w information-row">
              <div
                className="fs-14 md:fs-16 fw-700 text-left md:text-trim mb-20 exercise-title"
                dangerouslySetInnerHTML={{
                  __html: videoTitle,
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            </div>

            <div className="flex flex-nowrap information-row instructors-datetime-row">
              <div className="mr-auto">
                <div className="flex flex-nowrap pr-15 instructors">
                  {video_instructors?.map((instructor, key) => (
                    <div
                      key={key}
                      className="w-30 h-30 r-100 of-hidden img-avatar"
                      style={{ marginRight: '-12px', zIndex: video_instructors.length - key }}
                    >
                      <img
                        loading="lazy"
                        onLoad={imageOnLoad}
                        className="fit fit-cover instructor-avatar transparent"
                        src={instructor?.picture}
                        alt={instructor?.name}
                        key={key}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {!disableLevel && video_level > 0 && (
                <div className="flex flex-nowrap align-center lh-1 pl-10 level">
                  <IconLevel level={video_level} className="w-15 h-15 mx-5 my-auto" />
                  <span className="fs-12 lg:fs-14 fg-grey-500 text-trim">
                    {/* {pathname === '/my-program' ? `${video_level} level` : `Level ${video_level}`} */}
                    Level {video_level}
                  </span>
                </div>
              )}

              <div className="flex flex-nowrap align-center lh-1 pl-10 duration">
                <IconClock className="w-15 h-15 mr-5 my-auto fg-primary" />
                <span className="fs-12 lg:fs-14 fg-grey-500 text-trim">{duration} mins</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToolTip />
    </div>
  );
};

export default ExerciseCard;
