/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  apiSettingPatch,
  apiAvatarChange,
  apiSettingRequest,
  apiPasswordChangeRequest,
} from '../Redux/actions/setting';
import {
  apiSubscriptionRequest,
  apiPaymentMethodRequest,
  apiUpdatePaymentMethodRequest,
  apiDeletePaymentMethodRequest,
} from '../Redux/actions/subscription';
import { updateAvatar } from '../Redux/actions/login';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import { updateSideMenuSelection } from '../Redux/actions/sideMenu';
import SettingsPage from '../Pages/SettingsPage';
import PictureCropModal from '../Components/PictureCropModal';
import { showErrorToastNoti } from '../Utils/errorToast';
import { toast } from 'react-toastify';
// import * as moment from 'moment';
import ProfilePage from '../Pages/ProfilePage';
import SettingsLayout from '../Layouts/SettingsLayout';
import SubscriptionContainer from './SubscriptionContainer';
import { apiDeleteAccountRequest } from '../Redux/actions/setting';
import {getSubscriptionPlanId} from "../Utils/subscriptionUtils";

const changePasswdSchema = yup.object().shape({
  current_password: yup.string().required('This field is required.'),
  new_password1: yup
    .string()
    .required('This field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters, of which at least one numeric and one upper case',
    ),
  new_password2: yup.string().oneOf([yup.ref('new_password1'), null], 'Passwords must match'),
});

const SettingsContainer = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();

  const cardMenuSelection = useSelector((state) => state.cardMenuSelection);
  const subscriptionPlans = useSelector((state) => state.subscription);
  const profile = useSelector((state) => state.setting);
  const auth = useSelector((state) => state.auth);

  const [avatarImage, setAvatarImage] = useState(null);
  const [isAvatarChanged, setIsAvatarChanged] = useState(false);
  const [prevPlan] = useState(0);
  // const [prevPlanEndDate] = useState('2021-12-11T18:49:14.866375Z');
  const [isAddPaymentModalVisible, setAddPaymentModalVisible] = useState(false);

  const [subscriptionPlanId, setSubscriptionPlanId] = useState(1);

  useEffect( () => {
    getSubscriptionPlanId(profile).then((res) => {
      setSubscriptionPlanId(res);
    })
  }, [profile])

  const currentPlan = _.find(subscriptionPlans.subscription, {
    id: subscriptionPlanId,
  });

  const {
    register: changePasswordRegister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(changePasswdSchema),
  });

  useEffect(() => {
    if (query.get('page') === null) {
      dispatch(updateCardMenuSelection('subscription'));
    }
    dispatch(updateSideMenuSelection('my-profile'));
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
    dispatch(apiSubscriptionRequest());
    dispatch(apiPaymentMethodRequest());
  }, []);

  useEffect(() => {
    if (!query.get('page')) navigate('/my-profile?page=profile');
    dispatchMenu();
  }, [query.get('page')]);

  useEffect(() => {
    if (!subscriptionPlans.isLoading && subscriptionPlans.success && isAddPaymentModalVisible) {
      setAddPaymentModalVisible(false);
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    showErrorToastNoti(profile);

    if (profile.success && isAvatarChanged) {
      dispatch(updateAvatar(profile.setting.avatar));
    }

    if (!profile.isLoading && profile.passwordChangeSuccess) {
      toast.success('Password changed successfully');
      reset();
    }

    // if (!profile.isLoading && !profile.passwordChangeSuccess && profile.error) {
    //   toast.error(profile.error.message?.Error || profile.error.message);
    // }
  }, [profile]);

  useEffect(() => {
    if (
      !subscriptionPlans.isLoading &&
      subscriptionPlans.success &&
      subscriptionPlans.isPlanChanged
    ) {
      if (prevPlan === 1) {
        // const planName = _.find(subscriptionPlans.subscription, {
        //   id: profile.setting?.active_membership?.plan?.id,
        // })?.['plan_name'];
        // let expireMonth = 3;
        // if (profile.setting?.active_membership?.plan?.id === 3) {
        //   expireMonth = 12;
        // }
        // const expireDate = moment(prevPlanEndDate).add(expireMonth, 'M').format('MM-DD-YYYY');

        // toast.warning(`You are currently in ${planName} which will expire on ${expireDate}`);
        toast.success('Subscription successfully downgraded.');
      }
      dispatch(apiSettingRequest({ user_id: auth.user.id }));
    }
    showErrorToastNoti(subscriptionPlans);
  }, [subscriptionPlans]);

  const reloadProfile = () => {
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  };

  const dispatchMenu = () => {
    const page = query.get('page');
    dispatch(updateCardMenuSelection(page));
  };

  const onSettingChange = (val) => {
    let tempSetting = profile.setting;
    tempSetting[val] = !tempSetting[val];
    let formData = {
      calendar_integration_enabled: tempSetting.calendar_integration_enabled,
      workout_reminders_enabled: tempSetting.workout_reminders_enabled,
      newsletter_enabled: tempSetting.newsletter_enabled,
      user_id: auth.user.id,
    };
    dispatch(apiSettingPatch(formData));
  };

  const onUsernameChange = (name) => {
    dispatch(apiSettingPatch({ name, user_id: auth.user.id }));
  };

  const uploadSelectedImage = (imageFile) => {
    setAvatarImage(imageFile);
  };

  // const onSubscriptionSelected = (id, membership_type) => {
  //   setPrevPlan(id);
  //   if (membership_type === 'Free') {
  //     let data = {
  //       plan_id: id,
  //     };
  //     dispatch(apiChangeSubscriptionRequest(data));
  //   } else {
  //     // navigate('/checkout?type=' + id);
  //   }
  // };

  // const onSubmitCoupon = (val) => {
  //   let data = {
  //     promo_code: val,
  //   };
  //   dispatch(apiCouponRequest(data));
  // };

  const onPaymentMethodChange = (cardNumber, cardExpMonth, cardExpYear, cardCode, isError) => {
    if (!isError) {
      let data = {
        card_number: cardNumber?.replace(/\s/g, ''),
        expiry_date_month: cardExpMonth?.replace(/\s/g, ''),
        expiry_date_year: cardExpYear?.replace(/\s/g, ''),
        security_code: cardCode?.replace(/\s/g, ''),
        card_holder_name: profile.setting.name,
      };
      dispatch(apiUpdatePaymentMethodRequest(data));
    } else {
      toast.warning('Please fix error before you submit');
    }
  };

  const onPaymentMethodDelete = (paymentMethodId) => {
    if (paymentMethodId) {
      dispatch(apiDeletePaymentMethodRequest(paymentMethodId));
    } else {
      toast.warning('Payment menthod ID is missing');
    }
  };

  const onImageCropChangeHandle = async (image) => {
    dispatch(apiAvatarChange({ image }));

    setIsAvatarChanged(true);
    setAvatarImage(null);
  };

  const onImageCropCancelHandle = () => {
    setAvatarImage(null);
  };

  const onPasswordChangeHandle = (data) => {
    dispatch(apiPasswordChangeRequest(data));
  };

  const onAccountDeleteHandle = () => {
    dispatch(apiDeleteAccountRequest(auth.user.id));
  };

  // return cardMenuSelection.menu === 'profile' ? (
  //   <DashboardSubscriptionPage
  //     state={subscriptionPlans}
  //     onSubscriptionSelected={onSubscriptionSelected}
  //     currentPlan={profile.setting?.active_membership}
  //     onSubmitCoupon={onSubmitCoupon}
  //   />
  // ) : (

  return (
    <>
      {cardMenuSelection.menu === 'subscription' && (
        <SettingsLayout>
          <SubscriptionContainer showPlanTableOnly />
        </SettingsLayout>
      )}

      {cardMenuSelection.menu === 'profile' && (
        <ProfilePage
          onUsernameChange={onUsernameChange}
          uploadSelectedImage={uploadSelectedImage}
          reloadProfile={reloadProfile}
          onSettingChange={onSettingChange}
          onPasswordChange={handleSubmit(onPasswordChangeHandle)}
          onAccountDelete={onAccountDeleteHandle}
          profile={profile}
          isAvatarChanged={isAvatarChanged}
          changePasswordRegister={changePasswordRegister}
          passwordChangeErrors={errors}
        />
      )}

      {cardMenuSelection.menu === 'settings' && (
        <SettingsPage
          onUpdatePaymentMethodClick={() => setAddPaymentModalVisible(true)}
          onSettingChange={onSettingChange}
          onPaymentMethodChange={onPaymentMethodChange}
          onPaymentMethodDelete={onPaymentMethodDelete}
          profile={profile}
          paymentMethod={subscriptionPlans.paymentMethod}
          paymentMethodIsLoading={subscriptionPlans.isLoading}
          currentPlan={currentPlan}
          isAvatarChanged={isAvatarChanged}
        />
      )}

      {/* {isAddPaymentModalVisible && (
        <AddPaymentMethod
          isModalVisible={isAddPaymentModalVisible}
          handleOk={onPaymentMethodChange}
          handleCancel={() => setAddPaymentModalVisible(false)}
          isLoading={subscriptionPlans.isLoading}
        ></AddPaymentMethod>
      )} */}

      <PictureCropModal
        isModalVisible={!!avatarImage}
        picture={avatarImage}
        handleOk={onImageCropChangeHandle}
        handleCancel={onImageCropCancelHandle}
        isLoading={false}
      />
    </>
  );
  // );
};

export default SettingsContainer;
