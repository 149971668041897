import React from 'react';
import Modal from './Modal';
import { Button } from './FormElements';

const AccountDeleteModal = ({ ...props }) => {
  const { isModalVisible, handleOk, handleCancel } = props;

  const handleClose = () => {
    handleCancel();
  };

  const onClickedOk = () => {
    handleOk();
  };

  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isModalVisible} onClose={handleClose}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">Delete account</p>
      <p className="fg-grey-500 pb-20">Are you sure you want to delete your account?</p>
      <p>
        <Button red className="fit-w md:w-290 my-15" onClick={onClickedOk}>
          Delete
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-16 fw-600 mt-20 underline hover:fg-primary"
          onClick={handleClose}
        >
          Cancel
        </button>
      </p>
    </Modal>
  );
};

export default AccountDeleteModal;
