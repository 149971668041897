import { put, call, all, spawn, takeEvery } from 'redux-saga/effects';
import { settingService } from '../services/setting';
import * as types from '../constants/setting';
import * as actions from '../actions/setting';

// Faq
function* apiSettingRequestWorker(action) {
  try {
    const result = yield call(settingService.apiSettingRequest, action);
    yield put(actions.apiSettingRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiSettingRequestFailed(err));
  }
}

function* apiSettingRequestWatcher() {
  yield takeEvery(types.API_REQUEST_SETTING, apiSettingRequestWorker);
}

function* apiAvatarChangeWorker(action) {
  try {
    const result = yield call(settingService.apiAvatarChange, action);
    yield put(actions.apiAvatarChangeSuccess(result));
  } catch (err) {
    yield put(actions.apiAvatarChangeFailed(err));
  }
}

function* apiAvatarChangeWatcher() {
  yield takeEvery(types.API_AVATAR_CHANGE, apiAvatarChangeWorker);
}

function* apiSettingPatchWorker(action) {
  try {
    const result = yield call(settingService.apiSettingPatch, action);
    yield put(actions.apiSettingPatchSuccess(result));
  } catch (err) {
    yield put(actions.apiSettingPatchFailed(err));
  }
}

function* apiSettingPatchWatcher() {
  yield takeEvery(types.API_PATCH_SETTING, apiSettingPatchWorker);
}

function* apiPasswordChangeRequest(action) {
  try {
    const result = yield call(settingService.apiPasswordChangeRequest, action);
    yield put(actions.apiPasswordChangeRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiPasswordChangeRequestFailed(err));
  }
}

function* apiPasswordChangeRequestWatcher() {
  yield takeEvery(types.API_PASSWORD_CHANGE_REQUEST, apiPasswordChangeRequest);
}

// Update calendar
function* apiUpdateCalendarRequestWorker(action) {
  try {
    const result = yield call(settingService.apiUpdateCalendarRequest, action);
    yield put(actions.apiUpdateCalendarRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiUpdateCalendarRequestFailed(err));
  }
}

function* apiUpdateClendarRequestWatcher() {
  yield takeEvery(types.API_UPDATE_CALENDAR_REQUEST, apiUpdateCalendarRequestWorker);
}

// Delete Account
function* apiDeleteAccountRequestWorker(action) {
  try {
    const result = yield call(settingService.apiDeleteAccountRequest, action);
    yield put(actions.apiDeleteAccountRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiDeleteAccountRequestFailed(err));
  }
}

function* apiDeleteAccountRequestWatcher() {
  yield takeEvery(types.API_DELETE_ACCOUNT_REQUEST, apiDeleteAccountRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* settingRootSaga() {
  const sagas = [
    apiSettingRequestWatcher,
    apiSettingPatchWatcher,
    apiAvatarChangeWatcher,
    apiPasswordChangeRequestWatcher,
    apiUpdateClendarRequestWatcher,
    apiDeleteAccountRequestWatcher,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}
