import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiGetMyStats() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/my_stats/`, { headers });
}

export const myStatsService = {
  apiGetMyStats,
};
