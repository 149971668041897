/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import SignUpPage from '../Pages/SignUpPage';
import { useDispatch, useSelector } from 'react-redux';
import { resetReduxState } from '../Redux/actions';
import { toast } from 'react-toastify';
import {
  apiQuestionRequest,
  apiSessionKeyRequest,
  apiSignUpQuestionnaireRequest,
  apiSubmitAnswerRequest,
} from '../Redux/actions/signup';

const SignUpContainer = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { question_id } = useParams();

  const state = useSelector((state) => state.signup);
  const auth = useSelector((state) => state.auth);
  const isLoggedIn = Boolean(auth.token && auth.user);

  const [init, setInit] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [expandId, setExpandId] = useState(-1);

  useEffect(() => {
    setInit(false);

    if (isLoggedIn) {
      toast.success('You are currently logged in.');
      navigate('/dashboard');
    } else {
      dispatch(resetReduxState());
      setSelectedChoices([]);
      setExpandId(-1);
      setInit(true);
      navigate('/signup/1', { replace: true });
    }
  }, []);

  // useEffect(() => {
  //   if (isLoggedIn || !init) return;

  //   if (!state.session_key) {
  //     dispatch(apiSessionKeyRequest());
  //   }
  // }, [state.session_key, init]);

  useEffect(() => {
    if (init) dispatch(apiSessionKeyRequest());
  }, [init]);

  useEffect(() => {
    if (isLoggedIn || !init) return;

    if (!question_id) dispatch(apiSignUpQuestionnaireRequest());
    else if (
      !state.currentQuestion ||
      (state.currentQuestion && state.currentQuestion.id !== question_id)
    )
      dispatch(apiQuestionRequest(question_id));
  }, [question_id, init]);

  useEffect(() => {
    if (isLoggedIn || !init) return;

    if (state.questionnaire && !question_id) {
      navigate(`/signup/${state.questionnaire.head}`, { replace: true });
    }
  }, [state.questionnaire, init]);

  useEffect(() => {
    if (isLoggedIn || !init) return;

    setSelectedChoices([]);

    // console.log(state.currentQuestion.choice_question);

    if (
      state.currentQuestion !== null &&
      state.success &&
      state.currentQuestion.id &&
      state.currentQuestion.id !== question_id
    )
      navigate(`/signup/${state.currentQuestion.id}`);
    else if (state.currentQuestion !== null && !state.currentQuestion.choice_question)
      navigate(`/add-account-details`);
  }, [state.currentQuestion, init]);

  const isChoiceSelected = (choiceId) => {
    const result = selectedChoices.find((element) => element.choice === choiceId);

    return !!result;
  };

  const onChoiceClicked = (id) => {
    // reset the selected sub choices
    // update the choices
    setSelectedChoices((oldArray) => {
      if (state.currentQuestion.question_type.name.indexOf('Single Choice') === -1) {
        const arrayCopy = JSON.parse(JSON.stringify(oldArray));

        if (isChoiceSelected(id)) {
          return arrayCopy.filter((element) => element.choice !== id);
        } else {
          const newChoiceObject = {
            choice: id,
            sub_choices: [],
          };

          return [...arrayCopy, newChoiceObject];
        }
      } else {
        const newChoiceObject = {
          choice: id,
          sub_choices: [],
        };

        return [newChoiceObject];
      }
    });
  };

  const onSubChoicesClicked = (choiceId, subChoiceId) => {
    setExpandId(-1);
    if (!isChoiceSelected(choiceId)) {
      setSelectedChoices((oldArray) => {
        const arrayCopy = JSON.parse(JSON.stringify(oldArray));

        const newChoiceObject = {
          choice: choiceId,
          sub_choices: [subChoiceId],
        };

        return [...arrayCopy, newChoiceObject];
      });
    } else {
      setSelectedChoices((oldArray) => {
        const arrayCopy = JSON.parse(JSON.stringify(oldArray));

        return arrayCopy.map((obj) => {
          // find the choice
          if (obj.choice === choiceId) {
            // check if sub choice is already selected
            if (obj.sub_choices.includes(subChoiceId)) {
              // remove subchoice selection
              obj.sub_choices = obj.sub_choices.filter((element) => element !== subChoiceId);
            } else {
              // select the subchoice
              obj.sub_choices = [subChoiceId];
            }
          }

          return obj;
        });
      });
    }
  };

  const onAnswerSubmit = () => {
    if (_.size(selectedChoices) === 0) {
      toast.warning('Please select a choice.');
      return;
    }
    // save answer
    const requestData = {
      question: state.currentQuestion.id,
      session_key: state.session_key,
      choices: selectedChoices,
    };

    dispatch(apiSubmitAnswerRequest(requestData));
  };

  const onClickExpand = (id) => {
    if (id === expandId) {
      setExpandId(-1);
    } else {
      setExpandId(id);
    }
  };

  const onClickBack = () => {
    navigate(state.currentQuestion.id > 1 ? -1 : '/');
  };

  return (
    <SignUpPage
      question={state.currentQuestion}
      progress={state.currentQuestion?.progress}
      isLoading={state.isLoading}
      selectedChoices={selectedChoices}
      onChoiceClicked={onChoiceClicked}
      onSubChoicesClicked={onSubChoicesClicked}
      onAnswerSubmit={onAnswerSubmit}
      expandId={expandId}
      onClickExpand={onClickExpand}
      onClickBack={onClickBack}
    />
  );
};

export default SignUpContainer;
