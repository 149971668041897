import * as types from '../constants/faq';

export const resetFaqState = () => ({
    type: types.RESET_FAQ_STATE
});

// LOGIN
export const apiGetFaqRequest = () => ({
    type: types.API_GET_REQUEST_FAQ
});

export const apiGetFaqRequestSuccess = (response) => ({
    type: types.API_GET_REQUEST_FAQ_SUCCESS,
    response
});

export const apiGetFaqRequestFailed = (response) => ({
    type: types.API_GET_REQUEST_FAQ_FAILED,
    response
});
