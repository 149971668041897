/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import {
  apiProgramRequest,
  apiScheduleVideoRequest,
  apiLevelChangeRequest,
  apiVideoActivityRequest,
  // lockVideos,
} from '../Redux/actions/studioVideo';
import { apiVideoCateogryRequest } from '../Redux/actions/exploreVideo';

import MyStudioPage from '../Pages/MyStudio';
import { showErrorToastNoti } from '../Utils/errorToast';

import { apiSettingRequest, unlockAccessAlertShow } from '../Redux/actions/setting';
import {checkIsFreeUser} from "../Utils/subscriptionUtils";

const MyProgramContainer = () => {
  const dispatch = useDispatch();
  const studioVideo = useSelector((state) => state.studio);
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.setting);
  const [isFreeUser, setIsFreeUser] = useState(true);
  const { categories } = useSelector((state) => state.explore);

  useEffect( () => {
    checkIsFreeUser(profile).then((res) => {
      setIsFreeUser(res);
    })
  }, [profile])

  useEffect(() => {
    showErrorToastNoti(studioVideo);
  }, [studioVideo]);

  const onVideoScheduled = (id, scheduledDate) => {
    let data = {
      video_id: id,
      scheduled_date: scheduledDate,
    };
    dispatch(apiScheduleVideoRequest(data));
  };

  useEffect(() => {
    dispatch(updateCardMenuSelection('my-program'));
    dispatch(apiVideoCateogryRequest());
    dispatch(apiProgramRequest());
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  }, []);

  const onLevelChange = (id, change) => {
    let data = {
      video_id: id,
      user_choice: change,
    };
    dispatch(apiLevelChangeRequest(data));
  };

  const onVideoWatched = (id, watchedTime) => {
    let data = {
      video_id: id,
      duration_watched: Math.round(watchedTime / 60),
    };
    dispatch(apiVideoActivityRequest(data));
  };

  const onVideoUnavailableHandle = () => {
    dispatch(unlockAccessAlertShow());
  };

  // const onVideoPlayedHandle = (id) => {
  //   dispatch(lockVideos(id));
  // };

  return (
    <MyStudioPage
      state={studioVideo}
      categories={categories}
      onVideoScheduled={onVideoScheduled}
      actions={['move']}
      onLevelChange={onLevelChange}
      onVideoWatched={onVideoWatched}
      onVideoUnavailable={onVideoUnavailableHandle}
      isFreeUser={isFreeUser}
      // onVideoPlayed={onVideoPlayedHandle}
    />
  );
};

export default MyProgramContainer;
