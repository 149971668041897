import React from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import { FaCircleNotch } from 'react-icons/fa';
import { Button, FooterWrapper } from './FormElements';
import { IconClock, IconLevel1, IconLevel2, IconLevel3, IconLevel4, IconLevel5 } from './Icons';

const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const IconLevel = ({ level, ...props }) => {
  switch (level) {
    case 1:
      return <IconLevel1 {...props} />;
    case 2:
      return <IconLevel2 {...props} />;
    case 3:
      return <IconLevel3 {...props} />;
    case 4:
      return <IconLevel4 {...props} />;
    case 5:
      return <IconLevel5 {...props} />;
    default:
      return false;
  }
};

const VideoPreviewModal = ({ ...props }) => {
  const { id, isModalVisible, isLoading, handleOk, handleCancel } = props;
  const state = useSelector((state) => state.studio.videos);
  const workout = state?.find((video) => video.id === id);

  const videoTitle = workout?.video_title
    ?.replaceAll('/n', '\n')
    .split('\n')
    .map((el) => el.trim())
    .filter((el) => !!el)
    .join(' — ');

  const handleClose = () => {
    handleCancel();
  };

  const handleClick = () => {
    handleOk();
  };

  return (
    <Modal
      size="xs"
      defaultClass={false}
      className="text-center md:text-left fs-14 md:fs-18 flex flex-col flex-nowrap"
      visible={isModalVisible}
      onClose={handleClose}
    >
      <div className="px-20 md:px-40">
        <p className="fs-24 fw-800 md:fw-700 mb-20">{videoTitle}</p>

        <div className="flex information-row instructors-datetime-row">
          <div className="mr-auto md:mr-15">
            <div className="flex instructors">
              {workout?.video_instructors?.map((instructor, key) => (
                <div
                  key={key}
                  className="w-35 h-35 r-100 of-hidden bg-grey-300 img-avatar"
                  style={{ marginRight: '-12px', zIndex: workout?.video_instructors.length - key }}
                >
                  <img
                    className="fit fit-cover instructor-avatar"
                    src={instructor?.picture || emptyImage}
                    alt={instructor?.name}
                    key={key}
                  />
                </div>
              ))}
            </div>
          </div>
          {workout?.video_level > 0 && (
            <div className="flex align-center lh-1 ml-10 level">
              <IconLevel level={workout.video_level} className="w-15 md:w-20 mr-5 my-auto" />
              <span className="fs-12 md:fs-16 fg-grey-500">Level {workout.video_level}</span>
            </div>
          )}
          <div className="flex align-center lh-1 ml-10 duration">
            <IconClock className="h-15 md:h-20 mr-5 my-auto fg-primary" />
            <span className="fs-12 md:fs-16 fg-grey-500">{workout?.duration} mins</span>
          </div>
        </div>
        <img
          src={workout?.video_thumbnail || emptyImage}
          alt=""
          className="fit-w fit-cover r-10 bg-grey-300 my-20"
        />
      </div>

      <FooterWrapper disableBackground>
        <Button primary className="fit-w md:w-290" onClick={handleClick}>
          Start workout
        </Button>
      </FooterWrapper>

      {isLoading && <FaCircleNotch className="w-25 h-25 spin fg-primary" />}
    </Modal>
  );
};

export default VideoPreviewModal;
