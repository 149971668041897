/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideDetector(ref, isMe, choiceId, onClickExpand) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && isMe) {
        onClickExpand(choiceId);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, isMe]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsiteDetector(props) {
  const wrapperRef = useRef(null);
  const { isMe, choiceId, onClickExpand, className } = props;
  useOutsideDetector(wrapperRef, isMe, choiceId, onClickExpand);

  return (
    <div ref={wrapperRef} className={className}>
      {props.children}
    </div>
  );
}

OutsiteDetector.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OutsiteDetector;
