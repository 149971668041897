import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { eventsService } from "../services/events";

import * as types from "../constants/events";
import * as actions from "../actions/events";

// Fetch events
function* apiEventsRequestWorker(action) {
  try {
    const result = yield call(eventsService.apiEventsRequest, action);
    yield put(actions.apiEventsRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiEventsRequestFailed(err));
  }
}

function* apiEventsRequestWatcher() {
  yield takeEvery(types.API_EVENTS_REQUEST, apiEventsRequestWorker);
}

// Order event
function* apiOrderEventRequestWorker(action) {
  try {
    const result = yield call(eventsService.apiOrderEventRequest, action);
    yield put(actions.apiOrderEventRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiOrderEventRequestFailed(err));
  }
}

function* apiOrderEventRequestWatcher() {
  yield takeEvery(types.API_ORDER_EVENT_REQUEST, apiOrderEventRequestWorker);
}

// Cancel order event
function* apiOrderCancelEventRequestWorker(action) {
  try {
    const result = yield call(eventsService.apiOrderCancelEventRequest, action);
    yield put(actions.apiOrderCancelEventRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiOrderCancelEventRequestFailed(err));
  }
}

function* apiOrderCancelEventRequestWatcher() {
  yield takeEvery(types.API_ORDER_CANCEL_EVENT_REQUEST, apiOrderCancelEventRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* subscriptionRootSaga() {
  const sagas = [apiEventsRequestWatcher, apiOrderEventRequestWatcher, apiOrderCancelEventRequestWatcher];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
