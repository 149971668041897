import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';

const menuItems = [
  { type: 'workshop', title: 'Workshops' },
  { type: 'private', title: 'Private Sessions' },
];

const LiveLayout = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const currentType =
    menuItems.find((el) => el.type === params.get('category'))?.type || menuItems[0].type;

  return (
    <DashboardLayout>
      <div className="relative">
        <div className="fs-24 md:fs-30 fw-800 md:fw-700 pb-20">Live</div>
        <div className="fs-14 md:fs-16 fw-600 md:fw-500 fg-grey-500 flex">
          {menuItems.map(({ type, title }, key) => {
            const isCurrentLink = currentType === type;

            return (
              <React.Fragment key={key}>
                <Link
                  to={`/live?category=${type}`}
                  className={cn(
                    'pb-10 mr-25',
                    // isLoading && !isCurrentLink && 'pointer-off',
                    isCurrentLink
                      ? 'fw-600 fg-primary bb-3 b-primary'
                      : 'hover:fg-black active:fg-black',
                  )}
                >
                  {title}
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <hr className="b-grey-300 m-0" style={{ marginTop: '-2px' }} />

      <div className="flex-1 flex flex-col page-content my-live-page">{children}</div>
    </DashboardLayout>
  );
};

export default LiveLayout;
