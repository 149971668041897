import React from 'react';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { apiLogoutRequest } from '../Redux/actions/login';
import { Button } from './FormElements';

const LogOutModal = ({ ...props }) => {
  const { isModalVisible, handleOk, handleCancel } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleClose = () => {
    handleCancel();
  };

  const onClickedOk = () => {
    handleOk();
    dispatch(apiLogoutRequest(auth.token));
  };

  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isModalVisible} onClose={handleClose}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">Log Out</p>
      <p className="fg-grey-500 pb-20">Are you sure you want to log out?</p>
      <p>
        <Button red className="fit-w md:w-290 my-15" onClick={onClickedOk}>
          Log Out
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-16 fw-600 mt-20 underline hover:fg-primary"
          onClick={handleClose}
        >
          Cancel
        </button>
      </p>
    </Modal>
  );
};

export default LogOutModal;
