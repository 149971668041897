import { useState, useCallback, useEffect } from 'react';
import ServiceWorkerModal from '../Components/ServiceWorkerModal';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export default function ServiceWorkerContainer() {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const onUpdate = useCallback((registration) => {
    setShowModal(true);
    setWaitingWorker(registration.waiting);
    console.log('New content is available, will be used when all tabs for this page are closed.');
  }, []);

  const onSuccess = useCallback((registration) => {
    console.log('Content is cached for offline use.');
  }, []);

  const reloadPage = useCallback(() => {
    setShowModal(false);
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }, [waitingWorker]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate, onSuccess });
  }, [onUpdate, onSuccess]);

  return (
    <ServiceWorkerModal
      isModalVisible={showModal}
      handleOk={reloadPage}
      handleCancel={closeModal}
    />
  );
}
