import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { IconTimes } from './Icons';
import { scrollLock, scrollUnlock } from '../Utils/scrollLock';

const cssSize = (size) => {
  switch (size) {
    case 'none':
      return false;
    case 'xs':
      return 'container-auto-xs';
    case 'sm':
      return 'container-auto-sm';
    case 'md':
      return 'container-auto-md';
    case 'lg':
      return 'container-auto-lg';
    case 'xl':
      return 'container-auto-xl';
    default:
      return 'container-auto-sm';
  }
};

export default function Modal({
  visible,
  size,
  className,
  classCard,
  onClose,
  defaultClass = true,
  disableHeader = false,
  disableBackdropPadding = false,
  disableBorderRadius = false,
  disableOverflow = false,
  children,
}) {
  const [show, setShow] = useState(visible);

  const handleClose = (e) => {
    setShow(false);

    if (typeof onClose === 'function') {
      onClose(e);
    }
  };

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  useEffect(() => {
    if (typeof show === 'boolean') {
      if (show) {
        scrollLock();
      } else {
        scrollUnlock();
      }
    }
  }, [show]);

  return (
    show && (
      <div
        className={cn(
          'page-backdrop flex flex-col of-y-auto',
          !disableBackdropPadding && 'p-20',
          disableOverflow && 'md:of-hidden ',
        )}
      >
        <div
          className={cn(
            'p-0 bg-white flex flex-col m-auto',
            cssSize(size),
            !disableBorderRadius && 'r-15',
            disableOverflow && 'md:limit-h md:of-hidden',
            disableOverflow && disableHeader && 'of-hidden',
            classCard,
          )}
        >
          {!disableHeader && (
            <div className="flex justify-end px-20 pt-20">
              <button
                type="button"
                className="w-40 h-40 r-100 fg-grey-900 hover:bg-grey-100 active:bg-grey-100 hover:fg-black active:fg-black "
              >
                <IconTimes className="fg-grey-400 w-30 h-30 m-auto" onClick={handleClose} />
              </button>
            </div>
          )}
          <div className={cn(defaultClass && 'px-20 md:px-40 pb-20 md:pb-40', className)}>
            {children}
          </div>
        </div>
      </div>
    )
  );
}
