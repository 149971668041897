import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { orderService } from "../services/order";

import * as types from "../constants/order";
import * as actions from "../actions/order";

function* apiProductsRequestWorker(action) {
    try {
        const result = yield call(orderService.apiProductsRequest, action);
        yield put(actions.apiProductsRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiProductsRequestFailed(err));
    }
}

function* apiProductsRequestWatcher() {
    yield takeEvery(types.API_PRODUCTS_REQUEST, apiProductsRequestWorker);
}


function* apiBuyCreditsRequestWorker(action) {
    try {
        const result = yield call(orderService.apiBuyCreditsRequest, action);
        yield put(actions.apiBuyCreditsRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiBuyCreditsRequestFailed(err));
    }
}

function* apiBuyCreditsWatcher() {
    yield takeEvery(types.API_BUY_CREDITS_REQUEST, apiBuyCreditsRequestWorker);
}


// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* subscriptionRootSaga() {
    const sagas = [
      apiProductsRequestWatcher,
      apiBuyCreditsWatcher
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
