import React from 'react';
import Modal from './Modal';
import { Button } from './FormElements';

const ServiceWorkerModal = ({ isModalVisible, handleOk, handleCancel }) => {
  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isModalVisible} onClose={handleCancel}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">New update available</p>
      <p className="fg-grey-500 pb-20">
        A new update is available for the app. <br /> Would you like to update now?
      </p>
      <p>
        <Button primary className="fit-w md:w-290 my-15" onClick={handleOk}>
          Update now
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-16 fw-600 mt-20 underline hover:fg-primary"
          onClick={handleCancel}
        >
          Later
        </button>
      </p>
    </Modal>
  );
};

export default ServiceWorkerModal;
