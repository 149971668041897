export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";

// LOGIN
export const API_REQUEST_LOGIN = "API_REQUEST_LOGIN";
export const API_REQUEST_LOGIN_SUCCESS = "API_REQUEST_LOGIN_SUCCESS";
export const API_REQUEST_LOGIN_FAILED = "API_REQUEST_LOGIN_FAILED";

// AVATAR
export const UPDATE_AVATAR = "UPDATE_AVATAR";

// LOGOUT
export const API_REQUEST_LOGOUT = "API_REQUEST_LOGOUT";
export const API_REQUEST_LOGOUT_SUCCESS = "API_REQUEST_LOGOUT_SUCCESS";
export const API_REQUEST_LOGOUT_FAILED = "API_REQUEST_LOGOUT_FAILED";

// SIGN UP
export const API_REQUEST_CREATE_ACCOUNT = "API_REQUEST_CREATE_ACCOUNT";
export const API_REQUEST_CREATE_ACCOUNT_SUCCESS = "API_REQUEST_CREATE_ACCOUNT_SUCCESS";
export const API_REQUEST_CREATE_ACCOUNT_FAILED = "API_REQUEST_CREATE_ACCOUNT_FAILED";

// PASSWORD RESET
export const API_REQUEST_PASSWORD_RESET = "API_REQUEST_PASSWORD_RESET";
export const API_REQUEST_PASSWORD_RESET_SUCCESS = "API_REQUEST_PASSWORD_RESET_SUCCESS";
export const API_REQUEST_PASSWORD_RESET_FAILED = "API_REQUEST_PASSWORD_RESET_FAILED";

// PASSWORD CONFIRM
export const API_REQUEST_PASSWORD_CONFIRM = "API_REQUEST_PASSWORD_CONFIRM";
export const API_REQUEST_PASSWORD_CONFIRM_SUCCESS = "API_REQUEST_PASSWORD_CONFIRM_SUCCESS";
export const API_REQUEST_PASSWORD_CONFIRM_FAILED = "API_REQUEST_PASSWORD_CONFIRM_FAILED";
