/* eslint-disable react-hooks/exhaustive-deps */
import DashboardLayout from './DashboardLayout';
import VirtualInstructorRightBar from '../Components/VirtualInstructorRightBar';
import { ReactComponent as Decoration } from '../Assets/my-studio-decoration.svg';
import { ReactComponent as TutorialArrowDesktop } from '../Assets/tutorial-arrow.svg';
import { ReactComponent as TutorialArrowMobile } from '../Assets/tutorial-arrow-mobile.svg';
import { ReactComponent as Instructor } from '../Assets/virtual-instructor.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../Components/FormElements';
import { useSelector } from 'react-redux';
import StartProgramOverlay from '../Components/StartProgramOverlay';
import viBannerText from '../Utils/viBannerText';
import { scrollLock, scrollUnlock } from '../Utils/scrollLock';
import {checkIsFreeUser} from "../Utils/subscriptionUtils";

const menuItems = [
  {
    link: '/dashboard',
    title: 'My essentials',
    titleMobile: 'Essentials',
    tutorial:
      'Here you will find important information and tutorials which we will refer to regularly. We recommend that you familiarize yourself with these first.',
  },
  {
    link: '/my-program',
    title: 'My program',
    titleMobile: 'Program',
    tutorial: (
      <>
        <p>We have created this personalized program just for you!</p>
        <ul
          className="hide"
          style={{ paddingLeft: '10px', listStyleType: 'square', listStylePosition: 'inside' }}
        >
          <li>Start each day with your Morning Routine.</li>
          <li>
            For healthy lungs, diaphragm and nervous system try to do your Breathwork routine daily
            or at least a few times a week.
          </li>
          <li>For best results, do all the workouts from your program regularly.</li>
        </ul>
        <p>
          You can adjust the difficulty level after almost every workout, allowing your program to
          adapt and grow with you!
        </p>
        <p>
          To help you build your healthy habits, you will be able to schedule your workouts to your
          calendar. We recommend daily movement, however be sure to listen to your body and take
          necessary rest.
        </p>
        <p>
          If you would like a detailed recommendation on how often you should be doing each workout,
          please click the question mark symbol, which you will later see, at the top right hand
          corner of your screen.
        </p>
      </>
    ),
  },
  {
    link: '/my-workouts',
    title: 'My workouts',
    titleMobile: 'Workouts',
    tutorial:
      'Here you have the freedom to create fully customized workouts by combining any videos from our extensive video library.',
  },
  {
    link: '/my-moods',
    title: 'My moods',
    titleMobile: 'Moods',
    tutorial:
      'If you’re ever in the mood for something different, my team and I are always here to recommend a workout for you based on how you feel in that moment.',
  },
];

const throttle = (fn, time) => {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn(...args);
    }, time);
  };
};

const Tutorial = ({ handleClickTutorial, showTutorial, refMenu }) => {
  const child = refMenu?.current.children[showTutorial];
  const [rect, setRect] = useState(child.getBoundingClientRect());
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setRect(child.getBoundingClientRect());
    setIsMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', throttle(handleResize, 50));

    return () => {
      window.removeEventListener('resize', throttle(handleResize, 50));
    };
  }, [child]);

  return (
    <div className="page-backdrop" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
      <div
        className={cn('fixed w-60 md:w-140')}
        style={{
          top: rect.top + rect.height + 20,
          left: showTutorial > 1 ? rect.right - 70 : rect.left + 20,
        }}
      >
        <TutorialArrowDesktop className="lg:show" />
        <TutorialArrowMobile className={cn('lg:hide', showTutorial > 1 && 'flip-x')} />
      </div>
      <div
        className={cn(
          'fixed top-190 md:top-320 lg:top-240 left-30 pr-30 fs-14 lg:fs-16 fw-400 md:fw-500 fg-white',
        )}
        style={
          !isMobile
            ? { maxWidth: '550px', left: showTutorial > 1 ? rect.right + 80 : rect.left + 180 }
            : { maxWidth: '550px' }
        }
      >
        <Instructor
          className={cn('w-100 lg:w-130 lg:mx-auto', showTutorial > 1 ? 'mr-auto' : 'ml-auto')}
        />

        <div
          className="prose pr-10 my-20 lg:my-40 of-y-auto modal-scrollstyle"
          style={{ maxHeight: '120px' }}
        >
          {menuItems[showTutorial]?.tutorial}
        </div>
        <p className="text-right">
          <Button secondary size="mini" onClick={handleClickTutorial}>
            {showTutorial >= 3 ? 'Close' : 'Next'}
          </Button>
        </p>
      </div>
    </div>
  );
};

const MyStudioLayout = ({
  state,
  isLoading,
  setIsScheduleModalVisible,
  setIsInstructorModalVisible,
  children,
}) => {
  const refMenu = useRef(null);
  const profile = useSelector((state) => state.setting);
  const [isFreeUser, setIsFreeUser] = useState(true);
  const [showTutorial, setShowTutorial] = useState(null);
  const [showStartOverlay, setShowStartOverlay] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const upgradeRequired =
    pathname === '/my-moods'
      ? isFreeUser && state.videos?.length > 0
      : pathname === '/my-workouts'
      ? false
      : isFreeUser;
  const showDecoration =
    ['/my-workouts', '/my-moods'].includes(pathname) ||
    (upgradeRequired && pathname === '/my-program');
  // const hasWorkoutCalendar = Object.keys(profile.setting?.workout_calendar || {})?.length > 0;

  useEffect( () => {
    checkIsFreeUser(profile).then((res) => {
      setIsFreeUser(res);
    })
  }, [profile])

  const handleClickTutorial = (e) => {
    if (showTutorial >= 3) {
      localStorage.setItem('tutorial', 1);
      navigate('/my-program?redirect=true');
    }

    setShowTutorial((state) => (state < 3 ? state + 1 : null));
  };

  const handleClickInstructor = () => {
    if (pathname === '/my-workouts') {
      navigate('/explore?showpanel=true');
    } else {
      setIsInstructorModalVisible(true);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('tutorial')) {
      setShowTutorial(0);
    }
    if (localStorage.getItem('tutorial') && !localStorage.getItem('tutorial2')) {
      setShowStartOverlay(true);
    }
  }, []);

  useEffect(() => {
    if (showTutorial !== null) {
      scrollLock();

      Object.values(refMenu.current.children).forEach((child, key) => {
        const classChild = ['bb-3', 'pointer-off'];

        if (showTutorial === key) {
          Object.assign(child.style, { zIndex: 1001, color: 'white', borderColor: 'white' });
          child.classList.add(...classChild);
        } else {
          Object.assign(child.style, { zIndex: null, color: null, borderColor: null });
          child.classList.remove(...classChild);
        }
      });
    } else {
      scrollUnlock();
    }
  }, [showTutorial]);

  return (
    <>
      <DashboardLayout rightBar={<VirtualInstructorRightBar />}>
        {/* <MyStudioTopCard /> */}
        <div className="relative">
          <div className="flex justify-between align-center pb-20">
            <div className="fs-24 md:fs-30 fw-800 md:fw-700">My Studio</div>
            {pathname === '/my-program' && !showStartOverlay && (
              <button
                className="lg:hide fw-700 fg-primary underline"
                onClick={() => setIsScheduleModalVisible(true)}
              >
                {/* {hasWorkoutCalendar ? 'Update schedule' : 'Setup workouts'} */}
                Update schedule
              </button>
            )}
          </div>
          <div className="flex justify-between align-center">
            <div
              ref={refMenu}
              className="fit-w md:w-440 fs-14 md:fs-16 fw-600 md:fw-500 fg-grey-500 flex justify-between"
            >
              {menuItems.map(({ link, title, titleMobile, tutorial }, key) => {
                const isCurrentLink = pathname === link;

                return (
                  <React.Fragment key={key}>
                    <Link
                      to={link}
                      className={cn(
                        'relative pb-10',
                        isLoading && !isCurrentLink && 'pointer-off',
                        isCurrentLink
                          ? 'fw-600 fg-primary bb-3 b-primary'
                          : 'hover:fg-black active:fg-black',
                      )}
                    >
                      <span className="md:show">{title}</span>
                      <span className="md:hide">{titleMobile}</span>
                    </Link>
                  </React.Fragment>
                );
              })}
            </div>
            {pathname === '/my-program' && !showStartOverlay && (
              <button
                className={cn(
                  'lg:show fs-16 fw-700 pb-10 fg-primary underline mb-3',
                  upgradeRequired && 'mr-450',
                )}
                onClick={() => setIsScheduleModalVisible(true)}
              >
                {/* {hasWorkoutCalendar ? 'Update schedule' : 'Setup workouts'} */}
                Update schedule
              </button>
            )}
          </div>
          <hr className="b-grey-300 m-0" style={{ marginTop: '-2px' }} />

          {/* Decoration Desktop */}
          {([2, 3].includes(showTutorial) || showDecoration) && (
            <div className="lg:show absolute right-0 bottom-2 flex align-center w-440 h-150">
              <div className="w-70/100 pt-20 z-1">
                <p className="fs-14 md:fs-16 fw-700">
                  {showTutorial === 3 || pathname === '/my-moods'
                    ? viBannerText.moods.title
                    : upgradeRequired && pathname === '/my-program'
                    ? viBannerText.program.title
                    : viBannerText.workouts.title}
                </p>
                <p className="fs-12 md:fs-14 fg-grey-500 mb-10">
                  {showTutorial === 3 || pathname === '/my-moods'
                    ? viBannerText.moods.caption
                    : upgradeRequired && pathname === '/my-program'
                    ? viBannerText.program.caption
                    : viBannerText.workouts.caption}
                </p>

                {upgradeRequired ? (
                  <Button
                    primary
                    size="mini"
                    disablePadding
                    className="px-20"
                    onClick={() => navigate('/my-profile?page=subscription')}
                  >
                    Upgrade Plan
                  </Button>
                ) : (
                  <Button
                    primary
                    size="mini"
                    disablePadding
                    className="px-20"
                    onClick={handleClickInstructor}
                  >
                    Create workout
                  </Button>
                )}
              </div>
              <Decoration
                className="absolute bottom-0 right-0 w-auto h-150"
                style={{ bottom: '-6.5%' }}
              />
            </div>
          )}

          {/* Decoration Mobile */}
          {([2, 3].includes(showTutorial) || showDecoration) && (
            <div className="lg:hide relative flex align-center r-15 p-15 b-1 b-grey-300 mt-25 of-hidden">
              <div className="w-70/100 z-1">
                <p className="fs-14 md:fs-18 fw-700">
                  {showTutorial === 3 || pathname === '/my-moods'
                    ? viBannerText.moods.title
                    : upgradeRequired && pathname === '/my-program'
                    ? viBannerText.program.title
                    : viBannerText.workouts.title}
                </p>
                <p className="fs-12 md:fs-14 fg-grey-500 py-2">
                  {showTutorial === 3 || pathname === '/my-moods'
                    ? viBannerText.moods.caption
                    : upgradeRequired && pathname === '/my-program'
                    ? viBannerText.program.caption
                    : viBannerText.workouts.caption}
                </p>
                {upgradeRequired ? (
                  <Button
                    primary
                    size="small"
                    disablePadding
                    className="px-20 lg:px-30 my-10"
                    onClick={() => navigate('/my-profile?page=subscription')}
                  >
                    Upgrade plan
                  </Button>
                ) : (
                  <Button primary size="small" className="my-10" onClick={handleClickInstructor}>
                    Create workout
                  </Button>
                )}
              </div>
              <Decoration
                className="absolute top-0 bottom-0 fit-h w-auto py-10"
                style={{ right: '-10px' }}
              />
            </div>
          )}
        </div>
        <div className="relative mt-20 flex-1 flex flex-col page-content my-essentials-page">
          {children}
          <StartProgramOverlay
            isVisible={showStartOverlay}
            setIsVisible={setShowStartOverlay}
            setIsScheduleModalVisible={setIsScheduleModalVisible}
          />
        </div>
      </DashboardLayout>
      {showTutorial !== null && (
        <Tutorial
          {...{
            showTutorial,
            handleClickTutorial,
            refMenu,
          }}
        />
      )}
    </>
  );
};

export default MyStudioLayout;
