import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiCheckCoupon(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/membership/check_coupon/`, action.data, { headers });
}

export const couponService = {
  apiCheckCoupon,
};
