import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiSubscriptionRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/membership/subscriptionplans/`, { headers });
}

function apiChangeSubscriptionRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/membership/changesubscriptionplan/`, action.data, { headers });
}

function apiCheckoutSubscriptionRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.post(`/api/v1/membership/sign_up_subscribe/`, action.data, { headers });
}

function apiTermsNConditionsRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return axios.get(`/api/v1/terms_and_conditions/latest/`, { headers });
}

function apiPaymentMethodRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/membership/update_payment_method/`, { headers });
}

function apiUpdatePaymentMethodRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/membership/update_payment_method/`, action.data, { headers });
}

function apiDeletePaymentMethodRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.delete(`/api/v1/membership/update_payment_method/${action.data}/`, {
    headers,
  });
}

export const subscriptionService = {
  apiSubscriptionRequest,
  apiChangeSubscriptionRequest,
  apiCheckoutSubscriptionRequest,
  apiTermsNConditionsRequest,
  apiPaymentMethodRequest,
  apiUpdatePaymentMethodRequest,
  apiDeletePaymentMethodRequest,
};
