import { put, call, all, spawn, takeEvery } from 'redux-saga/effects';
import { subscriptionService } from '../services/subscription';
import * as types from '../constants/subscription';
import * as actions from '../actions/subscription';

// Subscription
function* apiSubscriptionRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiSubscriptionRequest, action);
    yield put(actions.apiSubscriptionRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiSubscriptionRequestFailed(err));
  }
}

function* apiSubscriptionRequestWatcher() {
  yield takeEvery(types.API_REQUEST_SUBSCRIPTION, apiSubscriptionRequestWorker);
}

function* apiChangeSubscriptionRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiChangeSubscriptionRequest, action);
    yield put(actions.apiChangeSubscriptionRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiChangeSubscriptionRequestFailed(err));
  }
}

function* apiChangeSubscriptionRequestWatcher() {
  yield takeEvery(types.API_REQUEST_CHANGE_SUBSCRIPTION, apiChangeSubscriptionRequestWorker);
}

function* apiCheckoutSubscriptionRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiCheckoutSubscriptionRequest, action);
    yield put(actions.apiCheckoutSubscriptionRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiCheckoutSubscriptionRequestFailed(err));
  }
}

function* apiCheckoutSubscriptionRequestWatcher() {
  yield takeEvery(types.API_REQUEST_CHECKOUT_SUBSCRIPTION, apiCheckoutSubscriptionRequestWorker);
}

// Terms & Conditions
function* apiTermsNConditionsRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiTermsNConditionsRequest, action);
    yield put(actions.apiTermsNConditionsRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiTermsNConditionsRequestFailed(err));
  }
}

function* apiTermsNConditionsWatcher() {
  yield takeEvery(types.API_REQUEST_TERMS_CONDITIONS, apiTermsNConditionsRequestWorker);
}

// Payment method
function* apiPaymentMethodRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiPaymentMethodRequest, action);
    yield put(actions.apiPaymentMethodRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiPaymentMethodRequestFailed(err));
  }
}

function* apiPaymentMethodWatcher() {
  yield takeEvery(types.API_REQUEST_PAYMENT_METHOD, apiPaymentMethodRequestWorker);
}

// Payment method update
function* apiUpdatePaymentMethodRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiUpdatePaymentMethodRequest, action);
    yield put(actions.apiUpdatePaymentMethodRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiUpdatePaymentMethodRequestFailed(err));
  }
}

function* apiUpdatePaymentMethodWatcher() {
  yield takeEvery(types.API_REQUEST_UPDATE_PAYMENT_METHOD, apiUpdatePaymentMethodRequestWorker);
}

// Payment method delete
function* apiDeletePaymentMethodRequestWorker(action) {
  try {
    const result = yield call(subscriptionService.apiDeletePaymentMethodRequest, action);
    yield put(actions.apiDeletePaymentMethodRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiDeletePaymentMethodRequestFailed(err));
  }
}

function* apiDeletePaymentMethodWatcher() {
  yield takeEvery(types.API_REQUEST_DELETE_PAYMENT_METHOD, apiDeletePaymentMethodRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* subscriptionRootSaga() {
  const sagas = [
    apiSubscriptionRequestWatcher,
    apiChangeSubscriptionRequestWatcher,
    apiCheckoutSubscriptionRequestWatcher,
    apiTermsNConditionsWatcher,
    apiPaymentMethodWatcher,
    apiUpdatePaymentMethodWatcher,
    apiDeletePaymentMethodWatcher,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}
