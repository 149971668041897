/* eslint-disable react-hooks/exhaustive-deps */
import DashboardLayout from './DashboardLayout';
import { ReactComponent as Decoration } from '../Assets/my-studio-decoration.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import { Button } from '../Components/FormElements';
import styleCategories from '../Utils/styleCategories';
import cn from 'classnames';
import { FaCircleNotch } from 'react-icons/fa';

const ExploreLayout = ({
  children,
  statePanel,
  categories,
  onTopCardMenuSelected,
  isCombineLoading,
  isCombinePossible,
  selectedVideo,
  onCombineClick,
  onDeleteAVideoClick,
  isLoading,
  isFreeUser,
}) => {
  const [showPanel, setShowPanel] = statePanel;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const currentCategory = parseInt(query.get('category')) || 1;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const dispatchMenu = () => {
    const category = query.get('category');
    dispatch(updateCardMenuSelection(category));
  };

  useEffect(() => {
    dispatchMenu();
  }, [query.get('category')]);

  return (
    // <DashboardLayout
    //   rightBar={
    //     <VideoCombineRightBar
    //       selectedVideo={selectedVideo}
    //       onCombineClick={onCombineClick}
    //       onDeleteAVideoClick={onDeleteAVideoClick}
    //       isCombineLoading={isCombineLoading}
    //       isCombinePossible={isCombinePossible}
    //     />
    //   }
    // >
    <DashboardLayout>
      <div className="relative">
        <div className="lg:show absolute right-0 bottom-19 flex align-center w-440 h-150">
          <div className="w-70/100 pt-15 z-1">
            {!showPanel ? (
              <>
                <p className="fs-14 md:fs-16 fw-700">Want to create your own workout?</p>
                <p className="fs-12 md:fs-14 fg-grey-500 mb-10">
                  Combine any videos of your choice.
                </p>

                <Button
                  primary
                  size="mini"
                  disablePadding
                  className="px-20"
                  onClick={() =>
                    // isFreeUser ? navigate('/my-profile?page=subscription') : setShowPanel(true)
                    setShowPanel(true)
                  }
                >
                  {/* {isFreeUser ? 'Upgrade Plan' : 'Create workout'} */}
                  Create workout
                </Button>
              </>
            ) : (
              <>
                <p className="w-8/12 fs-12 md:fs-13 lh-1.3 py-2 my-5 fg-primary">
                  1. Select any combination of videos and press "Create workout".
                </p>
                <p
                  className={cn(
                    'w-8/12 fs-12 md:fs-13 lh-1.3 py-2 my-5 fg-primary',
                    // selectedVideo?.length > 0 && 'fg-primary',
                  )}
                >
                  2. I will arrange the videos in the right order and save it in your workouts.
                </p>
              </>
            )}
          </div>
          <Decoration
            className="absolute bottom-0 right-0 w-auto h-150"
            style={{ bottom: '-4.5%' }}
          />
        </div>

        <div className="fs-24 md:fs-30 fw-800 md:fw-700 pb-0">Explore</div>
        <div className="flex lg:flex-wrap lg:mr-460 pb-10 of-x-overlay hide-scrollbar explore-top-card-category-btns">
          {categories.map((category, key) => {
            const { Icon, className } = styleCategories.find((el) => el.id === category.id);
            const isCurrentCategory = currentCategory === category.id;

            return (
              <Button
                key={key}
                size="mini"
                disablePadding
                className={cn(
                  'flex-0 mr-5 my-3 px-15 b-1',
                  className,
                  !isCurrentCategory && 'b-white',
                  !isCurrentCategory && isLoading && 'pointer-off',
                )}
                onClick={() => {
                  onTopCardMenuSelected(category.id);
                  navigate('/explore?category=' + category.id);
                }}
              >
                {isLoading && isCurrentCategory ? (
                  <FaCircleNotch className="w-20 mr-5 spin" />
                ) : (
                  <Icon className="w-20 h-20 mr-5" />
                )}
                {category.title}
              </Button>
            );
          })}
        </div>
        <hr className="b-grey-300 mt-0 mb-20" />
      </div>
      {/* <ExploreTopCard categories={categories} onTopCardMenuSelected={onTopCardMenuSelected} /> */}
      <div className="flex-1 flex flex-col page-content my-essentials-page">{children}</div>
    </DashboardLayout>
  );
};

export default ExploreLayout;
