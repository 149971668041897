export const ADD_TODO = 'ADD_TODO';
export const LOAD_TODO_LIST = 'LOAD_TODO_LIST';
export const RENDER_TODO_LIST = 'RENDER_TODO_LIST';
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';

export function resetReduxState() {
  return {
    type: RESET_REDUX_STATE,
  };
}

export function addToDo(title) {
  return {
    type: ADD_TODO,
    toDoItem: {
      _id: new Date().getTime(),
      title,
    },
  };
}

export function loadToDoList() {
  return {
    type: LOAD_TODO_LIST,
  };
}
