import * as types from "../constants/signup";
import _ from "lodash";

function getUser() {
    try {
        return JSON.parse(localStorage.getItem("user"));
    }
    catch (error) {
        return null;
    }
}

const INITIAL_STATE = {
    session_key: localStorage.getItem("session_key") || null,
    isLoading: false,
    success: false,
    error: null,
    questionnaire: JSON.parse(localStorage.getItem("questionnaire")) || null,
    currentQuestion: JSON.parse(localStorage.getItem("currentQuestion")) || null,
    answer: null,
    progress: localStorage.getItem("progress"),
    user: getUser(),
};

export default function signupApiReducer(state = INITIAL_STATE, action) {
    let stateCp = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case types.API_REQUEST_SESSION_KEY:
            localStorage.removeItem("session_key");
            return { ...stateCp, session_key: null, error: null, success: false, isLoading: true };

        case types.API_REQUEST_FAILED_SESSION_KEY:
            return {
                ...stateCp,
                error: action.response,
                success: false,
                isLoading: false,
            };

        case types.API_REQUEST_SUCCESS_SESSION_KEY:
            localStorage.setItem("session_key", action.response.data.session_key);
            return {
                ...stateCp,
                error: null,
                success: true,
                isLoading: false,
                session_key: action.response.data.session_key,
            };

        case types.API_REQUEST_SIGNUP_PROFILING_QUESTIONNAIRE:
            return {
                ...stateCp,
                error: null,
                success: false,
                isLoading: true,
            };

        case types.API_REQUEST_SUCCESS_SIGNUP_PROFILING_QUESTIONNAIRE:
            localStorage.setItem("questionnaire", JSON.stringify(action.response.data));
            return {
                ...stateCp,
                error: null,
                success: true,
                isLoading: false,
                questionnaire: action.response.data,
            };

        case types.API_REQUEST_FAILED_SIGNUP_PROFILING_QUESTIONNAIRE:
            return {
                ...stateCp,
                error: action.response,
                success: false,
                isLoading: false,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE:
            return {
                ...stateCp,
                error: null,
                success: false,
                isLoading: true,
                questionnaire: null,
                currentQuestion: null,
                answer: null,
                progress: null,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_SUCCESS:
            localStorage.setItem("questionnaire", JSON.stringify(action.response.data));
            return {
                ...stateCp,
                error: null,
                success: true,
                isLoading: false,
                questionnaire: action.response.data,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE_FAILED:
            return {
                ...stateCp,
                error: action.response,
                success: false,
                isLoading: false,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE:
            return {
                ...stateCp,
                error: null,
                success: false,
                isLoading: true,
                questionnaire: null,
                currentQuestion: null,
                answer: null,
                progress: null,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_SUCCESS:
            localStorage.setItem("questionnaire", JSON.stringify(action.response.data));
            return {
                ...stateCp,
                error: null,
                success: true,
                isLoading: false,
                questionnaire: action.response.data,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE_FAILED:
            return {
                ...stateCp,
                error: action.response,
                success: false,
                isLoading: false,
            };

        case types.API_REQUEST_QUESTION_BY_ID:
            return {
                ...stateCp,
                error: null,
                success: false,
                isLoading: true,
            };

        case types.API_REQUEST_QUESTION_INIT:
            return {
                ...stateCp,
                error: null,
                success: false,
                isLoading: true,
            };

        case types.API_REQUEST_SUCCESS_QUESTION_BY_ID:
            let question = action.response.data;
            if (question["choice_question"]) {
                question["choice_question"] = _.orderBy(question["choice_question"], ["id"], ["asc"]);
            }
            localStorage.setItem("currentQuestion", JSON.stringify(question));
            localStorage.setItem("progress", 0);
            return {
                ...stateCp,
                error: null,
                success: true,
                isLoading: false,
                currentQuestion: question,
                progress: 0,
            };

        case types.API_REQUEST_FAILED_QUESTION_BY_ID:
            return {
                ...stateCp,
                error: action.response,
                success: false,
                isLoading: false,
            };

        case types.API_REQUEST_SUBMIT_ANSWER:
            return {
                ...stateCp,
                error: null,
                success: false,
                isLoading: true,
            };

        case types.API_REQUEST_SUCCESS_SUBMIT_ANSWER:
            localStorage.setItem("currentQuestion", JSON.stringify(action.response.data.next_question));
            localStorage.setItem("progress", action.response.data.progress);

            return {
                ...stateCp,
                error: null,
                success: true,
                isLoading: false,
                currentQuestion: action.response.data.next_question,
                progress: action.response.data.progress,
                answer: action.response.data.answer,
            };

        case types.API_REQUEST_FAILED_SUBMIT_ANSWER:
            return {
                ...stateCp,
                error: action.response,
                success: false,
                isLoading: false,
            };

        default:
            return state;
    }
}
