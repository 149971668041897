import cn from 'classnames';
import React, { createContext, useContext, useEffect, useState } from 'react';

const ReactContext = createContext();

export function MenuItem({ className, keepOpen = false, onClick, children, ...props }) {
  const [showMenu, setShowMenu] = useContext(ReactContext);

  const handleMenuClick = (e) => {
    if (!keepOpen) {
      setShowMenu(!showMenu);
    } else {
      e.stopPropagation();
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <div
      className={cn(
        'flex align-center fs-14 md:fs-16 px-15 py-10 hover:bg-grey-100 active:bg-grey-100 cursor-pointer',
        className,
      )}
      onClick={handleMenuClick}
      {...props}
    >
      {children}
    </div>
  );
}

export function ContextMenu({
  className,
  visible,
  disableFixedWidth = false,
  onClose,
  children,
  ...props
}) {
  const [showMenu, setShowMenu] = useState(visible);

  useEffect(() => {
    setShowMenu(visible);
  }, [visible]);

  useEffect(() => {
    const hideMenu = () => {
      window.removeEventListener('click', hideMenu, false);
      window.removeEventListener('scroll', hideMenu, { passive: false });
      if (typeof onClose === 'function') {
        onClose();
      }
      setShowMenu(false);
    };

    if (showMenu) {
      window.addEventListener('click', hideMenu, false);
      window.addEventListener('scroll', hideMenu, { passive: false });
    }
  }, [onClose, showMenu]);

  return (
    showMenu && (
      <ReactContext.Provider value={[showMenu, setShowMenu]}>
        <div
          className={cn(
            'absolute z-10 py-10 r-5 bg-white shadow-8',
            !disableFixedWidth && 'w-190',
            className,
          )}
          {...props}
        >
          {children}
        </div>
      </ReactContext.Provider>
    )
  );
}
