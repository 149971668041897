const viBannerText = {
  program: {
    title: 'Unlock your body’s full potential!',
    caption: 'Get unlimited access now',
  },
  workouts: {
    title: 'Want to create your own workout?',
    caption: 'Combine any videos of your choice.',
  },
  moods: {
    title: 'How are you feeling today?',
    caption: 'Let me suggest a workout for you!',
  },
  empty: {
    title: 'There are no videos to show yet',
    caption: "Let's create workouts to show videos here",
  },
  button: {
    create: 'Create workout',
    upgrade: 'Upgrade Plan',
  },
};

export default viBannerText;
