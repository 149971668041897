import * as types from '../constants/studioVideo';

export const apiEssentialsRequest = (data) => ({
  type: types.API_REQUEST_ESSENTIALS,
  data,
});

export const apiEssentialsRequestSuccess = (response) => ({
  type: types.API_REQUEST_VIDEO_SUCCESS,
  response,
});

export const apiEssentialsRequestFailed = (response) => ({
  type: types.API_REQUEST_VIDEO_FAILED,
  response,
});

export const apiProgramRequest = (data) => ({
  type: types.API_REQUEST_PROGRAM,
  data,
});

export const apiProgramRequestSuccess = (response) => ({
  type: types.API_REQUEST_VIDEO_SUCCESS,
  response,
});

export const apiProgramRequestFailed = (response) => ({
  type: types.API_REQUEST_VIDEO_FAILED,
  response,
});

export const apiMoodsRequest = (data) => ({
  type: types.API_REQUEST_MOODS,
  data,
});

export const apiMoodsRequestSuccess = (response) => ({
  type: types.API_REQUEST_VIDEO_SUCCESS,
  response,
});

export const apiMoodsRequestFailed = (response) => ({
  type: types.API_REQUEST_VIDEO_FAILED,
  response,
});

export const apiScheduleVideoRequest = (id, date, calendar, calendar_integration_enabled) => ({
  type: types.API_REQUEST_SCHEDULE_VIDEO,
  id,
  date,
  calendar,
  calendar_integration_enabled,
});

export const apiScheduleVideoRequestSuccess = (response) => ({
  type: types.API_REQUEST_SCHEDULE_VIDEO_SUCCESS,
  response,
});

export const apiScheduleVideoRequestFailed = (response) => ({
  type: types.API_REQUEST_SCHEDULE_VIDEO_FAILED,
  response,
});

export const apiRemoveVideoRequest = (data) => ({
  type: types.API_REQUEST_REMOVE_VIDEO,
  data,
});

export const apiRemoveVideoRequestSuccess = (response) => ({
  type: types.API_REQUEST_REMOVE_VIDEO_SUCCESS,
  response,
});

export const apiRemoveVideoRequestFailed = (response) => ({
  type: types.API_REQUEST_REMOVE_VIDEO_FAILED,
  response,
});

export const apiLevelChangeRequest = (data) => ({
  type: types.API_REQUEST_LEVEL_CHANGE,
  data,
});

export const apiLevelChangeRequestSuccess = (response) => ({
  type: types.API_REQUEST_LEVEL_CHANGE_SUCCESS,
  response,
});

export const apiLevelChangeRequestFailed = (response) => ({
  type: types.API_REQUEST_LEVEL_CHANGE_FAILED,
  response,
});

export const apiVideoActivityRequest = (data) => ({
  type: types.API_REQUEST_VIDEO_ACTIVITY,
  data,
});

export const apiVideoActivityRequestSuccess = (response) => ({
  type: types.API_REQUEST_VIDEO_ACTIVITY_SUCCESS,
  response,
});

export const apiVideoActivityRequestFailed = (response) => ({
  type: types.API_REQUEST_VIDEO_ACTIVITY_FAILED,
  response,
});

export const lockVideos = (data) => ({
  type: types.LOCK_VIDEOS,
  data,
});
