import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiSettingRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/user/user_info/${action.data.user_id}/`, { headers });
}

function apiSettingPatch(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    Authorization: `Token ${getToken()}`,
  };
  const formData = new FormData();
  // Update the formData object
  for (const [key, val] of Object.entries(action.data)) {
    formData.append(key, val);
  }

  return axios.patch(`/api/v1/user/user_info/${action.data.user_id}/`, formData, { headers });
}

function apiAvatarChange(action) {
  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.post(`/api/v1/user/upload_image/`, action.data, { headers });
}

function apiPasswordChangeRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.post(`/api/v1/user/change_user_password/`, action.data, { headers });
}

function apiUpdateCalendarRequest(action) {
  const { date, calendar, calendar_integration_enabled } = action;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(
    `/api/v1/user/update_workout_calendar/`,
    { date, calendar, calendar_integration_enabled },
    { headers },
  );
}

function apiDeleteAccountRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.delete(`/api/v1/user/delete_account/${action.user_id}/`, { headers });
}

export const settingService = {
  apiSettingRequest,
  apiSettingPatch,
  apiAvatarChange,
  apiPasswordChangeRequest,
  apiUpdateCalendarRequest,
  apiDeleteAccountRequest,
};
