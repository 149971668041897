import * as types from '../constants/studioVideo';
import _ from 'lodash';

import { mapErrorMessage } from '../../Utils/mapErrorMessage';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
  isLoading: false,
  isScheduleLoading: false,
  isActivityLoading: false,
  isLevelLoading: false,
  success: false,
  error: null,
  videos: null,
};

export default function studioVideoApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Essential
    case types.API_REQUEST_VIDEO:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_VIDEO_SUCCESS:
      let result = action.response.data;
      // result = _.orderBy(result, ["id"], ["desc"]);
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        videos: result,
      };

    case types.API_REQUEST_VIDEO_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };
    case types.API_REQUEST_ESSENTIALS:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_PROGRAM:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };
    case types.API_REQUEST_MOODS:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_SCHEDULE_VIDEO:
      return {
        ...state,
        isScheduleLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_SCHEDULE_VIDEO_SUCCESS:
      let org_videos = state.videos;
      let res_video = action.response.data;
      org_videos = _.map(org_videos, (video) => {
        if (video.id === res_video.id) {
          return res_video;
        }
        return video;
      });
      toast.success('Your calendar has been updated!');
      return {
        ...state,
        isScheduleLoading: false,
        success: true,
        error: null,
        videos: org_videos,
      };

    case types.API_REQUEST_SCHEDULE_VIDEO_FAILED:
      return {
        ...state,
        isScheduleLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_REMOVE_VIDEO:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_REMOVE_VIDEO_SUCCESS:
      let videos = state.videos;
      let id = action.response?.data?.id;
      videos = videos?.filter((it) => {
        return it.id !== id;
      });
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        videos: videos,
      };

    case types.API_REQUEST_REMOVE_VIDEO_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_LEVEL_CHANGE:
      return {
        ...state,
        isLevelLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_LEVEL_CHANGE_SUCCESS:
      let prevideos = state.videos;
      let previd = action.response?.action?.data?.video_id;
      // console.log("prevvideo", prevideos);
      prevideos = prevideos?.map((it) => {
        if (it.id === previd) {
          return action.response?.result?.data;
        }
        return it;
      });
      return {
        ...state,
        isLevelLoading: false,
        success: true,
        error: null,
        videos: prevideos,
      };

    case types.API_REQUEST_LEVEL_CHANGE_FAILED:
      return {
        ...state,
        isLevelLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_VIDEO_ACTIVITY:
      return {
        ...state,
        isActivityLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_VIDEO_ACTIVITY_SUCCESS:
      return {
        ...state,
        isActivityLoading: false,
        success: true,
        error: null,
      };

    case types.API_REQUEST_VIDEO_ACTIVITY_FAILED:
      return {
        ...state,
        isActivityLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.LOCK_VIDEOS:
      return {
        ...state,
        videos: state.videos.map((video) =>
          video.id !== action.data
            ? {
                ...video,
                list_of_videos: video.list_of_videos.map((v) => ({
                  free: v.free,
                  category: v.category,
                  id: v.id,
                  length: v.length,
                  thumbnail: v.thumbnail,
                  title: v.title,
                  video_category: v.video_category,
                  video_instructor: v.video_instructor,
                  video: v.free ? v.video : null,
                })),
              }
            : video,
        ),
      };

    default:
      return state;
  }
}
