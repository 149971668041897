import * as types from '../constants/faq';

import { mapErrorMessage } from '../../Utils/mapErrorMessage';

const INITIAL_STATE = {
    isLoading: false,
    success: false,
    error: null,
    faqs: [],
};

export default function faqApiReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        // FAQ
        case types.API_GET_REQUEST_FAQ:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: null,
            };

        case types.API_GET_REQUEST_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: null,
                faqs: action.response.data
            };
            
        case types.API_GET_REQUEST_FAQ_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: mapErrorMessage(action)
            };

        default:
            return state
    }
};
