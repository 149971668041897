/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import { updateSideMenuSelection } from '../Redux/actions/sideMenu';
import LiveWorkshopsPage from '../Pages/LiveWorkshopsPage';
import LiveSessionsPage from '../Pages/LiveSessionsPage';
import { apiSettingRequest } from '../Redux/actions/setting';
import BuyTicketModal from '../Components/BuyTicketModal';
import { apiBuyCreditsRequest, apiProductsRequest } from '../Redux/actions/order';
import { apiBalanceRequest } from '../Redux/actions/balance';
import BookEventModal from '../Components/BookEventModal';
import VideoEventModal from '../Components/VideoEventModal';
import { apiOrderCancelEventRequest, apiOrderEventRequest } from '../Redux/actions/events';
import { apiPaymentMethodRequest, apiSubscriptionRequest } from '../Redux/actions/subscription';
import moment from 'moment-timezone';

const LiveContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const order = useSelector((state) => state.order);
  const events = useSelector((state) => state.events);
  const stateSubscription = useSelector((state) => state.subscription);
  const paymentMethods = useSelector((state) => state.subscription?.paymentMethod);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const category = query.get('category') || 'workshop';
  const cardMenuSelection = useSelector((state) => state.cardMenuSelection);
  const PageComponent = cardMenuSelection.menu === 'private' ? LiveSessionsPage : LiveWorkshopsPage;
  const [showBuyTicketModal, setShowBuyTicketModal] = useState(false);
  const [showBookEventModal, setShowBookEventModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [video, setVideo] = useState(null);
  const product = order?.products?.find((el) => el.name.toLowerCase() === category);

  const handleClodeButyTicketModal = () => {
    setShowBuyTicketModal(false);
  };

  const handleCloseBookEventModal = () => {
    setShowBookEventModal(false);
    setEventDetails({});
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  const onSetVideo = (video) => {
    setVideo(video);
    setShowVideoModal(true);
  };

  const handleBuyCredit = (
    product,
    price,
    paymentMethod,
    cardNumber,
    cardExpMonth,
    cardExpYear,
    cardCode,
  ) => {
    dispatch(
      apiBuyCreditsRequest({
        event_type: product,
        event_pricing: price,
        payment_method: paymentMethod,
        card_holder: auth?.user?.name,
        card_number: cardNumber?.replace(/\s/g, ''),
        expiration_month: cardExpMonth?.replace(/\s/g, ''),
        expiration_year: cardExpYear?.replace(/\s/g, ''),
        security_code: cardCode?.replace(/\s/g, ''),
      }),
    );
  };

  const handleBookEvent = (id) => {
    dispatch(apiOrderEventRequest({ event_id: id, tz: moment.tz.guess() }));
  };

  const handleCancelEvent = (id) => {
    dispatch(apiOrderCancelEventRequest({ event_id: id }));
  };

  const dispatchMenu = () => {
    if (!query.get('category')) {
      navigate('/live?category=workshop');
    } else {
      dispatch(updateCardMenuSelection(category));
    }
  };

  useEffect(() => {
    dispatchMenu();
  }, [query.get('category')]);

  useEffect(() => {
    dispatchMenu();
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
    dispatch(updateCardMenuSelection(category));
    dispatch(updateSideMenuSelection('private'));
    dispatch(apiBalanceRequest());
    dispatch(apiProductsRequest());
    dispatch(apiSubscriptionRequest());
    dispatch(apiPaymentMethodRequest());
  }, []);

  useEffect(() => {
    if (order.success && !order.isBuyingCreditsLoading && !order.error) {
      dispatch(apiBalanceRequest());
      setShowBuyTicketModal(false);
    }
  }, [order.isBuyingCreditsLoading]);

  useEffect(() => {
    if (events.success && !events.isOrderingLoading && !events.error) {
      setShowBookEventModal(false);
    }
  }, [events.isOrderingLoading]);

  return (
    <>
      <PageComponent
        setShowBuyTicketModal={setShowBuyTicketModal}
        setShowBookEventModal={setShowBookEventModal}
        setEventDetails={setEventDetails}
        isBuyingCreditsLoading={order.isBuyingCreditsLoading}
      />
      <BuyTicketModal
        product={product}
        paymentMethod={paymentMethods}
        isModalVisible={showBuyTicketModal}
        setIsModalVisible={setShowBuyTicketModal}
        isLoading={stateSubscription.isLoading || order.isBuyingCreditsLoading}
        onClose={handleClodeButyTicketModal}
        handleBuyCredit={handleBuyCredit}
      />
      <BookEventModal
        event={eventDetails}
        isModalVisible={showBookEventModal}
        isLoading={events.isOrderingLoading}
        onSetVideo={onSetVideo}
        onClose={handleCloseBookEventModal}
        handleBookEvent={handleBookEvent}
        handleCancelEvent={handleCancelEvent}
      />
      <VideoEventModal
        video={video}
        isModalVisible={showVideoModal}
        onClose={handleCloseVideoModal}
      />
    </>
  );
};

export default LiveContainer;
