import React from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import { FaCircleNotch } from 'react-icons/fa';
import cn from 'classnames';
import { Button, FooterWrapper } from './FormElements';
import { IconClock, IconLevel1, IconLevel2, IconLevel3, IconLevel4, IconLevel5 } from './Icons';

const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const IconLevel = ({ level, ...props }) => {
  switch (level) {
    case 1:
      return <IconLevel1 {...props} />;
    case 2:
      return <IconLevel2 {...props} />;
    case 3:
      return <IconLevel3 {...props} />;
    case 4:
      return <IconLevel4 {...props} />;
    case 5:
      return <IconLevel5 {...props} />;
    default:
      return false;
  }
};

const VideoWorkoutModal = ({ id, isModalVisible, isLoading, handleOk, handleCancel, ...props }) => {
  const state = useSelector((state) => state.studio.videos);
  const workout = state?.find((video) => video.id === id);

  const videoTitle = workout?.video_title
    ?.replaceAll('/n', '\n')
    .split('\n')
    .map((el) => el.trim())
    .filter((el) => !!el)
    .join(' — ');

  const handleClose = () => {
    handleCancel();
  };

  const handleClick = () => {
    handleOk();
  };

  return (
    <Modal
      size="md"
      defaultClass={false}
      disableOverflow
      className="text-center md:text-left fs-14 md:fs-18 flex flex-col flex-nowrap of-hidden"
      visible={isModalVisible}
      onClose={handleClose}
    >
      <div className="px-20 md:px-40">
        <p className="md:show fs-20 md:fs-24 fw-800 md:fw-700 mb-20 line-clamp-2">{videoTitle}</p>

        <div className="flex information-row instructors-datetime-row">
          <div className="mr-auto md:mr-15">
            <div className="flex instructors">
              {workout?.video_instructors?.map((instructor, key) => (
                <div
                  key={key}
                  className="w-35 h-35 r-100 of-hidden bg-grey-300 img-avatar"
                  style={{ marginRight: '-12px', zIndex: workout?.video_instructors.length - key }}
                >
                  <img
                    className="fit fit-cover instructor-avatar"
                    src={instructor?.picture || emptyImage}
                    alt={instructor?.name}
                    key={key}
                  />
                </div>
              ))}
            </div>
          </div>
          {workout?.video_level > 0 && (
            <div className="flex align-center lh-1 ml-10 level">
              <IconLevel level={workout.video_level} className="w-15 md:w-20 mr-5 my-auto" />
              <span className="fs-12 md:fs-14 fg-grey-500">Level {workout.video_level}</span>
            </div>
          )}
          <div className="flex align-center lh-1 ml-10 duration">
            <IconClock className="h-15 md:h-20 mr-5 my-auto fg-primary" />
            <span className="fs-12 md:fs-14 fg-grey-500">{workout?.duration} mins</span>
          </div>
        </div>
      </div>
      <div className="px-20 md:px-40">
        <p className="fs-18 md:fs-20 fw-700 mt-10 md:mt-20 mb-10">Workout stucture</p>
      </div>
      <div className="px-20 md:px-40 of-y-auto modal-scrollstyle">
        {workout?.list_of_videos?.map((el, index) => (
          <div key={el.id} className="flex align-center">
            <div className="flex flex-col align-center align-self-stretch md:order-2">
              <div className={cn('flex-1 mb-10 md:mb-15 bg-grey-400', index > 0 ? 'w-1' : 'w-0')} />
              <div className="flex w-30 h-30 r-100 b-1 b-grey-400 fg-grey-500">
                <span className="fs-14 fw-500 lh-1 m-auto">{index + 1}</span>
              </div>
              <div
                className={cn(
                  'flex-1 mt-10 md:mt-15 bg-grey-400',
                  index + 1 < workout?.list_of_videos.length ? 'w-1' : 'w-0',
                )}
              />
            </div>
            <div className="w-15 md:order-1" />
            <div className="flex-1 flex align-center p-10 md:px-0 md:py-10 my-4 md:my-0 r-15 b-1 md:b-0 b-grey-400">
              <img
                src={el.thumbnail || emptyImage}
                alt=""
                className="w-65 h-65 fit-cover r-10 bg-grey-300"
              />
              <div className="flex-1 pl-20 lh-1">
                <p className="fs-12 md:fs-16 fw-700 text-left mb-5">{el.title}</p>

                <div className="flex align-center justify-end md:justify-start lh-1 duration">
                  <IconClock className="h-15 md:h-20 mr-5 my-auto fg-primary" />
                  <span className="fs-12 md:fs-14 fg-grey-500">{el.length} mins</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <FooterWrapper disableBackground>
        <Button primary className="fit-w md:w-290" onClick={handleClick}>
          Start workout
        </Button>
      </FooterWrapper>

      {isLoading && <FaCircleNotch className="w-25 h-25 spin fg-primary" />}
    </Modal>
  );
};

export default VideoWorkoutModal;
