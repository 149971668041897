export const API_REQUEST_EXPLORE_VIDEO_CATEGORY = "API_REQUEST_EXPLORE_VIDEO_CATEGORY";
export const API_REQUEST_EXPLORE_VIDEO_CATEGORY_SUCCESS = "API_REQUEST_EXPLORE_VIDEO_CATEGORY_SUCCESS";
export const API_REQUEST_EXPLORE_VIDEO_CATEGORY_FAILED = "API_REQUEST_EXPLORE_VIDEO_CATEGORY_FAILED";

export const API_REQUEST_EXPLORE_VIDEO = "API_REQUEST_EXPLORE_VIDEO";
export const API_REQUEST_EXPLORE_VIDEO_SUCCESS = "API_REQUEST_EXPLORE_VIDEO_SUCCESS";
export const API_REQUEST_EXPLORE_VIDEO_FAILED = "API_REQUEST_EXPLORE_VIDEO_FAILED";

export const API_REQUEST_BODY_PART = "API_REQUEST_BODY_PART";
export const API_REQUEST_BODY_PART_SUCCESS = "API_REQUEST_BODY_PART_SUCCESS";
export const API_REQUEST_BODY_PART_FAILED = "API_REQUEST_BODY_PART_FAILED";

export const API_REQUEST_COMBINE_VIDEO = "API_REQUEST_COMBINE_VIDEO";
export const API_REQUEST_COMBINE_VIDEO_SUCCESS = "API_REQUEST_COMBINE_VIDEO_SUCCESS";
export const API_REQUEST_COMBINE_VIDEO_FAILED = "API_REQUEST_COMBINE_VIDEO_FAILED";
