import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SignupQuestionnaireLayout from '../Layouts/SignupQuestionnaireLayout';
import MultipleChoiceQuestion from '../Components/MultipleChoiceQuestion';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { ReactComponent as Illustration } from '../Assets/goals-grats.svg';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { FaChevronLeft } from 'react-icons/fa';
import cn from 'classnames';
import { Button, FooterWrapper } from '../Components/FormElements';
import { IconDuration, IconGender, IconGoals, IconLevel } from '../Components/Icons';

const string = {
  title: 'Just 4 steps away from a healthier you.',
  steps: ['Gender', 'Goals', 'Fitness level', 'Workout duration'],
  icons: [IconGender, IconGoals, IconLevel, IconDuration],
  stepMatch: [
    { id: 1, index: 0 },
    { id: 2, index: 1 },
    { id: 3, index: 1 },
    { id: 4, index: 1 },
    { id: 5, index: 1 },
    { id: 6, index: 2 },
    { id: 7, index: 3 },
  ],
  back: 'Back',
  next: 'Next',
  niceJobTitle: 'Nice Job!',
  niceJobCaption: 'You’ve set your goal! You can always change this later.',
};

const SignUpPage = ({
  question,
  progress,
  isLoading,
  selectedChoices,
  onChoiceClicked,
  onSubChoicesClicked,
  onAnswerSubmit,
  expandId,
  onClickExpand,
  onClickBack,
}) => {
  const currentIndex = string.stepMatch.find((el) => el.id === question?.id)?.index;
  const [showCongratulation, setShowCongratulation] = useState(false);
  const refAnimationInstance = useRef(null);

  const getInstance = (instance) => {
    refAnimationInstance.current = instance;
  };

  const makeShot = (opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        colors: ['#FBC120', '#A2C875', '#F1E4C0', '#4993A4', '#D05953', '#FFCD86'],
        particleCount: 120,
        spread: 180,
        startVelocity: 45,
        decay: 0.9,
      });
  };

  useEffect(() => {
    return () => {
      setShowCongratulation(false);
    };
  }, []);

  useEffect(() => {
    if (currentIndex === 2) {
      setShowCongratulation(true);
      setTimeout(() => makeShot(), 0);
      setTimeout(() => setShowCongratulation(false), 3500);
    } else {
      setShowCongratulation(false);
    }
  }, [currentIndex]);

  return (
    <>
      <div className="flex justify-content align-center p-10 pt-20 lg:px-40 md:pt-10">
        <Link to="/">
          <Logo className="md:w-90 md:show" />
        </Link>
        <div className="md:hide flex w-35 h-35 r-100 active:bg-black active:fg-white">
          <FaChevronLeft className="w-25 m-auto" onClick={onClickBack} />
        </div>
        <div className="flex-1 fs-12 md:fs-16 fg-grey-400 text-center">{string.title}</div>
        <div className="w-35 md:hide" />
        <div className="md:w-90 md:show" />
      </div>
      <div className="flex h-40 mx-auto">
        {string.steps.map((step, index) => {
          const isLast = index === string.steps.length - 1;
          const isCurrent = index === currentIndex;
          const isDone = index < currentIndex;
          const Icon = string.icons[index];

          return (
            <div key={index} className="flex align-center">
              <div
                className={cn(
                  'flex w-40 h-40 md:mr-15 r-100 b-2',
                  isCurrent ? 'b-primary' : isDone ? 'b-primary' : 'b-grey-200',
                  isDone ? 'bg-primary' : null,
                )}
              >
                <Icon
                  className={cn(
                    'w-20 h-20 m-auto',
                    isCurrent ? null : isDone ? 'fg-white' : 'fg-grey-500',
                  )}
                />
              </div>
              <div
                className={cn(
                  'md:show fs-16 fw-600',
                  isCurrent ? null : isDone ? 'fg-primary' : 'fg-grey-500400',
                )}
              >
                {step}
              </div>
              {!isLast && (
                <hr
                  className={cn(
                    'w-15 lg:w-65 mx-5 md:mx-15 bb-2',
                    isCurrent || isDone ? 'b-primary' : 'b-grey-200',
                  )}
                />
              )}
            </div>
          );
        })}
      </div>
      <SignupQuestionnaireLayout id={question?.id} progress={progress} isLoading={isLoading}>
        <div className="my-auto px-20 py-10 question-container">
          {question ? (
            <MultipleChoiceQuestion
              question={question.text}
              questionId={question.id}
              subText={question.sub_text}
              choices={question.choice_question}
              selectedChoices={selectedChoices}
              isJointSelection={question.is_joint_selection}
              layoutColumns={question.choices_layout_columns}
              onChoiceClicked={onChoiceClicked}
              onSubChoicesClicked={onSubChoicesClicked}
              isMultipleSelectionAllowed={
                question?.question_type?.name?.indexOf('Single Choice') === -1
              }
              expandId={expandId}
              onClickExpand={onClickExpand}
            />
          ) : (
            <></>
          )}
        </div>

        <FooterWrapper>
          <Button grey className="md:show fit-w md:w-200 md:mx-10" onClick={onClickBack}>
            {string.back}
          </Button>
          <Button
            primary
            className={cn('fit-w md:w-290 md:mx-10', selectedChoices.length === 0 && 'disabled')}
            onClick={onAnswerSubmit}
          >
            {string.next}
          </Button>
        </FooterWrapper>
      </SignupQuestionnaireLayout>
      {showCongratulation && (
        <div
          className="fixed flex flex-col top-0 right-0 bottom-0 left-0 fg-white"
          style={{ background: 'rgba(0,0,0,.8)' }}
          onClick={() => setShowCongratulation(false)}
        >
          <div className="relative text-center m-auto">
            <div className="md:absolute top-0 right-0 left-0 lh-1.6 py-10 md:py-0">
              <p className="fs-24 md:fs-45 fw-700">{string.niceJobTitle}</p>
              <p className="fs-12 md:fs-20">{string.niceJobCaption}</p>
            </div>
            <Illustration />
          </div>
          <ReactCanvasConfetti
            refConfetti={getInstance}
            style={{
              position: 'fixed',
              pointerEvents: 'none',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        </div>
      )}
    </>
  );
};

export default SignUpPage;
