import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import VirtualInstructorRightBar from '../Components/VirtualInstructorRightBar';
import { IconLogout } from '../Components/Icons';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import LogOutModal from '../Components/LogOutModal';

const menuItems = [
  {
    link: '/my-profile?page=profile',
    title: 'Profile',
  },
  {
    link: '/my-profile?page=settings',
    title: 'Settings',
  },
];

const SettingsLayout = ({ children }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { pathname, search } = useLocation();

  const handleClickLogout = () => {
    setShowLogoutModal(true);
  };

  const handleLogout = () => {
    setShowLogoutModal(false);
  };

  const handleCancel = () => {
    setShowLogoutModal(false);
  };

  return (
    <>
      <DashboardLayout rightBar={<VirtualInstructorRightBar page={'settings'} />}>
        {/* <MyStudioTopCard /> */}
        <div className="relative">
          <div className="flex align-center fs-24 md:fs-30 fw-800 md:fw-700 pb-20">
            <div>My Profile</div>
            <div
              className="md:hide flex w-40 h-40 b-1 b-black r-100 ml-auto fg-red-700 hover:bg-black hover:fg-white active:bg-black active:fg-white cursor-pointer"
              onClick={handleClickLogout}
            >
              <IconLogout className="m-auto" />
            </div>
          </div>
          <div className="fs-14 md:fs-16 fw-600 md:fw-500 fg-grey-500 flex">
            {menuItems.map(({ link, title }, key) => (
              <React.Fragment key={key}>
                <Link
                  to={link}
                  className={cn(
                    'relative pb-10 mr-25',
                    `${pathname}${search}` === link
                      ? 'fw-600 fg-primary bb-3 b-primary'
                      : 'hover:fg-black active:fg-black',
                  )}
                >
                  {title}
                </Link>
              </React.Fragment>
            ))}
          </div>
          <hr className="b-grey-300 m-0" style={{ marginTop: '-2px' }} />
        </div>
        {children}

        {/* <div className="mt-20 page-content my-essentials-page">{children}</div>

      <SubsTopCard />
      <div>Top Card</div>
      <div className="page-content my-settings-page">{children}</div> */}
      </DashboardLayout>
      <LogOutModal
        isModalVisible={showLogoutModal}
        handleOk={handleLogout}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default SettingsLayout;
