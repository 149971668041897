import { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import getCroppedImg from '../Utils/cropImage';
import { Button } from './FormElements';
import Modal from './Modal';

const PictureCropModal = ({ isModalVisible, picture, isLoading, handleOk, handleCancel }) => {
  const [croppedAPixels, setCroppedAPixels] = useState({});
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAPixels(croppedAreaPixels);
  }, []);

  const onClickOk = async () => {
    const croppedImage = await getCroppedImg(picture, croppedAPixels);
    handleOk(croppedImage);
  };

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [isModalVisible]);

  return (
    <Modal className="text-center" visible={isModalVisible} onClose={handleCancel}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">Adjust profile picture</p>
      <p className="fg-grey-500">
        Adjust the zoom and position to get your perfect profile picture
      </p>
      <div className="my-30">
        <div className="relative fit-w h-250 crop-container">
          <Cropper
            image={picture}
            crop={crop}
            zoom={zoom}
            cropShape="round"
            showGrid={false}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="flex align-center justify-center mt-20 controls">
          <FaMinusCircle className="fg-grey-400" />
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="mx-15 zoom-range"
          />
          <FaPlusCircle className="fg-grey-400" />
        </div>
      </div>
      <p>
        <Button primary className="fit-w md:w-290 my-15" onClick={onClickOk}>
          Save picture
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-18 fw-600 mt-20 underline hover:fg-primary"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </p>
    </Modal>
  );
};

export default PictureCropModal;
