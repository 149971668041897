import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { balanceService } from "../services/balance";

import * as types from "../constants/balance";
import * as actions from "../actions/balance";

function* apiBalanceRequestWorker(action) {
  try {
    const result = yield call(balanceService.apiBalanceRequest, action);
    yield put(actions.apiBalanceRequestSuccess(result));
  } catch (err) {
    yield put(actions.apiBalanceRequestFailed(err));
  }
}

function* apiBalanceRequestWatcher() {
  yield takeEvery(types.API_BALANCE_REQUEST, apiBalanceRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* subscriptionRootSaga() {
  const sagas = [apiBalanceRequestWatcher];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
