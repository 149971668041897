import { ReactComponent as Instructor } from '../../Assets/virtual-instructor.svg';
import MultipleChoiceQuestion from '../../Components/MultipleChoiceQuestion';
import { FaCircleNotch } from 'react-icons/fa';
import cn from 'classnames';
import { Button, FooterWrapper } from '../FormElements';

const VirtualInstructor = ({
  page,
  question,
  progress,
  isLoading,
  isPossible,
  selectedChoices,
  onChoiceClicked,
  onSubChoicesClicked,
  onAnswerSubmit,
  expandId,
  onClickExpand,
  onClickBack,
  fromVirtualInstructor = true,
}) => {
  return isLoading ? (
    <FaCircleNotch className="w-25 h-25 mx-auto my-auto spin fg-primary" />
  ) : (
    <div className="flex-1 flex flex-col">
      <div className="px-20 my-auto">
        {!page && <Instructor className="mx-auto mb-10 md:w-80" />}

        {question && (
          <MultipleChoiceQuestion
            question={question.text}
            questionId={question.id}
            subText={question.sub_text}
            choices={question.choice_question}
            selectedChoices={selectedChoices}
            isJointSelection={question.is_joint_selection}
            layoutColumns={question.choices_layout_columns}
            onChoiceClicked={onChoiceClicked}
            onSubChoicesClicked={onSubChoicesClicked}
            isMultipleSelectionAllowed={
              question?.question_type?.name?.indexOf('Single Choice') === -1
            }
            fromVirtualInstructor={fromVirtualInstructor}
            expandId={expandId}
            onClickExpand={onClickExpand}
          />
        )}
      </div>

      {/* <div className="md:show flex-1" /> */}

      {!page ? (
        <FooterWrapper>
          <Button
            primary
            className={cn(
              'fit-w md:w-230 md:mx-10 my-10 md:order-1',
              !selectedChoices?.length && 'disabled',
            )}
            onClick={onAnswerSubmit}
          >
            Continue
          </Button>
          <Button grey className={cn('fit-w md:w-160 md:mx-10 my-10')} onClick={onClickBack}>
            Back
          </Button>
        </FooterWrapper>
      ) : (
        <Button
          primary
          className={cn('fit-w md:w-440 md:mx-auto my-10', !selectedChoices?.length && 'disabled')}
          onClick={onAnswerSubmit}
        >
          Continue
        </Button>
      )}
    </div>
  );
};

export default VirtualInstructor;
