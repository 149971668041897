import Choice from '../Choice';
import JointSelection from '../JointSelection';
import _ from 'lodash';

const MultipleChoiceQuestion = ({
  question,
  questionId,
  subText,
  choices,
  isJointSelection,
  layoutColumns,
  isMultipleSelectionAllowed,
  selectedChoices,
  fromVirtualInstructor,
  onSubChoicesClicked,
  onChoiceClicked,
  expandId,
  onClickExpand,
}) => {
  const sorted = _.orderBy(choices, ['order']);
  const sorted1 = sorted.filter((el) => [13, 14, 15, 17, 18].includes(el.id));
  const sorted2 = sorted.filter((el) => [16, 19, 20].includes(el.id));

  return (
    <>
      <h2
        className={
          fromVirtualInstructor
            ? 'fs-24 fw-700 virtual-question-label'
            : 'fs-24 md:fs-26 fw-700 m-5 text-center question-label'
        }
      >
        {question}
      </h2>
      {subText && <p className="fs-16 fg-primary text-center subTextx">{subText}</p>}
      {!isJointSelection ? (
        <div
          className={`flex flex-wrap justify-center container-auto-lg p-0 md:py-10 choices-container layout-${layoutColumns}`}
        >
          {_.map(choices, (choice, index) => {
            const choiceResult = selectedChoices.find((obj) => obj.choice === choice.id);
            const isSelected = choiceResult === undefined ? false : true;

            let selectedSubChoices = [];
            if (choiceResult) {
              selectedSubChoices = choiceResult.sub_choices;
            }
            return (
              // <Choice
              //   {...choice}
              //   layoutColumns={layoutColumns}
              //   isMultipleChoiceQuestion={isMultipleSelectionAllowed}
              //   isSelected={selectedChoices.find((obj) => obj.choice === choice.id) !== undefined}
              //   questionId={questionId}
              //   key={index}
              //   onChoiceClick={onChoiceClicked}
              // />
              <Choice
                {...choice}
                isSelected={isSelected}
                selectedSubChoices={selectedSubChoices}
                questionId={questionId}
                choiceId={choice.id}
                key={index}
                onChoiceClick={onChoiceClicked}
                onSubChoicesClicked={onSubChoicesClicked}
                isMultipleChoiceQuestion={isMultipleSelectionAllowed}
                expandId={expandId}
                onClickExpand={onClickExpand}
                layoutColumns={layoutColumns}
              />
            );
          })}
        </div>
      ) : (
        <>
          {fromVirtualInstructor ? (
            <>
              <div>
                <JointSelection selectedChoices={selectedChoices} />
              </div>
              <div className={`choices-container layout-${layoutColumns}`}>
                {sorted.map((choice, index) => {
                  const choiceResult = selectedChoices.find((obj) => obj.choice === choice.id);
                  const isSelected = choiceResult === undefined ? false : true;

                  let selectedSubChoices = [];
                  if (choiceResult) {
                    selectedSubChoices = choiceResult.sub_choices;
                  }
                  return (
                    <Choice
                      {...choice}
                      isSelected={isSelected}
                      selectedSubChoices={selectedSubChoices}
                      questionId={questionId}
                      choiceId={choice.id}
                      key={index}
                      onChoiceClick={onChoiceClicked}
                      onSubChoicesClicked={onSubChoicesClicked}
                      isMultipleChoiceQuestion={isMultipleSelectionAllowed}
                      expandId={expandId}
                      onClickExpand={onClickExpand}
                      showCheckbox={true}
                      layoutColumns={layoutColumns}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="container-auto-xl p-0 lg:py-15 flex flex-wrap justify-center mx-auto joint-selection-question">
              <div
                className={`flex flex-wrap justify-center mt-auto fit-w lg:w-4/12 choices-container layout-${layoutColumns}`}
              >
                <div className="fit-w px-15 py-5 fg-grey-400 text-center lg:text-left">
                  Upper body
                </div>
                {sorted1.map((choice, index) => {
                  const choiceResult = selectedChoices.find((obj) => obj.choice === choice.id);
                  const isSelected = choiceResult === undefined ? false : true;

                  let selectedSubChoices = [];
                  if (choiceResult) {
                    selectedSubChoices = choiceResult.sub_choices;
                  }

                  return (
                    <Choice
                      {...choice}
                      isSelected={isSelected}
                      selectedSubChoices={selectedSubChoices}
                      questionId={questionId}
                      choiceId={choice.id}
                      key={index}
                      onChoiceClick={onChoiceClicked}
                      onSubChoicesClicked={onSubChoicesClicked}
                      isMultipleChoiceQuestion={isMultipleSelectionAllowed}
                      expandId={expandId}
                      onClickExpand={onClickExpand}
                      showCheckbox={true}
                      layoutColumns={layoutColumns}
                    />
                  );
                })}
              </div>
              <div className="lg:show lg:w-4/12 flex flex-wrap justify-center joint-selection-wrapper">
                <JointSelection selectedChoices={selectedChoices} />
              </div>
              <div
                className={`flex flex-wrap justify-center mt-auto fit-w lg:w-4/12 choices-container layout-${layoutColumns}`}
              >
                <div className="fit-w px-15 py-5 fg-grey-400 text-center lg:text-left">
                  Lower body
                </div>
                {sorted2.map((choice, index) => {
                  const choiceResult = selectedChoices.find((obj) => obj.choice === choice.id);
                  const isSelected = choiceResult === undefined ? false : true;

                  let selectedSubChoices = [];
                  if (choiceResult) {
                    selectedSubChoices = choiceResult.sub_choices;
                  }

                  return (
                    <Choice
                      {...choice}
                      isSelected={isSelected}
                      selectedSubChoices={selectedSubChoices}
                      questionId={questionId}
                      choiceId={choice.id}
                      key={index}
                      onChoiceClick={onChoiceClicked}
                      onSubChoicesClicked={onSubChoicesClicked}
                      isMultipleChoiceQuestion={isMultipleSelectionAllowed}
                      expandId={expandId}
                      onClickExpand={onClickExpand}
                      showCheckbox={true}
                      layoutColumns={layoutColumns}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MultipleChoiceQuestion;
