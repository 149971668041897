export const API_REQUEST_VIDEO = "API_REQUEST_VIDEO";
export const API_REQUEST_VIDEO_SUCCESS = "API_REQUEST_VIDEO_SUCCESS";
export const API_REQUEST_VIDEO_FAILED = "API_REQUEST_VIDEO_FAILED";

export const API_REQUEST_ESSENTIALS = "API_REQUEST_ESSENTIALS";
export const API_REQUEST_ESSENTIALS_SUCCESS = "API_REQUEST_ESSENTIALS_SUCCESS";
export const API_REQUEST_ESSENTIALS_FAILED = "API_REQUEST_ESSENTIALS_FAILED";

export const API_REQUEST_PROGRAM = "API_REQUEST_PROGRAM";
export const API_REQUEST_PROGRAM_SUCCESS = "API_REQUEST_PROGRAM_SUCCESS";
export const API_REQUEST_PROGRAM_FAILED = "API_REQUEST_PROGRAM_FAILED";

export const API_REQUEST_MOODS = "API_REQUEST_MOODS";
export const API_REQUEST_MOODS_SUCCESS = "API_REQUEST_MOODS_SUCCESS";
export const API_REQUEST_MOODS_FAILED = "API_REQUEST_MOODS_FAILED";

export const API_REQUEST_SCHEDULE_VIDEO = "API_REQUEST_SCHEDULE_VIDEO";
export const API_REQUEST_SCHEDULE_VIDEO_SUCCESS = "API_REQUEST_SCHEDULE_VIDEO_SUCCESS";
export const API_REQUEST_SCHEDULE_VIDEO_FAILED = "API_REQUEST_SCHEDULE_VIDEO_FAILED";

export const API_REQUEST_REMOVE_VIDEO = "API_REQUEST_REMOVE_VIDEO";
export const API_REQUEST_REMOVE_VIDEO_SUCCESS = "API_REQUEST_REMOVE_VIDEO_SUCCESS";
export const API_REQUEST_REMOVE_VIDEO_FAILED = "API_REQUEST_REMOVE_VIDEO_FAILED";

export const API_REQUEST_LEVEL_CHANGE = "API_REQUEST_LEVEL_CHANGE";
export const API_REQUEST_LEVEL_CHANGE_SUCCESS = "API_REQUEST_LEVEL_CHANGE_SUCCESS";
export const API_REQUEST_LEVEL_CHANGE_FAILED = "API_REQUEST_LEVEL_CHANGE_FAILED";

export const API_REQUEST_VIDEO_ACTIVITY = "API_REQUEST_VIDEO_ACTIVITY";
export const API_REQUEST_VIDEO_ACTIVITY_SUCCESS = "API_REQUEST_VIDEO_ACTIVITY_SUCCESS";
export const API_REQUEST_VIDEO_ACTIVITY_FAILED = "API_REQUEST_VIDEO_ACTIVITY_FAILED";

export const LOCK_VIDEOS = "LOCK_VIDEOS";
