/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import cn from 'classnames';
import { Button } from './FormElements';

const answers = [
  {
    value: 'Up',
    label: 'Too easy',
    levelMax: 5,
  },
  {
    value: 'Down',
    label: 'Too hard',
    levelMin: 1,
  },
  {
    value: '',
    label: 'Just right',
  },
];

const VideoAdjustModal = ({ id, isModalVisible, isLoading, handleOk, handleCancel }) => {
  const [answer, setAnswer] = useState(null);
  const state = useSelector((state) => state.studio.videos);
  const video = state?.find((video) => video.id === id);
  const { video_level } = video || {};

  const onSelectedReason = (val) => {
    setAnswer(val);
  };

  const handleClose = () => {
    setAnswer(null);
    handleCancel();
  };

  const onClickedOk = () => {
    answer ? handleOk(id, answer) : handleCancel();
  };

  useEffect(() => {
    setAnswer(null);
    if (!video_level) {
      handleCancel();
    }
  }, [isModalVisible]);

  return (
    <Modal
      className="text-center fs-14 md:fs-18"
      visible={video_level && isModalVisible}
      onClose={handleClose}
    >
      <p className="fs-24 md:fs-28 fw-700 lg:mb-20">How was your workout?</p>
      <p className="fg-grey-500">We’ll adjust your next training according to your answer</p>
      <div className="lg:my-50">
        {answers.map(({ value, label, levelMin, levelMax }, key) => (
          <div
            key={key}
            className={cn(
              'flex justify-center align-center md:w-400 h-50 md:h-65 mx-auto my-15 fs-14 md:fs-18 fw-500 r-10 md:r-15 b-1 b-grey-400 hover:b-primary cursor-pointer',
              answer === value && 'b-primary bg-primary-light',
              levelMax && video_level >= levelMax && 'hide',
              levelMin && video_level <= levelMin && 'hide',
            )}
            onClick={() => onSelectedReason(value)}
          >
            {label}
          </div>
        ))}
      </div>
      <p>
        <Button primary className="fit-w md:w-290 lg:my-15" onClick={onClickedOk}>
          Continue
        </Button>
      </p>
    </Modal>
  );
};

export default VideoAdjustModal;
