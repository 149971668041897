import * as types from "../constants/order";

export const apiProductsRequest = (data) => ({
  type: types.API_PRODUCTS_REQUEST,
  data,
});

export const apiProductsRequestSuccess = (response) => ({
  type: types.API_PRODUCTS_REQUEST_SUCCESS,
  response,
});

export const apiProductsRequestFailed = (response) => ({
  type: types.API_PRODUCTS_REQUEST_FAILED,
  response,
});


export const apiBuyCreditsRequest = (data) => ({
  type: types.API_BUY_CREDITS_REQUEST,
  data,
});

export const apiBuyCreditsRequestSuccess = (response) => ({
  type: types.API_BUY_CREDITS_REQUEST_SUCCESS,
  response,
});

export const apiBuyCreditsRequestFailed = (response) => ({
  type: types.API_BUY_CREDITS_REQUEST_FAILED,
  response,
});
