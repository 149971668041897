import React from 'react';
import { ReactComponent as SvgBody } from '../../Assets/body.svg';
import _ from 'lodash';

const JointSelection = ({ selectedChoices, ...props }) => {
  let selectedParts = _.map(selectedChoices, (it) => {
    return it.choice;
  });

  const createStyle = (selector, ...args) => {
    let results = false;
    args.forEach((arg) => {
      if (_.includes(selectedParts, arg)) {
        results = true;
      }
    });
    return results ? `.indicator .${selector}{fill:#5DB797}` : null;
  };

  return (
    <>
      <style>
        {createStyle('neck', 13, 40)}
        {createStyle('upper', 14, 41)}
        {createStyle('lower', 15, 42)}
        {createStyle('hips', 16, 43)}
        {createStyle('elbow', 17, 44)}
        {createStyle('wrist', 18, 45)}
        {createStyle('knee', 19, 46)}
        {createStyle('feet', 20, 47)}
      </style>
      <SvgBody className="indicator md:w-170 xl:w-auto" />
    </>
  );
};

export default JointSelection;
