import * as types from '../constants/subscription';
import { mapErrorMessage } from '../../Utils/mapErrorMessage';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {Capacitor} from "@capacitor/core";

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  isPlanChanged: false,
  error: null,
  subscription: null,
  terms_conditions: null,
  paymentMethod: null,
};

export default function subscriptionApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // SUBSCRIPTION

    case types.API_REQUEST_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true,
        isPlanChanged: false,
        success: false,
        error: null,
      };

    case types.API_REQUEST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: false,
        success: true,
        error: null,
        subscription: action.response.data,
      };

    case types.API_REQUEST_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_CHANGE_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true,
        isPlanChanged: false,
        success: false,
        error: null,
      };

    case types.API_REQUEST_CHANGE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: true,
        success: true,
        error: null,
        // subscription: action.response.data,
      };

    case types.API_REQUEST_CHANGE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: false,
        success: false,
        error: mapErrorMessage(action),
      };
    case types.API_REQUEST_CHECKOUT_SUBSCRIPTION:
      return {
        ...state,
        isLoading: true,
        isPlanChanged: false,
        success: false,
        error: null,
      };

    case types.API_REQUEST_CHECKOUT_SUBSCRIPTION_SUCCESS:
      const res = action.response.data;
      toast.success('Subscription successfully activated.');
      if (Capacitor.getPlatform() === 'web') {
        window.gtag('event', 'successful_subscription_payment', {
          'event_label': 'Successful Subscription Payment',
          'value': true
        });

      } else {
        FirebaseAnalytics.logEvent({
          name: "successful_subscription_payment",
          params: {
            success: true,
          },
        }).then();
      }
      ReactPixel.track('Purchase', {
        value: res?.plan?.total_price || 0,
        currency: 'USD',
      });

      return {
        ...state,
        isLoading: false,
        isPlanChanged: true,
        success: true,
        error: null,
        // subscription: action.response.data,
      };

    case types.API_REQUEST_CHECKOUT_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_TERMS_CONDITIONS:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: false,
        success: true,
        error: null,
        terms_conditions: action.response.data,
      };

    case types.API_REQUEST_TERMS_CONDITIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isPlanChanged: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_PAYMENT_METHOD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        paymentMethod: action.response.data,
      };

    case types.API_REQUEST_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        paymentMethod: action.response.data,
      };

    case types.API_REQUEST_UPDATE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_REQUEST_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        paymentMethod: action.response.data,
      };

    case types.API_REQUEST_DELETE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    default:
      return state;
  }
}
