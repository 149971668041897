import axios from 'axios';
import { getToken } from '../../Utils/getToken';

// Fetch events
function apiEventsRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.get(
    `/api/v1/live/events/?date_from=${action.data[0].format(
      'YYYY-MM-DD',
    )}&date_to=${action.data[1].format('YYYY-MM-DD')}`,
    { headers },
  );
}

// Order event
function apiOrderEventRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.post(`/api/v1/live/book_event/`, action.data, { headers });
}

// Cancel order event
function apiOrderCancelEventRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.post(`/api/v1/live/cancel_event/`, action.data, { headers });
}

export const eventsService = {
  apiEventsRequest,
  apiOrderEventRequest,
  apiOrderCancelEventRequest,
};
