import axios from 'axios';

function apiLoginRequest(action) {
  return axios.post('/api/v1/login/', action.data, null);
}

function apiLogoutRequest(action) {
  let headers = { Authorization: `Token ${action.token}` };
  return axios.post('/rest-auth/logout/', null, { headers });
}

function apiCreateAccountRequest(action) {
  return axios.post(`/api/v1/signup/`, action.data, null);
}

function apiPasswordResetRequest(action) {
  return axios.post('/rest-auth/password/reset/', action.data);
}

function apiPasswordConfirmRequest(action) {
  return axios.post('/rest-auth/password/reset/confirm/', action.data);
}

export const loginService = {
  apiLoginRequest,
  apiLogoutRequest,
  apiCreateAccountRequest,
  apiPasswordResetRequest,
  apiPasswordConfirmRequest,
};
