import { toast } from 'react-toastify';
import _ from 'lodash';

const getErrors = (errors, message) => {
  if (_.isString(message)) {
    errors.push(message);
  } else if (_.isObject(message)) {
    _.map(message, (val, key) => {
      getErrors(errors, val);
    });
  } else if (_.isArray(message)) {
    errors.push(...message);
  }
};

export const showErrorToastNoti = (state) => {
  const message = state.error?.message || {};
  const errors = [];

  if (!state.isLoading && !state.success) {
    getErrors(errors, message);
    errors.forEach((e) => toast.error(e));
  }
};
