/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import Hls from 'hls.js'
import { scrollLock, scrollUnlock } from '../Utils/scrollLock';
import { IconTimes } from './Icons';
import {Capacitor} from "@capacitor/core";

const VideoEventModal = ({ isModalVisible, video, onClose, ...props }) => {
  const handleClose = () => {
    onClose();
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = Capacitor.getPlatform() === 'ios';

  const needsHlsLibrary = !isSafari && !isIOS;

  useEffect(() => {
    if (isModalVisible) {
      scrollLock();
    } else {
      scrollUnlock();
    }
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <div
        className="align-center bottom-0 fixed flex justify-center left-0 right-0 top-0 lg:p-20 video-dialog z-100"
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        onClick={handleClose}
      >
        <div
          className="container-auto-lg m-0 p-0 absolute ratio-16/9 bg-black lg:r-15 lg:of-hidden video-container"
          style={{ maxHeight: '100%' }}
          onClick={(e) => e.stopPropagation()}
        >
          <ReactPlayer
            url={video}
            controls
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload noremoteplayback noplaybackrate',
                  disablePictureInPicture: true,
                },
              },
            }}
            onEnded={handleClose}
            playing
            playsinline
            width="100%"
            height="100%"
          />
          <div
            className="absolute top-10 md:top-20 right-10 md:right-20 flex w-35 h-35 r-100 fg-white hover:bg-white hover:fg-black active:bg-white active:fg-black cursor-pointer"
            onClick={handleClose}
          >
            <IconTimes className="fg-grey-400 w-30 h-30 m-auto" />
          </div>
        </div>
      </div>
    )
  );
};

export default VideoEventModal;
