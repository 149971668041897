import cn from 'classnames';
import { useEffect, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { Button } from './FormElements';
import Modal from './Modal';

const reasons = [
  {
    value: 'want something new',
    label: 'Want something new',
  },
  {
    value: 'not what I need',
    label: 'Not what I need',
  },
];

const VideoDeleteModal = ({ ...props }) => {
  const { id, isModalVisible, isLoading, handleOk, handleCancel } = props;
  const [deleteReason, setDeleteReason] = useState('');

  const onSelectedReason = (r) => {
    setDeleteReason(r);
  };

  const handleClose = () => {
    setDeleteReason('');
    handleCancel();
  };

  const onClickedOk = () => {
    handleOk(id, deleteReason);
  };

  useEffect(() => {
    setDeleteReason('');
  }, [isModalVisible]);

  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isModalVisible} onClose={handleClose}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">Delete workout</p>
      <p className="fg-grey-500">Please choose the reason why you want to delete workout.</p>
      <div className="my-50">
        {reasons.map(({ value, label }, key) => (
          <div
            key={key}
            className={cn(
              'flex justify-center align-center md:w-400 h-50 md:h-65 mx-auto my-15 fs-14 md:fs-18 fw-500 r-10 md:r-15 b-1 b-grey-400 hover:b-primary cursor-pointer',
              deleteReason === value && 'b-primary bg-primary-light',
            )}
            onClick={() => onSelectedReason(value)}
          >
            {label}
          </div>
        ))}
      </div>
      <p>
        <Button red className="fit-w md:w-290 my-15" onClick={onClickedOk}>
          Delete
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-18 fw-600 mt-20 underline hover:fg-primary"
          onClick={handleClose}
        >
          Cancel
        </button>
      </p>
      {/* <Select labelInValue defaultValue={{ value: "" }} onChange={onSelectedReason}>
                  <Option value="" disabled>
                      Please select a reason
                  </Option>
                  <Option value="want something new">Want something new</Option>
                  <Option value="not what I need">Not what I need</Option>
                  <Option value="Too Easy">Too Easy</Option>
                  <Option value="Too Hard">Too Hard</Option>
              </Select> */}

      {/* {isLoading ? <Spin className="loader" /> : <></>} */}
      {isLoading && <FaCircleNotch className="w-25 h-25 spin fg-primary" />}
    </Modal>
    // <Modal title="Delete Video" visible={isModalVisible} onOk={onClickedOk} onCancel={handleCancel}>
    // </Modal>
  );
};

export default VideoDeleteModal;
