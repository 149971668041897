import {
  IconFocus,
  IconIntensity,
  IconMobility,
  IconPostnatal,
  IconRelax,
  IconStrong,
} from '../Components/Icons';

const styleCategories = [
  // Custom
  { id: null, Icon: false, className: false },
  // Focus
  { id: 1, Icon: IconFocus, className: 'category-focus' },
  // Essentials
  { id: 2, Icon: false, className: false },
  // High Intensity
  { id: 3, Icon: IconIntensity, className: 'category-intensity' },
  // Mobility
  { id: 4, Icon: IconMobility, className: 'category-mobility' },
  // Pre/Post Natal
  { id: 5, Icon: IconPostnatal, className: 'category-postnatal' },
  // Relax
  { id: 6, Icon: IconRelax, className: 'category-relax' },
  // Strength
  { id: 7, Icon: IconStrong, className: 'category-strength' },
];

export default styleCategories;
