import React from 'react';
import axios from 'axios';
import posthog from 'posthog-js';
import { Capacitor } from '@capacitor/core';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ServiceWorkerContainer from './Containers/ServiceWorkerContainer';
import App from './App';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import signupApiReducer from './Redux/reducers/signup';
import loginApiReducer from './Redux/reducers/login';
import faqApiReducer from './Redux/reducers/faq';
import exploreVideoApiReducer from './Redux/reducers/exploreVideo';
import updateCardMenuSelectionReducer from './Redux/reducers/topCard';
import updateSideMenuSelectionReducer from './Redux/reducers/sideMenu';
import myHabitApiReducer from './Redux/reducers/myHabit';
import myStatsApiReducer from './Redux/reducers/myStats';
import couponApiReducer from './Redux/reducers/coupon';
import orderApiReducer from './Redux/reducers/order';
import balanceApiReducer from './Redux/reducers/balance';
import eventsApiReducer from './Redux/reducers/events';

import createSagaMiddleware from 'redux-saga';
import signupRootSaga from './Redux/sagas';
import loginRootSaga from './Redux/sagas/login';
import faqRootSaga from './Redux/sagas/faq';
import aboutRootSaga from './Redux/sagas/about';
import settingRootSaga from './Redux/sagas/setting';
import contactUsRootSaga from './Redux/sagas/contactus';
import couponRootSaga from './Redux/sagas/coupon';
import settingApiReducer from './Redux/reducers/setting';
import aboutApiReducer from './Redux/reducers/about';
import contactusApiReducer from './Redux/reducers/contactus';
import subscriptionApiReducer from './Redux/reducers/subscription';
import subscriptionRootSaga from './Redux/sagas/subscription';
import studioVideoApiReducer from './Redux/reducers/studioVideo';
import studioRootSaga from './Redux/sagas/studioVideo';
import exploreRootSaga from './Redux/sagas/exploreVideo.js';
import virtualInstructorApiReducer from './Redux/reducers/virtualInstructor';
import virtualInstructorRootSaga from './Redux/sagas/virtualInstructor';
import myHabitRootSaga from './Redux/sagas/myHabit';
import myStatsRootSaga from './Redux/sagas/myStats';
import orderRootSaga from './Redux/sagas/order';
import balanceRootSaga from './Redux/sagas/balance';
import eventsRootSaga from './Redux/sagas/events';
import './index.css';

// For Capacitor native apps setup axios default base url
if (Capacitor.getPlatform() !== 'web') {
  axios.defaults.baseURL = 'https://app.incorpostudio.com';
}

const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers({
  signup: signupApiReducer,
  auth: loginApiReducer,
  cardMenuSelection: updateCardMenuSelectionReducer,
  sideMenuSelection: updateSideMenuSelectionReducer,
  faqs: faqApiReducer,
  about: aboutApiReducer,
  setting: settingApiReducer,
  contactus: contactusApiReducer,
  subscription: subscriptionApiReducer,
  studio: studioVideoApiReducer,
  explore: exploreVideoApiReducer,
  virtualInstructor: virtualInstructorApiReducer,
  myHabit: myHabitApiReducer,
  myStats: myStatsApiReducer,
  coupon: couponApiReducer,
  order: orderApiReducer,
  balance: balanceApiReducer,
  events: eventsApiReducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === 'API_REQUEST_LOGOUT' ||
    action.type === 'RESET_REDUX_STATE' ||
    action.response?.response?.status === 401 ||
    action.response?.response?.status === 403
  ) {
    const keepStorage = {
      tutorial: localStorage.getItem('tutorial'),
      tutorial2: localStorage.getItem('tutorial2'),
    };

    localStorage.clear();

    Object.keys(keepStorage).forEach((key) => {
      keepStorage[key] &&
        keepStorage[key] !== 'null' &&
        localStorage.setItem(key, keepStorage[key]);
    });

    if (action.response?.response?.status === 401) {
      window.location.href = '/login';
    }

    return appReducer(undefined, []);
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(signupRootSaga);
sagaMiddleware.run(loginRootSaga);
sagaMiddleware.run(faqRootSaga);
sagaMiddleware.run(aboutRootSaga);
sagaMiddleware.run(settingRootSaga);
sagaMiddleware.run(contactUsRootSaga);
sagaMiddleware.run(subscriptionRootSaga);
sagaMiddleware.run(studioRootSaga);
sagaMiddleware.run(exploreRootSaga);
sagaMiddleware.run(virtualInstructorRootSaga);
sagaMiddleware.run(myHabitRootSaga);
sagaMiddleware.run(myStatsRootSaga);
sagaMiddleware.run(couponRootSaga);
sagaMiddleware.run(orderRootSaga);
sagaMiddleware.run(balanceRootSaga);
sagaMiddleware.run(eventsRootSaga);

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  import('./nextcssConfig.js')
    .then(import('@nextcss/nextcss'))
    .then((config) => (window.nextcssConfig = config));
}

posthog.init(
  'phc_NbBw5tjV3GHnHMkN60uyO2BvaH41nZvhWP7V8t932H6',
  {
    api_host: 'https://eu.posthog.com',
  }
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    {/* For Capacitor native apps disable service worker */}
    {Capacitor.getPlatform() === 'web' && <ServiceWorkerContainer />}
  </>,

  // </React.StrictMode>,
);
