import * as types from '../constants/events';
import { mapErrorMessage } from '../../Utils/mapErrorMessage';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,

  isOrderingLoading: false,
  isOrderingSuccess: false,

  events: [],
};

export default function eventsApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Fetch events
    case types.API_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_EVENTS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        events: action.response.data,
      };

    case types.API_EVENTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    // Order event
    case types.API_ORDER_EVENT_REQUEST:
      return {
        ...state,
        isOrderingLoading: true,
        isOrderingSuccess: false,
      };

    case types.API_ORDER_EVENT_REQUEST_SUCCESS:
      toast.success('Booked! We have sent you an email confirmation and link.');

      return {
        ...state,
        isOrderingLoading: false,
        isOrderingSuccess: true,
        events: state.events.map((event) =>
          event.id === action.response.data.id
            ? {
                ...action.response.data,
                is_booked: true,
              }
            : event,
        ),
      };

    case types.API_ORDER_EVENT_REQUEST_FAILED:
      toast.error(mapErrorMessage(action).message.Error || 'Error booking event');

      return {
        ...state,
        isOrderingLoading: false,
        isOrderingSuccess: false,
      };

    // Cancel order event
    case types.API_ORDER_CANCEL_EVENT_REQUEST:
      return {
        ...state,
        isOrderingLoading: true,
        isOrderingSuccess: false,
      };

    case types.API_ORDER_CANCEL_EVENT_REQUEST_SUCCESS:
      toast.success('Event is canceled successfully');

      return {
        ...state,
        isOrderingLoading: false,
        isOrderingSuccess: true,
        events: state.events.map((event) =>
          event.id === action.response.data.id
            ? {
                ...action.response.data,
                is_booked: false,
              }
            : event,
        ),
      };

    case types.API_ORDER_CANCEL_EVENT_REQUEST_FAILED:
      toast.error(mapErrorMessage(action).message.Error || 'Error canceling event');

      return {
        ...state,
        isOrderingLoading: false,
        isOrderingSuccess: false,
      };

    default:
      return state;
  }
}
