import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unlockAccessAlertHide } from '../Redux/actions/setting';
import Modal from './Modal';
import cn from 'classnames';
import { Button } from './FormElements';

const UnlockAccessModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isActive, setActive] = useState(false);

  const { isFullAccessAlertVisible } = useSelector((state) => state.setting);

  useEffect(() => {
    setActive(isFullAccessAlertVisible);
  }, [isFullAccessAlertVisible]);

  const onClickHandle = () => {
    dispatch(unlockAccessAlertHide());
    navigate('/my-profile?page=subscription');
  };

  const onCloseHandle = () => {
    setActive(false);
    dispatch(unlockAccessAlertHide());
  };

  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isActive} onClose={onCloseHandle}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">Get full access</p>
      <p className="fg-grey-500 mb-40">Unlock full access by upgrading your subscription plan.</p>
      <p>
        <Button primary className={cn('fit-w md:w-290 my-15')} onClick={onClickHandle}>
          Upgrade Plan
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-18 fw-600 mt-20 underline hover:fg-primary"
          onClick={onCloseHandle}
        >
          Cancel
        </button>
      </p>
    </Modal>
  );
};

export default UnlockAccessModal;
