import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { myHabitService } from "../services/myHabit";
import * as types from "../constants/myHabit";
import * as actions from "../actions/myHabit";

//
function* apiMyHabitRequestWorker(action) {
    try {
        const result = yield call(myHabitService.apiGetMyHabits, action);
        yield put(actions.apiMyHabitRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiMyHabitRequestFailed(err));
    }
}

function* apiMyHabitRequestWatcher() {
    yield takeEvery(types.API_REQUEST_MY_HABIT, apiMyHabitRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* myHabitRootSaga() {
    const sagas = [apiMyHabitRequestWatcher];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
