import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { virtualInstructorService } from "../services/virtualInstructor";
import * as types from "../constants/virtualInstructor";
import * as actions from "../actions/virtualInstructor";

// Virtual Instructor
function* apiVirtualInstructorRequestWorker(action) {
    try {
        const result = yield call(virtualInstructorService.apiVirtualInstructorRequest, action);
        yield put(actions.apiVirtualInstructorRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiVirtualInstructorRequestFailed(err, action));
    }
}

function* apiVirtualInstructorRequestWatcher() {
    yield takeEvery(types.API_REQUEST_VIRTUAL_INSTRUCTOR, apiVirtualInstructorRequestWorker);
}
function* apiVirtualInstructorRecalibrateRequestWorker(action) {
    try {
        const result = yield call(virtualInstructorService.apiVirtualInstructorRecalibrateRequest, action);
        yield put(actions.apiVirtualInstructorRecalibrateRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiVirtualInstructorRecalibrateRequestFailed(err, action));
    }
}

function* apiVirtualInstructorRecalibrateRequestWatcher() {
    yield takeEvery(types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE, apiVirtualInstructorRecalibrateRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* virtualInstructorRootSaga() {
    const sagas = [apiVirtualInstructorRequestWatcher, apiVirtualInstructorRecalibrateRequestWatcher];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
