/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import { useDispatch, useSelector } from 'react-redux';
import defaultTimeZone from '../Utils/defaultTimeZone';
import moment from 'moment-timezone';
import LiveLayout from '../Layouts/LiveLayout';
import { apiBalanceRequest } from '../Redux/actions/balance';
import { apiEventsRequest } from '../Redux/actions/events';
import { Button } from '../Components/FormElements';
import { IconCalendar, IconLiveTicket } from '../Components/Icons';
import cn from 'classnames';
import { FaCircleNotch } from 'react-icons/fa';

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const range = (start = 0, end = 0, step = 1) => {
  const output = [];
  for (let i = start; i <= end; i += step) output.push(i);
  return output;
};

const CardCalendar = ({ small = false, event, setShowBookEventModal, setEventDetails }) => {
  const {
    // id,
    // title,
    // category,
    event_date,
    event_start,
    event_end,
    event_timezone,
    instructor,
    is_booked,
    max_no_participants,
    current_no_participants,
  } = event;
  const sourceTimeZone = event_timezone || defaultTimeZone;
  const targetTimeZone = moment.tz.guess();
  const momentSourceTimeStart = moment(`${event_date} ${event_start}`).tz(sourceTimeZone, true);
  const momentTargetTimeStart = momentSourceTimeStart.clone().tz(targetTimeZone);
  const isInPast = event_date ? momentTargetTimeStart.isSameOrBefore() : false;
  const hasFreeSpace = max_no_participants - current_no_participants > 0;
  const duration = moment(event_end, 'HH:mm:ss').diff(moment(event_start, 'HH:mm:ss')) / 1000 / 60;
  const isAvailable = !isInPast && hasFreeSpace && !is_booked;

  return (
    <div
      className={cn(
        'align-center p-4 md:p-8 mx-3 r-10 text-left b-1 cursor-pointer hover:b-grey-700',
        isAvailable ? 'bg-primary-light fg-primary' : 'bg-grey-100 b-grey-100',
        small ? 'inline-flex' : 'flex',
      )}
      onClick={() => {
        setEventDetails(event);
        setShowBookEventModal(true);
      }}
    >
      <div>
        {small && (
          <div className="flex justify-center align-center" style={{ marginTop: '-5px' }}>
            <IconLiveTicket className="w-10 h-10" />
            <span className="pl-2">1</span>
          </div>
        )}
        <img
          src={instructor?.picture}
          alt=""
          className="flex-0 w-30 h-30 r-5 fit-cover bg-grey-300"
        />
      </div>
      {!small && (
        <div className="flex-1 fs-12 lh-1.2 pl-6 of-hidden">
          <div className="fw-600 text-trim">{instructor?.name}</div>
          <div className="flex fg-grey-500">
            <div className="flex-1 text-trim">{duration} mins</div>

            <div className="flex align-center">
              <IconLiveTicket className="w-10 h-10" />
              <span className="pl-2">1</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const LiveSessionsPage = ({
  setShowBuyTicketModal,
  setShowBookEventModal,
  setEventDetails,
  isBuyingCreditsLoading,
  ...props
}) => {
  const dispatch = useDispatch();

  const wallet = useSelector((state) => state.balance);
  const events = useSelector((state) => state.events);
  const balance =
    wallet?.balance?.balance?.find((el) => el.product.name?.toLowerCase() === 'private')?.balance ||
    0;
  const eventList = events?.events?.filter((el) => el.event_type.name?.toLowerCase() === 'private');
  const [currentDate, setCurrentDate] = useState(new DateObject(moment().valueOf()));
  const [dates, setDates] = useState([
    new DateObject(moment().startOf('isoWeek').valueOf()),
    new DateObject(moment().endOf('isoWeek').valueOf()),
  ]);

  useEffect(() => {
    dispatch(apiBalanceRequest());
  }, [!events.isOrderingLoading && events.isOrderingSuccess]);

  useEffect(() => {
    dispatch(apiEventsRequest(dates));
  }, [dates]);

  const onDateChange = (selectedDate) => {
    const date = moment.unix((selectedDate[0] || selectedDate)?.unix).format('YYYY-MM-DD');

    setCurrentDate(selectedDate[0] || selectedDate);
    setDates([
      new DateObject(moment(date).startOf('isoWeek').valueOf()),
      new DateObject(moment(date).endOf('isoWeek').valueOf()),
    ]);
  };

  return (
    <LiveLayout>
      {/* Buy tickets */}
      <div className="flex flex-col md:flex-row py-10">
        <div className="flex-1 py-20">
          <p className="fs-20 md:fs-22 fw-700">Private Sessions</p>
          <p className="fs-14 fg-grey-500">
            Choose the time and instructor you would like to train with.
          </p>
        </div>
        <div className="flex-0 flex align-center md:pr-20">
          <Button
            primary
            size="small"
            disablePadding
            className={cn('flex-1 md:order-1 md:w-180', isBuyingCreditsLoading && 'disabled')}
            onClick={() => setShowBuyTicketModal(true)}
          >
            {isBuyingCreditsLoading && <FaCircleNotch className="w-20 mr-10 spin" />}
            Buy Tickets
          </Button>
          <div className="md:order-0 flex-0 flex px-15 fg-grey-500">
            <IconLiveTicket className="w-20 h-20 m-auto" />
            <div className="pl-8">{balance} tickets left</div>
          </div>
        </div>
      </div>

      <div className="text-right">
        <DatePicker
          className="calendar"
          containerClassName="fit-w md:w-200 md:ml-auto xl:hide"
          shadow={false}
          showOtherDays
          weekStartDayIndex={1}
          value={currentDate}
          onChange={onDateChange}
          format="MMM DD, YYYY"
          render={(value, openCalendar) => {
            return (
              <button
                type="button"
                className="fit-w flex justify-between align-center fs-14 fw-500 lh-1 b-1 b-grey-300 p-10 r-10 my-10"
                onClick={openCalendar}
              >
                <span>{value}</span>
                <IconCalendar className="w-25 h-25 ml-10" />
              </button>
            );
          }}
        />
      </div>

      <div className="flex flex-col lg:flex-row mt-10">
        {/* Calendar */}
        <div className="fit-w xl:show">
          <Calendar
            className="calendar xl:sticky xl:top-120"
            range
            shadow={false}
            showOtherDays
            weekStartDayIndex={1}
            value={dates}
            currentDate={currentDate}
            onChange={onDateChange}
          />
        </div>

        {/* Schedule table */}
        <div className={cn('flex of-x-hidden', events.isLoading && 'disabled')}>
          <div className="md:mt-50">
            {range(7, 20).map((time, key) => (
              <div key={key} className="w-40 md:w-70 h-70 relative fs-12 fg-grey-400">
                <span className="absolute right-10 top-0" style={{ top: '-8px' }}>
                  {key > 0 && time + ':00'}
                </span>
              </div>
            ))}
          </div>
          <div className="md:b-1 b-grey-300 r-15 of-x-overlay">
            <table className="fit-w b-1 b-grey-300 r-15 of-hidden" style={{ tableLayout: 'fixed' }}>
              <thead className="md:show">
                <tr>
                  {weekDays.map((day, key) => {
                    const isToday = day === currentDate?.weekDay?.name;

                    return (
                      <th
                        key={key}
                        className={cn(
                          'fit-w md:w-140 h-50 bg-grey-50 b-1 b-grey-300',
                          isToday ? 'fg-primary fw-600' : 'md:show',
                        )}
                      >
                        {day}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {range(7, 20).map((time, key) => (
                  <tr key={key}>
                    {weekDays.map((day, key) => {
                      const isToday = day === currentDate?.weekDay?.name;
                      const date = moment.unix(dates[0].unix).add(key, 'days').format('YYYY-MM-DD');
                      const events = eventList.filter((el) => {
                        const defaulTimeZone = 'Europe/Paris';
                        const sourceTimeZone = el.event_timezone || defaulTimeZone;
                        const targetTimeZone = moment.tz.guess();
                        const momentSourceTimeStart = moment(
                          `${el.event_date} ${el.event_start}`,
                        ).tz(sourceTimeZone, true);
                        const momentTargetTimeStart = momentSourceTimeStart
                          .clone()
                          .tz(targetTimeZone);

                        return (
                          momentTargetTimeStart.format('YYYY-MM-DD') === date &&
                          parseInt(momentTargetTimeStart.format('HH')) === time
                        );
                      });

                      return (
                        <td key={key} className={cn('h-70 b-1 b-grey-300', !isToday && 'md:show')}>
                          {events.map((event, key) => (
                            <CardCalendar
                              key={key}
                              small={events.length > 1}
                              event={event}
                              setEventDetails={setEventDetails}
                              setShowBookEventModal={setShowBookEventModal}
                            />
                          ))}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="page-wrapper live-schedule-page">
        <div className="page-left-column">
          <div className="calendar"></div>
          <div className="daily-schedule">
            <DailySchedule
              dates={dates.toString().split(',')}
              events={events}
              onOrder={onOrderHandle}
              onCancel={onCancelHandle}
            />
          </div>
        </div>

        <CurrentBalance wallet={wallet} />
      </div> */}
    </LiveLayout>
  );
};

export default LiveSessionsPage;
