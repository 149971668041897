import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiSessionKeyRequest(action) {
  return axios.get(`/api/v1/questionnaire/session/`, null);
}

function apiSignUpProfilingQuestionnaireRequest() {
  return axios.get(`/api/v1/questionnaire/1/`, null);
}

function apiVirtualInstructorQuestionaireRequest() {
  return axios.get(`/api/v1/questionnaire/2/`, null);
}

function apiVirtualInstructorRecalibrateQuestionaireRequest() {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/recalibrate_questionnaire/`, { headers });
}

function apiQuestionRequest(action) {
  return axios.get(`/api/v1/questionnaire/question/${action.question_id}/`, null);
}

function apiSubmitAnswerRequest(action) {
  let headers = null;

  if (action.data?.require_token) {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${getToken()}`,
    };
  }
  return axios.post(`/api/v1/questionnaire/answere/`, action.data, { headers });
}

export const signupServices = {
  apiSessionKeyRequest,
  apiSignUpProfilingQuestionnaireRequest,
  apiVirtualInstructorQuestionaireRequest,
  apiVirtualInstructorRecalibrateQuestionaireRequest,
  apiQuestionRequest,
  apiSubmitAnswerRequest,
};
