/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaCheck } from 'react-icons/fa';
import Modal from './Modal';
import cn from 'classnames';
import { Button, FooterWrapper } from './FormElements';
import { IconCalendar, IconTimes } from './Icons';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { apiUpdateCalendarRequest } from '../Redux/actions/setting';
import { useDispatch, useSelector } from 'react-redux';
import { apiSettingRequest } from '../Redux/actions/setting';
import { apiScheduleVideoRequest } from '../Redux/actions/studioVideo';
import { useNavigate } from 'react-router-dom';

// function zeroPad(num, places) {
//   const zero = places - num.toString().length + 1;
//   return Array(+(zero > 0 && zero)).join('0') + num;
// }

// function timeRange(from, to) {
//   return Array.from({ length: to - from + 1 }, (_, i) => zeroPad(from + i, 2));
// }

function timeSpan(time, minutes) {
  function D(J) {
    return (J < 10 ? '0' : '') + J;
  }
  var piece = time.split(':');
  var mins = piece[0] * 60 + +piece[1] + +minutes;

  return D(((mins % (24 * 60)) / 60) | 0) + ':' + D(mins % 60);
}

// const timePool = timeRange(0, 23);
// const minutesPool = timeRange(0, 59);
// const timeSteps = ['00', '30'];
const defaultTimeStart = '16:00';
const defaultTimeEnd = '16:00';

const days = [
  { shortName: '', longName: '' },
  { shortName: 'Mon', longName: 'Monday' },
  { shortName: 'Tue', longName: 'Tuesday' },
  { shortName: 'Wed', longName: 'Wednesday' },
  { shortName: 'Thu', longName: 'Thursday' },
  { shortName: 'Fri', longName: 'Friday' },
  { shortName: 'Sat', longName: 'Saturday' },
  { shortName: 'Sun', longName: 'Sunday' },
];

const Checkbox = ({ onChange, checked, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    onChange && onChange(e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <>
      <input
        type="checkbox"
        onChange={handleChange}
        checked={isChecked}
        className="hide"
        {...props}
      />
      <div
        className={cn('flex w-40 h-40 r-10 b-1', isChecked ? 'bg-primary b-primary' : 'b-grey-300')}
      >
        {isChecked && <FaCheck className="w-25 h-25 m-auto fg-white" />}
      </div>
    </>
  );
};

// const Select = React.forwardRef(({ hasError, className, children, ...props }, ref) => {
//   return (
//     <select
//       ref={ref}
//       className={cn(
//         className,
//         'fs-16 md:fs-18 fit-w h-40 r-10 md:r-5 px-10 b-1',
//         hasError ? 'b-red-500' : 'b-grey-400 focus:b-primary',
//       )}
//       {...props}
//     >
//       {children}
//     </select>
//   );
// });

const VideoScheduleModal = ({ ...props }) => {
  const { id, scheduled, isModalVisible, handleCancel } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const state = useSelector((state) => state.setting);
  const today = moment().format('YYYY-MM-DD');
  const isGCIEnabled = state.setting?.calendar_integration_enabled;
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get('redirect') === 'true' || false;
  const [showCalendarIntergation, setShowCalendarIntergation] = useState(true);
  const [showMobileTimeSelector, setShowMobileTimeSelector] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(today);

  const handleChangeCheckbox = (e) => {
    const value = parseInt(e.target.value);
    let updateState = [...selectedDays];

    if (e.target.checked) {
      updateState.push({ day: value, start: defaultTimeStart, end: defaultTimeEnd });
      updateState.sort((a, b) => a.day - b.day);
    } else {
      updateState = updateState.filter((day) => day.day !== value);
    }

    setSelectedDays(updateState);
  };

  const handleChangeTimeStart = (index) => (e) => {
    const updateState = [...selectedDays];
    updateState.forEach((el, key) => {
      if (key === index) el.start = e.target.value || '16:00';
    });
    setSelectedDays(updateState);
  };

  const handleChangeTimeEnd = (index) => (e) => {
    const updateState = [...selectedDays];
    updateState.forEach((el, key) => {
      if (key === index) el.end = e.target.value || '16:00';
    });
    setSelectedDays(updateState);
  };

  const handleBlurTimeStart = () => {
    const updateState = [...selectedDays];
    updateState.forEach((el) => {
      if (String(el.start) > String(el.end)) el.end = timeSpan(el.start, 30);
    });
    setSelectedDays(updateState);
  };

  const handleBlurTimeEnd = () => {
    const updateState = [...selectedDays];
    updateState.forEach((el) => {
      if (String(el.start) > String(el.end)) el.start = timeSpan(el.end, -30);
    });
    setSelectedDays(updateState);
  };

  const handleClickAddMore = (day) => {
    const updateState = [...selectedDays];
    updateState.push({ day, start: defaultTimeStart, end: defaultTimeEnd });
    updateState.sort((a, b) => a.day - b.day);
    setSelectedDays(updateState);
  };

  const handleClickRemove = (key) => {
    const updateState = [...selectedDays];
    updateState.splice(key, 1);
    setSelectedDays(updateState);
  };

  useEffect(() => {
    const calendar = state.setting?.workout_calendar;
    const defaultDays = [
      { day: 1, start: defaultTimeStart, end: defaultTimeEnd },
      { day: 3, start: defaultTimeStart, end: defaultTimeEnd },
      { day: 5, start: defaultTimeStart, end: defaultTimeEnd },
    ];

    setShowCalendarIntergation(false);
    setShowDateSelector(false);
    setShowMobileTimeSelector(false);

    setSelectedDate(today);
    setSelectedDays(defaultDays);

    if (isModalVisible) {
      if (!parseInt(id)) {
        dispatch(apiSettingRequest({ user_id: auth.user.id }));
      }

      if (parseInt(id) && scheduled?.date && scheduled?.calendar) {
        setSelectedDate(scheduled?.date || today);
        setSelectedDays(scheduled?.calendar || selectedDays);
      }
      if (!parseInt(id) && calendar) {
        setSelectedDate(calendar?.date || today);
        setSelectedDays(calendar?.calendar || selectedDays);
      }
    }
  }, [isModalVisible]);

  const onDateChange = (dateObj) => {
    const date = moment.unix(dateObj?.unix).format('YYYY-MM-DD');
    setSelectedDate(date);
  };

  const handleSave = (calendar_integration_enabled) => {
    if (id) {
      dispatch(
        apiScheduleVideoRequest(id, selectedDate, selectedDays, !!calendar_integration_enabled),
      );
    } else {
      dispatch(
        apiUpdateCalendarRequest(selectedDate, selectedDays, !!calendar_integration_enabled),
      );
    }

    handleCancel();
    if (redirect) navigate('/dashboard');
  };

  const handleClose = () => {
    handleCancel();
    if (redirect) navigate('/dashboard');
  };

  return (
    <Modal
      size="md"
      defaultClass={false}
      disableOverflow
      className={cn(
        'fs-14 text-center md:text-left flex flex-col of-hidden',
        state.isLoading && 'disabled',
      )}
      visible={isModalVisible}
      onClose={handleClose}
    >
      {showCalendarIntergation ? (
        <div className="flex flex-col of-hidden px-20 md:px-40 pb-20 md:pb-40 text-center">
          <p className="fs-24 md:fs-28 fw-800 md:fw-700 mb-20">
            Turn on Google Calendar integration
          </p>
          <p className="fs-14 md:fs-16 fg-grey-500">
            Turn on Google calendar integration and don’t miss your workouts
          </p>
          <p className="pt-50">
            <Button primary className="fit-w md:w-290 my-10" onClick={() => handleSave(true)}>
              Turn on
            </Button>
          </p>
          <p className="pt-10">
            <button
              className="fs-14 md:fs-16 fw-600 mt-20 underline hover:fg-primary"
              onClick={() => handleSave()}
            >
              Skip now
            </button>
          </p>
        </div>
      ) : showDateSelector ? (
        <div className="flex flex-col of-hidden px-20 md:px-40 pb-20 md:pb-40 text-center">
          <p className="fs-24 md:fs-28 fw-800 md:fw-700 mb-20">When would you like to start?</p>
          <p className="fs-14 md:fs-16 fg-grey-500">The program will start on this day.</p>
          <div className="py-25">
            <DatePicker
              className="calendar"
              containerClassName="fit-w md:w-300"
              shadow={false}
              showOtherDays
              weekStartDayIndex={1}
              value={new DateObject(selectedDate)}
              onChange={onDateChange}
              format="MMM DD, YYYY"
              render={(value, openCalendar) => {
                return (
                  <button
                    type="button"
                    className="fs-16 md:fs-18 fit-w h-40 md:h-50 r-10 fit-w flex justify-between align-center lh-1 b-1 b-grey-300 p-10 md:px-20 r-10 my-10"
                    onClick={openCalendar}
                  >
                    <span>{value}</span>
                    <IconCalendar className="w-25 h-25 ml-10" />
                  </button>
                );
              }}
            />
          </div>
          <p className="pt-15">
            <Button
              primary
              className="fit-w md:w-290 my-10"
              onClick={() => (isGCIEnabled ? handleSave(true) : setShowCalendarIntergation(true))}
            >
              {isGCIEnabled ? 'Save' : 'Continue'}
            </Button>
          </p>
        </div>
      ) : (
        <>
          {/* Modal body */}
          <div className="flex flex-col of-hidden pb-20 md:pb-0">
            {/* Days */}
            <div className={cn('px-20 md:px-40', showMobileTimeSelector ? 'md:show' : null)}>
              <p className="fs-24 fw-800 md:fw-700 mb-5">
                Which days can you dedicate to exercising?
              </p>
              <p className="fs-14 fg-grey-500">
                We recommend daily practice, starting with a quick Morning Routine.
              </p>
              <div className="flex flex-wrap justify-center md:justify-start">
                {days.map(
                  ({ shortName }, key) =>
                    key > 0 && (
                      <label key={key} className="py-10 px-10 md:pl-0 md:pr-20">
                        <Checkbox
                          name="day"
                          value={key}
                          checked={selectedDays.filter((el) => el.day === key).length > 0}
                          onChange={handleChangeCheckbox}
                        />
                        <p className="fs-14 text-center px-5 py-10 fg-grey-500">{shortName}</p>
                      </label>
                    ),
                )}
              </div>
              <Button
                primary
                className="md:hide fit-w my-10"
                onClick={() => setShowMobileTimeSelector(true)}
              >
                Continue
              </Button>
            </div>

            {/* Hours */}
            <div
              className={cn('flex flex-col of-y-auto', showMobileTimeSelector ? null : 'md:show')}
            >
              <div className={cn('px-20 md:px-40')}>
                <p className="fs-24 fw-700 mb-5">What time works for you?</p>
                <p className="fs-14 fg-grey-500">You can change this later.</p>
              </div>
              <div className="of-y-auto modal-scrollstyle px-20 md:px-40 my-10">
                <div className="md:show flex align-center fs-14 fg-grey-500">
                  <div className="w-3/12 pr-20"></div>
                  <div className="w-3/12 pr-20">Start time</div>
                  <div className="w-3/12 pr-20">End time</div>
                  <div className="w-3/12 pr-20"></div>
                </div>
                {selectedDays.map((el, key, array) => {
                  const { day, start, end } = el;
                  const dayName = array[key - 1]?.day !== day ? days[day]?.longName : null;
                  const dayCounter = array.filter((el) => el.day === day).length;

                  return (
                    <div key={key} className="flex flex-wrap align-center my-8 text-left">
                      <div className="fit-w md:w-3/12 pr-20 fs-14 md:fs-16 fw-500">{dayName}</div>
                      <div className="relative w-45/100 md:w-3/12 md:pr-20 flex">
                        <label className="fit-w r-10 md:r-5 px-10 b-1 b-grey-400 focus:b-primary">
                          <input
                            type="time"
                            className="fs-16 md:fs-18 h-40 w-full"
                            value={start}
                            onChange={handleChangeTimeStart(key)}
                            onBlur={handleBlurTimeStart}
                          />
                        </label>
                        {/* <Select
                          value={start}
                          onChange={handleChangeTimeStart(key)}
                          style={{ appearance: 'none' }}
                          className="pr-30"
                        >
                          {timePool.map((hour, key) =>
                            timeSteps.map((minute, key2) => (
                              <option key={key + '-' + key2} value={hour + ':' + minute}>
                                {hour}:{minute}
                              </option>
                            )),
                          )}
                        </Select> */}
                      </div>
                      <div className="md:hide flex-1 fs-14 fg-grey-500 text-center">to</div>
                      <div className="relative w-45/100 md:w-3/12">
                        <label className="fit-w r-10 md:r-5 px-10 b-1 b-grey-400 focus:b-primary">
                          <input
                            type="time"
                            className="fs-16 md:fs-18 h-40 w-full"
                            value={end}
                            onChange={handleChangeTimeEnd(key)}
                            onBlur={handleBlurTimeEnd}
                          />
                        </label>
                        {/* <Select
                          value={end}
                          onChange={handleChangeTimeEnd(key)}
                          style={{ appearance: 'none' }}
                          className="pr-30"
                        >
                          {timePool.map((hour, key) =>
                            timeSteps.map((minute, key2) => (
                              <option key={key + '-' + key2} value={hour + ':' + minute}>
                                {hour}:{minute}
                              </option>
                            )),
                          )}
                        </Select> */}
                        {/* <div className="absolute top-0 bottom-0 flex right-5 md:right-5 w-20 pointer-off">
                          <FaChevronDown className="w-15 h-15 my-auto fg-grey-500" />
                        </div> */}
                      </div>
                      <div className="fit-w md:w-3/12">
                        {dayName !== null ? (
                          <button
                            type="button"
                            className={cn(
                              'fs-14 fw-600 fg-primary hover:fg-black cursor-pointer py-10 md:px-20',
                              dayCounter >= 5 && 'disabled',
                            )}
                            onClick={() => handleClickAddMore(day)}
                          >
                            + Add more
                          </button>
                        ) : (
                          <button
                            type="button"
                            className={cn(
                              'fs-14 fw-600 fg-grey-500 hover:fg-black cursor-pointer py-10 md:px-20',
                            )}
                            onClick={() => handleClickRemove(key)}
                          >
                            <IconTimes className="w-20 h-20" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="md:hide px-20 md:px-40">
                <Button
                  primary
                  className="md:hide fit-w my-10"
                  onClick={() => setShowDateSelector(true)}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>

          {/* Modal footer */}
          <FooterWrapper className="md:show">
            <Button primary className="md:w-290 my-10" onClick={() => setShowDateSelector(true)}>
              Continue
            </Button>
          </FooterWrapper>
        </>
      )}
    </Modal>
  );
};

export default VideoScheduleModal;
