/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import AccountDetailsPage from '../Pages/AccountDetailsPage';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { apiSubmitCreateAccountRequest } from '../Redux/actions/login';
import { apiTermsNConditionsRequest } from '../Redux/actions/subscription';
import { useEffect } from 'react';
import { showErrorToastNoti } from '../Utils/errorToast';
import ReactPixel from 'react-facebook-pixel';
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {Capacitor} from "@capacitor/core";

const accountDetailsSchema = yup.object().shape({
  name: yup.string().required('This field is required.'),
  email: yup.string().email('A valid email is required.').required('This field is required.'),
  password: yup
    .string()
    .required('This field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters, of which at least one numeric and one upper case',
    ),
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  terms: yup.boolean().oneOf([true], 'Accepting the Terms and Conditions is mandatory.'),
  note: yup.boolean().oneOf([true], 'Accepting the Important Notice is mandatory.'),
});

const AccountDetailsContainer = () => {
  const dispatch = useDispatch();

  const login = useSelector((state) => state.auth);
  const state = useSelector((state) => state.signup);
  const subscription = useSelector((state) => state.subscription);

  let nagivate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accountDetailsSchema),
  });

  const onSubmit = (data) => {
    // save answer
    data['session_key'] = state.session_key;
    delete data['confirm_password'];

    dispatch(apiSubmitCreateAccountRequest(data))
  };

  useEffect(() => {
    dispatch(apiTermsNConditionsRequest());
  }, []);

  useEffect(() => {
    if (!state.session_key) {
      nagivate('/signup/');
    }
  }, [state.session_key]);

  useEffect(() => {
    if (login.user !== null) {
      ReactPixel.track('CompleteRegistration');
      if (Capacitor.getPlatform() === 'web') {
        window.gtag('event', 'successful_registration', {
          'event_label': 'Successful Registration',
          'value': true
        });
      } else {
        FirebaseAnalytics.logEvent({
          name: "successful_registration",
          params: {
            success: true,
          },
        }).then();
      }
      nagivate('/select-subscription-plan');
    }
  }, [login.user]);

  useEffect(() => {
    showErrorToastNoti(login);
  }, [login.isLoading]);

  return (
    <>
      <AccountDetailsPage
        isLoading={login.isLoading}
        setValue={setValue}
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        terms={subscription?.terms_conditions?.terms}
      />
    </>
  );
};

export default AccountDetailsContainer;
