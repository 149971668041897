import React from 'react';
import SubChoice from '../SubChoice';
import OutsiteDetector from '../OutsiteDetector';
import { ReactComponent as SvgLevel1 } from '../../Assets/level-1.svg';
import { ReactComponent as SvgLevel2 } from '../../Assets/level-2.svg';
import { ReactComponent as SvgLevel3 } from '../../Assets/level-3.svg';
import { ReactComponent as SvgDuration20 } from '../../Assets/duration-20.svg';
import { ReactComponent as SvgDuration40 } from '../../Assets/duration-40.svg';
import { FaCheck } from 'react-icons/fa';
import cn from 'classnames';

const getLayoutWidth = (columns) => {
  switch (columns) {
    case 2:
      return 'md:w-50/100';
    case 3:
      return 'md:w-4/12';
    case 4:
      return 'md:w-3/12';
    default:
      return 'md:w-550';
  }
};

const Choice = ({
  id,
  text,
  isSelected,
  selectedSubChoices,
  layoutColumns,
  isMultipleChoiceQuestion = false,
  sub_choices,
  sub_choices_title,
  questionId,
  choiceId,
  onChoiceClick,
  onSubChoicesClicked,
  expandId,
  onClickExpand,
  showCheckbox = false,
}) => {
  // Special questions
  const isSpecialQuestion = [6, 7].includes(questionId);

  // Fitness level | id: 30-31-32
  if (questionId === 6) {
    const matches = text.match(/(.*?)\((.*?)\)/);
    const SvgComponent = id === 30 ? SvgLevel1 : id === 31 ? SvgLevel2 : SvgLevel3;

    text = (
      <div className="text-center lh-1.6 md:w-220 py-10 md:py-20 of-hidden">
        <div className="md:show flex w-160 h-160 mb-10 m-auto justify-center align-end">
          <SvgComponent className="mx-auto" />
        </div>
        <p className="fs-14 md:fs-18 fw-600 text-trim">{matches[1]}</p>
        <p className="fs-12 md:fs-16 fg-grey-400 text-trim">{matches[2]}</p>
      </div>
    );
  }

  // Workouts duration | id: 33-34
  if (questionId === 7) {
    const SvgComponent = id === 33 ? SvgDuration20 : SvgDuration40;

    text = (
      <div className="text-center lh-1.6 md:w-240 py-20 of-hidden">
        <div className="md:show w-160 h-160 m-auto justify-center align-end">
          <SvgComponent className="mx-auto" />
        </div>
        <p className="fs-14 md:fs-18 fw-600 text-trim">{text}</p>
      </div>
    );
  }

  if (isMultipleChoiceQuestion) {
    const haveSubChoices = sub_choices.length;

    return (
      <div className={cn('fit-w py-6 md:p-4', getLayoutWidth(layoutColumns))}>
        <div
          className={cn(
            'flex justify-center align-center fs-14 md:fs-16 fw-600 fit-w r-15 b-1 hover:b-primary of-hidden md:of-visible',
            isSelected ? 'bg-primary-light b-primary' : 'b-grey-300',
          )}
          onClick={() => {
            onChoiceClick(id);
          }}
        >
          {/* <CheckCircleIcon color="inherit" /> */}
          {!haveSubChoices ? (
            // <span className="flex flex-1 align-center h-50 md:h-75 px-15 md:px-25 choice-text">
            //   {text}
            // </span>
            <div
              className={cn(
                'flex flex-1 align-center h-50 md:h-55 px-15 md:px-20 text-center hover:b-primary choice-text',
                showCheckbox ? 'md:text-left' : null,
              )}
            >
              {showCheckbox && (
                <div
                  className={cn(
                    'md:show flex w-25 h-25 mr-15 r-100 b-2 b-primary',
                    isSelected ? 'bg-primary' : null,
                  )}
                >
                  <FaCheck className="w-15 h-15 m-auto fg-white" />
                </div>
              )}
              <span className="flex-1 text-trim">{text}</span>
            </div>
          ) : (
            <div className="fit-w have-sub-choices">
              <OutsiteDetector
                isMe={choiceId === expandId}
                choiceId={choiceId}
                onClickExpand={onClickExpand}
                className="flex flex-wrap relative"
              >
                <>
                  <div className="flex w-60/100 flex-1 align-center h-50 md:h-55 px-15 md:px-20 hover:b-primary choice-text">
                    <div
                      className={cn(
                        'md:show flex w-25 h-25 mr-15 r-100 b-2 b-primary',
                        isSelected ? 'bg-primary' : null,
                      )}
                    >
                      <FaCheck className="w-15 h-15 m-auto fg-white" />
                    </div>
                    <span className="flex-1 pl-45 md:pl-0 text-trim text-center md:text-left">
                      {text}
                    </span>
                  </div>
                  <SubChoice
                    label="Joints"
                    isSelectedChoice={isSelected}
                    selectedSubChoices={selectedSubChoices}
                    onSelected={onSubChoicesClicked}
                    choiceId={choiceId}
                    menuTitle={sub_choices_title}
                    choices={sub_choices}
                    expandId={expandId}
                    onClickExpand={onClickExpand}
                  />
                </>
              </OutsiteDetector>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={cn(
          'fit-w py-6 md:px-10',
          isSpecialQuestion ? 'md:w-auto' : getLayoutWidth(layoutColumns),
        )}
      >
        <div
          className={cn(
            'flex justify-center align-center fs-14 md:fs-16 fw-600 fit-w r-15 b-1 hover:b-primary of-hidden',
            isSelected ? 'bg-primary-light b-primary' : 'b-grey-300',
            isSpecialQuestion ? null : 'h-50 md:h-55',
          )}
          onClick={() => {
            onChoiceClick(id);
          }}
        >
          {text}
        </div>
      </div>
    );
  }
};

export default Choice;
