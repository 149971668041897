import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { apiPasswordConfirmRequest } from '../Redux/actions/login';
import ConfirmResetPasswordPage from '../Pages/ConfirmResetPasswordPage';
import { useEffect } from 'react';
import { showErrorToastNoti } from '../Utils/errorToast';

const passwordResetSchema = yup.object().shape({
  new_password1: yup
    .string()
    .required('This field is required.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  new_password2: yup.string().oneOf([yup.ref('new_password1'), null], 'Passwords must match'),
});

const ConfirmResetPasswordContainer = () => {
  const dispatch = useDispatch();
  let { uid, token } = useParams();
  const state = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordResetSchema),
  });
  useEffect(() => {
    showErrorToastNoti(state);
  }, [state]);

  const onSubmit = (data) => {
    dispatch(apiPasswordConfirmRequest(data));
  };

  return (
    <ConfirmResetPasswordPage
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      uid={uid}
      token={token}
      state={state}
      errors={errors}
    />
  );
};

export default ConfirmResetPasswordContainer;
