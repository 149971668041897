import * as types from "../constants/myStats";

export const apiMyStatsRequest = (data) => ({
    type: types.API_REQUEST_MY_STATS,
    data,
});

export const apiMyStatsRequestSuccess = (response) => ({
    type: types.API_REQUEST_MY_STATS_SUCCESS,
    response,
});

export const apiMyStatsRequestFailed = (response) => ({
    type: types.API_REQUEST_MY_STATS_FAILED,
    response,
});
