/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiMoodsRequest } from '../Redux/actions/studioVideo';
import VirtualInstructorRightBar from './VirtualInstructorRightBar';
import { toast } from 'react-toastify';
import Modal from './Modal';
import { ReactComponent as Decoration } from '../Assets/my-studio-decoration.svg';
import { ReactComponent as Instructor } from '../Assets/virtual-instructor.svg';
import ExerciseCard from './ExerciseCard';
import cn from 'classnames';
import { Button, FooterWrapper } from './FormElements';

const VirtualIntructorModal = ({ ...props }) => {
  const { onVideoClicked, isModalVisible, isLoading, handleCancel } = props;
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const dispatch = useDispatch();
  const viState = useSelector((state) => state.virtualInstructor);
  const video = viState?.videos;

  const handleStart = () => {
    setStarted(true);
    setFinished(false);
  };

  const handleWatch = () => {
    const id = video.id;
    const urls = video.list_of_videos
      .filter((el) => el.video)
      .map((el) => ({ id: el.id, video: el.video }));
    handleCancel();
    onVideoClicked(urls, id, true);
  };

  const handleClose = () => {
    handleCancel();

    if (finished) {
      toast.success('Your workout was saved!');
    }
  };

  const handleRestart = () => {
    setStarted(false);
    setFinished(false);
  };

  useEffect(() => {
    setStarted(false);
    setFinished(false);
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible && !viState.loading && viState.success) {
      setStarted(true);
      setFinished(true);
      dispatch(apiMoodsRequest());
    }
  }, [viState]);

  const LayoutStart = () => (
    <div className="flex flex-col flex-nowrap fit-h justify-center">
      {/* Content */}
      <div className="md:flex-1 flex flex-col justify-center px-20 md:w-500 mx-auto my-20">
        <Decoration className="lg:w-400 mx-auto mb-20" />
        <p className="fs-20 md:fs-28 fw-700 mb-15">In the mood for something different?</p>
        <p className="fs-14 md:fs-18 fw-600 fg-primary mb-15">
          {/* <span className="md:show">Do you want to get a workout for your mood of the moment?</span>
          <span className="md:hide">Do you want to get workout for mood?</span> */}
          Answer a few questions and I will recommend a workout just for you!
        </p>
        {/* <p className="fs-14 md:fs-18 fg-grey-600">
          Answer a few questions and I will recommend a workout just for you!
        </p> */}
      </div>

      <FooterWrapper>
        <Button primary className="fit-w md:w-230" onClick={handleStart}>
          Let's start
        </Button>
      </FooterWrapper>
    </div>
  );

  const LayoutFinish = () => (
    <div className="flex flex-col flex-nowrap fit-h justify-center">
      {/* Content */}
      <div className="md:flex-1 flex flex-col justify-center px-20 md:w-500 mx-auto">
        <Instructor className="md:show mx-auto md:w-80" />
        <div className="flex flex-col justify-center md:w-500 mx-auto">
          <p className="fs-24 fw-800 md:fw-700 mb-5">Here's your workout!</p>
          <p className="fs-14 md:fs-16 fg-grey-500 mb-5">
            Your workout will be saved to "My moods"
          </p>
          <div className="fit-w md:w-350 mx-auto">
            <ExerciseCard
              index={0}
              standAlone={true}
              disableControls={true}
              {...video}
              onVideoClicked={false}
              onScheduleClicked={false}
              actions={[]}
              onDelete={false}
              onLevelChange={false}
              onVideoUnavailable={false}
            />
          </div>
        </div>
      </div>

      <FooterWrapper className="flex">
        <Button
          primary
          className={cn('fit-w md:w-230 my-10 md:mx-10 md:order-1', isLoading && 'disabled')}
          onClick={handleWatch}
        >
          Watch now
        </Button>
        <Button grey className="fit-w md:w-160 my-10 md:mx-10" onClick={handleClose}>
          Later
        </Button>
      </FooterWrapper>
    </div>
  );

  return (
    <Modal
      defaultClass={false}
      disableBackdropPadding={true}
      disableBorderRadius={true}
      size="none"
      className="flex flex-col fit-h text-center"
      classCard="ml-auto mr-0 fit-w md:w-600 flex-1 flex flex-col flex-nowrap"
      visible={isModalVisible}
      onClose={handleClose}
    >
      {!started && <LayoutStart />}
      {finished && <LayoutFinish />}

      <VirtualInstructorRightBar
        visible={started && !finished}
        onClose={handleCancel}
        handleRestart={handleRestart}
      />
    </Modal>
  );
};

export default VirtualIntructorModal;
