import * as types from '../constants/order';
import { mapErrorMessage } from '../../Utils/mapErrorMessage';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
  isLoading: false,
  isBuyingCreditsLoading: false,
  success: false,
  error: null,
  products: [],
};

export default function orderApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.API_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_PRODUCTS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        products: action.response.data,
      };

    case types.API_PRODUCTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_BUY_CREDITS_REQUEST:
      return {
        ...state,
        isBuyingCreditsLoading: true,
        success: false,
        error: null,
      };

    case types.API_BUY_CREDITS_REQUEST_SUCCESS:
      toast.success('Credits bought successfully');

      return {
        ...state,
        isBuyingCreditsLoading: false,
        success: true,
        error: null,
      };

    case types.API_BUY_CREDITS_REQUEST_FAILED:
      toast.error(mapErrorMessage(action).message.Error || 'Unable to buy credits');

      return {
        ...state,
        isBuyingCreditsLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    default:
      return state;
  }
}
