import * as types from '../constants/setting';

export const apiSettingRequest = (data) => ({
  type: types.API_REQUEST_SETTING,
  data,
});

export const apiSettingRequestSuccess = (response) => ({
  type: types.API_REQUEST_SETTING_SUCCESS,
  response,
});

export const apiSettingRequestFailed = (response) => ({
  type: types.API_REQUEST_SETTING_FAILED,
  response,
});

export const apiAvatarChange = (data) => ({
  type: types.API_AVATAR_CHANGE,
  data,
});

export const apiAvatarChangeSuccess = (response) => ({
  type: types.API_AVATAR_CHANGE_SUCCESS,
  response,
});

export const apiAvatarChangeFailed = (response) => ({
  type: types.API_AVATAR_CHANGE_FAILED,
  response,
});

export const apiSettingPatch = (data) => ({
  type: types.API_PATCH_SETTING,
  data,
});

export const apiSettingPatchSuccess = (response) => ({
  type: types.API_PATCH_SETTING_SUCCESS,
  response,
});

export const apiSettingPatchFailed = (response) => ({
  type: types.API_PATCH_SETTING_FAILED,
  response,
});

// Password change request
export const apiPasswordChangeRequest = (data) => ({
  type: types.API_PASSWORD_CHANGE_REQUEST,
  data,
});

export const apiPasswordChangeRequestSuccess = (response) => ({
  type: types.API_PASSWORD_CHANGE_SUCCESS,
  response,
});

export const apiPasswordChangeRequestFailed = (response) => ({
  type: types.API_PASSWORD_CHANGE_FAILED,
  response,
});

export const unlockAccessAlertShow = () => ({
  type: types.UNLOCK_ACCESS_ALERT_SHOW,
});

export const unlockAccessAlertHide = () => ({
  type: types.UNLOCK_ACCESS_ALERT_HIDE,
});

// Update calendar
export const apiUpdateCalendarRequest = (date, calendar, calendar_integration_enabled) => ({
  type: types.API_UPDATE_CALENDAR_REQUEST,
  date,
  calendar,
  calendar_integration_enabled,
});

export const apiUpdateCalendarRequestSuccess = (response) => ({
  type: types.API_UPDATE_CALENDAR_SUCCESS,
  response,
});

export const apiUpdateCalendarRequestFailed = (response) => ({
  type: types.API_UPDATE_CALENDAR_FAILED,
  response,
});

// Delete account
export const apiDeleteAccountRequest = (user_id) => ({
  type: types.API_DELETE_ACCOUNT_REQUEST,
  user_id,
});

export const apiDeleteAccountRequestSuccess = (response) => ({
  type: types.API_DELETE_ACCOUNT_SUCCESS,
  response,
});

export const apiDeleteAccountRequestFailed = (response) => ({
  type: types.API_DELETE_ACCOUNT_FAILED,
  response,
});
