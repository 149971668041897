import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { FaCircleNotch } from 'react-icons/fa';
import cn from 'classnames';
import { Button, Error, Input } from '../Components/FormElements';

const ConfirmResetPasswordPage = (props) => {
  const { onSubmit, register, errors, uid, token, state } = props;

  return (
    <form
      onSubmit={onSubmit}
      className="container-auto-sm flex-1 flex flex-col justify-center text-center px-20 py-20 my-auto"
    >
      <input type="hidden" {...register('uid')} value={uid} />
      <input type="hidden" {...register('token')} value={token} />

      <Link to="/">
        <Logo className="h-50 mx-auto" />
      </Link>
      <p className="fs-24 md:fs-28 fw-700 py-30">Set your new password</p>

      <div>
        <Input
          className="form-control custom-input"
          type="password"
          placeholder="New Password"
          hasError={errors.new_password1}
          {...register('new_password1')}
        />
        {errors.new_password1 && <Error text={errors.new_password1.message} />}

        <Input
          className="form-control custom-input"
          type="password"
          placeholder="Confirm New Password"
          hasError={errors.new_password2}
          {...register('new_password2')}
        />
        {errors.new_password2 && <Error text={errors.new_password2.message} />}

        {!state.success && (
          <Button
            primary
            type="submit"
            className={cn('fit-w md:w-550 my-20', state.isLoading && 'disabled')}
          >
            {state.isLoading ? <FaCircleNotch className="w-20 h-20 spin" /> : 'Set Password'}
          </Button>
        )}

        {!state.isLoading && state.success && (
          <>
            <div className="fg-primary fw-700 uccess-message">
              Password has been changed successfully.
            </div>
            <p className="login-bottom-text">
              <Link
                to="/login"
                className="fs-14 md:fs-18 fw-600 fit-w md:w-550 h-50 md:h-40 md:h-65 inline-flex justify-center align-center r-40 fg-white bg-primary hover:bg-black active:bg-black my-20 signin-link"
              >
                Sign In
              </Link>
            </p>
          </>
        )}
      </div>
    </form>
  );
};

export default ConfirmResetPasswordPage;
