import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from './FormElements';
import {checkIsFreeUser} from "../Utils/subscriptionUtils";

export default function StatisticsOverlay({ ...props }) {
  const profile = useSelector((state) => state.setting);
  const [isFreeUser, setIsFreeUser] = useState(true);
  const navigate = useNavigate();

  useEffect( () => {
    checkIsFreeUser(profile).then((res) => {
      setIsFreeUser(res);
    })
  }, [profile])

  const onClickHandle = () => {
    navigate('/my-profile?page=subscription');
  };

  return (
    isFreeUser && (
      <div
        className="absolute z-10 top-0 right-0 bottom-0 left-0 flex opaque-backdrop-statistics"
        style={{ marginLeft: '-8px', marginRight: '-8px' }}
        {...props}
      >
        <div className="container-auto-xs mt-0 text-center sticky" style={{ top: '40%' }}>
          <p className="fs-24 md:fs-26 fw-700 my-20">Get full access</p>
          <p className="fs-16 fg-grey-600 pb-20">
            Unlock full access by upgrading your subscription plan.
          </p>
          <p>
            <Button primary className="fit-w md:w-290 my-15" onClick={onClickHandle}>
              Upgrade Plan
            </Button>
          </p>
        </div>
      </div>
    )
  );
}
