import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Instructor } from '../Assets/virtual-instructor.svg';
import { Button } from './FormElements';
import Modal from './Modal';

export default function StartProgramOverlay({
  isVisible = false,
  setIsVisible,
  setIsScheduleModalVisible,
  ...props
}) {
  const handleClickEssentials = () => {
    localStorage.setItem('tutorial2', 1);
    setIsVisible(false);
  };

  const handleClickStart = () => {
    handleClickEssentials();
    setTimeout(() => {
      setIsScheduleModalVisible(true);
    }, 0);
  };

  return (
    <Modal
      size="sm"
      disableHeader
      className="text-center fs-14 md:fs-18 pt-20 md:pt-40"
      visible={isVisible}
    >
      <Instructor className="w-130 mx-auto" />
      <p className="fs-24 md:fs-26 fw-700 my-15">Your program is ready!</p>
      <p className="fs-16 fg-grey-600 pb-15">
        Let's schedule your healthy habits, and then familiarize yourself with the essentials so
        that you get the most out of your program.
      </p>
      <p>
        <Button primary className="fit-w md:w-290" onClick={handleClickStart}>
          Start now
        </Button>
      </p>
      <p>
        <Link to="/dashboard">
          <button
            className="fs-16 fw-600 mt-15 underline hover:fg-primary"
            onClick={handleClickEssentials}
          >
            Check my essentials
          </button>
        </Link>
      </p>
    </Modal>
  );
}
