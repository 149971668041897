import * as types from "../constants/events";

// Fetch events
export const apiEventsRequest = (data) => ({
  type: types.API_EVENTS_REQUEST,
  data,
});

export const apiEventsRequestSuccess = (response) => ({
  type: types.API_EVENTS_REQUEST_SUCCESS,
  response,
});

export const apiEventsRequestFailed = (response) => ({
  type: types.API_EVENTS_REQUEST_FAILED,
  response,
});


// Order event
export const apiOrderEventRequest = (data) => ({
  type: types.API_ORDER_EVENT_REQUEST,
  data,
});

export const apiOrderEventRequestSuccess = (response) => ({
  type: types.API_ORDER_EVENT_REQUEST_SUCCESS,
  response,
});

export const apiOrderEventRequestFailed = (response) => ({
  type: types.API_ORDER_EVENT_REQUEST_FAILED,
  response,
});


// Cancel order event
export const apiOrderCancelEventRequest = (data) => ({
  type: types.API_ORDER_CANCEL_EVENT_REQUEST,
  data,
});

export const apiOrderCancelEventRequestSuccess = (response) => ({
  type: types.API_ORDER_CANCEL_EVENT_REQUEST_SUCCESS,
  response,
});

export const apiOrderCancelEventRequestFailed = (response) => ({
  type: types.API_ORDER_CANCEL_EVENT_REQUEST_FAILED,
  response,
});
