import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiVirtualInstructorRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/videos/virtual_instructor/`, action.data, { headers });
}

function apiVirtualInstructorRecalibrateRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/videos/recalibrate/`, action.data, { headers });
}

export const virtualInstructorService = {
  apiVirtualInstructorRequest,
  apiVirtualInstructorRecalibrateRequest,
};
