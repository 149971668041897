/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import MyStudioLayout from '../Layouts/MyStudioLayout';
import VideoWorkoutModal from '../Components/VideoWorkoutModal';
import VideoAdjustModal from '../Components/VideoAdjustModal';
import VideoScheduleModal from '../Components/VideoScheduleModal';
import VideoDeleteModal from '../Components/VideoDeleteModal';
import { toast } from 'react-toastify';
import { IconTimes } from '../Components/Icons';
import { FaCircleNotch } from 'react-icons/fa';
import VirtualIntructorModal from '../Components/VirtualIntructorModal';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoPreviewModal from '../Components/VideoPreviewModal';
import viBannerText from '../Utils/viBannerText';
import VideoList from '../Components/VideoList';
import { scrollLock, scrollUnlock } from '../Utils/scrollLock';
import ReactPlayer from "react-player";
import Hls from 'hls.js';
import {Capacitor} from "@capacitor/core";

const MyStudioPage = ({
                        state,
                        actions,
                        categories,
                        onVideoScheduled,
                        onVideoWatched,
                        onDelete,
                        onLevelChange,
                        onVideoUnavailable,
                        isFreeUser,
                        // onVideoPlayed,
                      }) => {
  const params = new URLSearchParams(window.location.search);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const videoState = useRef({ time: 0, status: '' });
  const [urls, setURLs] = useState(undefined | []);
  const [readyCount, setReadyCount] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [callback, setCallback] = useState(null);
  const [selectedScheduled, setSelectedScheduled] = useState(null);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [isWorkoutModalVisible, setIsWorkoutModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [isAdjustModalVisible, setIsAdjustModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isInstructorModalVisible, setIsInstructorModalVisible] = useState(false);
  const [isLevelChange, setIsLevelChange] = useState(false);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = Capacitor.getPlatform() === 'ios';

  const needsHlsLibrary = !isSafari && !isIOS;

  const filterVideos = useMemo(() => {
    return state.videos
      ?.filter(({ category_type }) =>
        pathname === '/my-workouts' ? category_type === 'Manual' : true,
      )
      ?.filter(({ category_type }) =>
        pathname === '/my-moods' ? category_type !== 'Manual' : true,
      );
  }, [state.videos, pathname]);

  useEffect(() => {
    if (!state.isScheduleLoading && state.success && isScheduleModalVisible) {
      setIsScheduleModalVisible(false);
      // toast.success('Video is scheduled successfully.');
    }
  }, [state?.isScheduleLoading]);

  useEffect(() => {
    if (!state.isLoading && state.success && isDeleteModalVisible) {
      setIsDeleteModalVisible(false);
      toast.success('Video is deleted successfully.');
    }
  }, [state?.isLoading]);

  useEffect(() => {
    if (!state.isLevelLoading && state.success && isLevelChange) {
      toast.success('Your workout was adjusted!');
      setIsLevelChange(false);
    }
  }, [state?.isLevelLoading]);

  // Video Functions
  const onVideoClicked = (urls = [], id, skipModal = false, cb = null) => {
    setSelectedId(id);
    setURLs(urls);
    setCallback(() => cb);

    if (!urls.length) {
      toast.error('This video is not available.');
      return;
    }

    if (parseInt(params.get('play_video')) && urls?.length === 1) {
      setIsPreviewModalVisible(true);
      navigate(pathname, { replace: true });
      return;
    }

    if (!skipModal && urls?.length > 1 && !isWorkoutModalVisible) {
      setIsWorkoutModalVisible(true);
      return;
    }

    videoState.current.status = '';
    videoState.current.time = 0;

    setIsPlaying(true);
    // if (isFreeUser) onVideoPlayed(id);
  };

  const onProgress = () => {
    videoState.current.status !== 'pause' && videoState.current.time++;
  };

  const onEnded = () => {
    onVideoWatched(urls[readyCount].id, videoState.current.time);

    if (typeof callback == 'function' && urls.length === readyCount + 1) {
      callback(videoRef);
    }

    if (urls.length > readyCount + 1) {
      setReadyCount(readyCount + 1);
    } else {
      setIsPlaying(false);
      setReadyCount(0);

      if (typeof onLevelChange === 'function') {
        setIsAdjustModalVisible(true);
      }
    }
  };


  const onVideoClose = () => {
    onVideoWatched(urls[readyCount].id, videoState.current.time);

    if (typeof callback == 'function' && urls.length === readyCount + 1) {
      callback(videoRef);
    }

    setReadyCount(0);
    setIsPlaying(false);
  };

  // Modal actions
  const onModalClickCancel = () => {
    setIsScheduleModalVisible(false);
    setSelectedId(null);
    setSelectedScheduled(null);
  };

  // const onModalClickOk = (id, selectedDate) => {
  //   onVideoScheduled(id, selectedDate);
  // };

  // Schedule Actions
  const onScheduleClicked = (id) => {
    const video = filterVideos.find((video) => video.id === id);

    setSelectedId(id);
    setSelectedScheduled(video?.scheduled);
    setIsScheduleModalVisible(true);
  };

  // Delete Actions
  const onDeleteModalClickOk = (id, reason) => {
    onDelete(id, reason);
  };

  const onDeleteModalClickCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const onDeleteClicked = (id) => {
    setSelectedId(id);
    setIsDeleteModalVisible(true);
  };

  // Workout Actions
  const onWorkoutModalClickOk = () => {
    setIsWorkoutModalVisible(false);
    setTimeout(() => onVideoClicked(urls, selectedId, false, callback));
  };

  const onWorkoutModalClickCancel = () => {
    setIsWorkoutModalVisible(false);
  };

  // Preview Actions
  const onPreviewModalClickOk = () => {
    setIsPreviewModalVisible(false);
    setTimeout(() => onVideoClicked(urls, selectedId, false, callback));
  };

  const onPreviewModalClickCancel = () => {
    setIsPreviewModalVisible(false);
  };

  // Adjust Actions
  const onVidLevelChange = (id, change) => {
    setIsLevelChange(true);
    onLevelChange(id, change);
    setIsAdjustModalVisible(false);
  };

  const onAdjustModalClickCancel = (id) => {
    setIsAdjustModalVisible(false);
  };

  // Virtual Instructor
  const onInstructorModalClickCancel = (id) => {
    setIsInstructorModalVisible(false);
  };

  useEffect(() => {
    if (isPlaying) {
      scrollLock();
    } else {
      scrollUnlock();
    }
  }, [isPlaying]);

  return (
    <MyStudioLayout
      state={state}
      isLoading={state?.isLoading}
      setIsInstructorModalVisible={setIsInstructorModalVisible}
      setIsScheduleModalVisible={setIsScheduleModalVisible}
    >
      {!!state?.isLoading && (
        <div className="flex flex-col m-auto fs-20 fw-600 text-center pt-20">
          <FaCircleNotch className="w-25 h-25 mx-auto my-20 fg-primary spin" />
        </div>
      )}

      {!state?.isLoading && (
        <VideoList
          {...{
            actions,
            filterVideos,
            categories,
            pathname,
            isFreeUser,
            isPlaying,
            onVideoClicked,
            onScheduleClicked,
            onDeleteClicked,
            onVidLevelChange,
            onVideoUnavailable,
          }}
        />
      )}

      {/* Empty states */}
      {!state?.isLoading && !filterVideos?.length && (
        <div className="flex flex-col m-auto text-center fg-grey-600">
          <p className="fs-14 md:fs-16 fw-700">{viBannerText.empty.title}</p>
          <p className="fs-12 md:fs-14 py-5">{viBannerText.empty.caption}</p>
        </div>
      )}

      {/* {state?.isLoading ? <Spin className="loader" /> : <></>} */}
      {!!isPlaying && (
        <div
          className="align-center bottom-0 fixed flex justify-center left-0 right-0 top-0 lg:p-20 video-dialog z-100"
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
          onClick={onVideoClose}
        >
          {/* <Draggable> */}
          <div
            className="container-auto-lg m-0 p-0 absolute ratio-16/9 bg-black lg:r-15 lg:of-hidden video-container"
            style={{ maxHeight: '100%' }}
            onClick={(e) => e.stopPropagation()}
          >
            <ReactPlayer
              ref={videoRef}
              url={urls.length ? urls[readyCount].video : ''}
              controls
              config={{
                file: {
                  forceHLS: needsHlsLibrary,
                  ...(needsHlsLibrary && { hlsVersion: Hls.version }),
                  attributes: {
                    controlsList: 'nodownload noremoteplayback noplaybackrate',
                    disablePictureInPicture: true,
                  },
                },
              }}
              onEnded={onEnded}
              onProgress={onProgress}
              onPlay={() => (videoState.current.status = '')}
              onPause={() => (videoState.current.status = 'pause')}
              onBufferEnd={() => (videoState.current.status = '')}
              onBuffer={() => (videoState.current.status = 'pause')}
              playing
              playsinline
              width="100%"
              height="100%"
            />
            {/* <CloseIcon className="close-icon" onClick={onVideoClose} /> */}
            <div
              className="absolute top-10 md:top-20 right-10 md:right-20 flex w-35 h-35 r-100 fg-white hover:bg-white hover:fg-black active:bg-white active:fg-black cursor-pointer"
              onClick={onVideoClose}
            >
              <IconTimes className="fg-grey-400 w-30 h-30 m-auto" />
            </div>
          </div>
          {/* </Draggable> */}
        </div>
      )}

      <VideoScheduleModal
        isModalVisible={isScheduleModalVisible}
        // isLoading={state?.isScheduleLoading}
        id={selectedId}
        scheduled={selectedScheduled}
        handleCancel={onModalClickCancel}
        // handleOk={onModalClickOk}
      />

      <VideoWorkoutModal
        isModalVisible={isWorkoutModalVisible}
        isLoading={state?.isScheduleLoading}
        id={selectedId}
        handleCancel={onWorkoutModalClickCancel}
        handleOk={onWorkoutModalClickOk}
      />

      <VideoPreviewModal
        isModalVisible={isPreviewModalVisible}
        isLoading={state?.isScheduleLoading}
        id={selectedId}
        handleCancel={onPreviewModalClickCancel}
        handleOk={onPreviewModalClickOk}
      />

      <VideoAdjustModal
        isModalVisible={isAdjustModalVisible}
        id={selectedId}
        handleCancel={onAdjustModalClickCancel}
        handleOk={onVidLevelChange}
      />

      <VideoDeleteModal
        isModalVisible={isDeleteModalVisible}
        id={selectedId}
        handleCancel={onDeleteModalClickCancel}
        handleOk={onDeleteModalClickOk}
      />

      <VirtualIntructorModal
        isModalVisible={isInstructorModalVisible}
        isLoading={state?.isLoading}
        handleCancel={onInstructorModalClickCancel}
        onVideoClicked={onVideoClicked}
      />
    </MyStudioLayout>
  );
};

export default MyStudioPage;
