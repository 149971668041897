import React, {useEffect, useState} from 'react';
import Modal from './Modal';
import { Button } from './FormElements';
import bgEvent from '../Assets/bg-event.jpg';
import cn from 'classnames';
import { IconCalendarNext, IconHistory, IconLiveTicket } from './Icons';
import defaultTimeZone from '../Utils/defaultTimeZone';
import moment from 'moment-timezone';
import { FaCircleNotch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import {getSubscriptionPlanId} from "../Utils/subscriptionUtils";

const BookEventModal = ({
  isModalVisible,
  isLoading,
  event,
  onClose,
  handleBookEvent,
  handleCancelEvent,
  onSetVideo,
  ...props
}) => {
  const {
    id,
    title,
    // category,
    event_record,
    event_date = '1900-01-01',
    event_start = '12:00',
    event_end = '12:00',
    event_type,
    event_timezone,
    instructor,
    // isAvailable,
    is_booked,
    is_cancellable,
    max_no_participants,
    current_no_participants,
    participants,
  } = event;

  const profile = useSelector((state) => state.setting);

  const [subscriptionPlanId, setSubscriptionPlanId] = useState(1);

  useEffect( () => {
    getSubscriptionPlanId(profile).then((res) => {
      setSubscriptionPlanId(res);
    })
  }, [profile])

  const sourceTimeZone = event_timezone || defaultTimeZone;
  const targetTimeZone = moment.tz.guess();
  const momentSourceTimeStart = moment(`${event_date} ${event_start}`).tz(sourceTimeZone, true);
  const momentSourceTimeEnd = moment(`${event_date} ${event_end}`).tz(sourceTimeZone, true);
  const momentTargetTimeStart = momentSourceTimeStart.clone().tz(targetTimeZone);
  const momentTargetTimeEnd = momentSourceTimeEnd.clone().tz(targetTimeZone);
  const isInPast = event_date ? momentTargetTimeStart.isSameOrBefore() : false;
  // const slotsAvailable = max_no_participants - current_no_participants || 0;
  const hasFreeSpace = max_no_participants - current_no_participants > 0;
  const IconType = isInPast ? IconHistory : IconCalendarNext;
  const isWorkshop = event_type?.name?.toLowerCase() === 'workshop';

  const imageOnLoad = (e) => {
    e.target.classList.add('fadein');
    e.target.classList.remove('transparent');
  };

  const handleClose = () => {
    onClose();
  };

  const onClickBook = (id) => {
    if (id && !isInPast && !is_booked) {
      handleBookEvent(id);
    }

    if (isInPast && event_record) {
      onSetVideo(event_record);
      onClose();
    }
  };

  const onClickCancel = (id) => {
    if (id && is_booked && is_cancellable) {
      handleCancelEvent(id);
    }

    onClose();
  };

  return (
    <Modal
      size="xs"
      defaultClass={false}
      disableHeader
      disableOverflow
      visible={isModalVisible}
      onClose={handleClose}
    >
      {/* Cover */}
      <div className="relative of-hidden" style={{ paddingTop: '56.25%' }}>
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-grey-50">
          <img
            className={cn('fit fit-cover transparent')}
            src={bgEvent}
            alt=""
            onLoad={imageOnLoad}
          />
        </div>
        <div className="absolute top-0 right-0 left-0 flex justify-between align-center p-20 fs-12 md:fs-15 fw-600 lh-1">
          <div
            className={cn(
              'h-30 md:h-40 r-100 flex align-center px-10 md:px-15',
              isInPast ? 'bg-grey-100' : 'bg-primary-light fg-primary',
            )}
          >
            <IconType className="flex-0 w-20 h-20 mr-6 md:mr-8" />
            <span className="text-trim">{isInPast ? 'Past' : 'Upcoming'}</span>
          </div>
          {!isInPast && (
            <div className="h-30 md:h-40 r-100 flex align-center px-10 md:px-15 bg-grey-100">
              <IconLiveTicket className="flex-0 h-20 mr-6 md:mr-8" />
              <span className="text-trim">
                {isWorkshop && (subscriptionPlanId === 3) ? 'Free access' : '1 ticket'}
              </span>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 right-0 left-0 text-left">
          <div
            className="inline-block fs-12 md:fs-12 bg-black fg-white px-20 py-10"
            style={{ backgroundColor: 'rgba(0,0,0,.5)', borderTopRightRadius: '10px' }}
          >
            {momentTargetTimeStart.format('MMM D, YYYY')} · {momentTargetTimeStart.format('HH:mm')}{' '}
            – {momentTargetTimeEnd.format('HH:mm')} {momentTargetTimeStart.format('z')}
          </div>
        </div>
      </div>

      {/* Details */}
      <div className="p-20">
        <p className="fs-14 md:fs-16 fw-600">{title}</p>
        <div className="flex flex-nowrap align-center mt-15">
          {participants?.map(({ name, avatar }, key) => (
            <div
              key={key}
              className="w-35 h-35 md:w-40 md:h-40 r-100 of-hidden img-avatar b-2 b-white"
              style={{ marginRight: '-12px', zIndex: participants.length - key }}
            >
              {avatar ? (
                <img loading="lazy" className="fit fit-cover" src={avatar} alt="" />
              ) : (
                <div className="fit flex align-center justify-center fs-13 fw-700 lh-1 bg-primary-light fg-primary">
                  {name}
                </div>
              )}
            </div>
          ))}
          {current_no_participants > participants?.length && (
            <span className="ml-20 fs-12 md:fs-15 fg-grey-500">
              +{current_no_participants - participants?.length} more people
            </span>
          )}
        </div>
        <div className="flex align-center mt-15 fs-12 md:fs-15 fg-grey-500">
          <div className="flex-0 flex align-center md:ml-auto">
            <div className="w-35 h-35 md:w-40 md:h-40 r-100 of-hidden md:order-2 b-2 b-white">
              <img
                loading="lazy"
                onLoad={imageOnLoad}
                className="fit fit-cover instructor-avatar transparent"
                src={instructor?.picture}
                alt={instructor?.name}
              />
            </div>
            <div className="ml-10 md:ml-0 md:mr-10 md:order-1">by {instructor?.name}</div>
          </div>
        </div>
        <div className="text-center mt-15">
          {is_booked && is_cancellable ? (
            <>
              <p className="mt-20">
                Booking can be canceled up to 24 hours before the event start time. Your ticket will
                be refunded if you proceed to cancel the booking.
              </p>
              <Button red className="fit-w my-15" onClick={() => onClickCancel(id)}>
                {isLoading && <FaCircleNotch className="w-20 mr-10 spin" />}
                Cancel booking
              </Button>
            </>
          ) : (
            <Button
              primary
              className={cn(
                'fit-w my-15',
                ((isInPast && !event_record) ||
                  (!isInPast && is_booked) ||
                  !hasFreeSpace ||
                  isLoading) &&
                  'disabled',
              )}
              onClick={() => onClickBook(id)}
            >
              {isLoading && <FaCircleNotch className="w-20 mr-10 spin" />}
              {isInPast
                ? event_record
                  ? 'View workshop'
                  : 'Event closed'
                : hasFreeSpace
                ? 'Book now'
                : 'Sold out'}
            </Button>
          )}
          <button
            className={cn(
              'fs-14 md:fs-18 fw-600 mt-20 underline hover:fg-primary',
              isLoading && 'disabled',
            )}
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BookEventModal;
