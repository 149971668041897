import * as types from '../constants/login';
import { mapErrorMessage } from '../../Utils/mapErrorMessage';

function getUser() {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    return null;
  }
}

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  token: localStorage.getItem('token'),
  user: getUser(),
};

export default function loginApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // LOGIN
    case types.API_REQUEST_LOGIN:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        token: null,
        user: null,
      };

    case types.API_REQUEST_LOGIN_SUCCESS:
      localStorage.setItem('token', action.response.data.token);
      localStorage.setItem('user', JSON.stringify(action.response.data.user));

      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        token: action.response.data.token,
        user: action.response.data.user,
      };

    case types.API_REQUEST_LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    // LOGOUT
    case types.API_REQUEST_LOGOUT:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        token: null,
      };

    case types.API_REQUEST_LOGOUT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response,
      };

    // create account
    case types.API_REQUEST_CREATE_ACCOUNT:
      return {
        ...state,
        error: null,
        success: false,
        isLoading: true,
      };

    case types.API_REQUEST_CREATE_ACCOUNT_SUCCESS:
      localStorage.setItem('token', action.response.data.token);
      localStorage.setItem('user', JSON.stringify(action.response.data.user));
      localStorage.removeItem('tutorial');
      localStorage.removeItem('tutorial2');

      return {
        ...state,
        error: null,
        success: true,
        isLoading: false,
        token: action.response.data.token,
        user: action.response.data.user,
      };

    case types.API_REQUEST_CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        error: mapErrorMessage(action),
        success: false,
        isLoading: false,
      };

    // PASSWORD RESET
    case types.API_REQUEST_PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_REQUEST_PASSWORD_RESET_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    // PASSWORD CONFIRM
    case types.API_REQUEST_PASSWORD_CONFIRM:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_REQUEST_PASSWORD_CONFIRM_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.UPDATE_AVATAR:
      let user = JSON.parse(JSON.stringify(state.user));
      user.avatar = action.avatar;
      localStorage.setItem('user', JSON.stringify(user));

      return {
        ...state,
        user,
      };

    case types.RESET_LOGIN_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
