import * as types from "./../constants/login";

export const resetLoginState = () => ({
  type: types.RESET_LOGIN_STATE,
});

// LOGIN
export const apiLoginRequest = (data) => ({
  type: types.API_REQUEST_LOGIN,
  data,
});

export const apiLoginRequestSuccess = (response) => ({
  type: types.API_REQUEST_LOGIN_SUCCESS,
  response,
});

export const apiLoginRequestFailed = (response) => ({
  type: types.API_REQUEST_LOGIN_FAILED,
  response,
});

export const updateAvatar = (avatar) => ({
  type: types.UPDATE_AVATAR,
  avatar,
});

// LOGOUT
export const apiLogoutRequest = (token) => ({
  type: types.API_REQUEST_LOGOUT,
  token,
});

export const apiLogoutRequestSuccess = (response) => ({
  type: types.API_REQUEST_LOGOUT_SUCCESS,
  response,
});

export const apiLogoutRequestFailed = (response) => ({
  type: types.API_REQUEST_LOGOUT_FAILED,
  response,
});

// SIGN UP
export const apiSubmitCreateAccountRequest = (data) => ({
  type: types.API_REQUEST_CREATE_ACCOUNT,
  data,
});

export const apiSubmitCreateAccountRequestFailed = (response) => ({
  type: types.API_REQUEST_CREATE_ACCOUNT_FAILED,
  response,
});

export const apiSubmitCreateAccountRequestSuccess = (response) => ({
  type: types.API_REQUEST_CREATE_ACCOUNT_SUCCESS,
  response,
});

// PASSWORD RESET
export const apiPasswordResetRequest = (data) => ({
  type: types.API_REQUEST_PASSWORD_RESET,
  data,
});

export const apiPasswordResetRequestSuccess = (response) => ({
  type: types.API_REQUEST_PASSWORD_RESET_SUCCESS,
  response,
});

export const apiPasswordResetRequestFailed = (response) => ({
  type: types.API_REQUEST_PASSWORD_RESET_FAILED,
  response,
});

// PASSWORD CONFIRM
export const apiPasswordConfirmRequest = (data) => ({
  type: types.API_REQUEST_PASSWORD_CONFIRM,
  data,
});

export const apiPasswordConfirmRequestSuccess = (response) => ({
  type: types.API_REQUEST_PASSWORD_CONFIRM_SUCCESS,
  response,
});

export const apiPasswordConfirmRequestFailed = (response) => ({
  type: types.API_REQUEST_PASSWORD_CONFIRM_FAILED,
  response,
});
