/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionPage from '../Pages/SubscriptionPage';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  apiChangeSubscriptionRequest,
  apiCheckoutSubscriptionRequest,
  apiPaymentMethodRequest,
  apiSubscriptionRequest,
  apiTermsNConditionsRequest,
} from '../Redux/actions/subscription';
import _ from 'lodash';
import { apiCouponRequest } from '../Redux/actions/coupon';
import { apiSettingRequest } from '../Redux/actions/setting';
// import moment from 'moment';
import { toast } from 'react-toastify';
import { showErrorToastNoti } from '../Utils/errorToast';
import ReactPixel from 'react-facebook-pixel';
import {Capacitor} from "@capacitor/core";
import RevenueCatService from "../Components/RevenueCatService";

const SubscriptionContainer = ({ showPlanTableOnly }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const state = useSelector((state) => state.signup);
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.setting);
  const subscriptionPlans = useSelector((state) => state.subscription);
  const [isPlanRequested, setIsPlanRequested] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [membershipType, setMembershipType] = useState(null);
  const [prevPlan, setPrevPlan] = useState(0);
  const isIOS = Capacitor.getPlatform() === 'ios';
  // const [prevPlanEndDate] = useState('2021-12-11T18:49:14.866375Z');
  // const currentPlan = _.find(subscriptionPlans.subscription, {
  //   id: profile.setting?.active_membership?.plan?.id,
  // });

  const onSubscriptionSelected = async (id, membership_type) => {
    setPrevPlan(id);
    if (membership_type === 'Free') {
      let data = {
        plan_id: id,
      };
      dispatch(apiChangeSubscriptionRequest(data));
      setIsPlanRequested(true);
      ReactPixel.track('StartTrial');
      return;
    }
    if (isIOS) {
      const fetchedOfferings = await RevenueCatService.getOfferings();
      const requestedPackage = id === 2 ? 'THREE_MONTH' : id === 3 ? 'ANNUAL' : '';
      const pkg = fetchedOfferings.current?.availablePackages.find(pkg => pkg.packageType === requestedPackage);
      RevenueCatService.purchasePackage(pkg).then(() => {
        setMembershipType(id)
      });
    } else {
      setMembershipType(id);
      setShowCheckout(true);
    }
  };

  useEffect(() => {
    dispatch(apiSubscriptionRequest());
    dispatch(apiTermsNConditionsRequest());
    if (auth.user) {
      dispatch(apiSettingRequest({ user_id: auth.user.id }));
      dispatch(apiPaymentMethodRequest());
    }
    ReactPixel.track('InitiateCheckout');
  }, []);

  useEffect(() => {
    if (
      !subscriptionPlans.isLoading &&
      subscriptionPlans.success &&
      subscriptionPlans.isPlanChanged
    ) {
      navigate(pathname === '/my-profile' ? '/my-profile?page=settings' : '/dashboard');
    }
    // showErrorToastNoti(subscriptionPlans);
  }, [subscriptionPlans.isLoading]);

  useEffect(() => {
    if (
      !subscriptionPlans.isLoading &&
      subscriptionPlans.success &&
      subscriptionPlans.isPlanChanged
    ) {
      if (prevPlan === 1) {
        // const planName = _.find(subscriptionPlans.subscription, {
        //   id: profile.setting?.active_membership?.plan?.id,
        // })?.['plan_name'];
        // let expireMonth = 3;
        // if (profile.setting?.active_membership?.plan?.id === 3) {
        //   expireMonth = 12;
        // }
        // const expireDate = moment(prevPlanEndDate).add(expireMonth, 'M').format('MM-DD-YYYY');

        // if (planName && expireDate) {
        //   toast.warning(`You are currently in ${planName} which will expire on ${expireDate}`);
        // }
        toast.success('Subscription successfully downgraded.');
      }
      dispatch(apiSettingRequest({ user_id: auth.user.id }));
    }
    showErrorToastNoti(subscriptionPlans);
  }, [subscriptionPlans]);

  useEffect(() => {
    if (!subscriptionPlans.isLoading && subscriptionPlans.isPlanChanged && isPlanRequested) {
      navigate(pathname === '/my-profile' ? '/my-profile?page=settings' : '/dashboard');
    }
  }, [subscriptionPlans]);

  const onSubmitCoupon = (val) => {
    // Ensure that membershipType is set before attempting to submit
    if (membershipType) {
      let data = {
        promo_code: val,
        plan_id: membershipType,
      };
      dispatch(apiCouponRequest(data));
    } else {
      toast.warning('Please select a plan before submitting a coupon.');
    }
  };

  const onSubmitCheckout = (
    type,
    paymentMethod,
    cardNumber,
    cardExpMonth,
    cardExpYear,
    cardCode,
    couponCode,
    isError,
  ) => {
    if (!isError) {
      let data = {
        plan_id: parseInt(type),
        payment_method: paymentMethod,
        card_holder: auth?.user?.name,
        card_number: cardNumber?.replace(/\s/g, ''),
        expiration_month: cardExpMonth?.replace(/\s/g, ''),
        expiration_year: cardExpYear?.replace(/\s/g, ''),
        security_code: cardCode?.replace(/\s/g, ''),
        promo_code: couponCode || null,
      };
      dispatch(apiCheckoutSubscriptionRequest(data));
    } else {
      toast.warning('Please fix error before you submit');
    }
  };

  return (
    <SubscriptionPage
      showPlanTableOnly={showPlanTableOnly}
      isLoading={state.isLoading}
      state={subscriptionPlans}
      currentPlan={profile?.setting?.active_membership}
      terms={_.get(subscriptionPlans, 'terms_conditions.terms', '')}
      onSubscriptionSelected={onSubscriptionSelected}
      onSubmitCheckout={onSubmitCheckout}
      onSubmitCoupon={onSubmitCoupon}
      showCheckout={showCheckout}
      setShowCheckout={setShowCheckout}
      membershipType={membershipType}
    />
  );
};

export default SubscriptionContainer;
