import cn from 'classnames';
import { useState } from 'react';
import LogOutModal from '../Components/LogOutModal';
import NavBar from '../Components/NavBar';
import UnlockAccessModal from '../Components/UnlockAccessModal';

const DashboardLayout = ({ dimmedBackground = false, children, rightBar }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    setShowLogoutModal(false);
  };

  const handleCancel = () => {
    setShowLogoutModal(false);
  };

  return (
    <>
      <div className="flex-1 flex flex-col bg-grey-100 dashboard-page">
        <NavBar setShowLogoutModal={setShowLogoutModal} />
        <div className="flex-1 flex flex-col container-auto-xxl position-relative">
          <div
            className={cn(
              'flex-1 flex flex-col p-20 md:p-35 xl:p-50 md:m-20 my-20 md:my-35 r-10 main-column',
              dimmedBackground ? 'bg-grey-50' : 'bg-white',
            )}
          >
            {children}
          </div>

          {/* <div className="layout-cols">
          <div className="left-column">
            <SidebarMenu />
            </div>
          {rightBar ? <div className="right-column">{rightBar}</div> : <></>}
        </div> */}
        </div>
      </div>
      <UnlockAccessModal />
      <LogOutModal
        isModalVisible={showLogoutModal}
        handleOk={handleLogout}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default DashboardLayout;
