/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconLogout, IconProfile } from './Icons';
import { ContextMenu, MenuItem } from './ContextMenu';

const UserInfo = ({ setShowLogoutModal }) => {
  // const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.setting);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (e) => {
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  const handleLogout = (e) => {
    // e.stopPropagation();
    setShowLogoutModal(true);
    // dispatch(apiLogoutRequest(auth.token));
  };

  // useEffect(() => {
  //   console.log(profile)
  //   if (!profile.isLoading && profile?.setting) {
  //     const membership = profile?.setting?.active_membership;
  //     if (!membership || !membership?.plan?.id) {
  //       // dispatch(apiChangeSubscriptionRequest({ plan_id: 1 }));
  //     }
  //   }
  // }, [profile.setting]);

  if (!profile.setting) return null;

  return (
    <>
      {/* User Info */}
      <div
        className="md:show inline-flex align-center of-hidden cursor-pointer hover:fg-primary user-info-container"
        onClick={handleMenuClick}
      >
        <div
          className="flex-0 flex w-45 h-45 fg-primary bg-primary-light r-100 avatar"
          style={
            profile.setting?.avatar && {
              backgroundImage: `url(${profile.setting.avatar})`,
              backgroundSize: 'cover',
            }
          }
        >
          {profile.setting.avatar ? (
            <></>
          ) : (
            <span className="fs-16 fw-700 m-auto default-text-avatar">
              {profile.setting.name.charAt(0)}
            </span>
          )}
        </div>
        <div className="lg:show flex-1 inline-flex align-center of-hidden fs-16 fw-600 ml-10 full-name">
          <span className="text-trim">{profile.setting.name}</span>
          <FaChevronDown className="flex-0 w-10 m-10 fg-grey-500" />
        </div>
      </div>

      {/* User Menu */}
      <ContextMenu visible={showMenu} onClose={handleMenuClose} className="top-100">
        <Link to="/my-profile">
          <MenuItem>
            <IconProfile className="mr-10" />
            Profile
          </MenuItem>
        </Link>
        <MenuItem className="fg-red-700" onClick={handleLogout}>
          <IconLogout className="mr-10" />
          Log Out
        </MenuItem>
      </ContextMenu>
    </>
  );
};

export default UserInfo;
