import React from 'react';
import Modal from './Modal';
import { Button } from './FormElements';

const PaymnetMethodDeleteModal = ({ ...props }) => {
  const { isModalVisible, data, handleOk, handleCancel } = props;

  const expireDate = (card) =>
    card?.exp_month.toString().padStart(2, '0') + '/' + card?.exp_year.toString().slice(-2);

  const handleClose = () => {
    handleCancel(false);
  };

  const onClickedOk = () => {
    handleOk(data?.id);
    handleClose();
  };

  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isModalVisible} onClose={handleClose}>
      <p className="fs-24 md:fs-28 fw-700 mb-20">Remove Credit Card</p>
      <p className="fg-grey-500 pb-20">
        Are you sure you want to remove {data?.card?.brand.toUpperCase()} Credit Card?
      </p>
      <p className="fg-grey-500 pb-20">•• {data?.card?.last4}</p>
      <p className="fg-grey-500 pb-20">Expires {expireDate(data?.card)}</p>
      <p>
        <Button red className="fit-w md:w-290 my-15" onClick={onClickedOk}>
          Delete
        </Button>
      </p>
      <p>
        <button
          className="fs-14 md:fs-16 fw-600 mt-20 underline hover:fg-primary"
          onClick={handleClose}
        >
          Cancel
        </button>
      </p>
    </Modal>
  );
};

export default PaymnetMethodDeleteModal;
