import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiEssentialsRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/my_essentials/`, { headers });
}

function apiProgramRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/my_program/`, { headers });
}

function apiMoodsRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/my_moods/`, { headers });
}

function apiScheduleVideoRequest(action) {
  const { id, date, calendar, calendar_integration_enabled } = action;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(
    `/api/v1/videos/schedule_video/`,
    { id, date, calendar, calendar_integration_enabled },
    { headers },
  );
}

function apiRemoveVideoRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/videos/remove_video/`, action.data, { headers });
}

function apiLevelChangeRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/videos/change_video_level/`, action.data, { headers });
}

function apiVideoActivityRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/videos/record_activity/`, action.data, { headers });
}

export const studioVideoService = {
  apiEssentialsRequest,
  apiProgramRequest,
  apiMoodsRequest,
  apiScheduleVideoRequest,
  apiRemoveVideoRequest,
  apiLevelChangeRequest,
  apiVideoActivityRequest,
};
