import { FaCircleNotch } from 'react-icons/fa';

const SignupQuestionnaireLayout = ({
  id = 0,
  title,
  page = '',
  width,
  progress,
  isLoading,
  children,
  extra_details_page = false,
}) => {
  return (
    <>
      <>
        {isLoading ? (
          <div className="fs-20 fw-600 text-center my-auto">
            <FaCircleNotch className="w-25 h-25 mx-auto my-20 fg-primary spin" />
            <p>Loading...</p>
          </div>
        ) : (
          <>
            {progress === 0 || extra_details_page ? <h2 className="page-title">{title}</h2> : <></>}
            <>{children}</>
          </>
        )}
      </>
    </>
    // <GuestLayout width={width}>
    //   <div className="progress-logo-circle">
    //     <div className="progress-bar-container">
    //       <CircularProgressbarWithChildren
    //             styles={{
    //                 root: {
    //                     width: '100%',
    //                     height: '100%'
    //                 },
    //                 path: {
    //                     stroke: `rgba(8, 158, 85)`,
    //                     strokeWidth: '4px',
    //                     strokeLinecap: 'round',
    //                     transition: 'stroke-dashoffset 0.5s ease 0s',
    //                 },
    //                 trail: {
    //                     opacity: 0,
    //                     strokeWidth: '4px'
    //                 },
    //                 background: {
    //                     fill: '#3e98c7',
    //                 }
    //             }}
    //             backgroundPadding={10}
    //             value={progress}>

    //                 <img className="small-logo-image" src={logo} alt="IS" />
    //                 <div className="progressBarPercentageText">{progress}%</div>

    //             </CircularProgressbarWithChildren>
    //     </div>
    //   </div>
    //   <div className="questionnaire-contents">
    //     <div className="questionnaire-body">
    //       {isLoading ? (
    //         <div>
    //           <h4>Loading...</h4>
    //         </div>
    //       ) : (
    //         <>
    //           {progress === 0 || extra_details_page ? (
    //             <h2 className="page-title">{title}</h2>
    //           ) : (
    //             <></>
    //           )}
    //           <div className="page-content">{children}</div>
    //         </>
    //       )}
    //     </div>

    //     {page !== 'subscription' ? (
    //       <div className="questionnaire-footer">
    //         <div className="footer-text-container">
    //           <p>
    //             Note: incorpo studio is not a medical device. Do not exercise through pain, and in
    //             case of any doubt regarding your physical condition or ability to exercise please
    //             consult your medical professional.
    //           </p>
    //         </div>
    //       </div>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    // </GuestLayout>
  );
};

export default SignupQuestionnaireLayout;
