// Fetch events
export const API_EVENTS_REQUEST = "API_EVENTS_REQUEST";
export const API_EVENTS_REQUEST_SUCCESS = "API_EVENTS_REQUEST_SUCCESS";
export const API_EVENTS_REQUEST_FAILED = "API_EVENTS_REQUEST_FAILED";

// Order event
export const API_ORDER_EVENT_REQUEST = "API_ORDER_EVENT_REQUEST";
export const API_ORDER_EVENT_REQUEST_SUCCESS = "API_ORDER_EVENT_REQUEST_SUCCESS";
export const API_ORDER_EVENT_REQUEST_FAILED = "API_ORDER_EVENT_REQUEST_FAILED";

// Cancel order event
export const API_ORDER_CANCEL_EVENT_REQUEST = "API_ORDER_CANCEL_EVENT_REQUEST";
export const API_ORDER_CANCEL_EVENT_REQUEST_SUCCESS = "API_ORDER_CANCEL_EVENT_REQUEST_SUCCESS";
export const API_ORDER_CANCEL_EVENT_REQUEST_FAILED = "API_ORDER_CANCEL_EVENT_REQUEST_FAILED";
