/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './Modal';
import cn from 'classnames';
import { Button, Error } from './FormElements';
import { apiContactUsRequest } from '../Redux/actions/contactus';
import { toast } from 'react-toastify';
import { FaCircleNotch } from 'react-icons/fa';

const ContactUsModal = ({ ...props }) => {
  const { isModalVisible, handleOk, handleCancel } = props;

  const dispatch = useDispatch();
  const { isLoading, error, success } = useSelector((state) => state.contactus);
  const [isEmpty, setIsEmpty] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (success) {
      setMessage('');
      setIsEmpty(true);
      handleOk();
      toast.success('Your message has been sent!');
    }
  }, [isLoading]);

  const sendMessage = () => {
    if (message.length === 0) {
      setIsEmpty(true);
      return false;
    }
    let formData = {
      message: message,
    };
    dispatch(apiContactUsRequest(formData));
  };

  const onMsgChange = (e) => {
    setMessage(e.target.value);
    setIsEmpty(e.target.value.length === 0);
  };

  return (
    <Modal
      className={cn('text-center fs-14 md:fs-16', isLoading && 'disabled')}
      visible={isModalVisible}
      onClose={handleCancel}
    >
      <p className="fs-20 md:fs-24 md:fs-28 fw-700 mb-20 text-left md:text-center">Contact us</p>
      <p className="fg-grey-500 mb-20 text-left md:text-center">Share your feedback with us</p>
      <textarea
        name=""
        placeholder="Type your message here..."
        className="fit-w md:w-490 h-180 md:h-160 p-20 my-5 r-5 b-1 b-grey-400 focus:b-black modal-scrollstyle"
        onInput={onMsgChange}
      />
      <div className="container-auto-xs text-center">
        <Error
          disableFixedWidth
          text={error?.code === 400 && 'The e-mail address is not assigned to any user account.'}
        />
        <Error disableFixedWidth text={error?.message} />
      </div>
      <p>
        <Button
          primary
          className={cn('fit-w md:w-290 my-30', isEmpty && 'disabled')}
          onClick={sendMessage}
        >
          {isLoading ? <FaCircleNotch className="w-20 h-20 spin" /> : 'Send a message'}
        </Button>
      </p>
      <p className="container-auto-xs fs-12 fg-grey-500">
        Give us your feedback, positive and negative, and help us develop the perfect experience for
        you!
      </p>
    </Modal>
  );
};

export default ContactUsModal;
