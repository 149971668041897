import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import { ToastContainer } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import {usePostHog} from "posthog-js/react";
import {useSelector} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {Capacitor} from "@capacitor/core";

const ToastifyCloseButton = ({ closeToast }) => {
  const initialOpacity = 0.5;
  const [opacity, setOpacity] = useState(initialOpacity);

  return (
    <button
      type="button"
      style={{ paddingLeft: '10px', paddingRight: '10px', opacity: opacity }}
      onClick={closeToast}
      onTouchStart={() => setOpacity(1)}
      onTouchEnd={() => setOpacity(initialOpacity)}
      onMouseEnter={() => setOpacity(1)}
      onMouseLeave={() => setOpacity(initialOpacity)}
    >
      Close
    </button>
  );
};

function App() {
  const posthog = usePostHog();
  const profile = useSelector((state) => state.setting);

  useEffect(() => {
    if (profile.setting && profile.setting.name && profile.setting.email) {
      posthog?.identify(profile.setting.id, {
        email: profile.setting.email,
        name: profile.setting.name,
      });
    }
  }, [posthog, profile]);

  useEffect(() => {
    if (profile?.setting?.id && Capacitor.getPlatform() === 'web') {
      // Use Google Analytics' gtag function to send the user ID
      window.gtag('config', 'G-Q6720TSPV8', {
        'user_id': profile?.setting?.id,
      });
    }
  }, [profile?.setting?.id]);

  useEffect(() => {
    // Meta Pixel
    ReactPixel.init('789292785316682', null, { debug: true });
    ReactPixel.pageView();
    ReactPixel.track('ViewContent');
  }, []);

  return (
    <>
      <Routes />
      <ToastContainer
        hideProgressBar
        position="bottom-center"
        theme="colored"
        closeButton={ToastifyCloseButton}
      />
    </>
  );
}

export default App;
