import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { signupServices } from "./../services/signup";
import * as types from "./../constants/signup";
import * as actions from "./../actions/signup";

// Session Key
function* apiSessionKeyRequestWorker(action) {
    try {
        const result = yield call(signupServices.apiSessionKeyRequest, action);
        yield put(actions.apiSessionKeyRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiSessionKeyRequestFailed(err, action));
    }
}

function* apiSessionKeyRequestWatcher() {
    yield takeEvery(types.API_REQUEST_SESSION_KEY, apiSessionKeyRequestWorker);
}

// Sign UP profiling questionnaire
function* apiSignUpProfilingQuestionaireRequestWorker(action) {
    try {
        const result = yield call(signupServices.apiSignUpProfilingQuestionnaireRequest, action);
        yield put(actions.apiSignUpQuestionnaireRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiSignUpQuestionnaireRequestFailed(err, action));
    }
}

function* apiSignUpProfilingQuestionaireRequestWatcher() {
    yield takeEvery(types.API_REQUEST_SIGNUP_PROFILING_QUESTIONNAIRE, apiSignUpProfilingQuestionaireRequestWorker);
}

// Virtual Instructor questionnaire
function* apiVirtualInstrcutorQuestionnaireRequestWorker(action) {
    try {
        const result = yield call(signupServices.apiVirtualInstructorQuestionaireRequest, action);
        yield put(actions.apiVirtualInstructorRecalibrateQuestionaireRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiVirtualInstructorRecalibrateQuestionaireRequestFailed(err, action));
    }
}

function* apiVirtualInstructorQuestionnaireRequestWatcher() {
    yield takeEvery(types.API_REQUEST_VIRTUAL_INSTRUCTOR_QUESTIONNAIRE, apiVirtualInstrcutorQuestionnaireRequestWorker);
}

// Virtual Instructor recalibrate
function* apiVirtualInstrcutorRecalibrateQuestionaireRequestWorker(action) {
    try {
        const result = yield call(signupServices.apiVirtualInstructorRecalibrateQuestionaireRequest, action);
        yield put(actions.apiVirtualInstructorRecalibrateQuestionaireRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiVirtualInstructorRecalibrateQuestionaireRequestFailed(err, action));
    }
}

function* apiVirtualInstructorRecalibrateQuestionaireRequestWatcher() {
    yield takeEvery(
        types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_QUESTIONAIRE,
        apiVirtualInstrcutorRecalibrateQuestionaireRequestWorker
    );
}

// Init Question
function* apiQuestionInitRequestWorker(action) {
    try {
        yield put(actions.apiQuestionRequestSuccess(action));
    } catch (err) {
        yield put(actions.apiQuestionRequestFailed(err, action));
    }
}
function* apiQuestionInitRequestWatcher() {
    yield takeEvery(types.API_REQUEST_QUESTION_INIT, apiQuestionInitRequestWorker);
}

// Question
function* apiQuestionRequestWorker(action) {
    try {
        const result = yield call(signupServices.apiQuestionRequest, action);
        yield put(actions.apiQuestionRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiQuestionRequestFailed(err, action));
    }
}

function* apiQuestionRequestWatcher() {
    yield takeEvery(types.API_REQUEST_QUESTION_BY_ID, apiQuestionRequestWorker);
}

// Submit answer
function* apiSubmitAnswerRequestWorker(action) {
    try {
        const result = yield call(signupServices.apiSubmitAnswerRequest, action);
        yield put(actions.apiSubmitAnswerRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiSubmitAnswerRequestFailed(err, action));
    }
}

function* apiSubmitAnswerRequestWatcher() {
    yield takeEvery(types.API_REQUEST_SUBMIT_ANSWER, apiSubmitAnswerRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* signupRootSaga() {
    const sagas = [
        apiSessionKeyRequestWatcher,
        apiSignUpProfilingQuestionaireRequestWatcher,
        apiVirtualInstructorQuestionnaireRequestWatcher,
        apiVirtualInstructorRecalibrateQuestionaireRequestWatcher,
        apiQuestionInitRequestWatcher,
        apiQuestionRequestWatcher,
        apiSubmitAnswerRequestWatcher
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
