import React, { useEffect, useState } from 'react';
import { Toggle } from './Icons';
import cn from 'classnames';

export const SettingsCard = ({ className, children, ...props }) => {
  return (
    <div className={cn('fit-h r-15 b-1 b-grey-300 of-hidden', className)} {...props}>
      {children}
    </div>
  );
};

export const SettingsCardHeader = ({ className, children, ...props }) => {
  return (
    <div
      className={cn(
        'h-70 lg:h-80 flex align-center justify-between px-20 md:px-30 fs-20 fw-700 bg-grey-100',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const SettingsCardContent = ({ className, children, ...props }) => {
  return (
    <div className={cn('p-20 md:px-30', className)} {...props}>
      {children}
    </div>
  );
};

export const SettingsCardButton = ({ secondary = false, className, children, ...props }) => {
  return (
    <div
      className={cn(
        'w-90 md:w-130 h-40 md:h-50 fs-16 fw-600 lh-1 ml-15 inline-flex justify-center align-center r-100 b-1 hover:fg-white hover:bg-black hover:b-black active:fg-white active:bg-black active:b-black cursor-pointer',
        !secondary && 'fg-white bg-primary b-primary',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const CardInfoRow = ({ title, className, classTitle, children }) => (
  <div className="flex flex-wrap align-center px-10 md:px-0" style={{ minHeight: '50px' }}>
    {title && (
      <div
        className={cn(
          'fit-w pb-10 md:pb-0 md:pr-10 fg-grey-500',
          classTitle,
          children && 'md:w-110',
        )}
      >
        {title}
      </div>
    )}
    {children && <div className={cn('fit-w md:flex-1 text-trim', className)}>{children}</div>}
  </div>
);

export const CardInfoRowToggle = ({ title, caption, onChange, value }) => {
  const [toggle, setToggle] = useState(false);

  const handleClick = (e) => {
    onChange && onChange(!toggle);
    setToggle(!toggle);
  };

  useEffect(() => {
    setToggle(value || false);
  }, [value]);

  return (
    <div
      className="flex align-center cursor-pointer"
      style={{ minHeight: '50px' }}
      onClick={handleClick}
    >
      <div className="flex-1 pr-10 md:pr-15">
        <p className="fw-600 pb-5">{title}</p>
        <p className="fg-grey-500">{caption}</p>
      </div>
      <div className="w-auto text-trim">
        <Toggle className={cn(!!toggle ? 'flip-x fg-primary' : 'fg-grey-200')} />
      </div>
    </div>
  );
};
