import RevenueCatService from "../Components/RevenueCatService";
import { Capacitor } from "@capacitor/core";

export const getSubscriptionPlanId = async (profile) => {
  const isIOS = Capacitor.getPlatform() === 'ios';

  // Default plan ID for non-iOS or in case of an error
  const defaultPlanId = profile.setting?.active_membership?.plan?.id || 1;

  if (!isIOS) {
    return defaultPlanId;
  } else {
    try {
      const customerInfo = await RevenueCatService.getCustomerInfo();
      if (Object.entries(customerInfo?.customerInfo?.entitlements?.active).length === 0) {
        return defaultPlanId;
      } else {
        // Check based on productIdentifier
        const productIdentifier = customerInfo?.customerInfo?.entitlements?.active['Premium'].productIdentifier;
        if (productIdentifier === 'yearly') {
          return 3; // Plan ID for yearly
        } else if (productIdentifier === 'quarterly') {
          return 2; // Plan ID for quarterly
        } else {
          return defaultPlanId;
        }
      }
    } catch (error) {
      console.error('Error checking user entitlements: ', error);
      return defaultPlanId; // Return default plan ID in case of an error
    }
  }
}

export const checkIsFreeUser = async (profile) => {
  const isIOS = Capacitor.getPlatform() === 'ios';

  // Check based on the user's profile for non-iOS or as a fallback
  const isFreeBasedOnProfile = profile.setting?.active_membership?.plan?.id === 1;

  if (!isIOS) {
    return isFreeBasedOnProfile;
  } else {
    try {
      const customerInfo = await RevenueCatService.getCustomerInfo();
      if (Object.entries(customerInfo?.customerInfo?.entitlements?.active).length === 0) {
        return isFreeBasedOnProfile;
      } else {
        // Check based on specific entitlement
        return typeof customerInfo?.customerInfo?.entitlements?.active['Premium'] === "undefined";
      }
    } catch (error) {
      console.error('Error checking user entitlements: ', error);
      return true; // Assume user is free in case of an error
    }
  }
}
