import * as types from "../constants/contactus";

import { mapErrorMessage } from "../../Utils/mapErrorMessage";

const INITIAL_STATE = {
    isLoading: false,
    success: false,
    error: null,
    response: null,
};

export default function contactusApiReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        // FAQ
        case types.API_CONTACT_US_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: null,
            };

        case types.API_CONTACT_US_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: null,
                response: action.response.data,
            };

        case types.API_CONTACT_US_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: mapErrorMessage(action),
            };

        default:
            return state;
    }
}
