import DashboardLayout from './DashboardLayout';

const FaqLayout = ({ children }) => {
  return (
    // <DashboardLayout rightBar={<ContactUsRightBar />}>
    <DashboardLayout>
      {/* <FaqTopCard /> */}
      <div className="fs-24 md:fs-30 fw-800 md:fw-700 pb-20">FAQ</div>
      <hr className="b-grey-300 m-0" />
      <div className="flex-1 flex flex-col page-content my-faqs-page">{children}</div>
    </DashboardLayout>
  );
};

export default FaqLayout;
