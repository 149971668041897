import React, { useState } from 'react';
import MyStatsLayout from '../Layouts/MyStatsLayout';
import StatisticsOverlay from '../Components/StatisticsOverlay';
import { ContextMenu, MenuItem } from '../Components/ContextMenu';
import { FaChevronDown } from 'react-icons/fa';
import cn from 'classnames';
import {
  IconCalendar,
  IconChartColored,
  IconCheckColored,
  IconClockColored,
  IconHotColored,
} from '../Components/Icons';

const filters = [
  { filter: 'week', title: 'Last week' },
  { filter: 'month', title: 'Last month' },
  { filter: '3_months', title: 'Last 3 months' },
  { filter: 'all_time', title: 'All time' },
];

const convertMinutes = (mins) => {
  mins = parseInt(mins);
  const hours = Math.floor(mins / 60);
  const minutes = mins - hours * 60;
  return hours ? `${hours}h ${minutes}m` : `${minutes || 0} min`;
};

const StatsCard = ({ Icon, className, children, ...props }) => {
  return (
    <div className={cn('fit-w flex py-10 md:p-15', className)}>
      <div className="flex-1 flex p-15 md:p-20 r-15 bg-white b-1 b-grey-300 of-hidden">
        <div className="flex-1 of-hidden">{children}</div>
      </div>
    </div>
  );
};

const Dropdown = ({ filter, setFilter }) => {
  const [showMenu, setShowMenu] = useState(false);
  const currentTitle = filters.find((el) => el.filter === filter)?.title || filters[0].title;

  return (
    <>
      <div
        className="md:w-190 flex align-center fs-14 p-10 md:px-15 r-15 fg-grey-700 b-1 b-grey-300 hover:fg-black active:fg-black cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu(!showMenu);
        }}
      >
        <div className="md:show fit-w flex align-center of-hidden">
          <span className="flex-1 pr-15 text-trim">{currentTitle}</span>
          <FaChevronDown className="w-15" />
        </div>
        <div className="md:hide">
          <IconCalendar />
        </div>
      </div>
      <ContextMenu
        visible={showMenu}
        onClose={() => setShowMenu(false)}
        className="fw-500 top-65 right-0"
      >
        {filters.map((item, key) => (
          <MenuItem
            key={key}
            className={item?.filter === filter ? 'bg-primary-light hover:bg-primary-light' : ''}
            onClick={() => {
              setFilter(item?.filter);
              setShowMenu(false);
            }}
          >
            {item?.title}
          </MenuItem>
        ))}
      </ContextMenu>
    </>
  );
};

const StatRow = ({ title, caption, value = 0, color }) => {
  return (
    <div className="flex flex-col md:flex-row w-20/100 md:w-12/12 align-center mt-20 md:mt-30">
      <div className="flex flex-nowrap md:order-2 md:flex-1 w-15 h-160 md:w-auto md:h-20 r-10 mx-auto md:mx-15 bg-grey-200">
        <div
          className="md:show fit-h r-10"
          style={{ backgroundColor: color, width: value + '%' }}
        />
        <div
          className="md:hide fit-w r-10 mt-auto"
          style={{ backgroundColor: color, height: value + '%' }}
        />
      </div>
      <div className="md:order-1 w-95 text-center md:text-left fs-12 md:fs-14 pt-15 md:pt-0 text-trim">
        {title}
      </div>
      <div className="md:order-3 w-50 text-center md:text-left fs-12 md:fs-14 fg-grey-500 text-trim">
        {caption}
      </div>
    </div>
  );
};

const MyStatsPage = ({ myHabbit, myStats, ...props }) => {
  const [filter, setFilter] = useState('week');

  const currentDataset = {
    habbits: myHabbit?.habbits?.find((el) => el.period === filter)?.data,
    stats: myStats?.stats?.find((el) => el.period === filter)?.data,
  };

  const getRawTimeByCategory = (category) => {
    return currentDataset.stats?.find((el) => el.Category === category)?.total;
  };

  const getTimeByCategory = (category) => {
    const time = getRawTimeByCategory(category);
    return convertMinutes(time);
  };

  const getPercentageByCategory = (category) => {
    const time = getRawTimeByCategory(category);
    const totalTime = currentDataset.habbits?.time_viewed;
    return (time / totalTime) * 100 || 0;
  };

  const getRawTimeByLevel = (level) => {
    let total = 0;
    currentDataset.stats?.forEach((el) => {
      total += el.levels[level.toString()];
    });
    return total;
  };

  const getTimeByLevel = (level) => {
    const time = getRawTimeByLevel(level);
    return convertMinutes(time);
  };

  const getPercentageByLevel = (level) => {
    const time = getRawTimeByLevel(level) || 0;
    const totalTime = currentDataset.habbits?.time_viewed || 0;
    return (time / totalTime) * 100 || 0;
  };

  return (
    <MyStatsLayout>
      <div className="relative">
        <div className="relative flex justify-between align-center fs-24 md:fs-30 fw-800 md:fw-700 pb-5 md:pb-20">
          <span>Statistics</span>
          <Dropdown filter={filter} setFilter={setFilter} />
        </div>

        <div className="flex flex-wrap p-15 md:p-0" style={{ margin: '-15px' }}>
          <StatsCard className="md:w-6/12 lg:w-3/12">
            <div className="flex">
              <p className="flex-1 pt-5 fs-14 md:fs-16 fw-600 text-trim">Time scheduled</p>
              <IconCheckColored className="w-30 ml-10" />
            </div>
            <p className="fs-24 md:fs-30 fw-700 text-trim">
              {currentDataset.habbits?.time_scheduled || 0} min
            </p>
            <p className="pt-10 fs-14 fg-grey-500 text-trim">
              {currentDataset.habbits?.time_scheduled_comment}
            </p>
          </StatsCard>
          <StatsCard className="md:w-6/12 lg:w-3/12">
            <div className="flex">
              <p className="flex-1 pt-5 fs-14 md:fs-16 fw-600 text-trim">Time spent exercising</p>
              <IconClockColored className="w-30 ml-10" />
            </div>
            <p className="fs-24 md:fs-30 fw-700 text-trim">
              {convertMinutes(currentDataset.habbits?.time_viewed)}
            </p>
            <p className="pt-10 fs-14 fg-grey-500 text-trim">
              {currentDataset.habbits?.total_viewed_comment}
            </p>
          </StatsCard>
          <StatsCard className="md:w-6/12 lg:w-3/12">
            <div className="flex">
              <p className="flex-1 pt-5 fs-14 md:fs-16 fw-600 text-trim">Time since last workout</p>
              <IconHotColored className="w-30 ml-10" />
            </div>
            <p className="fs-24 md:fs-30 fw-700 text-trim">
              {currentDataset.habbits?.time_since_last_view?.toString().replace('ago', '')}
            </p>
            <p className="pt-10 fs-14 fg-grey-500 text-trim">
              {currentDataset.habbits?.time_since_last_view_comment}
            </p>
          </StatsCard>
          <StatsCard className="md:w-6/12 lg:w-3/12">
            <div className="flex">
              <p className="flex-1 pt-5 fs-14 md:fs-16 fw-600 text-trim">Most attention given to</p>
              <IconChartColored className="w-30 ml-10" />
            </div>
            <p className="fs-24 md:fs-30 fw-700 text-trim">
              {currentDataset.habbits?.most_viewed_body_part}
            </p>
            <p className="pt-10 fs-14 fg-grey-500 text-trim"> </p>
          </StatsCard>
        </div>

        <div className="fs-20 md:fs-22 fw-800 md:fw-700 pt-40 pb-5 md:pb-20">Time spent</div>

        <div className="flex flex-wrap p-15 md:p-0" style={{ margin: '-15px' }}>
          <StatsCard className="md:w-6/12">
            <p className="flex-1 fs-14 md:fs-16 fw-600">Category</p>
            <div className="flex flex-wrap mb-5 md:mb-15">
              <StatRow
                title="Focus"
                caption={getTimeByCategory('Focus')}
                color="#93CFBA"
                value={getPercentageByCategory('Focus')}
              />
              <StatRow
                title="High Intensity"
                caption={getTimeByCategory('High Intensity')}
                color="#AD9BF0"
                value={getPercentageByCategory('High Intensity')}
              />
              <StatRow
                title="Mobility"
                caption={getTimeByCategory('Mobility')}
                color="#7CA8EA"
                value={getPercentageByCategory('Mobility')}
              />
              <StatRow
                title="Relax"
                caption={getTimeByCategory('Relax')}
                color="#A59E92"
                value={getPercentageByCategory('Relax')}
              />
              <StatRow
                title="Strength"
                caption={getTimeByCategory('Strength')}
                color="#E7AC66"
                value={getPercentageByCategory('Strength')}
              />
            </div>
          </StatsCard>
          <StatsCard className="md:w-6/12">
            <p className="flex-1 fs-14 md:fs-16 fw-600">Level</p>
            <div className="flex flex-wrap mb-5 md:mb-15">
              <StatRow
                title="Level 1"
                caption={getTimeByLevel(1)}
                color="#CEE9DF"
                value={getPercentageByLevel(1)}
              />
              <StatRow
                title="Level 2"
                caption={getTimeByLevel(2)}
                color="#B1DCCD"
                value={getPercentageByLevel(2)}
              />
              <StatRow
                title="Level 3"
                caption={getTimeByLevel(3)}
                color="#95D0BB"
                value={getPercentageByLevel(3)}
              />
              <StatRow
                title="Level 4"
                caption={getTimeByLevel(4)}
                color="#79C3A9"
                value={getPercentageByLevel(4)}
              />
              <StatRow
                title="Level 5"
                caption={getTimeByLevel(5)}
                color="#5DB797"
                value={getPercentageByLevel(5)}
              />
            </div>
          </StatsCard>
        </div>
        <StatisticsOverlay />
      </div>
    </MyStatsLayout>
  );
};

export default MyStatsPage;
