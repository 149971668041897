import * as types from "../constants/myHabit";

export const apiMyHabitRequest = (data) => ({
    type: types.API_REQUEST_MY_HABIT,
    data,
});

export const apiMyHabitRequestSuccess = (response) => ({
    type: types.API_REQUEST_MY_HABIT_SUCCESS,
    response,
});

export const apiMyHabitRequestFailed = (response) => ({
    type: types.API_REQUEST_MY_HABIT_FAILED,
    response,
});
