import * as types from '../constants/coupon';

export const apiCouponRequest = (data) => ({
  type: types.API_REQUEST_COUPON,
  data,
});

export const apiCouponRequestSuccess = (response) => ({
  type: types.API_REQUEST_COUPON_SUCCESS,
  response,
});

export const apiCouponRequestFailed = (response) => ({
  type: types.API_REQUEST_COUPON_FAILED,
  response,
});

export const apiCouponReset = (response) => ({
  type: types.API_COUPON_RESET,
  response,
});
