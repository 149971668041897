/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetFaqRequest } from '../Redux/actions/faq';

import FaqPage from '../Pages/FaqPage';
import { apiSettingRequest } from '../Redux/actions/setting';

const FaqContainer = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const faqs = useSelector((state) => state.faqs);

  useEffect(() => {
    dispatch(apiGetFaqRequest());
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  }, []);

  return <FaqPage state={faqs} />;
};

export default FaqContainer;
