const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
const styleBody = document.body.style;

export const scrollLock = () => {
  styleBody.overflow = 'hidden';
  styleBody.paddingRight = scrollbarWidth + 'px';
};

export const scrollUnlock = () => {
  styleBody.overflow = '';
  styleBody.paddingRight = '';
};
