import DashboardLayout from './DashboardLayout';

const MyStatsLayout = ({ children }) => {
  return (
    <DashboardLayout dimmedBackground>
      {/* <MyStatsTopCard /> */}
      {/* <div className="page-content my-stats-page">{children}</div> */}
      {children}
    </DashboardLayout>
  );
};

export default MyStatsLayout;
