import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../Assets/logo-white.svg';
import { ReactComponent as Decor } from '../Assets/decor.svg';
import { FaCheck } from 'react-icons/fa';
import cn from 'classnames';

import css from './HomePage.module.css';
import { Button } from '../Components/FormElements';

const string = {
  title: ['Unlock your body', 'Improve your', 'lifestyle'],
  list: [
    'Improve posture and relieve different types of back pain',
    'Focus on targeted joints and painpoints',
    'Improve sport performance by strengthening the right muscles',
    'Increase your range of motion',
  ],
  logIn: 'Log In',
  join: 'Join now',
};

const HomePage = () => {
  return (
    <div className={cn(css.background, 'flex-1 flex')}>
      <div className={cn(css.backdrop, 'fit flex flex-col p-20 md:px-40 md:py-25 fg-white lh-1.2')}>
        <Logo className="w-140 md:w-180 pb-20 lg:pb-10" />
        <div className="my-auto">
          <div className="relative inline-block fs-32 md:fs-50 fw-800">
            {string.title[0]}
            <br />
            {string.title[1]}{' '}
            <span className="relative">
              {string.title[2]}
              <Decor className="absolute left-0 right-0" />
            </span>
          </div>
          <ul className="fs-16 md:fs-20 py-35">
            {string.list.map((item, index) => (
              <li key={index} className="py-10 flex align-center">
                <div className="flex w-20 h-20 md:w-25 md:h-25 bg-primary r-100 mr-15">
                  <FaCheck className="w-10 md:w-15 m-auto fg-white" />
                </div>
                <span className="flex-1">{item}</span>
              </li>
            ))}
          </ul>

          <Link to="/login">
            <Button landing className="fit-w md:w-230 my-10 md:mr-40">
              {string.logIn}
            </Button>
          </Link>

          <Link to="/signup">
            <Button primary className="fit-w md:w-230 my-10">
              {string.join}
            </Button>
          </Link>
        </div>
        <div className="" />
      </div>
    </div>
  );
};

export default HomePage;
