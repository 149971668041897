/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateObject } from 'react-multi-date-picker';
import LiveLayout from '../Layouts/LiveLayout';
import { IconCalendarNext, IconClock, IconHistory, IconLiveTicket } from '../Components/Icons';
import { Button } from '../Components/FormElements';
import { apiBalanceRequest } from '../Redux/actions/balance';
import { apiEventsRequest } from '../Redux/actions/events';
import defaultTimeZone from '../Utils/defaultTimeZone';
import moment from 'moment-timezone';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FaCircleNotch } from 'react-icons/fa';
import {getSubscriptionPlanId} from "../Utils/subscriptionUtils";

const tabs = ['upcoming', 'past'];

const LiveWorkshopsPage = ({
  setShowBuyTicketModal,
  setShowBookEventModal,
  setEventDetails,
  isBuyingCreditsLoading,
}) => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const tab = tabs.includes(params.get('tab')) ? params.get('tab') : tabs[0];

  const wallet = useSelector((state) => state.balance);
  const events = useSelector((state) => state.events);
  const profile = useSelector((state) => state.setting?.setting);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(1);

  useEffect( () => {
    getSubscriptionPlanId(profile).then((res) => {
      setSubscriptionPlanId(res);
    })
  }, [profile])

  const balance =
    wallet?.balance?.balance?.find((el) => el.product.name?.toLowerCase() === 'workshop')
      ?.balance || 0;

  const eventList = events?.events
    ?.filter((el) => el.event_type.name?.toLowerCase() === 'workshop')
    ?.filter((el) =>
      tab === 'upcoming'
        ? moment(`${el.event_date} ${el.event_start}`).isAfter()
        : moment(`${el.event_date} ${el.event_start}`).isSameOrBefore(),
    )
    ?.sort((a, b) =>
      tab === 'upcoming'
        ? a.event_date.localeCompare(b.event_date) || a.event_start.localeCompare(b.event_start)
        : b.event_date.localeCompare(a.event_date) || b.event_start.localeCompare(a.event_start),
    );

  const [dates] = useState([
    new DateObject(moment().add(-365, 'days').valueOf()),
    new DateObject(moment().add(365, 'days').valueOf()),
  ]);

  useEffect(() => {
    dispatch(apiBalanceRequest());
  }, [!events.isOrderingLoading && events.isOrderingSuccess]);

  useEffect(() => {
    dispatch(apiEventsRequest(dates));
  }, [dates]);

  return (
    <LiveLayout>
      {/* Buy tickets */}
      <div className="flex flex-col md:flex-row py-10">
        <div className="flex-1 py-20">
          <p className="fs-20 md:fs-22 fw-700">Upcoming Workshops</p>
          <p className="fs-14 fg-grey-500">Workshops have an unlimited number of participants.</p>
        </div>
        <div
          className={cn('flex-0 flex align-center md:pr-20', (subscriptionPlanId === 3) && 'disabled')}
        >
          <Button
            primary
            size="small"
            disablePadding
            className={cn('flex-1 md:order-1 md:w-180', isBuyingCreditsLoading && 'disabled')}
            onClick={() => setShowBuyTicketModal(true)}
          >
            {isBuyingCreditsLoading && <FaCircleNotch className="w-20 mr-10 spin" />}
            Buy Tickets
          </Button>
          <div className="md:order-0 flex-0 flex px-15 fg-grey-500">
            <IconLiveTicket className="w-20 h-20 m-auto" />
            <div className="pl-8">
              {subscriptionPlanId === 3 ? 'Free access' : `${balance} tickets left`}
            </div>
          </div>
        </div>
      </div>

      {/* Filter */}
      <div className="flex flex-nowrap py-15 md:pt-0 of-x-overlay hide-scrollbar explore-top-card-category-btns">
        <Link to="/live?category=workshop&tab=upcoming">
          <Button
            size="mini"
            disablePadding
            className={cn(
              'flex-0 px-15',
              tab === 'upcoming' ? 'fg-primary bg-primary-light' : 'bg-grey-100 fg-grey-500',
            )}
          >
            <IconCalendarNext className="w-20 h-20 mr-5" />
            Upcoming
          </Button>
        </Link>
        <Link to="/live?category=workshop&tab=past">
          <Button
            size="mini"
            disablePadding
            className={cn(
              'flex-0 px-15 ml-10',
              tab === 'past' ? 'fg-primary bg-primary-light' : 'bg-grey-100 fg-grey-500',
            )}
          >
            <IconHistory className="w-20 h-20 mr-5" />
            Past
          </Button>
        </Link>
      </div>
      <hr className="md:show fit-w b-grey-300 mt-0 mb-20" />

      {/* Loading indicator */}
      {events?.isLoading && (
        <div className="flex flex-col justify-center fs-20 fw-600 text-center pt-20">
          <FaCircleNotch className="w-25 h-25 mx-auto my-20 fg-primary spin" />
        </div>
      )}

      {/* Workshop list */}
      {!events?.isLoading &&
        eventList.map((el, key) => {
          const sourceTimeZone = el.event_timezone || defaultTimeZone;
          const targetTimeZone = moment.tz.guess();
          const momentSourceTimeStart = moment(`${el.event_date} ${el.event_start}`).tz(
            sourceTimeZone,
            true,
          );
          const momentSourceTimeEnd = moment(`${el.event_date} ${el.event_end}`).tz(
            sourceTimeZone,
            true,
          );
          const momentTargetTimeStart = momentSourceTimeStart.clone().tz(targetTimeZone);
          const momentTargetTimeEnd = momentSourceTimeEnd.clone().tz(targetTimeZone);
          const hasFreeSpace = el.max_no_participants - el.current_no_participants > 0;
          const isToday =
            momentTargetTimeStart.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
          const isInPast = momentTargetTimeStart.isSameOrBefore();
          const isAvailable =
            (tab === 'upcoming' && !el.is_booked && hasFreeSpace) ||
            (tab === 'past' && el.is_booked && el.event_record) ||
            (subscriptionPlanId === 3 && el.event_record);

          return (
            <div key={key} className="flex flex-wrap align-center p-20 my-10 r-15 b-1 b-grey-300">
              <div
                className={cn(
                  'order-0 flex md:flex-col justify-center align-center sm:w-auto md:w-120 h-40 md:h-75 px-15 md:px-0 r-10',
                  isInPast ? 'fit-w' : 'w-6/12',
                  isToday ? 'bg-primary-light fg-primary' : 'bg-grey-50',
                )}
              >
                <p className="md:order-1 flex-0 fs-14 md:fs-20 fw-700">
                  {!isToday ? momentTargetTimeStart.format('MMM D') : 'Today'}
                </p>
                {!isToday && (
                  <p className="fs-14 text-trim fg-grey-500 pl-10 md:pl-0">
                    {momentTargetTimeStart.format('dddd')}
                  </p>
                )}
              </div>
              <div className="order-1 fit-w md:flex-1 md:px-15 py-5">
                <p className="fs-14 fw-600 py-5">{el.title}</p>
                <p className="flex align-center fs-14 fg-grey-500 py-5 lh-1">
                  <img
                    src={el.instructor?.picture}
                    alt=""
                    className="w-20 h-20 r-100 fit-cover bg-grey-100"
                  />
                  <span className="pl-5">by {el.instructor?.name}</span>
                  <span className="px-5">•</span>
                  <IconClock className="w-15 h-15" />
                  <span className="pl-5">
                    {momentTargetTimeStart.format('HH:mm')} - {momentTargetTimeEnd.format('HH:mm')}{' '}
                    {momentTargetTimeStart.format('z')}
                  </span>
                </p>
              </div>
              <div className="order-0 md:order-1 flex align-center fg-grey-500 pl-15 md:px-15 ml-auto md:ml-0">
                {!isInPast && (
                  <>
                    <IconLiveTicket className="w-20 h-20" />
                    <span className="fs-12 md:fs-14 pl-8 text-trim">
                      {subscriptionPlanId === 3 ? 'Free access' : '1 ticket'}
                    </span>
                  </>
                )}
              </div>
              <Button
                secondary
                size="small"
                disablePadding
                className={cn(
                  'order-2 fit-w lg:w-180 mt-20 lg:mt-0',
                  el.is_booked && el.is_cancellable
                    ? 'b-red-700'
                    : isAvailable
                    ? 'b-primary'
                    : 'disabled b-grey-300',
                )}
                onClick={() => {
                  setEventDetails(el);
                  setShowBookEventModal(true);
                }}
              >
                {tab === 'upcoming'
                  ? el.is_booked
                    ? el.is_cancellable
                      ? 'Cancel booking'
                      : 'Booked'
                    : hasFreeSpace
                    ? 'Book now'
                    : 'Sold out'
                  : el.event_record
                  ? 'View now'
                  : 'In progress'}
              </Button>
            </div>
          );
        })}
    </LiveLayout>
  );
};

export default LiveWorkshopsPage;
