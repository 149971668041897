/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import {
  apiMoodsRequest,
  apiScheduleVideoRequest,
  apiRemoveVideoRequest,
  apiVideoActivityRequest,
} from '../Redux/actions/studioVideo';
import { apiVideoCateogryRequest } from '../Redux/actions/exploreVideo';
import MyStudioPage from '../Pages/MyStudio';
import { showErrorToastNoti } from '../Utils/errorToast';
import { apiSettingRequest } from '../Redux/actions/setting';
import {checkIsFreeUser} from "../Utils/subscriptionUtils";

const MyMoodsContainer = () => {
  const dispatch = useDispatch();

  const studioVideo = useSelector((state) => state.studio);
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.setting);
  const { categories } = useSelector((state) => state.explore);
  const [isFreeUser, setIsFreeUser] = useState(true);

  useEffect(() => {
    dispatch(updateCardMenuSelection('my-moods'));
    dispatch(apiVideoCateogryRequest());
    dispatch(apiMoodsRequest());
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  }, []);

  useEffect(() => {
    showErrorToastNoti(studioVideo);
  }, [studioVideo]);

  const onVideoScheduled = (id, scheduledDate) => {
    let data = {
      video_id: id,
      scheduled_date: scheduledDate,
    };
    dispatch(apiScheduleVideoRequest(data));
  };

  const onVideoWatched = (id, watchedTime) => {
    let data = {
      video_id: id,
      duration_watched: Math.round(watchedTime / 60),
    };
    dispatch(apiVideoActivityRequest(data));
  };

  const onDelete = (id, reason) => {
    let data = {
      video_id: id,
      delete_reason: reason,
    };
    dispatch(apiRemoveVideoRequest(data));
  };

  useEffect( () => {
    checkIsFreeUser(profile).then((res) => {
      setIsFreeUser(res);
    })
  }, [profile])

  return (
    <MyStudioPage
      state={studioVideo}
      categories={categories}
      onVideoScheduled={onVideoScheduled}
      onVideoWatched={onVideoWatched}
      onDelete={onDelete}
      actions={['delete']}
      isFreeUser={isFreeUser}
    />
  );
};

export default MyMoodsContainer;
