import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiBalanceRequest() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.get(`/api/v1/live/user_wallet/`, { headers });
}

export const balanceService = {
  apiBalanceRequest,
};
