import * as types from '../constants/setting';
import { mapErrorMessage } from '../../Utils/mapErrorMessage';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  setting: null,
  passwordChangeSuccess: false,
  isFullAccessAlertVisible: false,
};

export default function settingApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // FAQ
    case types.API_REQUEST_SETTING:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_SETTING_SUCCESS:
      // localStorage.setItem("user", JSON.stringify(action.response.data));

      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.response.data,
        },
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_REQUEST_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_AVATAR_CHANGE:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_AVATAR_CHANGE_SUCCESS:
      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.response.data,
        },
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_AVATAR_CHANGE_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_PATCH_SETTING:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_PATCH_SETTING_SUCCESS:
      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.response.data,
        },
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_PATCH_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        passwordChangeSuccess: false,
        error: mapErrorMessage(action),
      };

    case types.API_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        passwordChangeSuccess: true,
        error: null,
      };

    case types.API_PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        isLoading: false,
        passwordChangeSuccess: false,
        error: mapErrorMessage(action),
      };

    case types.UNLOCK_ACCESS_ALERT_SHOW:
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      return {
        ...state,
        isFullAccessAlertVisible: true,
      };
    case types.UNLOCK_ACCESS_ALERT_HIDE:
      return {
        ...state,
        isFullAccessAlertVisible: false,
      };

    case types.API_UPDATE_CALENDAR_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_UPDATE_CALENDAR_SUCCESS:
      toast.success('Your calendar has been updated!');
      return {
        ...state,
        setting: {
          ...state.setting,
          ...action.response.data,
        },
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_UPDATE_CALENDAR_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    case types.API_DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_DELETE_ACCOUNT_SUCCESS:
      toast.success('Your account has been successfully deleted.');
      localStorage.clear();
      window.location.reload();

      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
      };

    case types.API_DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    default:
      return state;
  }
}
