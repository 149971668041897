import React from 'react';
import VirtualInstructorRightBar from './VirtualInstructorRightBar';
import Modal from './Modal';

const RecalibrateModal = ({ isModalVisible, isLoading, handleCancel }) => {
  return (
    <Modal size="xl" className="text-center" visible={isModalVisible} onClose={handleCancel}>
      <VirtualInstructorRightBar visible page="settings" onClose={handleCancel} />
    </Modal>
  );
};

export default RecalibrateModal;
