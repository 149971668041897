/* eslint-disable react-hooks/exhaustive-deps */
import LoginPage from '../Pages/LoginPage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { resetLoginState, apiLoginRequest } from '../Redux/actions/login';
import { useNavigate } from 'react-router-dom';
import { showErrorToastNoti } from '../Utils/errorToast';

const loginSchema = yup.object().shape({
  username: yup.string().email('A valid email is required.').required('This field is required.'),
  password: yup.string().required('This field is required.'),
});

const LoginContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    dispatch(apiLoginRequest(data));
  };

  useEffect(() => {
    // console.log(localStorage.getItem("token"))
    /* if (localStorage.getItem("token")) {
            console.log('regidrect')
            window.location.href = "/dashboard";
        } */
  }, []);

  useEffect(() => {
    if (state.token && state.user) {
      navigate('/dashboard');
    }

    if (state.error) {
      showErrorToastNoti(state);
      dispatch(resetLoginState());
    }
  }, [state]);

  return (
    <LoginPage
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      errors={errors} // client side form errors
      state={state}
    />
  );
};

export default LoginContainer;
