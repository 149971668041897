import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { aboutService } from "../services/about";
import * as types from "../constants/about";
import * as actions from "../actions/about";

// About
function* apiGetAboutRequestWorker(action) {
    try {
        const result = yield call(aboutService.apiGetAboutRequest, action);
        yield put(actions.apiGetAboutRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiGetAboutRequestFailed(err));
    }
}

function* apiGetAboutRequestWatcher() {
    yield takeEvery(types.API_GET_REQUEST_ABOUT, apiGetAboutRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* aboutRootSaga() {
    const sagas = [apiGetAboutRequestWatcher];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
