import * as types from "../constants/virtualInstructor";

import { mapErrorMessage } from "../../Utils/mapErrorMessage";

const INITIAL_STATE = {
    isLoading: false,
    success: false,
    error: null,
    videos: [],
};

export default function virtualInstructorApiReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        // Virtual Instructor
        case types.API_REQUEST_VIRTUAL_INSTRUCTOR:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: null,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: null,
                videos: action.response.data,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: mapErrorMessage(action),
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: null,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: null,
                videos: action.response.data,
            };

        case types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: mapErrorMessage(action),
            };

        default:
            return state;
    }
}
