import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { studioVideoService } from "../services/studioVideo";
import * as types from "../constants/studioVideo";
import * as actions from "../actions/studioVideo";

// Essential
function* apiEssentialRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiEssentialsRequest, action);
        yield put(actions.apiEssentialsRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiEssentialsRequestFailed(err));
    }
}

function* apiEssentialRequestWatcher() {
    yield takeEvery(types.API_REQUEST_ESSENTIALS, apiEssentialRequestWorker);
}

// Program
function* apiProgramRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiProgramRequest, action);
        yield put(actions.apiProgramRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiProgramRequestFailed(err));
    }
}

function* apiProgramRequestWatcher() {
    yield takeEvery(types.API_REQUEST_PROGRAM, apiProgramRequestWorker);
}

// Program
function* apiMoodsRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiMoodsRequest, action);
        yield put(actions.apiMoodsRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiMoodsRequestFailed(err));
    }
}

function* apiMoodsRequestWatcher() {
    yield takeEvery(types.API_REQUEST_MOODS, apiMoodsRequestWorker);
}

// Schedule Video
function* apiScheduleVideoRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiScheduleVideoRequest, action);
        yield put(actions.apiScheduleVideoRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiScheduleVideoRequestFailed(err));
    }
}

function* apiScheduleVideoRequestWatcher() {
    yield takeEvery(types.API_REQUEST_SCHEDULE_VIDEO, apiScheduleVideoRequestWorker);
}

// Remove Video
function* apiRemoveVideoRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiRemoveVideoRequest, action);
        yield put(actions.apiRemoveVideoRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiRemoveVideoRequestFailed(err));
    }
}

function* apiRemoveVideoRequestWatcher() {
    yield takeEvery(types.API_REQUEST_REMOVE_VIDEO, apiRemoveVideoRequestWorker);
}

// Video Level Change
function* apiLevelChangeRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiLevelChangeRequest, action);
        yield put(actions.apiLevelChangeRequestSuccess({ result, action }));
    } catch (err) {
        yield put(actions.apiLevelChangeRequestFailed(err));
    }
}

function* apiLevelChangeRequestWatcher() {
    yield takeEvery(types.API_REQUEST_LEVEL_CHANGE, apiLevelChangeRequestWorker);
}


//
function* apiVideoActivityRequestWorker(action) {
    try {
        const result = yield call(studioVideoService.apiVideoActivityRequest, action);
        yield put(actions.apiVideoActivityRequestSuccess(result));
    } catch (err) {
        yield put(actions.apiVideoActivityRequestFailed(err));
    }
}

function* apiVideoActivityRequestWatcher() {
    yield takeEvery(types.API_REQUEST_VIDEO_ACTIVITY, apiVideoActivityRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* studioRootSaga() {
    const sagas = [
        apiEssentialRequestWatcher,
        apiProgramRequestWatcher,
        apiMoodsRequestWatcher,
        apiScheduleVideoRequestWatcher,
        apiRemoveVideoRequestWatcher,
        apiLevelChangeRequestWatcher,
        apiVideoActivityRequestWatcher
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        )
    );
}
