export const API_REQUEST_SETTING = 'API_REQUEST_SETTING';
export const API_REQUEST_SETTING_SUCCESS = 'API_REQUEST_SETTING_SUCCESS';
export const API_REQUEST_SETTING_FAILED = 'API_REQUEST_SETTING_FAILED';

export const API_AVATAR_CHANGE = 'API_AVATAR_CHANGE';
export const API_AVATAR_CHANGE_SUCCESS = 'API_AVATAR_CHANGE_SUCCESS';
export const API_AVATAR_CHANGE_FAILED = 'API_AVATAR_CHANGE_FAILED';

export const API_PATCH_SETTING = 'API_PATCH_SETTING';
export const API_PATCH_SETTING_SUCCESS = 'API_PATCH_SETTING_SUCCESS';
export const API_PATCH_SETTING_FAILED = 'API_PATCH_SETTING_FAILED';

export const API_PASSWORD_CHANGE_REQUEST = 'API_PASSWORD_CHANGE_REQUEST';
export const API_PASSWORD_CHANGE_SUCCESS = 'API_PASSWORD_CHANGE_SUCCESS';
export const API_PASSWORD_CHANGE_FAILED = 'API_PASSWORD_CHANGE_FAILED';

export const UNLOCK_ACCESS_ALERT_SHOW = 'UNLOCK_ACCESS_ALERT_SHOW';
export const UNLOCK_ACCESS_ALERT_HIDE = 'UNLOCK_ACCESS_ALERT_HIDE';

export const API_UPDATE_CALENDAR_REQUEST = 'API_UPDATE_CALENDAR_REQUEST';
export const API_UPDATE_CALENDAR_SUCCESS = 'API_UPDATE_CALENDAR_SUCCESS';
export const API_UPDATE_CALENDAR_FAILED = 'API_UPDATE_CALENDAR_FAILED';

export const API_DELETE_ACCOUNT_REQUEST = 'API_DELETE_ACCOUNT_REQUEST';
export const API_DELETE_ACCOUNT_SUCCESS = 'API_DELETE_ACCOUNT_SUCCESS';
export const API_DELETE_ACCOUNT_FAILED = 'API_DELETE_ACCOUNT_FAILED';
