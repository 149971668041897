import * as types from "../constants/exploreVideo";

export const apiVideoCateogryRequest = (data) => ({
    type: types.API_REQUEST_EXPLORE_VIDEO_CATEGORY,
    data,
});

export const apiVideoCateogryRequestSuccess = (response) => ({
    type: types.API_REQUEST_EXPLORE_VIDEO_CATEGORY_SUCCESS,
    response,
});

export const apiVideoCateogryRequestFailed = (response) => ({
    type: types.API_REQUEST_EXPLORE_VIDEO_CATEGORY_FAILED,
    response,
});

export const apiBodyPartyRequest = (data) => ({
    type: types.API_REQUEST_BODY_PART,
    data,
});

export const apiBodyPartyRequestSuccess = (response) => ({
    type: types.API_REQUEST_BODY_PART_SUCCESS,
    response,
});

export const apiBodyPartyRequestFailed = (response) => ({
    type: types.API_REQUEST_BODY_PART_FAILED,
    response,
});

export const apiExploreVideoRequest = (data) => ({
    type: types.API_REQUEST_EXPLORE_VIDEO,
    data,
});

export const apiExploreVideoRequestSuccess = (response) => ({
    type: types.API_REQUEST_EXPLORE_VIDEO_SUCCESS,
    response,
});

export const apiExploreVideoRequestFailed = (response) => ({
    type: types.API_REQUEST_EXPLORE_VIDEO_FAILED,
    response,
});

export const apiCombineVideoRequest = (data) => ({
    type: types.API_REQUEST_COMBINE_VIDEO,
    data,
});

export const apiCombineVideoRequestSuccess = (response) => ({
    type: types.API_REQUEST_COMBINE_VIDEO_SUCCESS,
    response,
});

export const apiCombineVideoRequestFailed = (response) => ({
    type: types.API_REQUEST_COMBINE_VIDEO_FAILED,
    response,
});
