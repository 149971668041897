import * as types from '../constants/topCard';

const INITIAL_STATE = {
    menu: null
};

export default function updateCardMenuSelectionReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.UPDATE_CARD_MENU_SELECTION:
            return {
                menu: action.menu
            }

        default:
            return state;
    }
}
