import * as types from '../constants/coupon';

import { mapErrorMessage } from '../../Utils/mapErrorMessage';

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  coupon: null,
};

export default function couponApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.API_REQUEST_COUPON:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_REQUEST_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        coupon: action.response.data,
      };

    case types.API_REQUEST_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        coupon: null,
        error: mapErrorMessage(action),
      };

    case types.API_COUPON_RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
}
