import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiVideoCategoryRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/video_categories/`, { headers });
}

function apiBodyPartyRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  // return axios.get(`/api/v1/videos/body_parts/`, { headers });
  return axios.get(`/api/v1/videos/body_parts/?category=${action.data.category}`, { headers });
}

function apiExploreVideoRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  let data = action.data;
  let config = {
    headers: headers,
    params: data,
  };

  return axios.get(`/api/v1/videos/explore_videos/`, config);
}

function apiCombineVideoRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.post(`/api/v1/videos/combine_videos/`, action.data, { headers });
}

export const exploreVideoService = {
  apiVideoCategoryRequest,
  apiBodyPartyRequest,
  apiExploreVideoRequest,
  apiCombineVideoRequest,
};
