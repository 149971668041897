import cn from 'classnames';
import React from 'react';

export const FooterWrapper = ({ disableBackground = false, className, children, ...props }) => {
  return (
    <>
      <div
        className={cn(
          'md:show flex-0 flex py-10 md:p-0 md:h-75 justify-center align-center',
          !disableBackground && 'bg-grey-50 bt-1 b-grey-200',
          className,
        )}
        {...props}
      >
        {children}
      </div>
      <div className={cn('md:hide p-20', className)}>{children}</div>
    </>
  );
};

export const Button = ({
  className,
  size,
  type = 'button',
  primary = false,
  secondary = false,
  red = false,
  grey = false,
  landing = false,
  disablePadding = false,
  disableHoverColor = false,
  children,
  ...props
}) => {
  const cssSize = (size) => {
    switch (size) {
      case 'none':
        return false;
      case 'mini':
        return cn('fs-14 h-40', !disablePadding && 'px-30 md:px-45');
      case 'small':
        return cn('fs-14 h-40 md:h-45', !disablePadding && 'px-30 md:px-45');
      default:
        return cn('fs-14 lg:fs-18 h-50 md:h-55', !disablePadding && 'px-40 md:px-65');
    }
  };

  return (
    <button
      type={type}
      className={cn(
        'fw-600 lh-1 inline-flex justify-center align-center r-40 hover:fg-white hover:bg-black hover:b-black active:fg-white active:bg-black active:b-black',
        cssSize(size),
        primary && 'fg-white bg-primary',
        red && 'fg-white bg-red-700',
        grey && 'fg-grey-700 bg-grey-200',
        secondary && 'b-1',
        landing && 'b-1 b-white',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const Input = React.forwardRef(
  (
    {
      type = 'text',
      disableFixedWidth = false,
      disableFiexedHeight = false,
      disableMargin = false,
      hasError,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <input
        ref={ref}
        type={type}
        className={cn(
          className,
          'fs-16 fit-w r-10 md:r-15 px-15 md:px-25 b-1',
          !disableFixedWidth && 'md:w-550',
          !disableFiexedHeight && 'h-40 md:h-55',
          !disableMargin && 'my-5',
          hasError ? 'b-red-500' : 'b-grey-400 focus:b-primary',
        )}
        {...props}
      />
    );
  },
);

export const InputExtra = React.forwardRef(
  (
    {
      Icon,
      Button,
      type = 'text',
      disablePadding = false,
      disableFiexedHeight = false,
      disableMargin = false,
      hasError,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <label
        className={cn(
          className,
          'flex r-10 md:r-15 b-1',
          !disablePadding && 'px-15 md:px-20',
          !disableMargin && 'my-5',
          !disableFiexedHeight && 'h-50 md:h-55',
          hasError ? 'b-red-500' : 'b-grey-400 focus:b-primary',
        )}
      >
        {Icon && <Icon className="w-25 my-auto mr-5 md:mr-10 fg-grey-400" />}
        <input ref={ref} type={type} className={cn('flex-1 fs-16 fit')} {...props} />
        {Button}
      </label>
    );
  },
);

export const Error = ({ text, disableFixedWidth = false, className }) => {
  return (
    text && (
      <p
        className={cn(
          'fs-14 fw-600 fit-w fg-red-500',
          !disableFixedWidth && 'md:w-550 mx-auto text-left',
          className,
        )}
      >
        {text}
      </p>
    )
  );
};
