/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Choice = ({ id, index, choiceId, text, isSelected, onClick }) => {
  const onChoiceClicked = (e) => {
    e.stopPropagation();
    onClick(choiceId, id);
  };

  return (
    <div
      className={cn(
        'flex w-50/100 py-15 md:py-10 align-center text-center md:text-left bt-1 b-grey-300 sub-choice-item',
        index % 2 ? 'bl-1' : null,
        isSelected ? 'bg-primary-light selected-sub-choice' : '',
      )}
      onClick={onChoiceClicked}
    >
      <div className="w-15" />
      <span className="flex-1 text-trim pr-5 md:pr-15">{text}</span>
      <div className={cn('flex w-25 h-25 mr-15 r-100', isSelected ? 'bg-primary' : null)}>
        <FaCheck className="w-15 h-15 m-auto fg-white" />
      </div>
    </div>
  );
};

const Choice2 = ({ id, index, choiceId, text, isSelected, onClick }) => {
  const onChoiceClicked = (e) => {
    e.stopPropagation();
    onClick(choiceId, id);
  };

  return (
    <div
      className={cn(
        'flex py-15 md:py-10 align-center text-center md:text-left sub-choice-item hover:bg-primary-light',
        isSelected ? 'selected-sub-choice' : '',
      )}
      onClick={onChoiceClicked}
    >
      <div className="w-25" />
      <span className="flex-1 text-trim pr-5 md:pr-15">{text}</span>

      <div className={cn('flex w-25 h-25 mr-15 r-100', isSelected && 'bg-primary')}>
        {isSelected && <FaCheck className="w-15 h-15 m-auto fg-white" />}
      </div>
    </div>
  );
};

const SubChoice = ({
  label,
  menuTitle,
  choices,
  isSelectedChoice,
  selectedSubChoices,
  choiceId,
  onSelected,
  expandId,
  onClickExpand,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    setMenuVisible(choiceId === expandId);
  }, [expandId]);

  const onMenuButtonClicked = (e) => {
    e.stopPropagation();
    onClickExpand(choiceId);
  };

  useEffect(() => {}, [selectedSubChoices]);

  const selectedSubChoiceText = choices.find((el) => el.id === selectedSubChoices?.[0])?.text;

  return (
    <>
      <div
        className={cn(
          'flex flex-nowrap md:w-40/100 align-center menu-button',
          isSelectedChoice ? 'fg-primary' : 'fg-grey-500',
        )}
        onClick={onMenuButtonClicked}
      >
        <div className="md:show flex-0 w-1 fit-h bg-grey-300"></div>
        <div className="flex fit-w align-center px-15 md:px-20">
          <span className="md:show flex-1 text-trim pr-5 menu-label">
            {selectedSubChoiceText || label}
          </span>
          {choiceId === expandId ? <FaChevronUp /> : <FaChevronDown />}
          {/* <ExpandMoreIcon /> */}
        </div>
      </div>
      <div
        className={cn(
          'fit-w flex subchoice-container',
          selectedSubChoices?.length ? 'selected' : '',
        )}
      >
        {/* Mobile menu */}
        <div className={cn('md:hide fit-w bg-white subchoice-menu', menuVisible ? null : 'hide')}>
          {/* <div className="menu-title">{menuTitle}:</div> */}
          <div
            className={cn(
              'menu-title fs-12 md:fs-14 fw-400 py-10 fg-grey-500 text-center md:text-left',
              isSelectedChoice ? 'b-primary' : 'b-grey-300',
            )}
          >
            Selection is optional
          </div>
          <div className="flex flex-wrap menu-options">
            {choices.map((choice, index) => (
              <Choice
                key={index}
                {...choice}
                index={index}
                choiceId={choiceId}
                isSelected={selectedSubChoices?.includes(choice.id)}
                onClick={onSelected}
              />
            ))}
            {choices?.length % 2 !== 0 && <div className="w-50/100 bt-1 bl-1 b-grey-300" />}
          </div>
        </div>

        {/* Desktop menu */}
        <div
          className={cn(
            'md:show absolute z-1 top-0 right-0 w-200 r-15 shadow-4 b-1 b-grey-300 bg-white subchoice-menu',
            menuVisible ? null : 'hide',
          )}
          style={{ top: '-10px', right: '-1px', width: 'max-content' }}
        >
          {/* <div className="menu-title">{menuTitle}:</div> */}
          <div
            className={cn(
              'menu-title fs-12 md:fs-14 fw-400 pt-20 pb-10 fg-grey-500 px-15 text-center md:text-left',
            )}
          >
            Selection is optional
          </div>
          <div
            className="menu-options of-y-overlay modal-scrollstyle mb-10"
            style={{ maxHeight: '230px' }}
          >
            {choices.map((choice, index) => (
              <Choice2
                key={index}
                {...choice}
                index={index}
                choiceId={choiceId}
                isSelected={selectedSubChoices?.includes(choice.id)}
                onClick={onSelected}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubChoice;
