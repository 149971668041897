import { ReactComponent as Logo } from '../Assets/logo.svg';

export default function Offline() {
  return (
    <div className="flex-1 flex flex-col align-center justify-center fs-14 md:fs-18">
      <Logo className="h-50 mx-auto mb-30" />
      <p className="fs-24 md:fs-28 fw-700 mb-5">You are currently offline</p>
      <p className="fg-grey-600 fw-500">Please check your internet connection.</p>
      <p></p>
    </div>
  );
}
