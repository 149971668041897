import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiProductsRequest() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.get(`/api/v1/live/event_types/`, { headers });
}

function apiBuyCreditsRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };

  return axios.post(`/api/v1/live/buy_credit/`, action.data, { headers });
}

export const orderService = {
  apiProductsRequest,
  apiBuyCreditsRequest,
};
