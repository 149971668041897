import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiGetMyHabits() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  return axios.get(`/api/v1/videos/my_habits/`, { headers });
}

export const myHabitService = {
  apiGetMyHabits,
};
