import * as types from "../constants/virtualInstructor";

// Virtual Instructor
export const apiVirtualInstructorRequest = (data) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR,
    data,
});

export const apiVirtualInstructorRequestSuccess = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_SUCCESS,
    response,
});

export const apiVirtualInstructorRequestFailed = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_FAILED,
    response,
});

// Recalibrate
export const apiVirtualInstructorRecalibrateRequest = (data) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE,
    data,
});

export const apiVirtualInstructorRecalibrateRequestSuccess = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_SUCCESS,
    response,
});

export const apiVirtualInstructorRecalibrateRequestFailed = (response) => ({
    type: types.API_REQUEST_VIRTUAL_INSTRUCTOR_RECALIBRATE_FAILED,
    response,
});
