import axios from 'axios';
import { getToken } from '../../Utils/getToken';

function apiContactUsRequest(action) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${getToken()}`,
  };
  const formData = new FormData();
  // Update the formData object
  for (const [key, val] of Object.entries(action.data)) {
    formData.append(key, val);
  }

  return axios.post(`/api/v1/contactusmessage/`, formData, { headers });
}

export const contactusService = {
  apiContactUsRequest,
};
