/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiQuestionRequest,
  apiVirtualInstructorQuestionnaireRequest,
  apiVirtualInstructorRecalibrateQuestionaireRequest,
  apiSessionKeyRequest,
  apiSubmitAnswerRequest,
} from '../../Redux/actions/signup';
import { unlockAccessAlertShow } from '../../Redux/actions/setting';
import _ from 'lodash';
import VirtualInstructor from '../VirtualInstructor';
import { toast } from 'react-toastify';
import {
  apiVirtualInstructorRequest,
  apiVirtualInstructorRecalibrateRequest,
} from '../../Redux/actions/virtualInstructor';
import { showErrorToastNoti } from '../../Utils/errorToast';

const VirtualInstructorRightBar = ({ children, ...props }) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.signup);
  const viState = useSelector((state) => state.virtualInstructor);
  // const auth = useSelector((state) => state.auth);
  // const profile = useSelector((state) => state.setting);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [totalSelectedIds, setTotalSelectedIds] = useState([]);
  const [history, setHistory] = useState([]);
  const [hasAnswers, setHasAnswers] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [expandId, setExpandId] = useState(-1);

  const { page, visible, onClose, handleRestart } = props;

  useEffect(() => {
    showErrorToastNoti(state);
    if (state.session_key === null) {
      dispatch(apiSessionKeyRequest());
    }
  }, [state.session_key]);

  useEffect(() => {
    if (page === 'settings') {
      // dispatch(
      //   apiQuestionInitRequest({
      //     text: 'Recalibrate your program',
      //     is_init: true,
      //     sub_text:
      //       'Doing so will delete and regenerate all the content in My Program. Are you sure you want to proceed?',
      //   }),
      // );
      dispatch(apiVirtualInstructorRecalibrateQuestionaireRequest());
    } else {
      dispatch(apiVirtualInstructorQuestionnaireRequest());
    }
  }, []);

  useEffect(() => {
    showErrorToastNoti(state);
    if (!state.isLoading && state.success && !state.currentQuestion) {
      dispatch(apiQuestionRequest(state.questionnaire?.head));
    }
  }, [state.questionnaire]);

  useEffect(() => {
    showErrorToastNoti(state);
    setSelectedChoices([]);
  }, [state.currentQuestion]);

  useEffect(() => {
    if (state.answer && state.success && !state.isLoading && hasAnswers) {
      if (isLast) {
        let selectedIds = [...totalSelectedIds, state.answer?.id];
        submitTotal(selectedIds);
        setIsLast(false);
        setTotalSelectedIds([]);
        setHistory([]);

        if (page === 'settings') {
          toast.success('You program has been recalibrated!');
          onClose();
        }
      } else {
        if (totalSelectedIds.length < history.length) {
          setTotalSelectedIds([...totalSelectedIds, state.answer?.id]);
        }
      }
    }
  }, [state.answer]);

  useEffect(() => {
    if (hasAnswers) {
      showErrorToastNoti(viState);
    }

    if (
      !viState.isLoading &&
      viState.error &&
      viState.error.message.Error === 'Recalibration of program is only for paid users.'
    ) {
      dispatch(unlockAccessAlertShow());
    } else if (!viState.isLoading && hasAnswers) {
      if (viState.success) {
        // toast.success('Your workout is ready.');
        // dispatch(apiMoodsRequest());
        // if (page === 'settings') navigate('/my-program');
        // else navigate('/my-moods');
      }

      if (page === 'settings') {
        // dispatch(
        //   apiQuestionInitRequest({
        //     text: 'Recalibrate your program',
        //     is_init: true,
        //     sub_text:
        //       'Doing so will delete and regenerate all the content in My Program. Are you sure you want to proceed?',
        //   }),
        // );
        dispatch(apiVirtualInstructorRecalibrateQuestionaireRequest());
      } else {
        dispatch(apiVirtualInstructorQuestionnaireRequest());
      }

      setHasAnswers(false);
    }
  }, [viState]);

  const isChoiceSelected = (choiceId) => {
    const result = selectedChoices.find((element) => element.choice === choiceId);
    return !!result;
  };

  const onChoiceClicked = (id) => {
    // reset the selected sub choices
    // update the choices
    setSelectedChoices((oldArray) => {
      if (state.currentQuestion.question_type.name.indexOf('Single Choice') === -1) {
        const arrayCopy = JSON.parse(JSON.stringify(oldArray));

        if (isChoiceSelected(id)) {
          return arrayCopy.filter((element) => element.choice !== id);
        } else {
          const newChoiceObject = {
            choice: id,
            sub_choices: [],
          };

          return [...arrayCopy, newChoiceObject];
        }
      } else {
        const newChoiceObject = {
          choice: id,
          sub_choices: [],
        };

        return [newChoiceObject];
      }
    });
  };

  const onSubChoicesClicked = (choiceId, subChoiceId) => {
    setExpandId(-1);
    if (!isChoiceSelected(choiceId)) {
      setSelectedChoices((oldArray) => {
        const arrayCopy = JSON.parse(JSON.stringify(oldArray));

        const newChoiceObject = {
          choice: choiceId,
          sub_choices: [subChoiceId],
        };

        return [...arrayCopy, newChoiceObject];
      });
    } else {
      setSelectedChoices((oldArray) => {
        const arrayCopy = JSON.parse(JSON.stringify(oldArray));

        return arrayCopy.map((obj) => {
          // find the choice
          if (obj.choice === choiceId) {
            // check if sub choice is already selected
            if (obj.sub_choices.includes(subChoiceId)) {
              // remove subchoice selection
              obj.sub_choices = obj.sub_choices.filter((element) => element !== subChoiceId);
            } else {
              // select the subchoice
              obj.sub_choices = [...obj.sub_choices, subChoiceId];
            }
          }

          return obj;
        });
      });
    }
  };

  const onAnswerSubmit = () => {
    /* if (auth.user.active_membership && auth.user.active_membership.plan.id === 1) {
            dispatch(unlockAccessAlertShow());
            return;
        } */

    // save answer
    const requestData = {
      question: state.currentQuestion.id,
      session_key: state.session_key,
      choices: selectedChoices,
      require_token: true,
    };

    let selections = _.map(selectedChoices, (it) => {
      return parseInt(it.choice);
    });

    if (selections.length === 0) {
      // if (page === 'settings' && state.currentQuestion.is_init) {
      //   dispatch(apiVirtualInstructorRecalibrateQuestionaireRequest());
      //   return;
      // }
      toast.warning('Please select answers');
      return;
    }
    setHasAnswers(true);
    if (state.currentQuestion?.is_last) {
      setIsLast(true);
    }

    if (state.currentQuestion?.id && !history.includes(state.currentQuestion.id)) {
      setHistory([...history, state.currentQuestion?.id]);
    }

    dispatch(apiSubmitAnswerRequest(requestData));
  };

  const submitTotal = (selectedIds) => {
    if (selectedIds.length === 0) {
      toast.error('There are no available videos.');
    }
    if (page === 'settings') {
      let data = {
        answer_ids: selectedIds,
        delete_reason: 'not what I need',
      };
      dispatch(apiVirtualInstructorRecalibrateRequest(data));
    } else {
      let data = {
        answer_ids: selectedIds,
      };
      dispatch(apiVirtualInstructorRequest(data));
    }
  };
  const onClickExpand = (id) => {
    if (id === expandId) {
      setExpandId(-1);
    } else {
      setExpandId(id);
    }
  };

  const onClickBack = () => {
    if (!history.length) {
      handleRestart();
    } else {
      if (!state.isLoading && state.success) {
        const lastQuestionId = history.pop();
        const lastSelectedId = totalSelectedIds.pop();

        setHistory((state) => state.filter((el) => el !== lastQuestionId));
        setTotalSelectedIds((state) => state.filter((el) => el !== lastSelectedId));

        dispatch(apiQuestionRequest(lastQuestionId));
      }
    }
  };

  return (
    visible && (
      <VirtualInstructor
        page={page}
        fromVirtualInstructor={false}
        question={state.currentQuestion}
        progress={state.currentQuestion?.progress}
        isLoading={state.isLoading || viState.isLoading}
        // isPossible={profile.setting && profile.setting.active_membership && profile.setting.active_membership.plan.id !== 1}
        isPossible={true}
        selectedChoices={selectedChoices}
        onChoiceClicked={onChoiceClicked}
        onSubChoicesClicked={onSubChoicesClicked}
        expandId={expandId}
        onAnswerSubmit={onAnswerSubmit}
        onClickExpand={onClickExpand}
        onClickBack={onClickBack}
      />
    )
  );
};

export default VirtualInstructorRightBar;
