import { put, call, all, spawn, takeEvery } from "redux-saga/effects";
import { loginService } from "./../services/login";
import * as types from "./../constants/login";
import * as actions from "./../actions/login";

// LOGIN
function* apiLoginRequestWorker(action) {
  try {
    const result = yield call(loginService.apiLoginRequest, action);
    yield put(actions.apiLoginRequestSuccess(result, action));
  } catch (err) {
    yield put(actions.apiLoginRequestFailed(err, action));
  }
}

function* apiLoginRequestWatcher() {
  yield takeEvery(types.API_REQUEST_LOGIN, apiLoginRequestWorker);
}

// LOGOUT
function* apiLogoutRequestWorker(action) {
  try {
    const result = yield call(loginService.apiLogoutRequest, action);
    yield put(actions.apiLogoutRequestSuccess(result, action));
  } catch (err) {
    yield put(actions.apiLogoutRequestFailed(err, action));
  }
}

function* apiLogoutRequestWatcher() {
  yield takeEvery(types.API_REQUEST_LOGOUT, apiLogoutRequestWorker);
}

// SIGN UP
function* apiCreateAccountRequestWorker(action) {
  try {
      const result = yield call(loginService.apiCreateAccountRequest, action);
      yield put(actions.apiSubmitCreateAccountRequestSuccess(result, action));
  } catch (err) {
      yield put(actions.apiSubmitCreateAccountRequestFailed(err, action));
  }
}

function* apiCreateAccountRequestWatcher() {
  yield takeEvery(types.API_REQUEST_CREATE_ACCOUNT, apiCreateAccountRequestWorker);
}

// PASSWORD RESET
function* apiPasswordResetRequestWorker(action) {
  try {
    const result = yield call(loginService.apiPasswordResetRequest, action);
    yield put(actions.apiPasswordResetRequestSuccess(result, action));
  } catch (err) {
    yield put(actions.apiPasswordResetRequestFailed(err, action));
  }
}

function* apiPasswordResetRequestWatcher() {
  yield takeEvery(
    types.API_REQUEST_PASSWORD_RESET,
    apiPasswordResetRequestWorker
  );
}

// PASSWORD CONFIRM
function* apiPasswordConfirmRequestWorker(action) {
  try {
    const result = yield call(loginService.apiPasswordConfirmRequest, action);
    yield put(actions.apiPasswordConfirmRequestSuccess(result, action));
  } catch (err) {
    yield put(actions.apiPasswordConfirmRequestFailed(err, action));
  }
}

function* apiPasswordConfirmRequestWatcher() {
  yield takeEvery(
    types.API_REQUEST_PASSWORD_CONFIRM,
    apiPasswordConfirmRequestWorker
  );
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* loginRootSaga() {
  const sagas = [
    apiLoginRequestWatcher,
    apiLogoutRequestWatcher,
    apiCreateAccountRequestWatcher,
    apiPasswordResetRequestWatcher,
    apiPasswordConfirmRequestWatcher,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
