import cn from 'classnames';
import { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaCircleNotch } from 'react-icons/fa';
import ContactUsModal from '../Components/ContactUsModal';
import FaqLayout from '../Layouts/FaqLayout';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="my-15 r-10 b-1 b-grey-300 hover:b-primary accordion-item">
      <div
        className="flex align-center p-20 fs-14 md:fs-16 fw-600 cursor-pointer accordion-title"
        onClick={() => setIsActive(!isActive)}
      >
        <div className={cn('flex-1 pr-20', isActive && 'fg-primary')}>{title}</div>
        <div>{isActive ? <FaChevronUp /> : <FaChevronDown />}</div>
      </div>
      {isActive && (
        <div
          className="fs-12 md:fs-14 px-20 pb-20 fg-grey-600 prose accordion-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
    </div>
  );
};

const FaqPage = ({ ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { state } = props;

  return (
    <>
      <FaqLayout>
        <div className="py-10 page-wrapper page-about-methods">
          {state.isLoading && <FaCircleNotch className="w-25 h-25 my-50 mx-auto spin fg-primary" />}
          <div className="faq-accordion">
            {state?.faqs
              .sort((a, b) => a.id - b.id)
              .map((faq) => (
                <Accordion title={faq.question} content={faq.answer} key={faq.id} />
              ))}
          </div>
        </div>
        <p className="fs-14 md:fs-16 fw-600 text-center">
          <span>Still have a question? </span>
          <span
            className="fg-primary underline hover:fg-black active:fg-black cursor-pointer"
            onClick={() => setIsModalVisible(true)}
          >
            Contact us
          </span>
        </p>
      </FaqLayout>
      <ContactUsModal
        isModalVisible={isModalVisible}
        handleOk={() => setIsModalVisible(false)}
        handleCancel={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default FaqPage;
