import * as types from "../constants/myHabit";

import { mapErrorMessage } from "../../Utils/mapErrorMessage";

const INITIAL_STATE = {
    isLoading: false,
    success: false,
    error: null,
    habbits: null
};

export default function myHabitApiReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.API_REQUEST_MY_HABIT:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: null,
            };

        case types.API_REQUEST_MY_HABIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                error: null,
                habbits: action.response.data,
            };

        case types.API_REQUEST_MY_HABIT_FAILED:
            return {
                ...state,
                isLoading: false,
                success: false,
                error: mapErrorMessage(action),
            };

        default:
            return state;
    }
}
