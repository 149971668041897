/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AbstractPricingPlan from '../AbstractPricingPlan';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FaCheck, FaTimes } from 'react-icons/fa';
import cn from 'classnames';
import { Button } from '../FormElements';

const Circles = ({ isActive }) => {
  return isActive ? (
    <div className="w-25 h-25 r-100 flex bg-primary">
      <FaCheck className="w-15 fg-white m-auto" />
    </div>
  ) : (
    <div className="w-25 h-25 r-100 flex bg-grey-300">
      <FaTimes className="w-15 fg-white m-auto" />
    </div>
  );
};

const PlanFeature = ({ index, feature, fetauredPlan, isActive, membershipType }) => {
  let extraText = '';

  if ([4].includes(feature.id) && membershipType === 'Free') {
    extraText = '2 workouts';
  }

  if ([5].includes(feature.id) && membershipType === 'Free') {
    extraText = '1 workout';
  }

  return (
    <>
      {/* Desktop */}
      <div
        className={cn(
          'md:show fs-14 flex h-50 align-center md:justify-center plan-feature',
          fetauredPlan ? 'r-15 fs-18 fw-700' : 'px-15',
          index % '2' === 0 ? 'bg-grey-100' : null,
        )}
      >
        {extraText ? extraText : <Circles isActive={isActive} />}
        <div className="md:hide flex-1 text-trim feature-text">{feature.content}</div>
      </div>

      {/* Mobile */}
      <div className={cn('md:hide flex align-start py-5 fs-14 text-left plan-feature')}>
        <FaCheck className="w-10 mt-4 fg-primary" />
        <div className="flex-1 pl-15 feature-text">
          {feature.content}
          {extraText ? ` (${extraText})` : ''}
        </div>
      </div>
    </>
  );
};

const SubscriptionPlan = ({ currentPlan, ...props }) => {
  const {
    id,
    plan_name,
    membership_type,
    duration,
    features,
    featuresAll,
    fetauredPlan,
    monthly_price,
    total_price,
    promo_codes_enabled,
    // terms,
    fromDashboard,
    onSelected,
    // onSubmitCoupon,
  } = props;

  const couponState = useSelector((state) => state.coupon);
  // const [, setIsModalVisible] = useState(false);
  const [isPromoModalVisible, setIsPromoModalVisible] = useState(false);
  const [isAccepted] = useState(true);

  currentPlan = currentPlan || { plan: { id: 0 } };

  useEffect(() => {
    if (!couponState.isLoading && couponState.success && isPromoModalVisible) {
      setIsPromoModalVisible(false);
      toast.success('Promo code is valid!');
    }
    if (!couponState.isLoading && !couponState.success && isPromoModalVisible) {
      toast.error('Promo code is invalid!');
    }
  }, [couponState]);

  const body = (
    <>
      <div className={cn('md:show plan-features px-15', fetauredPlan ? null : 'md:px-0')}>
        {featuresAll.map((feature, index) => {
          const isActive = features.find((f) => f.id === feature.id);
          return (
            <PlanFeature
              key={index}
              index={index}
              isActive={isActive}
              feature={feature}
              fetauredPlan={fetauredPlan}
              membershipType={membership_type}
            />
          );
        })}
      </div>
      <div className={cn('md:hide plan-features px-15', fetauredPlan ? null : 'md:px-0')}>
        {featuresAll
          .sort((a, b) => a.id - b.id)
          .map((feature, index) => {
            const isActive =
              features.find((f) => f.id === feature.id) || [4, 5].includes(feature.id);
            return (
              isActive && (
                <PlanFeature
                  key={index}
                  index={index}
                  feature={feature}
                  fetauredPlan={fetauredPlan}
                  membershipType={membership_type}
                />
              )
            );
          })}
      </div>
    </>
  );

  const onPlanSelected = () => {
    if (isAccepted || fromDashboard) {
      onSelected(id, membership_type);
    } else {
      toast.warning('Please accept the terms & conditions.');
    }
  };

  // const onAcceptChange = () => {
  //   setIsAccepted(!isAccepted);
  // };

  const Footer = ({ ...props }) => {
    const { onPlanSelected } = props;
    let planText = '';

    if (currentPlan.plan.id === id) {
      planText = 'Current Plan';
    } else {
      if (currentPlan.plan.id === 0) {
        planText = 'Select Plan';
        // if (membership_type === 'Free') {
        //   planText = 'Check it out for free';
        // } else {
        //   planText = 'Select Plan';
        // }
      } else {
        planText = 'Upgrade Plan';
        switch (currentPlan.plan.id) {
          case 2:
            if (id === 1) planText = 'Downgrade Plan';
            break;
          case 3:
            planText = 'Downgrade Plan';
            break;
          default:
            break;
        }
      }
    }

    return (
      <>
        <div>
          {/* {fromDashboard !== true ? (
            <div className="terms-and-condition-text">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isAccepted}
                onChange={onAcceptChange}
              />
              <p>
                By signing this I accept all{' '}
                <Link
                  to=""
                  onClick={(event) => {
                    event.preventDefault();
                    setIsModalVisible(true);
                  }}
                >
                  Terms & Conditions
                </Link>
              </p>
            </div>
          ) : (
            <></>
          )} */}

          <Button
            disablePadding
            className={cn('fit-w my-10', fetauredPlan ? 'bg-primary fg-white' : 'b-1 b-grey-400')}
            onClick={onPlanSelected}
            disabled={currentPlan.plan.id === id}
          >
            {planText}
          </Button>

          {/* <div className="h-75 flex flex-col justify-center plan-pricing">
            <p className="monthly-cost">
              <span className="fs-26">
                {membership_type === 'Paid' ? '$' + monthly_price : 'Free'}
              </span>
              {membership_type === 'Paid' && <span className="fs-14">/month</span>}
            </p>
            {membership_type === 'Paid' && (
              <p className="fs-14 fw-500 fg-grey-400">
                ${total_price} {duration === 12 ? 'yearly' : duration === 3 ? 'quarterly' : ''}
              </p>
            )}
          </div> */}

          {currentPlan.plan.id === id && currentPlan.plan.id !== 1 && currentPlan.end_date && (
            <p className="fs-14 feature-text d-flex align-items-center justify-content-between">
              <span>{currentPlan.canceled ? 'Expiry date (canceled)' : 'Next billing date'}: </span>
              <span>{moment(currentPlan.end_date).format('DD/MM/YYYY')}</span>
            </p>
          )}
        </div>
      </>
    );
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const htmlDecode = (input) => {
  //   var e = document.createElement('div');
  //   e.innerHTML = input;
  //   return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  // };

  const onUsePromoClick = () => {
    setIsPromoModalVisible(true);
  };

  // const handlePromoCancel = () => {
  //   setIsPromoModalVisible(false);
  // };

  // const onSubmitCouponClick = (val) => {
  //   onSubmitCoupon(val);
  // };

  return (
    <>
      <AbstractPricingPlan
        title={plan_name}
        duration={duration}
        body={body}
        footer={<Footer {...props} onPlanSelected={onPlanSelected} />}
        membershipType={membership_type}
        monthlyPrice={monthly_price}
        fetauredPlan={fetauredPlan}
        totalPrice={total_price}
        displayRedeemPromoCode={promo_codes_enabled}
        className={
          currentPlan.plan.id === id
            ? 'current-plan subscription-plan-container'
            : 'subscription-plan-container'
        }
        isCurrent={currentPlan.plan.id === id}
        onUsePromoClick={onUsePromoClick}
      />
      {/* <Modal
                className="terms-modal"
                title="Terms & Conditions"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
            >
                <div dangerouslySetInnerHTML={{ __html: terms }} />
            </Modal> */}
      {/* <CheckPromoCodeModal
                isModalVisible={isPromoModalVisible}
                handleOk={onSubmitCouponClick}
                handleCancel={handlePromoCancel}
                isLoading={couponState.isLoading}
            ></CheckPromoCodeModal> */}
    </>
  );
};

export default SubscriptionPlan;
