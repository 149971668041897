import * as types from '../constants/subscription';

export const apiSubscriptionRequest = (data) => ({
  type: types.API_REQUEST_SUBSCRIPTION,
  data,
});

export const apiSubscriptionRequestSuccess = (response) => ({
  type: types.API_REQUEST_SUBSCRIPTION_SUCCESS,
  response,
});

export const apiSubscriptionRequestFailed = (response) => ({
  type: types.API_REQUEST_SUBSCRIPTION_FAILED,
  response,
});

export const apiChangeSubscriptionRequest = (data) => ({
  type: types.API_REQUEST_CHANGE_SUBSCRIPTION,
  data,
});

export const apiChangeSubscriptionRequestSuccess = (response) => ({
  type: types.API_REQUEST_CHANGE_SUBSCRIPTION_SUCCESS,
  response,
});

export const apiChangeSubscriptionRequestFailed = (response) => ({
  type: types.API_REQUEST_CHANGE_SUBSCRIPTION_FAILED,
  response,
});

export const apiCheckoutSubscriptionRequest = (data) => ({
  type: types.API_REQUEST_CHECKOUT_SUBSCRIPTION,
  data,
});

export const apiCheckoutSubscriptionRequestSuccess = (response) => ({
  type: types.API_REQUEST_CHECKOUT_SUBSCRIPTION_SUCCESS,
  response,
});

export const apiCheckoutSubscriptionRequestFailed = (response) => ({
  type: types.API_REQUEST_CHECKOUT_SUBSCRIPTION_FAILED,
  response,
});

export const apiTermsNConditionsRequest = (data) => ({
  type: types.API_REQUEST_TERMS_CONDITIONS,
  data,
});

export const apiTermsNConditionsRequestSuccess = (response) => ({
  type: types.API_REQUEST_TERMS_CONDITIONS_SUCCESS,
  response,
});

export const apiTermsNConditionsRequestFailed = (response) => ({
  type: types.API_REQUEST_TERMS_CONDITIONS_FAILED,
  response,
});

export const apiPaymentMethodRequest = (data) => ({
  type: types.API_REQUEST_PAYMENT_METHOD,
  data,
});

export const apiPaymentMethodRequestSuccess = (response) => ({
  type: types.API_REQUEST_PAYMENT_METHOD_SUCCESS,
  response,
});

export const apiPaymentMethodRequestFailed = (response) => ({
  type: types.API_REQUEST_PAYMENT_METHOD_FAILED,
  response,
});

export const apiUpdatePaymentMethodRequest = (data) => ({
  type: types.API_REQUEST_UPDATE_PAYMENT_METHOD,
  data,
});

export const apiUpdatePaymentMethodRequestSuccess = (response) => ({
  type: types.API_REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS,
  response,
});

export const apiUpdatePaymentMethodRequestFailed = (response) => ({
  type: types.API_REQUEST_UPDATE_PAYMENT_METHOD_FAILED,
  response,
});

export const apiDeletePaymentMethodRequest = (data) => ({
  type: types.API_REQUEST_DELETE_PAYMENT_METHOD,
  data,
});

export const apiDeletePaymentMethodRequestSuccess = (response) => ({
  type: types.API_REQUEST_DELETE_PAYMENT_METHOD_SUCCESS,
  response,
});

export const apiDeletePaymentMethodRequestFailed = (response) => ({
  type: types.API_REQUEST_DELETE_PAYMENT_METHOD_FAILED,
  response,
});
