import * as types from "../constants/balance";
import { mapErrorMessage } from "../../Utils/mapErrorMessage";
// import { toast } from "react-toastify";

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  balance: { id: null, balance: [] },
};

export default function balanceApiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.API_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case types.API_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        balance: action.response.data,
      };

    case types.API_BALANCE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: mapErrorMessage(action),
      };

    default:
      return state;
  }
}
