import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import LoginContainer from './Containers/LoginContainer';
import ResetPasswordContainer from './Containers/ResetPasswordContainer';
import ConfirmResetPasswordContainer from './Containers/ConfirmResetPasswordContainer';
import SignUpContainer from './Containers/SignUpContainer';
import HomeContainer from './Containers/HomeContainer';
import AccountDetailsContainer from './Containers/AccountDetailsContainer';
import SubscriptionContainer from './Containers/SubscriptionContainer';

import DashboardContainer from './Containers/DashboardContainer';
import SettingsContainer from './Containers/SettingsContainer';
import MyProgramContainer from './Containers/MyProgramContainer';
import MyMoodsContainer from './Containers/MyMoodsContainer';
import FaqContainer from './Containers/FaqContainer';
import MyStatsContainer from './Containers/MyStatsContainer';
import ExploreContainer from './Containers/ExploreContainer';
import LiveContainer from './Containers/LiveContainer';
import { useEffect, useState } from 'react';
import Offline from './Pages/Offline';

const ProtectedRoute = () => {
  const auth = useSelector((state) => state.auth);
  return auth.token && auth.user ? <Outlet /> : <Navigate to="/" replace />;
};

const OfflineRoute = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleStatusChange = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);

  return isOnline ? <Outlet /> : <Offline />;
};

const RouteStack = () => {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
    ReactPixel.track('ViewContent');
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<OfflineRoute />}>
        {/* Public Routes */}
        <Route path="/" element={<HomeContainer />} />
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/reset-password" element={<ResetPasswordContainer />} />
        <Route path="/set-new-password/:uid/:token" element={<ConfirmResetPasswordContainer />} />
        <Route path="/signup/" element={<SignUpContainer />} />
        <Route path="/signup/:question_id" element={<SignUpContainer />} />
        <Route path="/add-account-details" element={<AccountDetailsContainer />} />
        <Route path="/select-subscription-plan" element={<SubscriptionContainer />} />

        {/* Protected Routes */}
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardContainer />} />
          <Route path="/my-profile" element={<SettingsContainer />} />
          <Route path="/my-program" element={<MyProgramContainer />} />
          <Route path="/my-moods" element={<MyMoodsContainer />} />
          <Route path="/my-workouts" element={<MyMoodsContainer />} />
          <Route path="/faq" element={<FaqContainer />} />
          <Route path="/my-stats" element={<MyStatsContainer />} />
          <Route path="/explore" element={<ExploreContainer />} />
          <Route path="/live" element={<LiveContainer />} />
        </Route>
      </Route>

      {/* No route found */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default RouteStack;
