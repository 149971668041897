/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardMenuSelection } from '../Redux/actions/topCard';
import { updateSideMenuSelection } from '../Redux/actions/sideMenu';
import { unlockAccessAlertShow, apiSettingRequest } from '../Redux/actions/setting';
import { apiVideoActivityRequest } from '../Redux/actions/studioVideo';
import ExplorePage from '../Pages/ExplorePage';

import {
  apiBodyPartyRequest,
  apiCombineVideoRequest,
  apiExploreVideoRequest,
  apiVideoCateogryRequest,
} from '../Redux/actions/exploreVideo';
import { showErrorToastNoti } from '../Utils/errorToast';
import { toast } from 'react-toastify';
import {checkIsFreeUser} from "../Utils/subscriptionUtils";

const ExploreContainer = () => {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('category') || 1;

  const exploreVideo = useSelector((state) => state.explore);
  const cardMenuSelection = useSelector((state) => state.cardMenuSelection);

  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.setting);

  const [videos, setVideos] = useState(undefined || { results: [] });
  const [page, setPage] = useState(1);
  const [count] = useState(24);
  const [levels, setLevels] = useState('');
  const [bodyParts, setBodyParts] = useState('');
  const [noMore, setNoMore] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [showWorkoutModal, setShowWorkoutModal] = useState(false);
  const [isFreeUser, setIsFreeUser] = useState(true);

  useEffect(() => {
    dispatch(updateCardMenuSelection(parseInt(id)));
    dispatch(updateSideMenuSelection('explore'));
    dispatch(apiVideoCateogryRequest());
    dispatch(apiSettingRequest({ user_id: auth.user.id }));
  }, []);

  useEffect(() => {
    if (cardMenuSelection.menu && !isNaN(parseInt(cardMenuSelection.menu))) {
      dispatch(apiBodyPartyRequest({ category: cardMenuSelection.menu }));
      dispatch(apiBodyPartyRequest());

      // let data = {
      //   page: page,
      //   category: parseInt(id),
      //   levels: levels,
      //   body_parts: bodyParts,
      // };

      // dispatch(apiExploreVideoRequest(data));

      setVideos({ results: [] });
      setIsInitLoading(false);
    }
  }, [cardMenuSelection]);

  useEffect(() => {
    showErrorToastNoti(exploreVideo);
    if (!isInitLoading && !exploreVideo.isVideoLoading && exploreVideo.videoSuccess) {
      let newVideos = exploreVideo.videos;
      newVideos.results = [...videos.results, ...exploreVideo.videos.results];
      setVideos(newVideos);
      if (page * count < exploreVideo.videos?.count) {
        setNoMore(false);
      } else {
        setNoMore(true);
      }
    }
  }, [exploreVideo.isVideoLoading]);

  useEffect(() => {
    showErrorToastNoti(exploreVideo);
    if (!isInitLoading && !exploreVideo.isCombineLoading && exploreVideo.combineSuccess) {
      setShowWorkoutModal(true);
      setSelectedVideo([]);
      // navigate('/my-workouts');
      // toast.success('Your workout was saved!');
    }
  }, [exploreVideo.isCombineLoading]);

  const onTopCardMenuSelected = (id) => {
    setLevels([]);
    setBodyParts([]);
    // setPage(1);
    // setVideos({ results: [] });
    // let data = {
    //   page: 1,
    //   category: parseInt(id),
    //   levels: levels,
    //   body_parts: bodyParts,
    // };
    // dispatch(apiExploreVideoRequest(data));
    dispatch(updateCardMenuSelection(parseInt(id)));
  };

  const onFilterChange = (levels, body_parts) => {
    setVideos({ results: [] });
    setPage(1);
    const temp_levels = levels?.join(',');
    const temp_bodyParts = body_parts?.join(',');
    setLevels(temp_levels);
    setBodyParts(temp_bodyParts);
    let data = {
      page: 1,
      category: parseInt(cardMenuSelection.menu) || '1',
      levels: temp_levels,
      body_parts: temp_bodyParts,
    };
    dispatch(apiExploreVideoRequest(data));
  };

  // const onFilterChange = (levels, body_parts) => {
  //   setVideos({ results: [] });
  //   setPage(1);
  //   const temp_levels = _.map(levels, (e) => {
  //     return e.value;
  //   }).join(',');
  //   const temp_bodyParts = _.map(body_parts, (e) => {
  //     return e.value;
  //   }).join(',');
  //   setLevels(temp_levels);
  //   setBodyParts(temp_bodyParts);
  //   let data = {
  //     page: 1,
  //     category: parseInt(cardMenuSelection.menu) || '1',
  //     levels: temp_levels,
  //     body_parts: temp_bodyParts,
  //   };
  //   dispatch(apiExploreVideoRequest(data));
  // };

  const onLoadMore = () => {
    if (page * count < exploreVideo.videos?.count) {
      const nextPage = page + 1;

      setPage(nextPage);
      let data = {
        page: nextPage,
        category: cardMenuSelection.menu,
        levels: levels,
        body_parts: bodyParts,
      };
      dispatch(apiExploreVideoRequest(data));
    }
  };

  const onCombineClick = () => {
    let video_ids = _.map(selectedVideo, (it) => {
      return it.id;
    });

    if (video_ids.length === 0) {
      toast.warning('Please select videos.');
      return;
    }

    if (profile.setting && isFreeUser) {
      dispatch(unlockAccessAlertShow());
      return;
    }

    let data = {
      videos_ids: video_ids,
    };

    dispatch(apiCombineVideoRequest(data));
  };

  const onDeleteAVideoClick = (id) => {
    let newSelected = selectedVideo;
    _.remove(newSelected, { id: id });
    setSelectedVideo([...newSelected]);
  };

  const onClearVideos = (video) => {
    setSelectedVideo([]);
  };

  const onCheckboxClicked = (id, check_val) => {
    if (check_val) {
      if (selectedVideo?.length >= 6) {
        return toast.error('Max. 6 videos can be added to a workout.');
      } else {
        let newSelected = selectedVideo;
        newSelected = [...selectedVideo, _.find(videos.results, { id: id })];
        setSelectedVideo(newSelected);
      }
    } else {
      let newSelected = selectedVideo;
      _.remove(newSelected, { id: id });
      setSelectedVideo([...newSelected]);
    }
  };

  const onVideoWatched = (id, watchedTime) => {
    let data = {
      video_id: id,
      duration_watched: Math.round(watchedTime / 60),
    };
    dispatch(apiVideoActivityRequest(data));
  };

  useEffect( () => {
    checkIsFreeUser(profile).then((res) => {
      console.log('res', res);
      setIsFreeUser(res);
    })
  }, [profile])

  useEffect(() => {
    console.log(isFreeUser, ': isFreeUser');
  },[isFreeUser]);

  return (
    <>
      <ExplorePage
        stateWorkoutModal={[showWorkoutModal, setShowWorkoutModal]}
        categories={exploreVideo?.categories}
        selectedCategory={cardMenuSelection}
        body_parts={exploreVideo?.bodyparts}
        videos={videos}
        isLoading={exploreVideo?.isVideoLoading}
        isCombineLoading={exploreVideo?.isCombineLoading}
        isCombineSuccess={exploreVideo?.combineSuccess}
        isCombinePossible={profile.setting && profile.setting.active_membership?.plan.id}
        combinedVideos={exploreVideo?.combinedVideos}
        // isCombinePossible={false}
        onFilterChange={onFilterChange}
        onTopCardMenuSelected={onTopCardMenuSelected}
        onLoadMore={onLoadMore}
        noMore={noMore}
        selectedVideo={selectedVideo}
        onCombineClick={onCombineClick}
        onDeleteAVideoClick={onDeleteAVideoClick}
        onClearVideos={onClearVideos}
        onCheckboxClicked={onCheckboxClicked}
        onVideoWatched={onVideoWatched}
        isFreeUser={isFreeUser}
      />
    </>
  );
};

export default ExploreContainer;
