import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { Button } from './FormElements';
import cn from 'classnames';
import PaymentModal from './PaymentModal';
// import { useDispatch } from 'react-redux';
// import { apiCouponRequest } from '../Redux/actions/coupon';

const initialBuyState = {
  productId: null,
  priceId: null,
  quantity: 0,
  price: 0,
};

const BuyTicketModal = ({
  isModalVisible,
  setIsModalVisible,
  isLoading,
  product,
  paymentMethod,
  onClose,
  handleBuyCredit,
  ...props
}) => {
  const [buyState, setBuyState] = useState(initialBuyState);
  const productName = product?.name.toLowerCase() === 'private' ? 'Private Session' : 'Workshop';
  const strTicket = buyState.quantity > 1 ? 'tickets' : 'ticket';
  // const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  const handleSubmitCheckout = (...args) => {
    handleBuyCredit(...args);
  };

  // const handleSubmitCoupon = () => {
  //   dispatch(apiCouponRequest(null));
  // };

  const handleSelectProduct = (productId, priceId, quantity, price) =>
    setBuyState({
      ...initialBuyState,
      productId,
      priceId,
      quantity,
      price,
    });

  const PriceSelector = () => (
    <div className="px-20 md:px-40 pb-20 md:pb-40 text-center">
      <p className="fs-24 md:fs-28 fw-700 mb-20">Purchase {productName} Ticket(s)</p>
      <p className="fs-14 md:fs-18 fg-grey-500">{product?.type_description}</p>
      <div className="flex flex-col justify-center align-end md:flex-row mt-20">
        {product?.pricing.map(({ id, quantity, price }, key) => (
          <div
            key={key}
            className={cn('fit-w md:w-5/12 p-8', key === 1 && 'order-first md:order-1')}
          >
            <div className={cn('r-15 b-1 of-hidden', key === 1 ? 'b-primary' : 'b-grey-300')}>
              {key === 1 && (
                <div className="h-30 md:h-40 flex justify-center align-center fs-14 md:fs-16 bg-primary fg-white">
                  Most Popular
                </div>
              )}
              <div className="px-20 pt-20">
                <div>
                  <span className="fs-20 md:fs-24 fw-700">{quantity} </span>
                  <span className="fs-12 md:fs-14 fw-600">ticket{quantity > 1 && 's'}</span>
                </div>
                <div className="fs-12 fw-600 fg-grey-500 mb-10">${parseInt(price)}</div>
                <Button
                  secondary={key === 0}
                  primary={key === 1}
                  size="small"
                  disablePadding
                  className="fit-w my-15"
                  onClick={() => handleSelectProduct(product.id, id, quantity, price)}
                >
                  Buy now
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    if (!isModalVisible) setBuyState(initialBuyState);
  }, [isModalVisible]);

  return !buyState.price ? (
    <Modal
      defaultClass={false}
      className="fs-14 md:fs-18"
      visible={isModalVisible}
      onClose={handleClose}
    >
      <PriceSelector />
    </Modal>
  ) : (
    <PaymentModal
      // acceptCoupon
      show={isModalVisible}
      title={`Purchase ${productName} Ticket(s)`}
      caption={`Quantity: ${buyState.quantity} ${strTicket}`}
      productId={buyState.productId}
      priceId={buyState.priceId}
      price={buyState.price}
      isLoading={isLoading}
      onClose={handleClose}
      onSubmit={handleSubmitCheckout}
      // onSubmitCoupon={handleSubmitCoupon}
    />
  );
};

export default BuyTicketModal;
