import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import SubscriptionPlan from '../Components/SubscriptionPlan';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { ReactComponent as Decor } from '../Assets/decor.svg';
import cn from 'classnames';
import PaymentModal from '../Components/PaymentModal';
import { useSelector } from 'react-redux';

const SubscriptionPage = ({
  state,
  register,
  errors,
  isLoading,
  terms,
  currentPlan,
  showPlanTableOnly = false,
  onSubmitCheckout,
  onSubmitCoupon,
  onSubscriptionSelected,
  showCheckout,
  setShowCheckout,
  membershipType,
}) => {
  const featuresAll = [];
  const stateSubscription = useSelector((state) => state.subscription);
  const plans = stateSubscription.subscription || [];
  const selectedPlan = plans?.find((el) => el.id === parseInt(membershipType));

  const handleCloseModal = () => setShowCheckout(false);

  featuresAll.sort((a, b) => a.id - b.id);
  plans.forEach(({ features }, index) => {
    features.forEach((feature) => {
      const isIncluded = featuresAll.find((el) => el.id === feature.id);
      if (!isIncluded) {
        featuresAll.push(feature);
      }
    });
  });

  return (
    <>
      {!showPlanTableOnly && (
        <p className="flex justify-content align-center p-10 pt-20 lg:px-60">
          <Link
            to={currentPlan ? '/my-profile?page=settings' : '/'}
            className="inline-block m-auto md:ml-0"
          >
            <Logo className="md:w-100" />
          </Link>
        </p>
      )}
      <div
        className={cn(
          'container-auto-xl px-20 all-subscription-plans-container',
          showPlanTableOnly ? 'py-40' : 'pb-40',
        )}
        style={{ maxWidth: '1420px' }}
      >
        {!showPlanTableOnly && (
          <div className="text-center">
            <p className="container-auto-md md:w-500 fs-24 md:fs-28 fw-700 mb-40">
              Experience professional functional training, adapted to{' '}
              <span className="fg-primary relative">
                your needs
                <Decor className="absolute left-0 right-0" />
              </span>
            </p>
          </div>
        )}
        <div className="flex flex-wrap fs-16 fw-600">
          <div className="md:show w-40/100 order-0">
            {/* Most pupular */}
            <div className="md:h-50" />
            {/* Plan header */}
            <div className="md:h-90" />
            {featuresAll?.map((feature, index) => (
              <p
                key={index}
                className={cn(
                  'h-50 flex align-center px-15',
                  index % 2 === 0 ? 'bg-grey-100' : null,
                )}
              >
                {feature.content}
              </p>
            ))}
          </div>
          {_.map(state?.subscription, (plan, index) => {
            const featuredPlan = index === 2;
            const isFree = plan.membership_type === 'Free';

            return (
              <div
                className={cn(
                  'fit-w md:flex-1 my-10 md:my-0 pb-25 md:pb-50 r-15 of-hidden z-1',
                  featuredPlan
                    ? 'b-1 b-primary shadow-8 order-first md:order-2'
                    : 'b-1 b-grey-400 md:b-0',
                  isFree ? 'order-last md:order-1' : null,
                  !featuredPlan && !isFree ? 'md:order-3' : null,
                )}
                key={index}
              >
                {/* Most pupular */}
                <div
                  className={cn(
                    'md:h-50 py-8 flex align-center fs-12 md:fs-18 justify-center',
                    featuredPlan ? 'bg-primary fg-white' : 'bg-grey-400 md:bg-none',
                  )}
                >
                  {featuredPlan ? 'Most Popular' : ''}
                </div>
                <SubscriptionPlan
                  className="custom-subscription-plan"
                  {...plan}
                  currentPlan={currentPlan}
                  fetauredPlan={featuredPlan}
                  featuresAll={featuresAll}
                  onSelected={onSubscriptionSelected}
                  terms={terms}
                  onSubmitCoupon={onSubmitCoupon}
                />
              </div>
            );
          })}
        </div>
      </div>
      <PaymentModal
        show={showCheckout}
        title={`Subscribe to ${selectedPlan?.plan_name}`}
        caption={`Duration: ${selectedPlan?.duration} months`}
        price={selectedPlan?.total_price}
        acceptCoupon={selectedPlan?.promo_codes_enabled}
        membershipType={membershipType}
        isLoading={stateSubscription?.isLoading}
        onClose={handleCloseModal}
        onSubmit={onSubmitCheckout}
        onSubmitCoupon={onSubmitCoupon}
      />
    </>
  );
};

export default SubscriptionPage;
