import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { IconHelp, IconMenu } from './Icons';
import { Link, useLocation } from 'react-router-dom';
import UserInfo from './UserInfo';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { scrollLock, scrollUnlock } from '../Utils/scrollLock';
import {Capacitor} from "@capacitor/core";

const navItems = [
  { title: 'My Studio', links: ['/dashboard', '/my-program', '/my-workouts', '/my-moods'] },
  { title: 'Explore', links: ['/explore'] },
  { title: 'My Statistics', links: ['/my-stats'] },
  { title: 'Live', links: ['/live'] },
];

const NavBar = ({ setShowLogoutModal }) => {
  const profile = useSelector((state) => state.setting);
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  // Function to detect iOS devices
  const isIOS = () => {
    return Capacitor.getPlatform() === 'ios';
  };

  const onMobileMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const onMobileMenuItem = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (showMenu) {
      scrollLock();
    } else {
      scrollUnlock();
    }
  }, [showMenu]);

  return (
    <>
      <nav className={cn("h-55 md:h-75 bg-white bb-1 b-grey-200 sticky top-0 z-100 navbar", {"ios-safe-area": isIOS()})}>
        {/* Left */}
        <div className="relative container-auto-xxl px-10 md:px-40 fit-h flex align-center">
          <div className="md:w-15/100 lg:w-25/100">
            <Link to="/dashboard" className="inline-block">
              <Logo className="h-30 md:h-45 w-auto" />
            </Link>
          </div>

          {/* Mobile Placeholder */}
          <div className="md:hide flex-1" />

          {/* Menu Items Desktop */}
          <div className="md:show flex-1 px-20 fs-16 fg-grey-500 text-center">
            {navItems.map(({ title, links }, key) => (
              <Link
                key={key}
                to={links[0]}
                className={cn(
                  'py-20 mx-20 hover:fg-primary',
                  links.includes(pathname) && 'fg-black fw-600',
                )}
              >
                {title}
              </Link>
            ))}
          </div>

          {/* Right */}
          <div className="md:w-15/100 lg:w-25/100 flex flex-nowrap justify-end of-hidden">
            <Link to="/faq" className="flex-0 flex navbar-menu">
              {/* <HelpOutlineIcon color="inherit" /> */}
              <IconHelp className="w-20 md:w-30 m-10" />
            </Link>
            <UserInfo setShowLogoutModal={setShowLogoutModal} />
            {/* <button
              onClick={onLogoutClicked}
              className={auth.isLoading ? 'btn-img loading' : 'btn-img'}
            >
              <ExitToAppRoundedIcon color="inherit" />
            </button> */}
            {/* Mobile Menu */}
            <button type="button" className="md:hide" onClick={onMobileMenuClick}>
              <IconMenu className="w-30 h-auto m-10" />
            </button>
          </div>
        </div>

        {/* Menu Items Mobile */}
        {showMenu && (
          <div className="md:hide fs-14 fw-600 bg-white bt-1 b-grey-300">
            <Link
              to="/dashboard"
              className="block fg-black fw-600 py-25 pl-20 active:fg-primary"
              onClick={onMobileMenuItem}
            >
              My Studio
            </Link>
            <hr className="my-0 ml-20 b-grey-300" />
            <Link
              to="/explore"
              className="block py-25 pl-20 active:fg-primary"
              onClick={onMobileMenuItem}
            >
              Explore
            </Link>
            <hr className="my-0 ml-20 b-grey-300" />
            <Link
              to="/my-stats"
              className="block py-25 pl-20 active:fg-primary"
              onClick={onMobileMenuItem}
            >
              My Statistics
            </Link>
            <hr className="my-0 ml-20 b-grey-300" />
            <Link
              to="/live"
              className="block py-25 pl-20 active:fg-primary"
              onClick={onMobileMenuItem}
            >
              Live
            </Link>
            <hr className="my-0 ml-20 b-grey-300" />
            <Link
              to="/my-profile"
              className="flex align-center py-25 pl-20 active:fg-primary"
              onClick={onMobileMenuItem}
            >
              <div
                className="flex w-25 h-25 fg-primary bg-primary-light r-100 avatar"
                style={
                  profile.setting?.avatar && {
                    backgroundImage: `url(${profile.setting.avatar})`,
                    backgroundSize: 'cover',
                  }
                }
              >
                {profile.setting.avatar ? (
                  <></>
                ) : (
                  <span className="fw-700 m-auto default-text-avatar">
                    {profile.setting.name.charAt(0)}
                  </span>
                )}
              </div>
              <div className="pl-10 text-trim">{profile.setting.name}</div>
            </Link>
          </div>
        )}
      </nav>

      {/* Backdrop for Mobile Menu */}
      {showMenu && <div className="page-backdrop" style={{ zIndex: 99 }} />}
    </>
  );
};

export default NavBar;
