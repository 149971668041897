import * as types from "../constants/contactus";

// UPLOAD AVATAR
export const apiContactUsRequest = (data) => ({
    type: types.API_CONTACT_US_REQUEST,
    data,
});

export const apiContactUsRequestSuccess = (response) => ({
    type: types.API_CONTACT_US_SUCCESS,
    response,
});

export const apiContactUsRequestFailed = (response) => ({
    type: types.API_CONTACT_US_FAILED,
    response,
});
