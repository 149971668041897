import { put, call, all, spawn, takeEvery } from 'redux-saga/effects';
import { faqService } from '../services/faq';
import * as types from '../constants/faq';
import * as actions from '../actions/faq';

// Faq
function* apiGetFaqRequestWorker(action) {
    try {
        const result = yield call(faqService.apiGetFaqRequest, action);
        yield put(actions.apiGetFaqRequestSuccess(result, action));
    } catch (err) {
        yield put(actions.apiGetFaqRequestFailed(err, action));
    }
}

function* apiGetFaqRequestWatcher() {
    yield takeEvery(types.API_GET_REQUEST_FAQ, apiGetFaqRequestWorker);
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* faqRootSaga() {
    const sagas = [
        apiGetFaqRequestWatcher,
    ];

    yield all(
        sagas.map(saga =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.log(e);
                    }
                }
            }),
        ),
    );
}
