import React, { useEffect, useState, useRef } from 'react';
import SettingsLayout from '../Layouts/SettingsLayout';
import {
  CardInfoRow,
  SettingsCard,
  SettingsCardContent,
  SettingsCardHeader,
} from '../Components/SettingsCard';
import AccountDeleteModal from '../Components/AccountDeleteModal';
import { Button, Error, Input } from '../Components/FormElements';
import cn from 'classnames';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RecalibrateModal from '../Components/RecalibrateModal';
import {checkIsFreeUser} from "../Utils/subscriptionUtils";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ProfilePage = ({
  profile,
  paymentMethod,
  isAvatarChanged,
  onSettingChange,
  reloadProfile,
  onUsernameChange,
  uploadSelectedImage,
  onUpdatePaymentMethodClick,
  changePasswordRegister,
  onPasswordChange,
  passwordChangeErrors,
  onAccountDelete,
}) => {
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const setting = profile.setting;
  const answers = setting?.answers || {};
  const [isFreeUser, setIsFreeUser] = useState(true);
  // const isLoading = profile.isLoading;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecalibrateModal, setShowRecalibrateModal] = useState(false);
  const [editCardMain, setEditCardMain] = useState(false);
  const [editCardLogin, setEditCardLogin] = useState(false);
  // const [editCardWorkout, setEditCardWorkout] = useState(false);
  const [username, setUsername] = useState(null);

  useEffect( () => {
    checkIsFreeUser(profile).then((res) => {
      setIsFreeUser(res);
    })
  }, [profile])

  const handleClickDelete = () => {
    setShowDeleteModal(true);
  };

  const handleOk = () => {
    setShowDeleteModal(false);
    onAccountDelete();
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const onUploadPictureClicked = () => {
    fileInputRef.current.click();
  };

  const onAvatarImageSelected = async (e) => {
    const image = e.target.files[0];
    e.target.files = null;

    if (!image) {
      return false;
    }

    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      return false;
    }

    let imageDataUrl = await readFile(image);

    uploadSelectedImage(imageDataUrl);
  };

  const onSaveUsername = () => {
    if (username) {
      setEditCardMain(false);
      onUsernameChange(username);
    } else {
      toast.error('Please enter a username');
    }
  };

  useEffect(() => {
    setUsername(setting?.name);
  }, [setting]);

  return (
    <>
      <SettingsLayout>
        <div className="flex flex-wrap py-10">
          {/* Main info */}
          <div className="fit-w lg:w-50/100 py-10 lg:pr-15">
            <SettingsCard>
              <SettingsCardHeader>
                <div className="flex-1 text-trim">Main Info</div>
                <div>
                  {editCardMain ? (
                    <>
                      <Button
                        secondary
                        size="small"
                        className="w-90 md:w-130 ml-15"
                        onClick={() => setEditCardMain(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        primary
                        size="small"
                        disablePadding
                        className="w-40 md:w-130 ml-15"
                        onClick={onSaveUsername}
                      >
                        <span className="md:show">Save</span>
                        <span className="md:hide">
                          <FaCheck />
                        </span>
                      </Button>
                    </>
                  ) : (
                    <Button
                      primary
                      size="small"
                      className="w-90 md:w-130 ml-15"
                      onClick={() => setEditCardMain(true)}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </SettingsCardHeader>
              <SettingsCardContent className="fs-14">
                <div className="flex flex-wrap md:flex-nowrap">
                  <div className="flex-0 flex align-center md:block mb-30 md:mb-0">
                    <div
                      className="flex-0 flex w-100 h-100 md:mt-20 fg-primary bg-primary-light r-100 avatar"
                      style={
                        setting?.avatar && {
                          backgroundImage: `url(${setting.avatar})`,
                          backgroundSize: 'cover',
                        }
                      }
                    >
                      {setting?.avatar ? (
                        <></>
                      ) : (
                        <span className="fs-40 fw-700 m-auto default-text-avatar">
                          {setting?.name.charAt(0)}
                        </span>
                      )}
                    </div>
                    {editCardMain && (
                      <>
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/gif"
                          value=""
                          onChange={onAvatarImageSelected}
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        />
                        <p
                          className="fs-14 p-15 md:px-0 md:py-10 fg-grey-500 text-center underline hover:fg-black active:fg-black cursor-pointer"
                          onClick={onUploadPictureClicked}
                        >
                          Change photo
                        </p>
                      </>
                    )}
                  </div>
                  <div className="fit-w md:flex-1 md:pl-50">
                    <CardInfoRow title="Username">
                      {editCardMain ? (
                        <Input
                          disableFixedWidth
                          disableFiexedHeight
                          disableMargin
                          className="h-50"
                          defaultValue={setting?.name}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      ) : (
                        setting?.name
                      )}
                    </CardInfoRow>
                    <hr className="b-grey-300 my-10" />
                    <CardInfoRow title="Gender">{answers?.gender || 'N/A'}</CardInfoRow>
                  </div>
                </div>
              </SettingsCardContent>
            </SettingsCard>
          </div>

          {/* Login details */}
          <div className="fit-w lg:w-50/100 py-10 lg:pl-15">
            <SettingsCard>
              <form onSubmit={onPasswordChange}>
                <SettingsCardHeader>
                  <div className="flex-1 text-trim">Login Details</div>
                  <div>
                    {editCardLogin ? (
                      <>
                        <Button
                          secondary
                          size="small"
                          className="w-90 md:w-130 ml-15"
                          onClick={() => setEditCardLogin(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          primary
                          size="small"
                          disablePadding
                          className={cn('w-40 md:w-130 ml-15', profile.isLoading && 'disabled')}
                        >
                          <span className="md:show">Save</span>
                          <span className="md:hide">
                            <FaCheck />
                          </span>
                        </Button>
                      </>
                    ) : (
                      <Button
                        primary
                        size="small"
                        className="w-90 md:w-130 ml-15"
                        onClick={() => setEditCardLogin(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </SettingsCardHeader>
                <SettingsCardContent className="fs-14">
                  <CardInfoRow title="Email">{setting?.email}</CardInfoRow>
                  <hr className="b-grey-300 my-10" />
                  <CardInfoRow title="Password">
                    {editCardLogin ? (
                      <>
                        <Input
                          disableFixedWidth
                          disableFiexedHeight
                          disableMargin
                          className="h-50"
                          type="password"
                          placeholder="Current Password"
                          {...changePasswordRegister('current_password')}
                        />
                        <Error text={passwordChangeErrors.current_password?.message} />
                      </>
                    ) : (
                      '••••••••••••••••'
                    )}
                  </CardInfoRow>
                  {editCardLogin && (
                    <>
                      <hr className="b-grey-300 my-10" />
                      <CardInfoRow title="New Password">
                        <Input
                          disableFixedWidth
                          disableFiexedHeight
                          disableMargin
                          className="h-50"
                          type="password"
                          placeholder="New Password"
                          {...changePasswordRegister('new_password1')}
                        />
                        <Error text={passwordChangeErrors.new_password1?.message} />
                      </CardInfoRow>
                      <hr className="b-grey-300 my-10" />
                      <CardInfoRow title="Confirm Password">
                        <Input
                          disableFixedWidth
                          disableFiexedHeight
                          disableMargin
                          className="h-50"
                          type="password"
                          placeholder="Confirm New Password"
                          {...changePasswordRegister('new_password2')}
                        />
                        <Error text={passwordChangeErrors.new_password2?.message} />
                      </CardInfoRow>
                    </>
                  )}
                </SettingsCardContent>
              </form>
            </SettingsCard>
          </div>

          {/* Goal */}
          <div className="fit-w py-10">
            <SettingsCard>
              <SettingsCardHeader>
                <div className="flex-1 text-trim">Goal</div>
                <>
                  {isFreeUser ? (
                    <div className="flex align-center">
                      <p className="lg:show flex-1 fs-16 fg-grey-500 text-trim">
                        Upgrade Plan for changes
                      </p>
                      <Button
                        primary
                        disablePadding
                        size="small"
                        className="px-25 ml-15"
                        onClick={() => navigate('/my-profile?page=subscription')}
                      >
                        Upgrade Plan
                      </Button>
                    </div>
                  ) : (
                    <Button
                      primary
                      disablePadding
                      size="small"
                      className="px-25 ml-15"
                      onClick={() => setShowRecalibrateModal(true)}
                    >
                      Recalibrate my program
                    </Button>
                  )}
                </>
              </SettingsCardHeader>
              <SettingsCardContent className="fs-14">
                <CardInfoRow title="Goals">{answers?.goals?.join(' / ') || 'N/A'}</CardInfoRow>
                <hr className="b-grey-300 my-10" />
                <CardInfoRow title="Level">{answers?.level?.join(' / ') || 'N/A'}</CardInfoRow>
                <hr className="b-grey-300 my-10" />
                <CardInfoRow title="Duration">
                  {answers?.duration?.join(' / ') || 'N/A'}
                </CardInfoRow>
              </SettingsCardContent>
            </SettingsCard>
          </div>
        </div>

        {/* Delete account */}
        <div className="text-center">
          <button
            className="fs:14 lg:fs-16 fw-600 underline fg-red-700 hover:fg-black cursor-pointer"
            onClick={handleClickDelete}
          >
            Delete account
          </button>
        </div>
      </SettingsLayout>
      <RecalibrateModal
        isModalVisible={showRecalibrateModal}
        handleCancel={() => {
          reloadProfile();
          setShowRecalibrateModal(false);
        }}
      />
      <AccountDeleteModal
        isModalVisible={showDeleteModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default ProfilePage;
