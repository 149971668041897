import React from 'react';
import Modal from './Modal';
import { Button } from './FormElements';
import ExerciseCard from './ExerciseCard';

const CreateWorkoutModal = ({
  combinedVideos,
  isModalVisible,
  isLoading,
  handleOk,
  handleCancel,
}) => {
  return (
    <Modal className="text-center fs-14 md:fs-18" visible={isModalVisible} onClose={handleCancel}>
      <div className="flex flex-col flex-nowrap fit-h justify-center">
        {/* Content */}
        <div className="md:flex-1 flex flex-col justify-center md:w-500 mx-auto">
          <p className="fs-24 fw-800 md:fw-700 mb-5">Here’s your workout!</p>
          <p className="fs-14 md:fs-16 fg-grey-500 mb-5">
            Your workout will be saved to "My workouts".
          </p>
          <div className="fit-w md:w-350 m-auto">
            <ExerciseCard
              {...combinedVideos}
              index={0}
              standAlone={true}
              disableControls={true}
              onVideoClicked={false}
              onScheduleClicked={false}
              actions={[]}
              onDelete={false}
              onLevelChange={false}
              onVideoUnavailable={false}
            />
          </div>
        </div>
        <p>
          <Button primary className="fit-w md:w-290 my-10" onClick={handleOk}>
            Watch now
          </Button>
        </p>
        <p>
          <button
            className="fs-14 md:fs-16 fw-600 mt-10 underline hover:fg-primary"
            onClick={handleCancel}
          >
            Watch later
          </button>
        </p>
      </div>
    </Modal>
  );
};

export default CreateWorkoutModal;
