import cn from 'classnames';
import React from 'react';

const AbstractPricingPlan = ({
  title = 'Workshop',
  duration,
  body,
  footer,
  membershipType,
  monthlyPrice,
  fetauredPlan,
  totalPrice,
  displayRedeemPromoCode = false,
  onUsePromoClick,
  isCurrent,
  ...props
}) => {
  const Pricing = ({ className }) => (
    <div className={cn('h-75 flex flex-col justify-center text-center plan-pricing', className)}>
      {membershipType === 'Paid' && (
        <p className="fs-18 md:fs-14 fw-500 fg-grey-400">
          {membershipType === 'Paid' ? '$' + parseInt(monthlyPrice) : 'Free'}
          {membershipType === 'Paid' && <span className="md:fs-14">/month</span>}
        </p>
      )}
      <p className="monthly-cost">
        <span className="fs-24">
         {membershipType === 'Paid' ? '$' + parseInt(totalPrice) : 'Free'} {duration === 12 ? 'yearly' : duration === 3 ? 'quarterly' : ''}
        </span>
      </p>
    </div>
  );

  return (
    <div className="subscription-plan-container" {...props}>
      <div className={`h-65 md:h-90 md:pb-10 flex flex-col plan-header ${title.replace(' ', '-')}`}>
        <div className="fs-16 lh-1.8 mx-auto mt-auto text-center">
          <p className="md:show fs-16">{duration === 12 ? 'Advanced' : ''}</p>
          <p>{membershipType === 'Free' ? title : ''}</p>
          <p className={cn(fetauredPlan ? 'md:fs-24 md:fw-700' : null)}>
            {duration === 12 ? '1 year' : duration > 0 ? `${duration} months` : ''}
          </p>
        </div>
      </div>
      <Pricing className="md:hide fs-24 fw-700" />
      <div className="text-center plan-body">{body}</div>
      <div className="px-15 pt-40 text-center plan-footer">
        <div className="md:show h-25 fg-orange-700 text-trim">
          {fetauredPlan ? 'Save $60 yearly' : null}
        </div>
        {footer}
        <div className="md:hide fs-14 fg-orange-700">{fetauredPlan ? 'Save $60 yearly' : null}</div>
        <Pricing className="md:show" />
        {/* {displayRedeemPromoCode && !isCurrent && (
          <div className="promo-code-container">
            <Link
              to=""
              className="use-promo-code-link"
              onClick={(event) => {
                event.preventDefault();
                onUsePromoClick();
              }}
            >
              use a promo code
            </Link>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AbstractPricingPlan;
