import {Purchases, PURCHASES_ERROR_CODE} from '@revenuecat/purchases-capacitor';

class RevenueCatService {
  constructor() {
    // Initialize RevenueCat
    this.initialize();
  }

  initialize() {
    // Set up RevenueCat with your API key
    Purchases.configure({apiKey: "appl_jQCngEFJfzBHylVIaYaWBPwMFna"});
  }

  async getOfferings() {
    try {
      return await Purchases.getOfferings();
    } catch (error) {
      console.error("Error fetching offerings: ", error);
      throw error;
    }
  }

  async purchasePackage(packageToPurchase) {
    try {
      const purchaseResult = await Purchases.purchasePackage({aPackage: packageToPurchase});
      if (typeof purchaseResult.customerInfo.entitlements.active['my_entitlement_identifier'] !== "undefined") {
        // Unlock "pro" content here
      }
    } catch (error) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        console.error("Purchase cancelled: ", error);
      } else {
        console.error("Purchase failed: ", error);
      }
    }
  }


  async restorePurchases() {
    try {
      return await Purchases.restoreTransactions();
    } catch (error) {
      console.error("Restore purchases failed: ", error);
      throw error;
    }
  }

  async getCustomerInfo() {
    try {
      return await Purchases.getCustomerInfo();
    } catch (error) {
      console.error("Error fetching purchaser info: ", error);
      throw error;
    }
  }
}

export default new RevenueCatService();
