/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ExerciseCard from './ExerciseCard';
// import { arrayShuffle } from '../Utils/arrayShuffle';

// 1 Focus | 2 Essentials | 3 H.Intense | 4 Mobility | 5 Post Natal | 6 Relax | 7 Strength
const orderCatgories = [0, 5, 1, 7, 4, 3];

const getVideoCategory = (video) =>
  video?.video_category || video?.list_of_videos?.[0]?.video_category?.id || null;

const getCategoryIndex = (video) => {
  const index = orderCatgories.findIndex((el) => el === getVideoCategory(video));
  return index === -1 ? 100 : index;
};

const compareVideoCategories = (a, b) => getCategoryIndex(a) - getCategoryIndex(b);

const compareVideoIds = (a, b) => a.id - b.id;

const videosMixOrder = (videos = []) => {
  const results = [];
  const tmp = [];
  const cloneVideos = [...videos].sort(compareVideoIds).sort(compareVideoCategories);

  cloneVideos?.forEach((el) => {
    const categoryIndex = getCategoryIndex(el);
    if (!(categoryIndex in tmp)) tmp[categoryIndex] = [];
    tmp[categoryIndex].push(el);
  });

  for (let index = 0; index < cloneVideos.length; index++) {
    tmp.forEach((el, i) => {
      const shift = el.shift();
      if (shift) results.push(shift);
    });
  }

  tmp.splice(0, tmp.length);

  return results;
};

const getRefWatchPercentage = (ref) => {
  if (ref?.current) {
    return Math.round((100 * ref.current.getCurrentTime()) / ref.current.getDuration());
  } else {
    return 0;
  }
};

const setStoreVideoIds = (videoUpnext, videosFRC, videosOther) => {
  const videoIds = {
    upnext: videoUpnext?.id || 0,
    frc: videosFRC?.map((el) => el.id) || [],
    other: videosOther?.map((el) => el.id) || [],
  };

  localStorage.setItem('stateMyStudio', JSON.stringify(videoIds));
};

const getStoreVideoIds = () => {
  const storeRaw = localStorage.getItem('stateMyStudio');
  const store = JSON.parse(storeRaw);

  return {
    upnext: store?.upnext || 0,
    frc: store?.frc || [],
    other: store?.other || [],
  };
};

const compareStoreVideoIds = (arrayIds) => (a, b) => {
  const indexA = arrayIds.findIndex((id) => id === a.id);
  const indexB = arrayIds.findIndex((id) => id === b.id);
  return indexA - indexB;
};

const MapVideos = ({ videos, upNext = false, rest = {} }) => {
  const {
    actions,
    pathname,
    isFreeUser,
    isPlaying,
    onVideoClicked,
    onClickVideoUpNext,
    onScheduleClicked,
    onDeleteClicked,
    onVidLevelChange,
    onVideoUnavailable,
    getOvalColor,
    forceUpgradeRequired,
  } = rest;
  return (
    <>
      {videos?.map((video, index) => {
        const isUpNext = upNext && index === 0;
        const key = video?.id || index;

        return (
          <ExerciseCard
            key={isUpNext ? 'UPNEXT' + key : key}
            index={index}
            disableCategory={pathname === '/dashboard'}
            disableLevel={pathname === '/dashboard'}
            isFreeUser={isFreeUser}
            {...video}
            isPlaying={isPlaying}
            ovalColor={getOvalColor(getVideoCategory(video))}
            upNext={isUpNext}
            onVideoClicked={isUpNext ? onClickVideoUpNext : onVideoClicked}
            onScheduleClicked={onScheduleClicked}
            actions={actions}
            onDelete={onDeleteClicked}
            onLevelChange={onVidLevelChange}
            onVideoUnavailable={onVideoUnavailable}
            forceUpgradeRequired={forceUpgradeRequired}
          />
        );
      })}
    </>
  );
};

const Section = ({ title, videos, upNext = false, rest = {} }) => {
  const [collapse, setCollapse] = useState(false);
  const onClickCollapse = () => setCollapse((collapse) => !collapse);
  const Icon = collapse ? FaChevronUp : FaChevronDown;

  return (
    <div className="my-20 p-10 md:p-15 xl:p-30 r-15 bg-grey-50">
      <div className="flex align-center fw-700 md:mb-10 xl:mb-20">
        <div className="flex-1 fs-18 md:fs-22 lh-1 m-5">{title}</div>
        {videos?.length > 4 && (
          <div>
            <button
              className="flex align-center sx-10 fg-primary hover:fg-black"
              onClick={onClickCollapse}
            >
              <span>{collapse ? 'See less' : 'See all'}</span>
              <Icon className="w-15 h-15" />
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-wrap exercise-row md:flex-gap-15 xl:flex-gap-30">
        <MapVideos videos={collapse ? videos : videos?.slice(0, 4)} upNext={upNext} rest={rest} />
      </div>
    </div>
  );
};

export default function VideoList(props) {
  const {
    actions,
    filterVideos,
    categories,
    pathname,
    isFreeUser,
    isPlaying,
    onVideoClicked,
    onScheduleClicked,
    onDeleteClicked,
    onVidLevelChange,
    onVideoUnavailable,
  } = props;
  const [videosShuffled, setVideosShuffled] = useState([]);
  const [videoUpNext, setVideoUpNext] = useState(null);
  const [videosToday, setVideosToday] = useState([]);
  const [videosFRC, setVideosFRC] = useState([]);
  const [videosOther, setVideosOther] = useState([]);
  const [lastUpNextIndex, setLastUpNextIndex] = useState(0);

  const getOvalColor = (id) => {
    const category = categories.find((category) => category.id === id);
    return category ? category.color : null;
  };

  const onClickVideoUpNext = (urls, selectedId) => {
    const videoPoolSize = isFreeUser ? videosShuffled?.length : videosOther?.length;
    return onVideoClicked(urls, selectedId, false, (ref) => {
      if (getRefWatchPercentage(ref) >= 70) {
        setLastUpNextIndex((state) => (state < videoPoolSize - 1 ? state + 1 : 0));
        if (!isFreeUser) {
          setVideosOther((state) => {
            state.push(state.shift());
            return state;
          });
        }
      }
    });
  };

  // onVideoFinished((ref) => console.log(ref.current));

  const onClickVideoFRC = (urls, selectedId) => {
    return onVideoClicked(urls, selectedId, false, (ref) => {
      if (getRefWatchPercentage(ref) >= 70) {
        setVideosFRC((state) => {
          const cloneState = [...state];
          const index = cloneState.findIndex((el) => el.id === selectedId);
          cloneState.push(cloneState.splice(index, 1)[0]);
          return cloneState;
        });
      }
    });
  };

  const onClickVideoOther = (urls, selectedId) => {
    return onVideoClicked(urls, selectedId, false, (ref) => {
      if (getRefWatchPercentage(ref) >= 70) {
        setVideosOther((state) => {
          const cloneState = [...state];
          const index = cloneState.findIndex((el) => el.id === selectedId);
          cloneState.push(cloneState.splice(index, 1)[0]);
          return cloneState;
        });
      }
    });
  };

  const rest = {
    actions,
    pathname,
    isFreeUser,
    isPlaying,
    onVideoClicked,
    onScheduleClicked,
    onDeleteClicked,
    onVidLevelChange,
    onVideoUnavailable,
    getOvalColor,
  };

  useEffect(() => {
    if (pathname === '/my-program') {
      const videoUpNext = isFreeUser ? videosShuffled[lastUpNextIndex] : videosOther[0];

      if (videoUpNext) {
        setVideoUpNext(videoUpNext);
      } else {
        setLastUpNextIndex(0);
      }
    }
  }, [videosShuffled, lastUpNextIndex]);

  useEffect(() => {
    if (pathname === '/my-program' && (videoUpNext || videosFRC?.length || videosOther?.length)) {
      setStoreVideoIds(videoUpNext, videosFRC, videosOther);
    }
  }, [videoUpNext, videosFRC, videosOther]);

  useEffect(() => {
    if (pathname === '/my-program' && filterVideos?.length) {
      // const filterVideosShuffled = arrayShuffle([...filterVideos]);
      const storeIds = getStoreVideoIds();

      // Today videos
      const videosToday = filterVideos.slice(0, 2);

      // FRC videos
      const videosFRC = filterVideos
        .filter((el) => getVideoCategory(el) === 1 && el.video_title.includes('FRC'))
        .sort(compareStoreVideoIds(storeIds?.frc));

      // Upcoming workouts
      const videosOther = videosMixOrder(
        filterVideos.filter(
          (el) => ![...videosToday, ...videosFRC].map((video) => video.id).includes(el.id),
        ),
      ).sort(compareStoreVideoIds(storeIds?.other));
      const videosOtherFree = [...videosOther];

      // Up next
      const videoUpNext =
        videosOther.find((el) => el.id === storeIds?.upnext) || videosOther[lastUpNextIndex];

      // Free membership
      const videosShuffledFree = videosOther.slice(0, 2);
      const videoUpNextFree =
        videosShuffledFree.find((el) => el.id === storeIds?.upnext) ||
        videosShuffledFree[lastUpNextIndex];

      if (isFreeUser) {
        const index = videosShuffledFree.findIndex((el) => el.id === storeIds?.upnext);
        if (index > -1) setLastUpNextIndex(index);
      }

      setVideosShuffled(isFreeUser ? videosShuffledFree : []);
      setVideoUpNext(isFreeUser ? videoUpNextFree : videoUpNext);
      setVideosToday(videosToday);
      setVideosFRC(videosFRC);
      setVideosOther(isFreeUser ? videosOtherFree : videosOther);
    }
  }, [filterVideos]);

  return pathname === '/my-program' ? (
    <>
      {(videoUpNext || videosToday?.length > 0) && (
        <Section
          title="Today"
          videos={videoUpNext ? [videoUpNext, ...videosToday] : [...videosToday]}
          upNext
          rest={{ ...rest, onVideoClicked, onClickVideoUpNext, forceUpgradeRequired: false }}
        />
      )}
      {videosFRC?.length > 0 && (
        <Section
          title="Improve your range of motion"
          videos={videosFRC}
          rest={{ ...rest, onVideoClicked: onClickVideoFRC, forceUpgradeRequired: isFreeUser }}
        />
      )}
      {videosOther?.length > 1 && (
        <Section
          title="Upcoming workouts"
          videos={videosOther.filter((video) => video?.id !== videoUpNext?.id)}
          rest={{ ...rest, onVideoClicked: onClickVideoOther, forceUpgradeRequired: isFreeUser }}
        />
      )}
    </>
  ) : (
    <div className="flex flex-wrap exercise-row md:flex-gap-15 xl:flex-gap-30">
      <MapVideos videos={filterVideos} rest={rest} />
    </div>
  );
}
