import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { FaCheckSquare, FaCircleNotch, FaRegSquare } from 'react-icons/fa';
import cn from 'classnames';
import { Button, Error, Input } from '../Components/FormElements';
import Modal from '../Components/Modal';
import { IconApple, IconEmail, IconFacebook, IconGoogle } from '../Components/Icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';

const string = {
  title: 'Create your account',
  titleMobile: ['Create your', 'account with Email'],
  titleMobilePre: ['Create your account', ''],
  titleSocialLogin: 'or use login via',
  signup: 'Sign Up',
  accept: ['By signing this I accept all', 'Terms & Conditions and Privacy Policy'],
  haveAccount: 'Already have an account?',
  login: 'Log in',
  note: 'Important Notice: Incorpo Studio is not a medical device. Do not exercise through pain, and in case of any doubt regarding your physical condition or ability to exercise please consult your medical professional',
  withEmail: 'Create with Email',
  withApple: 'Create with Apple',
  withGoole: 'Create with Google',
  withFacebook: 'Create with Facebook',
};

const generatePassword = () => {
  const nums = '0123456789';
  const small = 'abcdefghijklmnopqrstuvwxyz';
  const large = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const specials = '!@#$%^&*';
  const extras = '!@#$%^&*-_+!%&/()=?$#';
  const chars = small + large + nums + extras;
  let password = '';
  password += specials.charAt(Math.floor(Math.random() * specials.length));
  password += small.charAt(Math.floor(Math.random() * small.length));
  password += nums.charAt(Math.floor(Math.random() * nums.length));
  password += large.charAt(Math.floor(Math.random() * large.length));
  for (let i = 0; i < 16; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return password;
};

const responseGoogle = async (access_token, setValue, onSubmit, setShowMobileForm) => {
  if (!access_token) return toast.error('Login failed.');

  const { data: userInfo } = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
    headers: { Authorization: `Bearer ${access_token}` },
  });

  const name = [userInfo?.given_name?.trim(), userInfo?.family_name?.trim()].join(' ');
  const password = generatePassword();
  setValue('name', name || '');
  setValue('email', userInfo.email || '');
  setValue('password', password);
  setValue('confirm_password', password);
  //setValue('terms', true);
  setShowMobileForm(true);
  onSubmit();
};

const responseFacebook = async (access_token, setValue, onSubmit, setShowMobileForm) => {
  if (!access_token) return toast.error('Login failed.');

  const userInfo = await FacebookLogin.getProfile({ fields: ['name', 'email'] });
  const password = generatePassword();
  setValue('name', userInfo.name || '');
  setValue('email', userInfo.email || '');
  setValue('password', password);
  setValue('confirm_password', password);
  //setValue('terms', true);
  setShowMobileForm(true);
  onSubmit();
};

const responseApple = async (
  { authorizationCode, email, givenName, familyName },
  setValue,
  onSubmit,
  setShowMobileForm,
  setShowAccountFields,
) => {
  if (!authorizationCode) return toast.error('Login failed.');

  const name = [givenName?.trim(), familyName?.trim()].join(' ');
  const password = generatePassword();
  setValue('name', name || '');
  setValue('email', email || '');
  setValue('password', password);
  setValue('confirm_password', password);
  //setValue('terms', true);
  setShowMobileForm(true);
  setShowAccountFields(false);
  onSubmit();
};

const Checkbox = React.forwardRef(({ onChange, ...props }, ref) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    onChange && onChange(e);
  };

  const Icon = checked ? FaCheckSquare : FaRegSquare;

  return (
    <>
      <input
        ref={ref}
        type="checkbox"
        onChange={handleChange}
        checked={checked}
        className="hide"
        {...props}
      />
      <Icon className={cn('flex-0 w-25 md:w-20 mr-8', checked ? 'fg-primary' : null)} />
    </>
  );
});

const signInGoogle = async (setValue, onSubmit, setShowMobileForm) => {
  const result = await GoogleAuth.signIn();
  const access_token = result?.authentication?.accessToken || null;
  await responseGoogle(access_token, setValue, onSubmit, setShowMobileForm);
};

const signInFacebook = async (setValue, onSubmit, setShowMobileForm) => {
  const result = await FacebookLogin.login({ permissions: ['email'] });
  const access_token = result?.accessToken?.token || null;
  await responseFacebook(access_token, setValue, onSubmit, setShowMobileForm);
};

const signInApple = async (setValue, onSubmit, setShowMobileForm, setShowAccountFields) => {
  const result = await SignInWithApple.authorize({
    clientId: 'com.edemos.incorpostudio',
    redirectURI: window.location.href,
    scopes: 'email name',
    nonce: 'nonce',
  })

  await responseApple(result?.response, setValue, onSubmit, setShowMobileForm, setShowAccountFields);
};

export default function AccountDetailsPage({
  register,
  errors,
  terms,
  isLoading,
  setValue,
  onSubmit,
}) {
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [showAccountFields, setShowAccountFields] = useState(true);
  const [showTerms, setShowTerms] = useState(false);

  const handleClickEmail = () => setShowMobileForm(true);

  const handleOpenTerms = (e) => {
    e.preventDefault();
    setShowTerms(true);
  };
  const handleCloseTerms = () => setShowTerms(false);

  useEffect(() => {
    GoogleAuth.initialize({
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });

    FacebookLogin.initialize({
      appId: '434775801821661',
    });
  }, []);

  return (
    <>
      <div className="lg:show flex justify-content align-center p-10 pt-20 lg:px-40 md:pt-10 lg:pb-0">
        <Link to="/">
          <Logo className="w-90 mx-auto" />
        </Link>
      </div>
      <form
        onSubmit={onSubmit}
        className={cn(
          'flex-1 flex flex-col container-auto-sm justify-center align-center text-center px-20 py-20 lg:pt-0',
          showMobileForm ? null : 'md:show',
        )}
      >
        <Link to="/">
          <Logo className="h-50 lg:hide mx-auto" />
        </Link>
        <p className="fs-24 md:fs-28 fw-700 py-30 lg:pt-0 lg:pb-5">
          <span className="md:show">{string.title}</span>
          <span className="md:hide">
            {string.titleMobile[0]}
            <br />
            {string.titleMobile[1]}
          </span>
        </p>

        <div className="md:show">
          <p className="fs-14 md:fs-18 fg-grey-400">{string.titleSocialLogin}</p>
          <div className="flex justify-center my-15">
            {/* Sign in with Apple */}
            <button
              type="button"
              className="flex w-50 h-50 md:w-55 md:h-55 r-100 b-1 b-grey-400 mx-10 hover:bg-grey-50 hover:b-grey-700 active:bg-grey-50 active:b-grey-700"
              onClick={() => signInApple(setValue, onSubmit, setShowMobileForm, setShowAccountFields)}
            >
              <IconApple className="h-25 m-auto" />
            </button>

            {/* Sign in with Google */}
            <button
              type="button"
              className="flex w-50 h-50 md:w-55 md:h-55 r-100 b-1 b-grey-400 mx-10 hover:bg-grey-50 hover:b-grey-700 active:bg-grey-50 active:b-grey-700"
              onClick={() => signInGoogle(setValue, onSubmit, setShowMobileForm)}
            >
              <IconGoogle className="h-25 m-auto" />
            </button>

            {/* Sign in with Facebook */}
            <button
              type="button"
              className="flex w-50 h-50 md:w-55 md:h-55 r-100 b-1 b-grey-400 mx-10 hover:bg-grey-50 hover:b-grey-700 active:bg-grey-50 active:b-grey-700"
              onClick={() => signInFacebook(setValue, onSubmit, setShowMobileForm)}
            >
              <IconFacebook className="h-25 m-auto" />
            </button>
          </div>
        </div>

        {showAccountFields &&
          <div>
            <Input
              id="name"
              placeholder="Your Name"
              hasError={errors.name}
              {...register('name')}
            />
            {errors.name && <Error text={errors.name.message} />}

            <Input
              type="email"
              id="email"
              placeholder="Email"
              hasError={errors.email}
              {...register('email')}
            />
            {errors.email && <Error text={errors.email.message} />}

            <Input
              type="password"
              id="password"
              placeholder="Password"
              hasError={errors.password}
              {...register('password')}
            />
            {errors.password && <Error text={errors.password.message} />}

            <Input
              type="password"
              id="confirm_password"
              placeholder="Confirm Password"
              hasError={errors.confirmPassword}
              {...register('confirm_password')}
            />
            {errors.confirm_password && <Error text={errors.confirm_password.message} />}
          </div>
        }

        {errors.terms && <Error text={errors.terms.message} />}

        <label className="flex fit-w md:w-550 align-start mb-10">
          <Checkbox name="terms" {...register('terms')} />
          <div className="fs-14 text-left pt-2 pr-20">
            <span>{string.accept[0]} </span>
            <span
              className="fg-primary fw-700 underline cursor-pointer hover:fg-black active:fg-black"
              onClick={handleOpenTerms}
            >
              {string.accept[1]}
            </span>
          </div>
        </label>

        {errors.note && <Error text={errors.note.message} />}

        <label className="flex fit-w md:w-550 align-start mb-10">
          <Checkbox name="terms" {...register('note')} />
          <p className="fs-14 text-left">{string.note}</p>
        </label>

        <Button
          primary
          type="submit"
          className={cn('fit-w md:w-550 my-20', isLoading && 'disabled')}
        >
          {isLoading ? <FaCircleNotch className="w-20 h-20 spin" /> : string.signup}
        </Button>

        <p className="md:show fs-18 fw-600 mt-45 lg:mt-25">
          {string.haveAccount}{' '}
          <Link to="/login" className="fw-700 fg-primary p-5">
            {string.login}
          </Link>
        </p>

        <Link to="https://incorpostudio.com/terms-of-use/" className="fw-700 fg-primary p-5">
          Terms of Use (EULA)
        </Link>
        <Link to="https://incorpostudio.com/privacy-policy/" className="fw-700 fg-primary p-5">
          Privacy Policy
        </Link>
      </form>

      {/* Pre-page on Mobile only */}
      <div
        className={cn(
          'flex-1 flex flex-col container-auto-sm justify-center text-center px-20 py-40',
          showMobileForm ? 'hide' : 'md:hide',
        )}
      >
        <Logo className="h-50 mx-auto" />
        <p className="fs-24 md:fs-28 fw-700 py-30">
          <span className="md:show">{string.title}</span>
          <span className="md:hide">
            {string.titleMobilePre[0]}
            <br />
            {string.titleMobilePre[1]}
          </span>
        </p>
        <div>
          {/* Sign in with Email */}
          <button
            type="button"
            className="fit-w flex align-center h-50 r-40 px-25 my-15 b-1 b-grey-400"
            onClick={handleClickEmail}
          >
            <IconEmail className="w-25 h-25" />
            <span className="fs-14 fw-700 pl-15">{string.withEmail}</span>
          </button>
          {/* Sign in with Apple */}
          <button
            type="button"
            className="fit-w flex align-center h-50 r-40 px-25 my-15 b-1 b-grey-400"
            onClick={() => signInApple(setValue, onSubmit, setShowMobileForm, setShowAccountFields)}
          >
            <IconApple className="w-25 h-25" />
            <span className="fs-14 fw-700 pl-15">{string.withApple}</span>
          </button>
          {/* Sign in with Google */}
          <button
            type="button"
            className="fit-w flex align-center h-50 r-40 px-25 my-15 b-1 b-grey-400"
            onClick={() => signInGoogle(setValue, onSubmit, setShowMobileForm)}
          >
            <IconGoogle className="w-25 h-25" />
            <span className="fs-14 fw-700 pl-15">{string.withGoole}</span>
          </button>
          {/* Sign in with Facebook */}
          <button
            type="button"
            className="fit-w flex align-center h-50 r-40 px-25 my-15 b-1 b-grey-400"
            onClick={() => signInFacebook(setValue, onSubmit, setShowMobileForm)}
          >
            <IconFacebook className="w-25 h-25" />
            <span className="fs-14 fw-700 pl-15">{string.withFacebook}</span>
          </button>
          <p className="fs-14 fw-600 my-25">
            {string.haveAccount}{' '}
            <Link to="/login" className="fw-700 fg-primary p-5">
              {string.login}
            </Link>
          </p>
        </div>
        <p className="fs-10 fg-grey-400">{string.note}</p>
      </div>
      <Modal
        size="lg"
        disableOverflow
        visible={showTerms}
        className="flex flex-col flex-nowrap of-y-auto modal-scrollstyle"
        onClose={handleCloseTerms}
      >
        <div className="prose fs-14 md:fs-16" dangerouslySetInnerHTML={{ __html: terms }} />
        <div className="text-center">
          <Button primary className="fit-w md:w-290" onClick={handleCloseTerms}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}
