import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { FaCircleNotch } from 'react-icons/fa';
import cn from 'classnames';
import { Button, Error, Input } from '../Components/FormElements';
import { IconApple, IconFacebook, IconGoogle } from '../Components/Icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';

const string = {
  title: 'Please login to proceed',
  titleSocialLogin: 'or use login via',
  login: 'Log in',
  noAccount: 'Don’t have an account?',
  signup: 'Sign Up',
  note: 'Note: Incorpo Studio is not a medical device. Do not exercise through pain, and in case of any doubt regarding your physical condition or ability to exercise please consult your medical professional',
  forgotPassword: 'Forgot your password?',
  reset: 'Reset',
};

const handleApiError = (error) => {
  console.log('error', error, error.response)
  switch (error.response.status) {
    case 400:
      return toast.error('Email address not provided.');
    case 401:
      return toast.error('No user found with this email address.');
    default:
      console.log('api error')
      return toast.error('Login failed.');
  }
};

const setUser = (token, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  window.location.href = '/dashboard';
};

const responseGoogle = async ({ access_token }) => {
  if (!access_token) return toast.error('Login failed.');

  const restAuth = await axios.post('/rest-auth/google/', { access_token }).catch(handleApiError);

  if (!restAuth.data?.key) return;

  const getUser = await axios.get('/api/v1/user/user_info/me/', {
    headers: { Authorization: `Token ${restAuth.data.key}` },
  });

  setUser(restAuth.data.key, getUser.data);
};

const responseFacebook = async ({ access_token }) => {
  if (!access_token) return toast.error('Login failed.');

  const restAuth = await axios.post('/rest-auth/facebook/', { access_token }).catch(handleApiError);

  if (!restAuth.data?.key) return;

  const getUser = await axios.get('/api/v1/user/user_info/me/', {
    headers: { Authorization: `Token ${restAuth.data.key}` },
  });

  setUser(restAuth.data.key, getUser.data);
};

const responseApple = async ({ access_token, id_token }) => {
  if (!access_token) {
    console.log('no access token')
    return toast.error('Login failed.');
  }

  const restAuth = await axios
    .post('/rest-auth/apple/', { access_token, id_token })
    .catch(handleApiError);

  if (!restAuth.data?.key) return;

  const getUser = await axios.get('/api/v1/user/user_info/me/', {
    headers: { Authorization: `Token ${restAuth.data.key}` },
  });

  setUser(restAuth.data.key, getUser.data);
};

const signInGoogle = async () => {
  const result = await GoogleAuth.signIn();
  const access_token = result?.authentication?.accessToken || null;
  await responseGoogle({access_token});
};

const signInFacebook = async () => {
  const result = await FacebookLogin.login({ permissions: ['email'] });
  const access_token = result?.accessToken?.token || null;
  await responseFacebook({access_token});
};

const signInApple = async () => {
  const result = await SignInWithApple.authorize({
    clientId: 'com.edemos.incorpostudio',
    redirectURI: window.location.href,
    scopes: 'email name',
    nonce: 'nonce',
  });
  console.log(result?.response)
  const access_token = result?.response?.authorizationCode || null;
  const id_token = result?.response?.identityToken || null;
  await responseApple({access_token, id_token});
};

export default function LoginPage({ onSubmit, register, errors, state }) {
  useEffect(() => {
    GoogleAuth.initialize({
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });

    FacebookLogin.initialize({
      appId: '434775801821661',
    });
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      className="container-auto-sm flex-1 flex flex-col justify-center text-center px-20 py-20 my-auto"
    >
      <Link to="/">
        <Logo className="h-50 mx-auto cursor-pointer" />
      </Link>
      <p className="fs-24 md:fs-28 fw-700 py-30">{string.title}</p>

      <div className="order-1 md:order-0">
        <p className="fs-12 md:fs-14 md:fs-18 fg-grey-400">{string.titleSocialLogin}</p>
        <div className="flex justify-center my-15">
          {/* Sign in with Apple */}
          <button
            type="button"
            className="flex w-50 h-50 md:w-55 md:h-55 r-100 b-1 b-grey-400 mx-10 hover:bg-grey-50 hover:b-grey-700 active:bg-grey-50 active:b-grey-700"
            onClick={() => signInApple()}
          >
            <IconApple className="h-25 m-auto" />
          </button>

          {/* Sign in with Google */}
          <button
            type="button"
            className="flex w-50 h-50 md:w-55 md:h-55 r-100 b-1 b-grey-400 mx-10 hover:bg-grey-50 hover:b-grey-700 active:bg-grey-50 active:b-grey-700"
            onClick={() => signInGoogle()}
          >
            <IconGoogle className="h-25 m-auto" />
          </button>

          {/* Sign in with Facebook */}
          <button
            type="button"
            className="flex w-50 h-50 md:w-55 md:h-55 r-100 b-1 b-grey-400 mx-10 hover:bg-grey-50 hover:b-grey-700 active:bg-grey-50 active:b-grey-700"
            onClick={() => signInFacebook()}
          >
            <IconFacebook className="h-25 m-auto" />
          </button>
        </div>
      </div>

      <div>
        <Input
          type="email"
          placeholder="Email"
          hasError={errors.username}
          {...register('username')}
        />
        {errors.username && <Error text={errors.username.message} />}

        <Input
          type="password"
          placeholder="Password"
          hasError={errors.password}
          {...register('password')}
        />
        {errors.password && <Error text={errors.password.message} />}

        <Button
          primary
          type="submit"
          className={cn('fit-w md:w-550 my-10', state.isLoading && 'disabled')}
        >
          {state.isLoading ? <FaCircleNotch className="w-20 h-20 spin" /> : 'Login'}
        </Button>
      </div>
      <p className="fs-14 md:fs-18 fw-600 my-15 lh-2">
        {string.noAccount}{' '}
        <Link to="/signup" className="fw-700 fg-primary p-5">
          {string.signup}
        </Link>
        <br />
        {string.forgotPassword}{' '}
        <Link to="/reset-password" className="fw-700 fg-primary p-5">
          {string.reset}
        </Link>
        <br />
        <Link to="https://incorpostudio.com/terms-of-use/" className="fw-700 fg-primary p-5">
          Terms of Use (EULA)
        </Link>
        <Link to="https://incorpostudio.com/privacy-policy/" className="fw-700 fg-primary p-5">
          Privacy Policy
        </Link>
      </p>
      <p className="md:show fs-14 fg-grey-400">{string.note}</p>
    </form>
  );
}
