/* eslint-disable react-hooks/exhaustive-deps */
import ResetPasswordPage from '../Pages/ResetPasswordPage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { apiPasswordResetRequest, resetLoginState } from '../Redux/actions/login';
import { useEffect } from 'react';
import { showErrorToastNoti } from '../Utils/errorToast';

const forgetPasswordSchema = yup.object().shape({
  email: yup.string().email('A valid email is required.').required('This field is required.'),
});

const ResetPasswordContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgetPasswordSchema),
  });

  const onSubmit = (data) => {
    dispatch(apiPasswordResetRequest(data));
  };

  useEffect(() => {
    dispatch(resetLoginState());
  }, []);

  useEffect(() => {
    showErrorToastNoti(state);
  }, [state]);

  return (
    <ResetPasswordPage
      onSubmit={handleSubmit(onSubmit)}
      register={register}
      errors={errors}
      state={state}
    />
  );
};

export default ResetPasswordContainer;
